import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency, formatPercentage } from '@homamo/meadow-utils';

import ArrangeCallback from '../ArrangeCallback';
import HeaderInfoBlock from '../../common/HeaderInfoBlock';
import Styles from './Styles';

const Rate = ({
  product,
  rate,
  fee,
  monthly,
  eligibleForSwitch,
  userPhone,
  info,
}) => {
  return (
    <Styles.Rate>
      <div className="inner">
        <HeaderInfoBlock label="Product" value={product} />
        <div className="attributes">
          <HeaderInfoBlock label="Rate" value={formatPercentage(rate, 2)} />
          <HeaderInfoBlock label="Fee" value={formatCurrency(fee)} />
          <HeaderInfoBlock
            label="Monthly"
            value={formatCurrency(monthly, { wholePounds: true })}
          />
        </div>
      </div>
      <ArrangeCallback
        buttonLabel={eligibleForSwitch ? 'Start switch' : 'Find out more'}
        buttonMode={eligibleForSwitch ? 'primary' : 'default'}
        message={info}
        userPhone={userPhone}
        product={`${product} - ${rate}%`}
        context={eligibleForSwitch ? 'rateswitchNow' : 'moreInfo'}
        eligibleForSwitch={eligibleForSwitch}
      />
    </Styles.Rate>
  );
};

Rate.defaultProps = {
  eligibleForSwitch: false,
  userPhone: undefined,
  info: undefined,
};

Rate.propTypes = {
  product: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  fee: PropTypes.number.isRequired,
  monthly: PropTypes.number.isRequired,
  eligibleForSwitch: PropTypes.bool,
  userPhone: PropTypes.string,
  info: PropTypes.string,
};

export default Rate;
