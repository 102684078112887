import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  convertToPence,
  convertToPounds,
  keepParams,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import DateInput from '../../common/DateInput';
import Textarea from '../../common/Textarea';
import CurrencyInput from '../../common/CurrencyInput';
import config from '../../../config';
import RewardTypeSelect from '../RewardTypeSelect';
import RewardStatusSelect from '../RewardStatusSelect';

const validationSchema = Yup.object().shape({
  amount: Yup.string().required('You need to enter an amount'),
  rewardDate: Yup.string().required('You need to enter a reward date'),
  status: Yup.string().required('You must select a status'),
  type: Yup.string().required('You must select a type'),
  description: Yup.string(),
});

const UpdateRewardForm = ({ id, initialData }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`rewards/update/${id}`, {
            date: values.rewardDate,
            amount: convertToPence(values.amount),
            status: values.status,
            type: values.type,
            description: values.description,
          })
            .then((res) => {
              const rewardId = res.data._id;
              toast.success('Reward updated');
              history.push(keepParams(`/admin/reward/${rewardId}`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          amount: initialData.amount
            ? convertToPounds(initialData.amount)
            : convertToPounds(config.rewardDefaults.switch),
          rewardDate: initialData.date || undefined,
          status: initialData.status || 'PENDING',
          type: initialData.type || undefined,
          description: initialData.description || undefined,
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <RewardTypeSelect />
                <RewardStatusSelect />
                <Field
                  label="Amount"
                  id="amount"
                  name="amount"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Reward Date"
                  id="rewardDate"
                  name="rewardDate"
                  component={DateInput}
                  isRequired
                />
                <Field
                  label="Description"
                  id="description"
                  name="description"
                  placeholder="Enter an optional description"
                  component={Textarea}
                />
                <ButtonGroup>
                  <Button
                    label="Update Reward"
                    type="submit"
                    mode="primary"
                    loadingLabel="Creating Reward"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/rewards'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

UpdateRewardForm.propTypes = {
  id: PropTypes.string.isRequired,
  initialData: PropTypes.shape({
    amount: PropTypes.number,
    date: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

UpdateRewardForm.defaultProps = {
  initialData: {},
};

export default UpdateRewardForm;
