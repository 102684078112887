import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessageComponent from './Component';

const ErrorMessage = ({ error }) => {
  if (!error || !error.message) return null;
  return <ErrorMessageComponent error={error} />;
};

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    message: PropTypes.string,
  }),
};

ErrorMessage.defaultProps = {
  error: {},
};

export default ErrorMessage;
