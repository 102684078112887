import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import Styles from './Styles';
import theme from '../../../styles/theme';
import CloseButton from '../CloseButton';

ReactModal.setAppElement('body');
ReactModal.defaultStyles.overlay = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: theme.overlayColor,
  zIndex: 10,
};

ReactModal.defaultStyles.content = {
  position: 'absolute',
  top: '5vh',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginBottom: 'auto',
  maxHeight: '90vh',
  left: '0',
  right: '0',
  border: '0',
  background: '#fff',
  overflow: 'auto',
  WebkitOverflowScrolling: 'touch',
  borderRadius: theme.borderRadius[1],
  outline: 'none',
  padding: 0,
  width: '95%',
  maxWidth: theme.space[15],
  zIndex: 20,
};

const Modal = ({ heading, contentLabel, isOpen, closeAction, children }) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={closeAction}
      closeAction={closeAction}
      contentLabel={contentLabel || heading}
    >
      <Styles.ModalHeader>
        {heading && <h2>{heading}</h2>}
        <CloseButton closeAction={closeAction} />
      </Styles.ModalHeader>
      <Styles.ModalBody>{children}</Styles.ModalBody>
    </ReactModal>
  );
};

Modal.defaultProps = {
  heading: undefined,
  contentLabel: undefined,
  isOpen: false,
};

Modal.propTypes = {
  heading: PropTypes.string,
  isOpen: PropTypes.bool,
  closeAction: PropTypes.func.isRequired,
  contentLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Modal;
