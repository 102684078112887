import axios from 'axios';
import config from '../config';

axios.defaults.timeout = 1000 * 30; // 30 seconds

export default axios.create({
  baseURL: `${config.apiUrl}/api/`,
  withCredentials: true,
});

export const handleApiError = ({ error, setDisplayError, no401Reload }) => {
  if (!error) return;

  // eslint-disable-next-line no-console
  console.error(error);

  if (!error.response || !error.response.status) {
    // NETWORK ERROR
    if (setDisplayError) {
      setDisplayError({
        code: 'Network',
        message:
          'There was an issue connecting to the server. Check your connection and retry.',
      });
    }
    return;
  }

  if (
    !no401Reload &&
    error &&
    error.response &&
    error.response.status === 401
  ) {
    // SIGN USER OUT
    window.location.reload();
  }

  // http status code
  const code = error.response.status;
  // response data
  const response = error.response.data;
  const message = response.errors && response.errors.message;

  if (setDisplayError) setDisplayError({ code, message });
};
