/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import {
  checkUserHasBetaAccess,
  checkUserHasOneOfRoles,
} from '@homamo/meadow-utils';

import { useAuth } from '../../../context/auth';
import Styles from './Styles';

const Nav = ({ navItems, isAuthenticated, onChangeRoute }) => {
  const { user } = useAuth();
  return (
    <Styles.Nav>
      {navItems.map(
        ({
          key,
          label,
          path,
          pathActiveIfIncludes,
          component,
          privateRoute,
          notAuthenticatedRoute,
          adminRoute,
          betaRoute,
          subNavItems,
        }) => {
          if (privateRoute && !isAuthenticated) return null;
          if (notAuthenticatedRoute && isAuthenticated) return null;
          if (
            (betaRoute && !checkUserHasBetaAccess(user)) ||
            (adminRoute && !checkUserHasOneOfRoles(user, ['ADMIN']))
          )
            return null;

          return (
            <Styles.NavItem key={key || label} onClick={onChangeRoute}>
              {component || (
                <NavLink
                  to={path}
                  activeClassName="--is-active"
                  isActive={(match, location) => {
                    return (
                      match || location.pathname.includes(pathActiveIfIncludes)
                    );
                  }}
                  exact
                >
                  {label}
                  {subNavItems && subNavItems.length && (
                    <Styles.SubNav>
                      {subNavItems.map((sub) => {
                        if (sub.privateRoute && !isAuthenticated) return null;
                        if (
                          sub.adminRoute &&
                          !checkUserHasOneOfRoles(user, ['ADMIN'])
                        )
                          return null;
                        return (
                          <Styles.NavItem
                            key={sub.key || sub.label}
                            onClick={onChangeRoute}
                          >
                            <NavLink
                              to={sub.path}
                              activeClassName="--is-active"
                            >
                              {sub.label}
                            </NavLink>
                          </Styles.NavItem>
                        );
                      })}
                    </Styles.SubNav>
                  )}
                </NavLink>
              )}
            </Styles.NavItem>
          );
        },
      )}
    </Styles.Nav>
  );
};

Nav.propTypes = {
  isAuthenticated: PropTypes.bool,
  onChangeRoute: PropTypes.func,
  navItems: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      path: PropTypes.string,
      component: PropTypes.node,
      privateRoute: PropTypes.bool,
      adminRoute: PropTypes.bool,
      pathActiveIfIncludes: PropTypes.string,
      subNavItems: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          label: PropTypes.string,
          path: PropTypes.string,
          component: PropTypes.node,
          privateRoute: PropTypes.bool,
          notAuthenticatedRoute: PropTypes.bool,
          adminRoute: PropTypes.bool,
        }),
      ),
    }),
  ),
};

Nav.defaultProps = {
  isAuthenticated: false,
  onChangeRoute: undefined,
  navItems: [],
};

export default Nav;
