/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles';

const LockedCellComponent = ({ isLocked }) => {
  return (
    <Styles.LockedCell isLocked={isLocked}>
      {isLocked ? (
        <FontAwesomeIcon icon="lock" />
      ) : (
        <FontAwesomeIcon icon="lock-open" />
      )}
    </Styles.LockedCell>
  );
};

LockedCellComponent.propTypes = {
  isLocked: PropTypes.bool,
};

LockedCellComponent.defaultProps = {
  isLocked: false,
};

export default LockedCellComponent;
