import React from 'react';
import PropTypes from 'prop-types';
import { formatUserName } from '@homamo/meadow-utils';

import AdminEditUserForm from './Form';
import AdminEditUserSuspension from '../AdminEditUserSuspension';
import AdminNav from '../../admin/AdminNav';

const AdminEditUserView = ({ data, onUpdateUser }) => {
  return (
    <div>
      <AdminNav />
      <h1>Edit: {formatUserName(data)}</h1>
      <AdminEditUserForm id={data._id} initialData={data} />
      <AdminEditUserSuspension
        userId={data._id}
        user={data}
        onUpdateUser={onUpdateUser}
      />
    </div>
  );
};

AdminEditUserView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  onUpdateUser: PropTypes.func.isRequired,
};

export default AdminEditUserView;
