import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { keepParams } from '@homamo/meadow-utils';
import Error from '../../common/ErrorMessage';

const AdminMortgageNotFoundComponent = ({ id, displayError }) => {
  return (
    <>
      <h1>404. Mortgage not found</h1>
      <p>No mortgage found with ID: {id}</p>
      <p>
        <Link to={keepParams('/admin/mortgages')}>Back to all mortgages</Link>
      </p>
      {displayError && displayError.message && (
        <>
          <p>
            <small>
              The following information may be useful for technical support:
            </small>
          </p>
          <Error error={displayError} />
        </>
      )}
    </>
  );
};

AdminMortgageNotFoundComponent.propTypes = {
  id: PropTypes.string.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

AdminMortgageNotFoundComponent.defaultProps = {
  displayError: {},
};

export default AdminMortgageNotFoundComponent;
