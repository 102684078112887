import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminRates from '../../../components/rate/AdminRates';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminRatesView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Rates">
        <AdminRates />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminRatesView;
