import styled from 'styled-components';
import { Styles as BadgeStyles } from '../../common/Badge';
import { Styles as NavStyles } from '../../common/Nav';

const Logo = styled.img`
  width: ${({ theme }) => theme.space[9]};

  @media ${({ theme }) => theme.device.tablet} {
    width: ${({ theme }) => theme.space[10]};
  }
`;

const LargeHeader = styled.header`
  display: none;

  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${({ theme }) => theme.color.white};
    padding: ${({ theme }) => `${theme.space[4]} ${theme.space[4]}`};

    a {
      display: flex;
      align-items: center;
      height: 100%;
    }

    ${NavStyles.Nav} {
      display: flex;
      justify-content: space-between;
      padding: 0;
      text-align: center;
      color: ${({ theme }) => theme.color['gray-800']};
      list-style: none;

      ${NavStyles.NavItem} {
        font-size: ${({ theme }) => theme.fontSize[2]};
        @media ${({ theme }) => theme.device.laptop} {
          font-size: ${({ theme }) => theme.fontSize[3]};
        }

        a {
          padding: 0;
          margin: ${({ theme }) => `0 ${theme.space[2]} -2px`};
          overflow: hidden;
          border-bottom: 2px solid ${({ theme }) => theme.color.white};
          text-decoration: none;

          @media ${({ theme }) => theme.device.tablet} {
            margin: ${({ theme }) => `0 ${theme[1]} -2px`};
          }

          @media ${({ theme }) => theme.device.laptop} {
            margin: ${({ theme }) => `0 ${theme[2]} -2px`};
          }

          &:hover {
            text-decoration: none;
            border-bottom: 2px solid ${({ theme }) => theme.color['gray-400']};
          }
        }

        a.--is-active {
          color: ${({ theme }) => theme.color['blue-900']};
          border-bottom: 2px solid ${({ theme }) => theme.color['orange-400']};
          font-weight: 600;
        }
      }

      ${BadgeStyles.Badge} {
        margin-left: ${({ theme }) => theme.space[0]};
      }
    }
  }
`;

const MobileHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};

  a {
    display: flex;
    align-items: center;
    height: 100%;
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: none;
  }
`;

const MenuButton = styled.button`
  ${({ hide }) => hide && 'display: none;'}
  padding: ${({ theme }) => theme.space[1]};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  border: 1px solid ${({ theme }) => theme.color['gray-300']};
  background-color: ${({ theme }) => theme.color.white};
  font-size: ${({ theme }) => theme.fontSize[1]};
  font-family: ${({ theme }) => theme.fontFamily};
  cursor: pointer;

  :disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color['gray-300']};
    border: 1px solid ${({ theme }) => theme.color['gray-300']};
  }
`;

export default {
  LargeHeader,
  MobileHeader,
  MenuButton,
  Logo,
};
