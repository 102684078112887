import React from 'react';
// import { Link } from 'react-router-dom';
import Styles from './Styles';
import config from '../../../config';
import TrustBox from '../../common/TrustBox';
import MarketingSiteLink from '../../common/MarketingSiteLink';

import appStore from '../../../images/app_store_black.svg';

const Footer = () => (
  <Styles.Footer>
    <Styles.FooterInner>
      <div className="footer-logo-links">
        <TrustBox />
        <a
          href="https://apps.apple.com/gb/app/accelerate-my-mortgage/id1545659732"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={appStore}
            className="app-store-logo"
            alt="Download on the App Store"
          />
        </a>
      </div>
      <ul>
        <li>
          <MarketingSiteLink path="/help" label="How it works" />
        </li>
        <li>
          <MarketingSiteLink path="/faqs" label="FAQs" />
        </li>
        <li>
          <a href={config.termsUrl} target="_blank" rel="noreferrer noopener">
            Terms &amp; Conditions
          </a>
        </li>
        <li>
          <a href={config.privacyUrl} target="_blank" rel="noreferrer noopener">
            Privacy Policy
          </a>
        </li>
      </ul>

      <p>
        <small>
          Accelerate My Mortgage is a trading style of Rateswitch Ltd.
        </small>
      </p>
      <p>
        <small>
          Rateswitch Ltd is an appointed representative of Stonebridge Mortgage
          Solutions Limited, which is authorised and regulated by the Financial
          Conduct Authority. Registered office: Rateswitch Ltd, Sophia House, 28
          Cathedral Road, Cardiff, CF11 9LJ. Registered company number:
          10100327. Registered in England and Wales. Risk warning: Your home may
          be repossessed if you do not keep up repayments on your mortgage.
        </small>
      </p>
      <p>
        <small>
          Accelerate My Mortgage {config.version} - &copy; Rateswitch Ltd{' '}
          {new Date().getFullYear()} - Software design &amp; development by{' '}
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://homamo.co" target="_blank">
            Homamo
          </a>
        </small>
      </p>
    </Styles.FooterInner>
  </Styles.Footer>
);

export default Footer;
