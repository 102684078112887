import styled from 'styled-components';

const HeaderInfoBlock = styled.div`
  margin-bottom: 4rem;
  dt {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    letter-spacing: 0.5px;
    line-height: 1.3rem;
    display: block;
    font-weight: 600;
    text-transform: uppercase;
    color: ${({ theme }) => theme.color['blue-500']};

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: 3rem;
      font-size: 1.4rem;
    }
  }

  dd {
    margin: 0rem;
    font-size: 3rem;
    line-height: 1;
    font-weight: 600;
    color: ${({ theme }) => theme.color['blue-700']};
    font-size: 2rem;

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: ${({ big }) => (big ? '6rem' : '3rem')};
    }
  }

  span {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.color['gray-600']};
    line-height: 1.1;
    display: block;
    margin-top: 1rem;

    a {
      text-decoration: underline;
    }
  }
`;

export default { HeaderInfoBlock };
