import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import AdminUser from '../../../components/user/AdminUser';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminUserView = () => {
  const { id } = useParams();

  return (
    <AnalyticsWrapper>
      <Layout title="User">
        <AdminUser id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminUserView;
