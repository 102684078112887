import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import AdminDeleteUser from '../../../components/user/AdminDeleteUser';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminDeleteUserView = () => {
  const { id } = useParams();
  return (
    <AnalyticsWrapper>
      <Layout title="Admin - Delete user">
        <AdminDeleteUser id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminDeleteUserView;
