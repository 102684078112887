// Box shadows increasing in perceived elevation

export default [
  '0 0px 0px hsla(199, 31%, 32%, 0.2)', // boxShadow[0]
  '0 1px 3px hsla(199, 31%, 32%, 0.2)', // boxShadow[1]
  '0 4px 6px hsla(199, 31%, 32%, 0.2)', // boxShadow[2]
  '0 5px 15px hsla(199, 31%, 32%, 0.2)', // boxShadow[3]
  '0 10px 24px hsla(199, 31%, 32%, 0.2)', // boxShadow[4]
  '0 15px 35px hsla(199, 31%, 32%, 0.2)', // boxShadow[5]
];
