import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NotFound = ({ pathname }) => {
  return (
    <div>
      <h1>404. Page not found</h1>
      <p>Unfortunately the page &apos;{pathname}&apos; doesn&apos;t exist.</p>
      <p>
        Double check the URL or <Link to="/">return to your dashboard</Link>
      </p>
    </div>
  );
};

NotFound.propTypes = {
  pathname: PropTypes.string.isRequired,
};

export default NotFound;
