import React from 'react';
import PropTypes from 'prop-types';

import Styles from './Styles';
import Footer from '../Footer';
import Header from '../Header';
import SEO from '../../common/SEO';
import config from '../../../config';

const Layout = ({ hideHeader, children, title }) => {
  return (
    <div>
      {config.isStaging && (
        <Styles.StagingWarning>STAGING ENVIRONMENT</Styles.StagingWarning>
      )}
      {config.isMaintenanceMode && (
        <Styles.MaintenanceWarning>
          Our service is currently under maintenance and temporarily unavailable
        </Styles.MaintenanceWarning>
      )}
      <SEO title={title} />
      {!hideHeader && <Header />}
      <Styles.Content>{children}</Styles.Content>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  hideHeader: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  title: PropTypes.string,
};

Layout.defaultProps = {
  hideHeader: false,
  children: [],
  title: undefined,
};

export default Layout;
