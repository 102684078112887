import React from 'react';
import PropTypes from 'prop-types';
import { formatUserName } from '@homamo/meadow-utils';

import AdminDeleteUserForm from './Form';
import Message from '../../common/Message';
import AdminNav from '../../admin/AdminNav';

const AdminDeleteUserView = ({ data }) => {
  return (
    <div>
      <AdminNav />
      <h1>Delete User</h1>
      <p>
        Confirm you want to delete {formatUserName(data)}&apos;s account by
        entering their registered email address - {data.email}
      </p>
      <Message mode="danger" icon="exclamation-triangle">
        This is final. The user, their mortgage, all associated transactions and
        rewards will all be permanently deleted.
      </Message>
      <AdminDeleteUserForm id={data._id} email={data.email} />
    </div>
  );
};

AdminDeleteUserView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    email: PropTypes.string,
    profile: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }).isRequired,
};

export default AdminDeleteUserView;
