import styled from 'styled-components';

const NoRates = styled.div`
  height: 100%;
  padding: 6rem 0;
  display: grid;
  grid-gap: 2rem;
  justify-content: center;
  align-items: center;

  img {
    max-width: 300px;
    display: block;
    margin: 0 auto;
  }
`;

export default {
  NoRates,
};
