import React from 'react';
import PropTypes from 'prop-types';
import {
  formatCurrency,
  formatDateTime,
  formatUserName,
  keepParams,
} from '@homamo/meadow-utils';

import Definition, { DefinitionList } from '../../common/Definition';
import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Panel, { PanelColumn } from '../../common/Panel';
import AdminNav from '../../admin/AdminNav';
import Message from '../../common/Message';

const TransactionView = ({ data, displayError, mode }) => {
  const termWidth = '200px';
  const isAdminMode = mode === 'admin';

  return (
    <div>
      {isAdminMode && <AdminNav />}
      <h1>
        {isAdminMode ? 'Payment' : 'Transaction'}: {data._id}
      </h1>
      <ButtonGroup>
        <Button
          size="s"
          label={isAdminMode ? 'View all payments' : 'View transaction history'}
          icon="arrow-left"
          linkTo={isAdminMode ? keepParams('/admin/payments') : '/history'}
        />
        <Button
          size="s"
          label="Edit"
          linkTo={
            isAdminMode
              ? `/admin/payment/${data._id}/edit`
              : `/transaction/${data._id}/edit`
          }
        />
      </ButtonGroup>
      <Error error={displayError} />
      <Message mode="warning" size="l">
        {isAdminMode
          ? `This is just a log of the payment. The actual transfer needs to be made
        manually. This payment will affect the user&apos;s displayed balance.`
          : `This is just a log of the transaction. The actual transfer needs to be made
        manually. This record will affect the your displayed balance.`}
      </Message>
      <PanelColumn>
        <Panel heading="Details">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Amount"
              definition={formatCurrency(data.amount)}
            />
            <Definition term="Type" definition={data.type} />

            <Definition
              term="Date"
              definition={formatDateTime(data.date, 'date')}
            />
          </DefinitionList>
        </Panel>
        <Panel heading="Payee">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Owner"
              definition={formatUserName(data.owner)}
              linkTo={`/admin/user/${data.owner_id}`}
            />
            <Definition
              term="Owner email"
              definition={data.owner && data.owner.email}
            />
            <Definition term="Owner ID" definition={data.owner_id} />
            <Definition term="Mortgage ID" definition={data.mortgage_id} />
          </DefinitionList>
        </Panel>
        {isAdminMode && (
          <Panel heading="Meta">
            <DefinitionList termWidth={termWidth}>
              <Definition term="ID" definition={data._id} />
              <Definition
                term="Created source"
                definition={data.createdSource}
              />
              <Definition
                term="Created by"
                definition={formatUserName(data.createdByUser)}
                linkTo={`/admin/user/${data.createdByUser_id}`}
              />
              <Definition
                term="Created"
                definition={formatDateTime(data.createdAt)}
              />
              <Definition
                term="Updated"
                definition={formatDateTime(data.updatedAt)}
              />
            </DefinitionList>
          </Panel>
        )}
      </PanelColumn>
    </div>
  );
};

TransactionView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    amount: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string,
    mortgage_id: PropTypes.string,
    owner_id: PropTypes.string,
    owner: PropTypes.shape({
      _id: PropTypes.string,
      email: PropTypes.string,
    }),
    createdByUser_id: PropTypes.string,
    createdByUser: PropTypes.shape({
      _id: PropTypes.string,
      email: PropTypes.string,
    }),
    createdSource: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
  mode: PropTypes.string,
};

TransactionView.defaultProps = {
  displayError: undefined,
  mode: 'default',
};

export default TransactionView;
