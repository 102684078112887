import styled from 'styled-components';
import Panel from '../../common/Panel';
import { Styles as DashboardEligibilityStyles } from '../DashboardEligibility';
import { Styles as ButtonStyles } from '../../common/Button';

const DashboardBlock = styled(Panel)`
  max-width: ${({ theme }) => theme.space[13]};
  margin: 0 auto;

  .body {
    height: calc(100% - 4rem - 4px);
  }

  .inner {
    display: grid;
    grid-gap: ${({ theme }) => theme.space[3]};
    height: 100%;
    grid-auto-rows: 150px auto 1fr auto;
    align-items: flex-start;

    @media ${({ theme }) => theme.device.tablet} {
      grid-auto-rows: 200px auto 1fr auto;
    }
  }

  p {
    margin: 0 auto;
    color: ${({ theme }) => theme.color['gray-700']};
    font-size: 1.6rem;
    padding-bottom: ${({ theme }) => theme.space[2]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 1.8rem;
    }

    @media ${({ theme }) => theme.device.laptop} {
      height: ${({ theme }) => theme.space[9]};
    }
  }

  ${DashboardEligibilityStyles.DashboardEligibility} {
    margin: 1rem auto;
  }

  img {
    max-width: 150px;
    height: 100%;
    margin: 2rem auto;
    padding-bottom: 2rem;
    display: block;

    @media ${({ theme }) => theme.device.tablet} {
      max-width: 220px;
    }
  }

  ${ButtonStyles.Button} {
    margin-top: ${({ theme }) => theme.space[2]};
    align-self: flex-end;
  }
`;

export default {
  DashboardBlock,
};
