import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';

import Button, { ButtonGroup } from '../Button';
import TextInput from '../TextInput';
import Form from '../Form';

const ConfirmModalForm = ({
  confirmAction,
  cancelAction,
  confirmButtonLabel,
  confirmButtonLoadingLabel,
  confirmButtonMode,
  cancelButtonLabel,
  confirmTextValue,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          confirmText: '',
        }}
        validationSchema={Yup.object().shape({
          confirmText: confirmTextValue
            ? Yup.string()
                .required(`You must enter '${confirmTextValue}' to confirm`)
                .test(
                  'confirm-text',
                  `Entered text doesn't match '${confirmTextValue}'`,
                  (value) => value === confirmTextValue,
                )
            : Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          confirmAction();
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, setSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                {confirmTextValue && (
                  <Field
                    label={`Enter '${confirmTextValue}' to confirm`}
                    id="confirmText"
                    type="text"
                    name="confirmText"
                    placeholder={confirmTextValue}
                    component={TextInput}
                    isRequired
                  />
                )}
                <ButtonGroup>
                  <Button
                    label={confirmButtonLabel}
                    type="submit"
                    mode={confirmButtonMode}
                    loadingLabel={confirmButtonLoadingLabel}
                    isLoading={isSubmitting}
                  />
                  {cancelAction && (
                    <Button
                      label={cancelButtonLabel}
                      onClick={() => {
                        cancelAction();
                        setSubmitting(false);
                      }}
                    />
                  )}
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

ConfirmModalForm.defaultProps = {
  confirmAction: undefined,
  cancelAction: undefined,
  confirmButtonLabel: 'Confirm',
  confirmButtonLoadingLabel: 'Confirming',
  cancelButtonLabel: 'Cancel',
  confirmTextValue: undefined,
  confirmButtonMode: 'primary',
};

ConfirmModalForm.propTypes = {
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  confirmButtonLabel: PropTypes.string,
  confirmButtonLoadingLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  confirmTextValue: PropTypes.string,
  confirmButtonMode: PropTypes.string,
};

export default ConfirmModalForm;
