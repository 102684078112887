import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles';

const Loading = () => (
  <Styles.Loading>
    <FontAwesomeIcon icon="spinner" spin />
  </Styles.Loading>
);

export default Loading;
