import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Modal from '../../common/Modal';
import Message from '../../common/Message';
import LogTransactionForm from './Form';

const LogTransactionModal = ({
  buttonLabel,
  buttonMode,
  buttonSize,
  buttonBlock,
  message,
  blockParentForm,
  unblockParentForm,
  amount,
  mortgageId,
  successAction,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    if (blockParentForm) unblockParentForm();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    if (unblockParentForm) unblockParentForm();
  };

  return (
    <>
      <Button
        size={buttonSize}
        mode={buttonMode}
        label={
          buttonLabel ||
          (amount
            ? `Log a ${formatCurrency(amount)} overpayment`
            : 'Log a lump sum overpayment')
        }
        block={buttonBlock}
        onClick={() => openModal()}
      />
      <Modal
        heading="Log a lump sum overpayment"
        isOpen={modalIsOpen}
        closeAction={() => closeModal()}
        contentLabel="Log an overpayment"
      >
        <p>
          This is just a log of the payment. The actual transfer needs to be
          made manually. This payment will affect your displayed balance.
        </p>
        <LogTransactionForm
          mortgageId={mortgageId}
          amount={amount}
          successAction={() => {
            if (successAction) successAction();
            closeModal();
          }}
          cancelAction={closeModal}
        />
        <br />
        {message && <Message>{message}</Message>}
      </Modal>
    </>
  );
};

LogTransactionModal.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonMode: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonBlock: PropTypes.bool,
  blockParentForm: PropTypes.func,
  unblockParentForm: PropTypes.func,
  message: PropTypes.string,
  amount: PropTypes.number,
  successAction: PropTypes.func,
};

LogTransactionModal.defaultProps = {
  buttonLabel: 'Log a lump sum overpayment',
  buttonMode: 'default',
  buttonSize: 'm',
  buttonBlock: false,
  blockParentForm: undefined,
  unblockParentForm: undefined,
  message: undefined,
  amount: undefined,
  successAction: undefined,
};

export default LogTransactionModal;
