import React from 'react';
import Layout from '../components/layout/Layout';
import MyMortgage from '../components/mortgage/MyMortgage';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const MyMortgageView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="My Mortgage">
        <MyMortgage />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default MyMortgageView;
