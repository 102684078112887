/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../../common/Panel';
import RewardsInfoModal from '../../reward/RewardsInfoModal';
import Styles from './Styles';

const RetailersListItem = ({ heading, description, link, image }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  return (
    <>
      <RewardsInfoModal
        retailerName={heading}
        isOpen={modalIsOpen}
        closeAction={() => setModalIsOpen(false)}
      />
      <Styles.RetailersListItem>
        <Panel heading={heading} smallHeader>
          <div className="inner">
            <a onClick={() => setModalIsOpen(true)} href={link} target="_blank">
              <img src={image} alt={`${heading} logo`} width="100%" />
            </a>
            <p>{description}</p>
            <a onClick={() => setModalIsOpen(true)} href={link} target="_blank">
              View website
            </a>
          </div>
        </Panel>
      </Styles.RetailersListItem>
    </>
  );
};

RetailersListItem.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
  link: PropTypes.string.isRequired,
};

RetailersListItem.defaultProps = {
  image: undefined,
};

export default RetailersListItem;
