import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminCreateUser from '../../../components/user/AdminCreateUser';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminCreateUserView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Admin - Create user">
        <AdminCreateUser />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminCreateUserView;
