import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import API, { handleApiError } from '../../../lib/api';
import EditRateView from './View';
import RateNotFound from '../RateNotFound';

import Loading from '../../common/Loading';

function EditRateContainer({ id }) {
  const rateId = id;
  // Data state to store the users data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [displayError, setDisplayError] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    await API.get(`rates/${rateId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!rateId) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateId]);

  if (isLoading) return <Loading />;
  return data && data._id ? (
    <EditRateView data={data} refetchData={fetchData} />
  ) : (
    <RateNotFound id={rateId} displayError={displayError} />
  );
}

EditRateContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditRateContainer;
