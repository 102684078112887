/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Styles as InputStyles } from '../Input';
import InputLabel from '../InputLabel';
import Styles from './Styles';

const Textarea = ({
  label,
  isRequired,
  field: { name, disabled, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const error = errors[name];
  const touch = touched[name];
  return (
    <Styles.Textarea>
      <InputLabel
        label={label}
        htmlFor={name}
        error={error}
        disabled={disabled}
        isRequired={isRequired}
      />
      <Styles.TextareaInput
        id={name}
        hasError={error && touch}
        rows={6}
        {...field}
        {...props}
      />
      {touch && error && (
        <InputStyles.InputError>{error}</InputStyles.InputError>
      )}
    </Styles.Textarea>
  );
};

Textarea.defaultProps = {
  isRequired: false,
};

Textarea.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
};

export default Textarea;
