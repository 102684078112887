import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import EditLender from '../../../components/lender/EditLender';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const EditLenderView = () => {
  const { id } = useParams();
  return (
    <AnalyticsWrapper>
      <Layout title="Edit lender">
        <EditLender id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default EditLenderView;
