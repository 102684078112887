import React from 'react';
import Layout from '../components/layout/Layout';
import RewardHistory from '../components/reward/RewardHistory';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const RewardHistoryView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Reward History">
        <RewardHistory />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default RewardHistoryView;
