import React, { createContext, useState, useEffect, useContext } from 'react';

import PropTypes from 'prop-types';
import API, { handleApiError } from '../lib/api';

export const AuthContext = createContext(null);

const initialAuthData = { isAuthenticated: false };

const AuthContextProvider = ({ setDisplayError, ...props }) => {
  const [authData, setAuthData] = useState(initialAuthData);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  const fetchUserData = async () => {
    const res = await API.get('users/me').catch((error) => {
      handleApiError({ error, setDisplayError, no401Reload: true });
      setIsCheckingAuth(false);
    });

    const user = res && res.data;
    if (user && user._id) {
      setIsCheckingAuth(false);
      setAuthData({ isAuthenticated: true, user });
    }
  };

  /* The first time the component is rendered, it tries to
   * fetch the auth data using the cookie token
   */
  useEffect(() => {
    fetchUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSignOut = () => setAuthData(initialAuthData);

  const onSignIn = ({ isAuthenticated, user }) => {
    setAuthData({ isAuthenticated, user });
  };

  const onUpdateUser = (newAuthData) =>
    setAuthData({ isAuthenticated: true, ...newAuthData });

  const authDataValue = {
    onSignIn,
    onSignOut,
    onUpdateUser,
    isCheckingAuth,
    fetchUserData,
    ...authData,
  };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AuthContext.Provider value={authDataValue} {...props} />;
};

export const useAuth = () => useContext(AuthContext);

AuthContextProvider.propTypes = {
  setDisplayError: PropTypes.func,
};

AuthContextProvider.defaultProps = {
  setDisplayError: undefined,
};

export default AuthContextProvider;
