import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import TabsNav from '../TabsNav';
import Styles from './Styles';

const TabsComponent = ({ items }) => {
  return (
    <Styles.TabsContainer>
      <TabsNav items={items} />
      <Styles.TabsView>
        <Switch>
          {items.map(({ path, component }) => (
            <Route key={path} path={path} exact component={() => component} />
          ))}
        </Switch>
      </Styles.TabsView>
    </Styles.TabsContainer>
  );
};

TabsComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.shape,
      label: PropTypes.string,
      component: PropTypes.node,
    }),
  ).isRequired,
};

export default TabsComponent;
