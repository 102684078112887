import styled from 'styled-components';

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;
  border-radius: 1rem;
  max-width: calc(100vw - 4rem);
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 1.3rem;
  line-height: 1.3;

  @media ${({ theme }) => theme.device.mobileL} {
    font-size: 1.6rem;
    line-height: 1.6;
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.8rem;
    line-height: 1.8;
  }

  th[scope='col'] {
    text-transform: uppercase;
    text-align: left;
    font-size: 1rem;
    background-color: ${({ theme }) => theme.color['blue-500']};
    color: ${({ theme }) => theme.color['gray-100']};

    @media ${({ theme }) => theme.device.mobileL} {
      font-size: 1.3rem;
    }

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 1.6rem;
    }
  }

  tr > th {
    text-align: left;
  }

  th,
  td {
    padding: 1rem;

    @media ${({ theme }) => theme.device.mobileL} {
      padding: 1rem 2rem;
    }
  }
`;

const Details = styled.tbody`
  background-color: ${({ theme }) => theme.color['gray-400']};
`;

const Balances = styled.tbody``;

const Row = styled.tr`
  svg {
    margin-left: 1rem;
    color: ${({ theme }) => theme.color['orange-700']};
  }

  background-color: ${({ theme, freedomHighlight }) =>
    freedomHighlight ? theme.color['orange-300'] : theme.color.white};

  :nth-child(even) {
    background-color: ${({ theme, freedomHighlight }) =>
      freedomHighlight ? theme.color['orange-300'] : theme.color['gray-200']};
  }
`;

export default {
  TableWrapper,
  Table,
  Details,
  Balances,
  Row,
};
