import React from 'react';
import PropTypes from 'prop-types';
import MortgageSettingsRateForm from './Form';

const MortgageSettingsRateView = ({ data, refetchData }) => {
  return (
    <div>
      <MortgageSettingsRateForm initialData={data} refetchData={refetchData} />
    </div>
  );
};

MortgageSettingsRateView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

MortgageSettingsRateView.defaultProps = {
  data: {},
};

export default MortgageSettingsRateView;
