/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  formatCurrency,
  formatDateTime,
  keepParams,
} from '@homamo/meadow-utils';

import Button from '../../common/Button';
import LockedCell from '../../common/LockedCell';
import Table, { TableButtonGroup } from '../../common/Table';

const AdminPaymentsTableComponent = ({
  data,
  isLoading,
  pageCount,
  fetchData,
  totalCount,
  initialSortBy,
  filterOptions,
  isCompact,
}) => {
  const columns = useMemo(
    /* eslint-disable react/prop-types */
    () => [
      {
        Header: () => <FontAwesomeIcon icon="lock" />,
        accessor: 'locked',
        id: 'locked',
        resizable: false,
        width: 50,
        disableSortBy: true,
        Cell: ({ value }) => <LockedCell isLocked={value} />,
      },
      {
        Header: 'Payee Email',
        accessor: ({ owner }) => (owner && owner.email) || 'User not found',
        sortType: 'basic',
        id: 'payee',
        width: 200,
      },
      {
        Header: 'Payee ID',
        accessor: ({ owner_id }) => owner_id || 'User not found',
        sortType: 'basic',
        id: 'payeeId',
        width: 200,
      },
      {
        Header: 'Date',
        accessor: ({ date }) => formatDateTime(date, 'date'),
        sortType: 'datetime',
        id: 'date',
        width: 180,
      },
      {
        Header: 'Amount',
        accessor: ({ amount }) => formatCurrency(amount),
        sortType: 'basic',
        id: 'amount',
      },
      {
        Header: 'Actions',
        accessor: '_id',
        disableSortBy: true,
        width: 100,
        sticky: 'right',
        Cell: ({ cell: { value } }) => (
          <TableButtonGroup>
            <Button
              label="View"
              size="s"
              linkTo={keepParams(`/admin/payment/${value}`)}
            />
            <Button
              label="Edit"
              size="s"
              linkTo={keepParams(`/admin/payment/${value}/edit`)}
            />
          </TableButtonGroup>
        ),
      },
    ],
    [], // Second array to allow memo
    /* eslint-enable react/prop-types */
  );

  return (
    <>
      <Table
        name="admin-payments"
        columns={columns}
        data={data}
        fetchData={fetchData}
        isLoading={isLoading}
        pageCount={pageCount}
        totalCount={totalCount}
        blankState="No transactions found"
        initialSortBy={initialSortBy}
        filterOptions={filterOptions}
        isCompact={isCompact}
        searchPlaceholder="Search for an owner ID"
        enableSearch
      />
    </>
  );
};

AdminPaymentsTableComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ).isRequired,
  isCompact: PropTypes.bool,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

AdminPaymentsTableComponent.defaultProps = {
  data: [],
  isLoading: false,
  pageCount: 0,
  totalCount: 0,
  isCompact: false,
  filterOptions: [],
};

export default AdminPaymentsTableComponent;
