import styled from 'styled-components';
import { Styles as ButtonStyles } from '../Button';

const Message = styled.div`
  display: flex;
  justify-content: ${({ center }) => (center ? 'space-around' : 'flex-start')};
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  text-align: ${({ center }) => (center ? 'center' : 'left')};

  padding: ${({ size, theme }) =>
    size === 'l'
      ? `${theme.space[4]} ${theme.space[3]}`
      : `${theme.space[1]} ${theme.space[2]}`};

  font-size: ${({ size, theme }) => {
    if (size === 's') return theme.fontSize[1];
    if (size === 'l') return theme.fontSize[3];
    return theme.fontSize[2];
  }};

  border-radius: ${({ theme }) => theme.borderRadius[1]};
  margin-bottom: ${({ theme }) => theme.space[3]};

  background-color: ${({ theme, mode }) => {
    if (mode === 'warning') return theme.color['yellow-100'];
    if (mode === 'danger') return theme.color['red-100'];
    return theme.color['gray-300'];
  }};

  color: ${({ theme, mode }) => {
    if (mode === 'warning') return theme.color['yellow-800'];
    if (mode === 'danger') return theme.color['red-500'];
    return theme.color['blue-700'];
  }};

  .inner {
    a {
      word-break: ${({ wrapLinks }) =>
        wrapLinks ? 'break-all' : 'break-word'};
    }

    width: 100%;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    display: block;
  }

  .inner > :first-child {
    margin-top: 0;
  }

  .inner > :last-child {
    margin-bottom: 0;
  }

  svg {
    margin-right: ${({ theme }) => theme.space[2]};
    height: ${({ theme }) => theme.fontSize[3]};
    width: ${({ theme }) => theme.fontSize[3]};
    color: ${({ theme, mode }) => {
      if (mode === 'warning') return theme.color['yellow-800'];
      if (mode === 'danger') return theme.color['red-500'];
      return theme.color['gray-600'];
    }};
  }

  ${ButtonStyles.Button} {
    background-color: ${({ theme }) => theme.color.white};
    border-color: ${({ theme }) => theme.color.white};
    color: ${({ theme }) => theme.color['gray-800']};

    &:hover {
      background-color: ${({ theme }) => theme.color['orange-400']};
      border-color: ${({ theme }) => theme.color['orange-400']};
      color: ${({ theme }) => theme.color['gray-800']};
    }
  }

  a {
    text-decoration: underline;
    color: ${({ theme, mode }) => {
      if (mode === 'warning') return theme.color['yellow-800'];
      if (mode === 'danger') return theme.color['red-500'];
      return theme.color['blue-700'];
    }};
  }

  a:hover {
    opacity: 0.8;
  }

  p {
    max-width: ${({ center, theme }) => (center ? theme.space[16] : 'none')};
    margin: ${({ center }) => (center ? '0 auto' : 'auto')};
  }
`;

export default {
  Message,
};
