import React from 'react';
import PropTypes from 'prop-types';
import {
  formatCurrency,
  formatDateTime,
  formatUserName,
  keepParams,
} from '@homamo/meadow-utils';

import Definition, { DefinitionList } from '../../common/Definition';
import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Panel, { PanelColumn } from '../../common/Panel';
import AdminNav from '../../admin/AdminNav';

const RewardView = ({ data, displayError }) => {
  const termWidth = '200px';

  return (
    <div>
      <AdminNav />
      <h1>Reward: {data._id}</h1>
      <ButtonGroup>
        <Button
          size="s"
          label="View all rewards"
          icon="arrow-left"
          linkTo={keepParams('/admin/rewards')}
        />
        <Button
          size="s"
          label="Edit"
          linkTo={keepParams(`/admin/reward/${data._id}/edit`)}
        />
      </ButtonGroup>
      <Error error={displayError} />
      <PanelColumn>
        <Panel heading="Details">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Amount"
              definition={formatCurrency(data.amount)}
            />
            <Definition term="Type" definition={data.type} />
            <Definition term="Status" definition={data.status} />
            <Definition
              term="Date"
              definition={formatDateTime(data.date, 'date')}
            />
            <Definition
              term="Retailer"
              definition={
                (data.retailer && data.retailer.heading) ||
                data.retailer_id ||
                '-'
              }
              linkTo={data.retailer_id && `/admin/retailer/${data.retailer_id}`}
            />
            <Definition term="Advertiser ID" definition={data.advertiserId} />
            <Definition term="Description" definition={data.description} />
          </DefinitionList>
        </Panel>
        <Panel heading="Payee">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Owner"
              definition={formatUserName(data.owner)}
              linkTo={data.owner_id && `/admin/user/${data.owner_id}`}
            />
            <Definition
              term="Owner email"
              definition={data.owner && data.owner.email}
            />
            <Definition term="Owner ID" definition={data.owner_id} />
          </DefinitionList>
        </Panel>
        <Panel heading="Meta">
          <DefinitionList termWidth={termWidth}>
            <Definition term="ID" definition={data._id} />
            <Definition term="Created source" definition={data.createdSource} />
            <Definition
              term="Created by"
              definition={formatUserName(data.createdByUser)}
              linkTo={
                data.createdByUser_id && `/admin/user/${data.createdByUser_id}`
              }
            />
            <Definition
              term="Created"
              definition={formatDateTime(data.createdAt)}
            />
            <Definition
              term="Updated"
              definition={formatDateTime(data.updatedAt)}
            />
          </DefinitionList>
        </Panel>
      </PanelColumn>
    </div>
  );
};

RewardView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    amount: PropTypes.number,
    type: PropTypes.string,
    date: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,
    owner_id: PropTypes.string,
    owner: PropTypes.shape({
      _id: PropTypes.string,
      email: PropTypes.string,
    }),
    createdByUser_id: PropTypes.string,
    createdByUser: PropTypes.shape({
      _id: PropTypes.string,
      email: PropTypes.string,
    }),
    createdSource: PropTypes.string,
    advertiserId: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    retailer_id: PropTypes.string,
    retailer: PropTypes.shape({
      heading: PropTypes.string,
    }),
  }).isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

RewardView.defaultProps = {
  displayError: undefined,
};

export default RewardView;
