import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import API, { handleApiError } from '../../../lib/api';
import ReferralView from './View';

import Loading from '../../common/Loading';
import ErrorMessage from '../../common/ErrorMessage';

function ReferralContainer({ userId }) {
  // Data state to store the users data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [displayError, setDisplayError] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    await API.get(`users/public/${userId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!userId) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (isLoading) return <Loading />;
  return (
    <>
      <ErrorMessage error={displayError} />
      <ReferralView
        referredByName={data && data.firstName}
        referredByUserId={data && data._id}
        isLive={data && data.firstName}
      />
    </>
  );
}

ReferralContainer.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ReferralContainer;
