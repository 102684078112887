import React from 'react';
import PropTypes from 'prop-types';
import Button, { ButtonGroup } from '../../../Button';
import SelectInput from '../../../SelectInput';
import Styles from './Styles';

const FilterComponent = ({
  options,
  selectedFilter,
  setSelectedFilter,
  mobileSelect,
}) => {
  return (
    <>
      <Styles.SmallVersionWrapper mobileSelect={mobileSelect}>
        <SelectInput
          // label="Filter"
          placeholder="Select a filter"
          // disabled={isLoading || disabled}
          options={options}
          value={selectedFilter || options[0].value}
          onChange={(e) => {
            const selectedValue = e.target.value;
            if (setSelectedFilter)
              setSelectedFilter(
                selectedValue === '' ? undefined : selectedValue,
              );
          }}
        />
      </Styles.SmallVersionWrapper>

      <Styles.LargeVersionWrapper mobileSelect={mobileSelect}>
        <ButtonGroup className="filter">
          {options.map(({ label, value }) => (
            <Button
              key={label}
              label={label}
              mode={selectedFilter === value ? 'default' : 'outline'}
              size="s"
              onClick={() => {
                if (setSelectedFilter)
                  setSelectedFilter(value === '' ? undefined : value);
              }}
            />
          ))}
        </ButtonGroup>
      </Styles.LargeVersionWrapper>
    </>
  );
};

FilterComponent.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  selectedFilter: PropTypes.string,
  setSelectedFilter: PropTypes.func.isRequired,
  mobileSelect: PropTypes.bool,
};

FilterComponent.defaultProps = {
  selectedFilter: undefined,
  mobileSelect: false,
};

export default FilterComponent;
