import React from 'react';
import { keepParams } from '@homamo/meadow-utils';
import Button, { Styles as ButtonStyles } from '../../common/Button';
import AdminRewardsTable from '../AdminRewardsTable';
// import ExportRewards from '../ExportRewards';
// import ImportRewards from '../ImportRewards';
import AdminNav from '../../admin/AdminNav';

const RewardsView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Rewards</h1>
      <ButtonStyles.ButtonGroup>
        <Button
          label="Log a reward"
          mode="primary"
          linkTo={keepParams('/admin/create-reward')}
          size="s"
        />
        {/* <ExportRewards />
        <ImportRewards /> */}
      </ButtonStyles.ButtonGroup>

      <AdminRewardsTable />
    </div>
  );
};

export default RewardsView;
