import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { checkUserNotificationsEnabled } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';

import Button from '../../common/Button';
import Form from '../../common/Form';
import Message from '../../common/Message';
import { Styles as InputStyles } from '../../common/Input';
import CheckboxInput from '../../common/CheckboxInput';
import Error from '../../common/ErrorMessage';

const AccountNotificationsForm = ({ initialData, refetchData }) => {
  const [displayError, setDisplayError] = React.useState();

  const user = initialData;

  const initialValues = {
    switchNotifications: checkUserNotificationsEnabled(user, 'switch') || false,
    transactionsNotifications:
      checkUserNotificationsEnabled(user, 'transactions') || false,
    rewardsNotifications:
      checkUserNotificationsEnabled(user, 'rewards') || false,
  };

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post('auth/update-settings', {
            'notifications.switch': values.switchNotifications,
            'notifications.transactions': values.transactionsNotifications,
            'notifications.rewards': values.rewardsNotifications,
          })
            .then(() => {
              toast.success('Settings updated!');
              refetchData();
              setDisplayError({});
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
      >
        {({ dirty, isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Message>Select your notification preferences.</Message>
                <InputStyles.InputGroup>
                  <Field
                    label="Notify me when I can switch rates"
                    id="switchNotifications"
                    name="switchNotifications"
                    component={CheckboxInput}
                    size="l"
                  />
                  <Field
                    label="Notify me when transactions are auto-logged"
                    id="transactionsNotifications"
                    name="transactionsNotifications"
                    component={CheckboxInput}
                    size="l"
                  />
                  <Field
                    label="Notify me about rewards"
                    id="rewardsNotifications"
                    name="rewardsNotifications"
                    component={CheckboxInput}
                    size="l"
                  />
                </InputStyles.InputGroup>
                <Button
                  label="Update preferences"
                  type="submit"
                  mode="primary"
                  loadingLabel="Updating preferences"
                  isLoading={isSubmitting}
                  disabled={!dirty}
                />
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

AccountNotificationsForm.propTypes = {
  initialData: PropTypes.shape({
    notifications: PropTypes.shape({
      exampleNotification: PropTypes.bool,
    }),
  }),
  refetchData: PropTypes.func.isRequired,
};

AccountNotificationsForm.defaultProps = {
  initialData: {},
};

export default AccountNotificationsForm;
