import styled from 'styled-components';

const CloseButton = styled.button`
  background: 0;
  border: 0;
  padding: ${({ theme }) => theme.space[2]};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize[4]};
  color: ${({ theme }) => theme.color['gray-500']};
`;

export default {
  CloseButton,
};
