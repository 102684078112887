import React from 'react';
import PropTypes from 'prop-types';
import { initGA, logPageView } from '../../../lib/analytics';

const isProd = process.env.NODE_ENV === 'production';

class AnalyticsWrapper extends React.Component {
  componentDidMount() {
    if (isProd) {
      if (!window.GA_INITIALIZED) {
        initGA();
        window.GA_INITIALIZED = true;
      }
      logPageView();
    }
  }

  render() {
    const { children } = this.props;
    return <div>{children}</div>;
  }
}

AnalyticsWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AnalyticsWrapper;
