import styled from 'styled-components';
import { Form as FormikForm } from 'formik';

import { ButtonGroup } from '../Button';

const Form = styled(FormikForm)`
  /* max-width: 600px; */
  width: 100%;

  fieldset {
    display: grid;
    border: none;
    padding: 0;
    height: 100%;

    > div {
      margin: ${({ theme }) => theme.space[3]} 0;
    }

    .form-section {
      margin: ${({ theme }) => theme.space[5]} 0;
    }
  }

  ${ButtonGroup} {
    margin-top: ${({ theme }) => theme.space[4]};
  }
`;

export default {
  Form,
};
