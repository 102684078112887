import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import AdminEditMortgage from '../../../components/mortgage/AdminEditMortgage';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminEditMortgageView = () => {
  const { id } = useParams();
  return (
    <AnalyticsWrapper>
      <Layout title="Edit mortgage">
        <AdminEditMortgage id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminEditMortgageView;
