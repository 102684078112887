import React from 'react';
import AdminMortgagesTable from '../AdminMortgagesTable';
import AdminNav from '../../admin/AdminNav';

const AdminMortgagesView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Mortgages</h1>
      <AdminMortgagesTable enableExport />
    </div>
  );
};

export default AdminMortgagesView;
