/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Styles as InputStyles } from '../Input';
import InputLabel from '../InputLabel';

const PercentageInput = ({
  label,
  isRequired,
  max,
  field: { name, disabled, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const percentageInputRef = React.useRef(null);
  React.useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    percentageInputRef.current.addEventListener('wheel', handleWheel);

    return () => {
      if (percentageInputRef && percentageInputRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        percentageInputRef.current.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const error = errors[name];
  const touch = touched[name];
  return (
    <InputStyles.InputWrapper>
      <InputLabel
        label={label}
        htmlFor={name}
        error={error}
        disabled={disabled}
        isRequired={isRequired}
      />
      <InputStyles.InputWithUnit>
        <InputStyles.Input
          id={name}
          type="number"
          step="0.01"
          min="0"
          max={max}
          hasError={error && touch}
          ref={percentageInputRef}
          {...field}
          {...props}
        />
        <InputStyles.InputUnit>
          <FontAwesomeIcon icon="percent" />
        </InputStyles.InputUnit>
      </InputStyles.InputWithUnit>
      {touch && error && (
        <InputStyles.InputError>{error}</InputStyles.InputError>
      )}
    </InputStyles.InputWrapper>
  );
};

PercentageInput.defaultProps = {
  isRequired: false,
  max: 100,
};

PercentageInput.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  max: PropTypes.number,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
};

export default PercentageInput;
