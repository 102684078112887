import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  convertPercentToDecimal,
  convertDecimalToPercent,
  keepParams,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import PercentageInput from '../../common/PercentageInput';
import CheckboxInput from '../../common/CheckboxInput';
import ImageUpload from '../../common/ImageUpload';
import Textarea from '../../common/Textarea';
import { Styles as InputStyles } from '../../common/Input';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('You need to enter a lender name'),
  image: Yup.string(),
  showRates: Yup.boolean(),
  svr: Yup.number().required('You need to enter a standard variable rate'),
  representativeExample: Yup.string(),
  productTransferNotes: Yup.string(),
  overpaymentNotes: Yup.string(),
  generalNotes: Yup.string(),
});

const EditLenderForm = ({ id, initialData }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();
  const [imageUrl, setImageUrl] = React.useState(
    initialData.image || undefined,
  );
  const [isUploading, setIsUploading] = React.useState(false);

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`lenders/update/${id}`, {
            ...values,
            svr: values.svr ? convertPercentToDecimal(values.svr) : undefined,
            image: imageUrl,
          })
            .then((res) => {
              const lenderId = res.data._id;
              toast.success('Lender updated');
              history.push(keepParams(`/admin/lender/${lenderId}`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          name: initialData.name || '',
          svr: initialData.svr ? convertDecimalToPercent(initialData.svr) : 0,
          showRates: initialData.showRates || false,
          representativeExample: initialData.representativeExample || '',
          productTransferNotes: initialData.productTransferNotes || '',
          overpaymentNotes: initialData.overpaymentNotes || '',
          generalNotes: initialData.generalNotes || '',
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset
                disabled={isSubmitting || isUploading}
                aria-busy={isSubmitting || isUploading}
              >
                <Field
                  label="Name"
                  id="name"
                  name="name"
                  placeholder="Enter a name"
                  component={TextInput}
                  isRequired
                />
                <ImageUpload
                  label="Image"
                  imageUrl={imageUrl}
                  onUpload={() => setIsUploading(true)}
                  onUploadSuccess={(url) => setImageUrl(url)}
                  onUploadError={(error) => setDisplayError(error)}
                  onUploadComplete={() => setIsUploading(false)}
                />
                <Field
                  label="SVR"
                  id="svr"
                  name="svr"
                  component={PercentageInput}
                  isRequired
                />
                <InputStyles.InputGroup>
                  <Field
                    label="Show rates"
                    id="showRates"
                    name="showRates"
                    component={CheckboxInput}
                  />
                </InputStyles.InputGroup>

                <Field
                  label="Representative Example"
                  id="representativeExample"
                  name="representativeExample"
                  placeholder="e.g Based on a repayment mortgage of £100,000 payable over 25 years, initially on a fixed rate for 2 years of 1.69%, followed by standard variable rate of 3.59% (currently) for the remaining 23 years, would require 24 monthly payments of £408.93 and 276 monthly payments of £497.93. The total amount payable would be £147,243.00, made up of the loan amount plus interest of £47,243.00. The overall cost for comparison is 3.31% APRC representative. What you will pay may vary from this and will depend on your personal circumstances."
                  component={Textarea}
                />
                <Field
                  label="Product Transfer Notes"
                  id="productTransferNotes"
                  name="productTransferNotes"
                  placeholder=""
                  component={Textarea}
                />
                <Field
                  label="Overpayment Notes"
                  id="overpaymentNotes"
                  name="overpaymentNotes"
                  placeholder=""
                  component={Textarea}
                />
                <Field
                  label="General Notes"
                  id="generalNotes"
                  name="generalNotes"
                  placeholder=""
                  component={Textarea}
                />
                <ButtonGroup>
                  <Button
                    label="Update Lender"
                    type="submit"
                    mode="primary"
                    loadingLabel="Updating Lender"
                    isLoading={isSubmitting || isUploading}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/lenders'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

EditLenderForm.propTypes = {
  id: PropTypes.string.isRequired,
  initialData: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    svr: PropTypes.number,
    showRates: PropTypes.bool,
    representativeExample: PropTypes.string,
    productTransferNotes: PropTypes.string,
    overpaymentNotes: PropTypes.string,
    generalNotes: PropTypes.string,
  }),
};

EditLenderForm.defaultProps = {
  initialData: {},
};

export default EditLenderForm;
