import React, { useEffect, useState } from 'react';

import API, { handleApiError } from '../../../lib/api';
import AccountView from './View';
import { useAuth } from '../../../context/auth';

import Loading from '../../common/Loading';

function AccountContainer() {
  // Data state to store the users data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [displayError, setDisplayError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { onUpdateUser } = useAuth();

  const fetchData = async () => {
    await API.get(`users/me`)
      .then((res) => {
        setData(res.data);
        onUpdateUser({ user: res.data });
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loading />;
  return (
    data && (
      <AccountView
        data={data}
        displayError={displayError}
        refetchData={fetchData}
      />
    )
  );
}

export default AccountContainer;
