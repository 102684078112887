/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles';

const Message = ({ children, showIcon, icon, spinIcon, ...props }) => (
  <Styles.Message {...props}>
    {showIcon || (icon && <FontAwesomeIcon icon={icon} spin={spinIcon} />)}
    <div className="inner">{children}</div>
  </Styles.Message>
);

Message.propTypes = {
  icon: PropTypes.string,
  showIcon: PropTypes.bool,
  spinIcon: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  size: PropTypes.string,
  mode: PropTypes.string,
  wrapLinks: PropTypes.bool,
  center: PropTypes.bool,
};

Message.defaultProps = {
  icon: undefined,
  showIcon: false,
  spinIcon: false,
  size: 'm',
  wrapLinks: false,
  mode: 'info',
  center: false,
};

export default Message;
