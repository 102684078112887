import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { formatTerm, getRemainingTerm } from '@rateswitch/amm-utils';

import { formatCurrency, formatPercentage } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../../../lib/api';
import { useAuth } from '../../../../../context/auth';

import Button, { ButtonGroup } from '../../../../common/Button';
import Form from '../../../../common/Form';
import Panel from '../../../../common/Panel';
import Error from '../../../../common/ErrorMessage';

import Definition, { DefinitionList } from '../../../../common/Definition';
import LayoutWithImage from '../../../../layout/LayoutWithImage';
import journeyImg from '../../../../../images/journey.svg';

const validationSchema = Yup.object().shape({
  accelerateEligibility: Yup.string().required('You need to select an option'),
});

const OnboardingSummary = ({
  user,
  mortgage,
  setSelectedStep,
  setDisplayError,
  displayError,
}) => {
  const [remainingTerm, setRemainingTerm] = React.useState(undefined);
  const { fetchUserData } = useAuth();

  React.useEffect(() => {
    setRemainingTerm(
      getRemainingTerm({
        remainingBalance: mortgage.initialBalance,
        interestRate: mortgage.interestRate,
        monthlyPayment: mortgage.monthlyPayment,
        overpaymentAmount: mortgage.monthlyOverpayment || 0,
        overpaymentInterval: 'monthly',
      }),
    );
  }, [mortgage]);

  return (
    <LayoutWithImage image={journeyImg} padTop>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`auth/update-settings`, {
            onboardingComplete: true,
          })
            .then(() => {
              fetchUserData();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          accelerateEligibility: user && user.accelerateEligibility ? 'no' : '',
        }}
        validationSchema={validationSchema}
        render={({ isSubmitting }) => {
          const {
            lender,
            accountNumber,
            interestRate,
            remainingBalance,
            monthlyPayment,
          } = mortgage;
          return (
            <Form>
              <div className="message">
                <h2>You&apos;re ready to accelerate to mortgage freedom</h2>
                <p>
                  The information and calculations we&apos;ll show you are based
                  on the details you have entered.
                </p>
                <p>
                  If anything changes you can update your details at any time in
                  your account settings.
                </p>
                <div>
                  <Panel heading="Your details" smallHeader>
                    <DefinitionList termWidth="160px">
                      <Definition
                        term="Lender"
                        definition={lender && lender.name}
                      />
                      {accountNumber && (
                        <Definition term="Account" definition={accountNumber} />
                      )}
                      <Definition
                        term="Remaining balance"
                        definition={formatCurrency(remainingBalance)}
                      />
                      <Definition
                        term="Remaining term"
                        definition={formatTerm(remainingTerm)}
                      />
                      <Definition
                        term="Interest rate"
                        definition={formatPercentage(interestRate, 2)}
                      />
                      {monthlyPayment && (
                        <Definition
                          term="Monthly Payment"
                          definition={formatCurrency(monthlyPayment)}
                        />
                      )}
                    </DefinitionList>
                  </Panel>
                </div>
                <Error error={displayError} />
                <ButtonGroup>
                  <Button
                    label="Back"
                    mode="default"
                    onClick={() => setSelectedStep('dob')}
                  />
                  <Button
                    label="Start accelerating"
                    mode="primary"
                    type="submit"
                    isLoading={isSubmitting}
                    loadingLabel="Setting up your account"
                  />
                </ButtonGroup>
              </div>
            </Form>
          );
        }}
      />
    </LayoutWithImage>
  );
};

OnboardingSummary.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    accelerateEligibility: PropTypes.bool,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  mortgage: PropTypes.shape({
    accountNumber: PropTypes.string,
    initialBalance: PropTypes.number,
    remainingBalance: PropTypes.number,
    mortgage: PropTypes.number,
    monthlyPayment: PropTypes.number,
    monthlyOverpayment: PropTypes.number,
    overpaymentAmount: PropTypes.number,
    interestRate: PropTypes.number,
    lender: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingSummary.defaultProps = {
  displayError: undefined,
};

export default OnboardingSummary;
