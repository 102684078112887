import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminRewards from '../../../components/reward/AdminRewards';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const RewardsView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Rewards">
        <AdminRewards />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default RewardsView;
