import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  getMonthSelectOptions,
  getYearSelectOptions,
  safeUserProfileField,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';
import Button from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import Error from '../../common/ErrorMessage';
import SelectInput from '../../common/SelectInput';
import Message from '../../common/Message';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(
    'We need a name to keep your account secure',
  ),
  lastName: Yup.string().required('We need a name to keep your account secure'),
  email: Yup.string()
    .email("Whoops! That doesn't look like a valid email")
    .required('We need an email address to keep your account secure'),
  phone: Yup.string(),
  postcode: Yup.string(),
  dobMonth: Yup.string(),
  dobYear: Yup.string(),
});

const AccountProfileForm = ({ initialData, refetchData }) => {
  const [displayError, setDisplayError] = React.useState();

  const user = initialData;

  const initialValues = {
    firstName: safeUserProfileField(user, 'firstName'),
    lastName: safeUserProfileField(user, 'lastName'),
    email: (user && user.email) || '',
    phone: safeUserProfileField(user, 'phone'),
    postcode: safeUserProfileField(user, 'postcode'),
    dobMonth: safeUserProfileField(user, 'dobMonth'),
    dobYear: safeUserProfileField(user, 'dobYear'),
  };

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post('auth/update-settings', {
            email: values.email,
            'profile.firstName': values.firstName,
            'profile.lastName': values.lastName,
            'profile.phone': values.phone,
            'profile.postcode': values.postcode,
            'profile.dobMonth': values.dobMonth,
            'profile.dobYear': values.dobYear,
          })
            .then(() => {
              toast.success('Settings updated!');
              refetchData();
              // setDisplayError({});
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="First name"
                  id="firstName"
                  name="firstName"
                  placeholder="Your first name"
                  component={TextInput}
                  autoComplete="given-name"
                  isRequired
                />
                <Field
                  label="Last name"
                  id="lastName"
                  name="lastName"
                  placeholder="Your last name"
                  component={TextInput}
                  autoComplete="family-name"
                  isRequired
                />
                <Field
                  label="Email address"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  component={TextInput}
                  autoComplete="email"
                  isRequired
                />
                <Field
                  label="Phone number"
                  id="phone"
                  name="phone"
                  placeholder="Your phone number"
                  component={TextInput}
                />
                <Field
                  label="Postcode"
                  id="postcode"
                  name="postcode"
                  placeholder="Your postcode"
                  component={TextInput}
                  autoComplete="postal-code"
                />
                <h4>Birthday</h4>
                <Message size="s">
                  We use this information to calculate your Mortgage Freedom
                  Birthday!
                </Message>
                <Field
                  label="Birth month"
                  id="dobMonth"
                  name="dobMonth"
                  component={SelectInput}
                  options={getMonthSelectOptions()}
                  placeholder="Select your birth month"
                />
                <Field
                  label="Birth year"
                  id="dobYear"
                  name="dobYear"
                  component={SelectInput}
                  options={getYearSelectOptions({ skip: 18 })}
                  placeholder="Select your birth year"
                />
                <Button
                  label="Update settings"
                  type="submit"
                  mode="primary"
                  loadingLabel="Updating settings"
                  isLoading={isSubmitting}
                  disabled={!dirty}
                />
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

AccountProfileForm.propTypes = {
  initialData: PropTypes.shape({
    _id: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

AccountProfileForm.defaultProps = {
  initialData: {},
};

export default AccountProfileForm;
