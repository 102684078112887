import React from 'react';
import { Formik, Field } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Whoops! That doesn't look like a valid email")
    .required('Enter your registered email address'),
});

const RequestResetPasswordForm = () => {
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post('auth/request-reset-password', values)
            .then(() => {
              toast.success('Reset link sent');
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        validationSchema={validationSchema}
        initialValues={{ email: '' }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="Your email"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  component={TextInput}
                  isRequired
                />
                <Error error={displayError} />
                <Button
                  mode="primary"
                  label="Send me a reset link"
                  type="submit"
                  loadingLabel="Sending link"
                  isLoading={isSubmitting}
                  block
                />
              </fieldset>
              <p>
                <small>
                  Remembered your password?{' '}
                  <Link to="/sign-in">Sign in here</Link>
                </small>
              </p>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default RequestResetPasswordForm;
