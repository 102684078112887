import React from 'react';

import AppContextProvider from './context/app';
import AuthContextProvider from './context/auth';

import StyleWrapper from './styles/StyleWrapper';
import ToastContainer from './components/common/ToastContainer';

import Routes from './Routes';

function App() {
  return (
    <AppContextProvider>
      <AuthContextProvider>
        <StyleWrapper>
          <ToastContainer position="top-right" />
          <Routes />
        </StyleWrapper>
      </AuthContextProvider>
    </AppContextProvider>
  );
}

export default App;
