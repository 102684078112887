import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import API, { handleApiError } from '../../../lib/api';
import { ConfirmButton } from '../../common/Confirm';

const DeleteLenderComponent = ({
  id,
  lender,
  onLenderDeleted,
  setDisplayError,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const deleteLender = async () => {
    setIsLoading(true);
    await API.post(`lenders/delete/${id}`)
      .then(() => {
        if (onLenderDeleted) onLenderDeleted(id);
        toast.success('Lender deleted');
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
        toast.error('Delete lender failed');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <ConfirmButton
      buttonLabel="Delete"
      buttonSize="s"
      buttonMode="danger"
      contentLabel={`Delete lender: ${lender.name}`}
      confirmAction={() => deleteLender()}
      confirmButtonLabel="Delete lender"
      confirmButtonLoadingLabel="Deleting"
      confirmTextValue={lender.name}
      modalHeading={`Delete lender: ${lender.name}`}
      modalMessage="Deleting a lender will make it unselectable and potentially cause issues for users with this lender. Consider this before deleting."
      isConfirmActionloading={isLoading}
    />
  );
};

DeleteLenderComponent.propTypes = {
  id: PropTypes.string.isRequired,
  onLenderDeleted: PropTypes.func,
  setDisplayError: PropTypes.func,
  lender: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

DeleteLenderComponent.defaultProps = {
  onLenderDeleted: undefined,
  setDisplayError: undefined,
};

export default DeleteLenderComponent;
