import styled from 'styled-components';
import { Styles as InputStyles } from '../Input';

const RadioInputWrapper = styled.div`
  margin: ${({ theme }) => `${theme.space[2]} 0`};
  width: 100%;
  border: 1px solid white;
  border-radius: ${({ theme }) => `${theme.borderRadius[1]}`};
  ${({ checked, theme }) =>
    checked && `background: ${theme.color['gray-400']} `};

  input[type='radio'] {
    opacity: 0;
    position: fixed;
  }

  /* Hide the checkmark by default */
  input[type='radio'] + label::after {
    content: none;
  }
  /* Unhide the checkmark on the checked state */
  input[type='radio']:checked + label {
    ::after {
      content: '';
    }
  }

  input[type='radio']:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }

  ${InputStyles.InputError} {
    display: block;
  }
`;

const RadioInput = styled.input``;

const RadioInputLabel = styled.label`
  font-size: ${({ theme }) => `${theme.fontSize[2]}`};
  line-height: 1.2;
  max-width: ${({ theme }) => `${theme.space[11]}`};
  color: ${({ theme }) => theme.inputLabelColor};
  position: relative;
  display: inline-block;
  padding: ${({ theme }) =>
    `${theme.space[3]} ${theme.space[3]} ${theme.space[3]} ${theme.space[6]}`};
  width: 100%;
  height: 100%;
  cursor: pointer;

  ::before {
    content: '';
    position: absolute;
    left: 14px;
    top: 14px;
    display: inline-block;
    height: ${({ theme }) => `${theme.space[4]}`};
    width: ${({ theme }) => `${theme.space[4]}`};
    border: 1px solid ${({ theme }) => theme.inputBorder};
    border-radius: ${({ theme }) => `${theme.borderRadius[4]}`};
    background: ${({ theme }) => theme.color.white};
  }

  ::after {
    content: '';
    position: absolute;
    left: 14px;
    top: 14px;
    display: inline-block;
    border-radius: ${({ theme }) => `${theme.borderRadius[4]}`};
    height: ${({ theme }) => `${theme.space[4]}`};
    width: ${({ theme }) => `${theme.space[4]}`};
    border: 0.5 solid ${({ theme }) => theme.color.white};
    background: ${({ theme }) => theme.color['gray-600']};
  }
`;

export default {
  RadioInput,
  RadioInputWrapper,
  RadioInputLabel,
};
