import React from 'react';
import PropTypes from 'prop-types';
import {
  formatDateTime,
  formatUserName,
  keepParams,
  checkUserNotificationsEnabled,
} from '@homamo/meadow-utils';

import Definition, { DefinitionList } from '../../common/Definition';
import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Panel, { PanelColumn } from '../../common/Panel';
import Icon from '../../common/Icon';
import AdminNav from '../../admin/AdminNav';

const AdminUserView = ({ data, displayError }) => {
  const termWidth = '200px';
  const user = data;

  return (
    <div>
      <AdminNav />
      <h1>{formatUserName(user)}</h1>
      <ButtonGroup>
        <Button
          size="s"
          label="View all users"
          icon="arrow-left"
          linkTo={keepParams('/admin/users')}
        />
        {data.mortgage_ids && data.mortgage_ids[0] && (
          <Button
            size="s"
            label="View Mortgage"
            linkTo={keepParams(`/admin/mortgage/${data.mortgage_ids[0]}`)}
          />
        )}
        <Button
          size="s"
          label="Edit"
          linkTo={keepParams(`/admin/user/${data._id}/edit`)}
        />
      </ButtonGroup>
      <Error error={displayError} />
      <PanelColumn>
        <Panel heading="Details">
          <DefinitionList termWidth={termWidth}>
            <Definition term="Email" definition={user.email} />
            <Definition
              term="Phone"
              definition={user.profile && user.profile.phone}
            />
            <Definition
              term="Postcode"
              definition={
                user.profile &&
                user.profile.address &&
                user.profile.address.postcode
              }
            />
            <Definition
              term="DOB"
              definition={
                user.profile &&
                `${user.profile.dobMonth || '-'} ${user.profile.dobYear || '-'}`
              }
            />
            <Definition
              term="Last login"
              definition={formatDateTime(user.lastLoginDate)}
            />
          </DefinitionList>
        </Panel>
        <Panel heading="Status">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Rateswitch Customer"
              definition={
                user.rateswitchCustomer ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Beta Features Enabled"
              definition={
                user.enableBetaFeatures ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Accelerate Eligibility"
              definition={
                user.accelerateEligibility ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Onboarding complete"
              definition={
                user.onboardingComplete ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Terms agreed"
              definition={
                user.termsAgreed ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Terms agreed date"
              definition={formatDateTime(user.termsAgreedDate)}
            />
            <Definition
              term="Marketing consent"
              definition={
                user.marketingConsent ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Marketing consent date"
              definition={formatDateTime(user.marketingConsentDate)}
            />
            {user.suspended && (
              <Definition
                term="Suspended date"
                definition={formatDateTime(user.suspendedDate)}
              />
            )}
            {user.accountDeletionRequestedDate && (
              <Definition
                term="Account deletion requested"
                definition={formatDateTime(user.accountDeletionRequestedDate)}
              />
            )}
          </DefinitionList>
        </Panel>

        <Panel heading="Referral">
          <DefinitionList termWidth={termWidth}>
            <Definition term="Referral Type" definition={user.referredByType} />
            {user.referredByPartner && (
              <Definition
                term="Referral Partner"
                definition={user.referredByPartner}
              />
            )}
            {user.referredByUser && user.referredByUser._id && (
              <Definition
                term="Referred by User"
                definition={formatUserName(user.referredByUser)}
                linkTo={
                  user.referredByUser._id &&
                  `/admin/user/${user.referredByUser._id}`
                }
              />
            )}
          </DefinitionList>
        </Panel>

        <Panel heading="Notifications">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Switch"
              definition={
                checkUserNotificationsEnabled(user, 'switch') ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Transactions"
              definition={
                checkUserNotificationsEnabled(user, 'transactions') ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Rewards"
              definition={
                checkUserNotificationsEnabled(user, 'rewards') ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
          </DefinitionList>
        </Panel>

        <Panel heading="Notes">
          <DefinitionList termWidth={termWidth}>
            <Definition term="Notes" definition={user.notes} />
          </DefinitionList>
        </Panel>

        <Panel heading="Meta">
          <DefinitionList termWidth={termWidth}>
            <Definition term="ID" definition={data._id} />
            <Definition
              term="Created"
              definition={formatDateTime(data.createdAt)}
            />
            <Definition
              term="Updated"
              definition={formatDateTime(data.updatedAt)}
            />
          </DefinitionList>
        </Panel>
      </PanelColumn>
    </div>
  );
};

AdminUserView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    mortgage_ids: PropTypes.arrayOf([PropTypes.string]),
  }).isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

AdminUserView.defaultProps = {
  displayError: undefined,
};

export default AdminUserView;
