import React from 'react';
import Layout from '../components/layout/Layout';
import Dashboard from '../components/dashboard/Dashboard';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const DashboardView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Dashboard">
        <Dashboard />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default DashboardView;
