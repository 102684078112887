import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../common/Badge';

export const getRewardStatus = (reward) => {
  if (reward && reward.status) {
    if (reward.status === 'PENDING')
      return { mode: 'default', status: 'Pending' };
    if (reward.status === 'CONFIRMED')
      return { mode: 'waiting', status: 'Confirmed' };
    if (reward.status === 'PAID') return { mode: 'positive', status: 'Paid' };
    if (reward.status === 'DECLINED')
      return { mode: 'negative', status: 'Declined' };
  }

  return { mode: 'default', status: 'Not set' };
};

const RewardStatusBadge = ({ reward, size }) => {
  const { mode, status } = getRewardStatus(reward);
  return (
    <Badge mode={mode} size={size}>
      {status}
    </Badge>
  );
};

RewardStatusBadge.propTypes = {
  reward: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  size: PropTypes.string,
};

RewardStatusBadge.defaultProps = {
  size: undefined,
};

export default RewardStatusBadge;
