import React from 'react';
import PropTypes from 'prop-types';

import Button, { ButtonGroup } from '../../common/Button';

const OnboardingViewActions = ({
  previousView,
  setSelectedStep,
  isSubmitting,
  isLoading,
}) => {
  return (
    <ButtonGroup>
      <Button
        label="Back"
        mode="default"
        onClick={() => setSelectedStep(previousView)}
      />
      <Button
        label="Next"
        mode="primary"
        type="submit"
        loadingLabel="Saving"
        isLoading={isSubmitting || isLoading}
      />
    </ButtonGroup>
  );
};

OnboardingViewActions.defaultProps = {
  isSubmitting: false,
  isLoading: false,
};

OnboardingViewActions.propTypes = {
  previousView: PropTypes.string.isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default OnboardingViewActions;
