/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Styles from './Styles';

/* eslint-disable react/prop-types */
const ButtonInner = ({
  isLoading,
  loadingLabel,
  label,
  icon,
  isIconButton,
}) => (
  <Styles.ButtonInner isIconButton={isIconButton}>
    {icon && <FontAwesomeIcon icon={icon} />}
    {!isIconButton && isLoading && <FontAwesomeIcon icon="spinner" spin />}
    {!isIconButton && ((isLoading && loadingLabel) || label)}
  </Styles.ButtonInner>
);
/* eslint-enable react/prop-types */

const Button = ({
  label,
  icon,
  isIconButton,
  type,
  loadingLabel,
  onClick,
  mode,
  size,
  block,
  linkTo,
  linkToExternal,
  isLoading,
  isDisabled,
  ...props
}) => {
  const buttonInner = (
    <ButtonInner
      icon={icon}
      isIconButton={isIconButton}
      label={label}
      loadingLabel={loadingLabel}
      isLoading={isLoading}
    />
  );

  if (linkToExternal) {
    return (
      <Styles.ExternalLinkButton
        mode={mode}
        size={size}
        onClick={onClick}
        disabled={isDisabled || isLoading}
        href={linkToExternal}
        block={block}
        {...props}
      >
        {buttonInner}
      </Styles.ExternalLinkButton>
    );
  }
  if (linkTo) {
    return (
      <Styles.LinkButton
        mode={mode}
        type={type}
        size={size}
        onClick={onClick}
        disabled={isDisabled || isLoading}
        to={linkTo}
        block={block}
        {...props}
      >
        {buttonInner}
      </Styles.LinkButton>
    );
  }
  return (
    <Styles.Button
      mode={mode}
      type={type}
      size={size}
      disabled={isDisabled || isLoading}
      onClick={onClick}
      block={block}
      {...props}
    >
      {buttonInner}
    </Styles.Button>
  );
};
Button.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  isIconButton: PropTypes.bool,
  loadingLabel: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  mode: PropTypes.string,
  block: PropTypes.bool,
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  linkToExternal: PropTypes.string,
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  label: undefined,
  icon: undefined,
  isIconButton: false,
  loadingLabel: undefined,
  mode: 'default',
  type: 'button',
  size: 'm',
  block: undefined,
  onClick: undefined,
  linkTo: undefined,
  linkToExternal: undefined,
  isDisabled: false,
  isLoading: false,
};

export default Button;
