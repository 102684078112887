import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { formatDateTime, keepParams } from '@homamo/meadow-utils';
// import { useHistory } from 'react-router-dom';

import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Table, { TableButtonGroup } from '../../common/Table';
import DeleteRetailer from '../DeleteRetailer';

const RetailersTableComponent = ({
  data,
  isLoading,
  pageCount,
  fetchData,
  totalCount,
  initialSortBy,
  filterOptions,
  onRetailerDeleted,
  isCompact,
}) => {
  // const history = useHistory();

  const columns = useMemo(
    /* eslint-disable react/prop-types */
    () => [
      {
        Header: 'Heading',
        accessor: 'heading',
        sortType: 'basic',
        id: 'heading',
        minWidth: 200,
      },
      {
        Header: 'Description',
        accessor: 'description',
        id: 'description',
        width: 200,
      },
      {
        Header: 'Link',
        accessor: 'link',
        id: 'link',
        width: 250,
      },
      {
        Header: 'Track',
        accessor: 'link',
        id: 'track',
        width: 100,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          // eslint-disable-next-line react/prop-types
          <span className="icon icon--success">
            {/* eslint-disable-next-line react/prop-types */}
            {value.includes('{userId}') ? (
              <Icon icon="check-circle" color="green-600" />
            ) : (
              ''
            )}
          </span>
        ),
      },
      {
        Header: 'Live',
        accessor: 'live',
        width: 100,
        // eslint-disable-next-line react/prop-types
        Cell: ({ value }) => (
          // eslint-disable-next-line react/prop-types
          <span className="icon icon--success">
            {/* eslint-disable-next-line react/prop-types */}
            {value ? <Icon icon="check-circle" color="green-600" /> : ''}
          </span>
        ),
      },
      {
        Header: 'Created',
        accessor: ({ createdAt }) => formatDateTime(createdAt),
        sortType: 'datetime',
        id: 'createdAt',
        width: 170,
      },
      {
        Header: 'Updated',
        accessor: ({ updatedAt }) => formatDateTime(updatedAt),
        sortType: 'datetime',
        id: 'updatedAt',
        width: 170,
      },
      {
        Header: 'Actions',
        accessor: '_id',
        disableSortBy: true,
        width: 200,
        sticky: 'right',
        Cell: ({ cell: { value }, row: { original } }) => (
          <TableButtonGroup>
            <Button
              label="View"
              size="s"
              linkTo={keepParams(`/admin/retailer/${value}`)}
            />
            <Button
              label="Edit"
              size="s"
              linkTo={keepParams(`/admin/retailer/${value}/edit`)}
            />
            <DeleteRetailer
              id={value}
              retailer={original}
              onRetailerDeleted={onRetailerDeleted}
            />
          </TableButtonGroup>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // Second array to allow memo
    /* eslint-enable react/prop-types */
  );

  return (
    <>
      <Table
        name="retailers"
        columns={columns}
        data={data}
        fetchData={fetchData}
        isLoading={isLoading}
        pageCount={pageCount}
        totalCount={totalCount}
        blankState="No retailers found"
        initialSortBy={initialSortBy}
        searchPlaceholder="Search for a retailer heading"
        filterOptions={filterOptions}
        isCompact={isCompact}
        defaultPageSize={20}
        enableSearch
      />
    </>
  );
};

RetailersTableComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  onRetailerDeleted: PropTypes.func,
  isLoading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ).isRequired,
  isCompact: PropTypes.bool,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

RetailersTableComponent.defaultProps = {
  data: [],
  onRetailerDeleted: undefined,
  isLoading: false,
  pageCount: 0,
  totalCount: 0,
  isCompact: false,
  filterOptions: [],
};

export default RetailersTableComponent;
