import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { formatUserName } from '@homamo/meadow-utils';
import Layout from '../../layout/Layout';
import config from '../../../config';
import Button, { ButtonGroup } from '../../common/Button';
import SignOut from '../SignOut';
import ErrorMessage from '../../common/ErrorMessage';
import API, { handleApiError } from '../../../lib/api';
import { useAuth } from '../../../context/auth';

const TermsAgreementView = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [displayError, setDisplayError] = useState([]);
  const { user, onUpdateUser } = useAuth();

  return (
    <Layout title="Agree to terms">
      <h1>Terms of use</h1>
      <p>
        Hey {formatUserName(user, { first: true })}, you need to agree to our
        terms &amp; conditions to continue. You can read them{' '}
        <a href={config.termsUrl} target="_blank" rel="noreferrer noopener">
          here
        </a>
        .
      </p>
      <ErrorMessage error={displayError} />
      <ButtonGroup>
        <Button
          mode="primary"
          label="I agree to the terms of use"
          loadingLabel="Submitting"
          isLoading={isLoading}
          onClick={async () => {
            setIsLoading(true);

            await API.post(`auth/agree-to-terms`, {
              agreeToTerms: true,
            })
              .then((res) => {
                onUpdateUser({ user: res.data.user });
                toast.success('Terms agreement logged');
              })
              .catch((error) => {
                handleApiError({ error, setDisplayError });
                toast.error('Terms agreement failed');
              })
              .finally(() => setIsLoading(false));
          }}
        />
        <SignOut />
      </ButtonGroup>
    </Layout>
  );
};

export default TermsAgreementView;
