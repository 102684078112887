import styled from 'styled-components';
import ButtonStyles from '../Button/Styles';

const Table = styled.div`
  width: 100%;

  .table-container {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: ${({ theme }) =>
      `${theme.borderWidth[1]} solid ${theme.color['gray-300']}`};
    border-right: ${({ theme }) =>
      `${theme.borderWidth[4]} solid ${theme.color['gray-500']}`};

    @media ${({ theme }) => theme.device.tablet} {
      border-right: 0;
    }
  }

  table {
    width: 100%;
    margin: ${({ theme }) => theme.space[2]} 0;
    border-radius: ${({ theme }) => theme.borderRadius[1]};
    overflow: hidden;
    border-collapse: collapse;
    font-size: ${({ theme }) => theme.fontSize[1]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ isCompact, theme }) =>
        isCompact ? theme.fontSize[1] : theme.fontSize[2]};
    }

    ${ButtonStyles.Button} {
      font-size: ${({ isCompact, theme }) =>
        isCompact ? theme.fontSize[1] : theme.fontSize[2]};
    }

    th {
      padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
      background-color: ${({ theme }) => theme.color['gray-200']};
      text-align: left;
      /* border-bottom: 2px solid ${({ theme }) => theme.color['gray-900']}; */
    }

    th,
    td {
      margin: 0;

      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        background: 0;
        width: ${({ theme }) => `${theme.space[1]}`};
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          background: ${({ theme }) => theme.color['gray-300']};
        }
      }
    }

    tr {
      td {
        background-color: ${({ theme }) => theme.color.white};
      }
      :nth-child(even) {
        td {
          background-color: ${({ theme }) => theme.color['gray-100']};
        }
      }
    }

    @media ${({ theme }) => theme.device.tablet} {
      &.sticky {
        overflow: scroll;
        .header,
        .footer {
          position: sticky;
          z-index: 1;
          width: fit-content;
        }

        .header {
          top: 0;
        }

        .footer {
          bottom: 0;
        }

        .body {
          position: relative;
          z-index: 0;
        }

        [data-sticky-td] {
          position: sticky;
        }

        [data-sticky-last-left-td] {
          box-shadow: ${({ theme }) => theme.boxShadow[4]};
        }

        [data-sticky-first-right-td] {
          box-shadow: ${({ theme }) => theme.boxShadow[4]};

          .resizer {
            display: none;
          }
        }
      }
    }
  }

  td {
    padding: ${({ theme }) => `${theme.space[1]} ${theme.space[1]}`};
    display: flex;
    align-items: center;

    @media ${({ theme }) => theme.device.tablet} {
      padding: ${({ theme }) => `${theme.space[2]} ${theme.space[2]}`};
    }

    em {
      color: ${({ theme }) => theme.color['gray-600']};
    }
  }

  .sorted-indicator {
    margin-left: ${({ theme }) => theme.space[0]};
    opacity: 0.2;
  }

  .sorted-indicator.--toggled {
    opacity: 1;
    color: ${({ theme }) => theme.color['orange-500']};
  }

  &.--loading {
    table {
      opacity: 0.5;
    }
  }

  .footer {
    width: 100%;
    padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
    background-color: ${({ theme }) => theme.color['gray-200']} !important;

    span {
      display: block;
      width: 100%;
      font-size: ${({ theme }) => `${theme.fontSize[1]}`};
      color: ${({ theme }) => theme.color['gray-600']};
      text-align: center;
    }
  }
`;

const TableButtonGroup = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[0]};
  width: auto;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};

  ${ButtonStyles.Button} {
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: block;
  }
`;

export default {
  Table,
  TableButtonGroup,
};
