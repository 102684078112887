import styled from 'styled-components';

const ComponentList = styled.div`
  .font-size-0 {
    font-size: ${({ theme }) => theme.fontSize[0]};
  }
  .font-size-1 {
    font-size: ${({ theme }) => theme.fontSize[1]};
  }
  .font-size-2 {
    font-size: ${({ theme }) => theme.fontSize[2]};
  }
  .font-size-3 {
    font-size: ${({ theme }) => theme.fontSize[3]};
  }
  .font-size-4 {
    font-size: ${({ theme }) => theme.fontSize[4]};
  }
  .font-size-5 {
    font-size: ${({ theme }) => theme.fontSize[5]};
  }
  .font-size-6 {
    font-size: ${({ theme }) => theme.fontSize[6]};
  }
  .font-size-7 {
    font-size: ${({ theme }) => theme.fontSize[7]};
  }
  .font-size-8 {
    font-size: ${({ theme }) => theme.fontSize[8]};
  }
  .font-size-9 {
    font-size: ${({ theme }) => theme.fontSize[9]};
  }
  .font-size-10 {
    font-size: ${({ theme }) => theme.fontSize[10]};
  }
`;

const ComponentListSection = styled.div`
  margin-bottom: ${({ theme }) => theme.space[8]};

  .section-heading {
    font-size: ${({ theme }) => theme.fontSize[2]};
    text-transform: uppercase;
    color: ${({ theme }) => theme.color['gray-500']};
    padding-bottom: ${({ theme }) => theme.space[1]};
    border-bottom: 1px solid ${({ theme }) => theme.color['gray-400']};
    margin-bottom: ${({ theme }) => theme.space[3]};
  }
`;

export default {
  ComponentList,
  ComponentListSection,
};
