import styled from 'styled-components';

const OnboardingView = styled.div`
  height: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 2rem 0;
  width: 100%;

  @media ${({ theme }) => theme.device.tablet} {
    margin-top: 2rem;
    padding: 4rem 0;
  }

  h2 {
    line-height: 1.3;
  }

  p {
    margin: 0 0 2rem;
    line-height: 1.3;
    max-width: 700px;
  }
`;

export default {
  OnboardingView,
};
