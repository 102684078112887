import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Styles from './Styles';

const Definition = ({ term, definition, linkTo, children }) => {
  const content = definition || children || '-';
  return (
    <Styles.Definition>
      <dt>{term}</dt>
      <dd>{linkTo ? <Link to={linkTo}>{content}</Link> : content}</dd>
    </Styles.Definition>
  );
};

Definition.propTypes = {
  term: PropTypes.string.isRequired,
  definition: PropTypes.string,
  linkTo: PropTypes.string,
  children: PropTypes.node,
};

Definition.defaultProps = {
  definition: undefined,
  linkTo: undefined,
  children: undefined,
};

export default Definition;
