import styled from 'styled-components';
import { Styles as PanelStyles } from '../../common/Panel';

const ReferralMessage = styled.div`
  img {
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
    display: block;

    @media ${({ theme }) => theme.device.tablet} {
      max-width: ${({ theme }) => theme.space[10]};
      margin: 0 3rem 0 0;
    }
  }

  .inner {
    max-width: 650px;
  }

  @media ${({ theme }) => theme.device.tablet} {
    ${PanelStyles.Panel} {
      .body {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        /* p:first-child {
          margin-top: 0;
          font-size: 2.2rem;
        } */
      }
    }
  }
`;

export default {
  ReferralMessage,
};
