import React from 'react';
import PropTypes from 'prop-types';
import EditRateForm from './Form';
import AdminNav from '../../admin/AdminNav';

const EditRateView = ({ data }) => {
  return (
    <div>
      <AdminNav />
      <h1>Edit rate: {data._id}</h1>
      <EditRateForm id={data._id} initialData={data} />
    </div>
  );
};

EditRateView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export default EditRateView;
