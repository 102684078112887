import React from 'react';
import config from '../../../config';

import Message from '../../common/Message';

const MaintenanceMessage = () => {
  return config.isMaintenanceMode ? (
    <Message mode="warning">
      Our service is currently under maintenance and temporarily unavailable.
    </Message>
  ) : null;
};

export default MaintenanceMessage;
