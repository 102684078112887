import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Link } from 'react-router-dom';
import { capitalize } from '@homamo/meadow-utils';

import { useAuth } from '../../../context/auth';

import SignUpForm from '../../auth/SignUp/Form';
import NotAuthorised from '../../auth/NotAuthorised';
import config from '../../../config';
import LayoutWithImage from '../../layout/LayoutWithImage/Component';
import MarketingSiteLink from '../../common/MarketingSiteLink';

import relaxImg from '../../../images/relax.svg';

const ReferralView = ({ referredByName, referredByUserId, isLive }) => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (config.signUpDisabled)
    return (
      <NotAuthorised
        pathname="/sign-up"
        message={`Registering new accounts has been disabled. Please contact ${config.supportEmail} if you have any questions.`}
      />
    );

  return (
    <LayoutWithImage image={relaxImg} padTop>
      {isLive ? (
        <>
          <h1>{capitalize(referredByName)}&apos;s referral page</h1>
          <p>
            We help homeowners take years off their mortgage and save thousands
            of pounds in interest
          </p>
          <p>
            Want to find out how it works before you sign up?{' '}
            <MarketingSiteLink label="Read this" />
          </p>
        </>
      ) : (
        <>
          <h1>Oops! That referral link isn&apos;t linked to anyone</h1>
          <p>
            Double check the link your friend gave you, or just sign up below
            and send us an email to{' '}
            <a href="mailto:support@acceleratemymortgage.com">
              support@acceleratemymortgage.com
            </a>{' '}
            with your friend&apos;s details.
          </p>
        </>
      )}
      <SignUpForm
        referredByUserId={
          isLive && referredByUserId ? referredByUserId : undefined
        }
      />
      <p>
        <small>
          Already have an account? <Link to="/sign-in">Sign in here</Link> or
          view <a href={config.termsUrl}> Terms &amp; Conditions</a>
        </small>
      </p>
    </LayoutWithImage>
  );
};

ReferralView.propTypes = {
  referredByName: PropTypes.string,
  referredByUserId: PropTypes.string,
  isLive: PropTypes.bool,
};

ReferralView.defaultProps = {
  referredByName: undefined,
  referredByUserId: undefined,
  isLive: false,
};

export default ReferralView;
