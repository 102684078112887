import React from 'react';
import PropTypes from 'prop-types';
import EditTransactionForm from './Form';
import AdminNav from '../../admin/AdminNav';
import Message from '../../common/Message';

const EditTransactionView = ({ data, mode }) => {
  const isAdminMode = mode === 'admin';

  return (
    <div>
      {isAdminMode && <AdminNav />}
      <h1>
        {isAdminMode ? 'Edit payment' : 'Edit transaction'}: {data._id}
      </h1>
      <Message mode="warning" size="l">
        {isAdminMode
          ? `This is just a log of the payment. The actual transfer needs to be made
        manually. This payment will affect the user&apos;s displayed balance.`
          : `This is just a log of the transaction. The actual transfer needs to be made
        manually. This record will affect the your displayed balance.`}
      </Message>
      <EditTransactionForm id={data._id} initialData={data} mode={mode} />
    </div>
  );
};

EditTransactionView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  mode: PropTypes.string,
};

EditTransactionView.defaultProps = {
  mode: 'default',
};

export default EditTransactionView;
