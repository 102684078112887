import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles';

const CloseButton = ({ closeAction }) => (
  <Styles.CloseButton type="button" onClick={closeAction}>
    <FontAwesomeIcon icon="times" />
  </Styles.CloseButton>
);

CloseButton.propTypes = {
  closeAction: PropTypes.func.isRequired,
};

export default CloseButton;
