import React from 'react';
import AdminRatesTable from '../AdminRatesTable';

import AdminNav from '../../admin/AdminNav';

const AdminRatesView = () => {
  const [displayError, setDisplayError] = React.useState();

  return (
    <div>
      <AdminNav />
      <h1>Rates</h1>
      <AdminRatesTable
        displayError={displayError}
        setDisplayError={setDisplayError}
      />
    </div>
  );
};

export default AdminRatesView;
