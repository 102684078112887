import React from 'react';
import PropTypes from 'prop-types';
import { pluralize } from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Modal from '../../common/Modal';
import Message from '../../common/Message';
import AdminEditMultipleRatesForm from './Form';

const AdminEditRatesModal = ({
  buttonLabel,
  buttonMode,
  buttonSize,
  buttonBlock,
  message,
  blockParentForm,
  unblockParentForm,
  successAction,
  selectedRates,
  selectedRate,
  mode,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    if (blockParentForm) unblockParentForm();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    if (unblockParentForm) unblockParentForm();
  };

  return (
    <>
      <Button
        size={buttonSize}
        mode={buttonMode}
        label={buttonLabel}
        block={buttonBlock}
        onClick={() => openModal()}
        disabled={
          mode === 'multi' && (!selectedRates || selectedRates.length === 0)
        }
      />
      <Modal
        heading="Edit selected rates"
        isOpen={modalIsOpen}
        closeAction={() => closeModal()}
        contentLabel="Edit selected rates"
      >
        {mode === 'single' ? (
          <Message>Updating {selectedRate._id}</Message>
        ) : (
          <Message>
            Updating {selectedRates.length}{' '}
            {pluralize(selectedRates.length, 'rate')}
          </Message>
        )}
        <AdminEditMultipleRatesForm
          successAction={() => {
            if (successAction) successAction();
            closeModal();
          }}
          cancelAction={closeModal}
          selectedRates={selectedRates}
          selectedRate={selectedRate}
          mode={mode}
        />
        <br />
        {message && <Message>{message}</Message>}
      </Modal>
    </>
  );
};

AdminEditRatesModal.propTypes = {
  buttonLabel: PropTypes.string,
  buttonMode: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonBlock: PropTypes.bool,
  blockParentForm: PropTypes.func,
  unblockParentForm: PropTypes.func,
  message: PropTypes.string,
  successAction: PropTypes.func,
  selectedRates: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  selectedRate: PropTypes.shape({
    _id: PropTypes.string,
  }),
  mode: PropTypes.string,
};

AdminEditRatesModal.defaultProps = {
  buttonMode: 'default',
  buttonSize: 'm',
  buttonBlock: false,
  blockParentForm: undefined,
  unblockParentForm: undefined,
  message: undefined,
  successAction: undefined,
  buttonLabel: 'Edit selected rates',
  selectedRates: [],
  selectedRate: {},
  mode: 'multi',
};

export default AdminEditRatesModal;
