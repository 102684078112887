/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import SelectInput from '../../common/SelectInput';

const getOptions = ({ includeBlank, blankLabel }) => {
  const typeOptions = [
    { label: 'Switch', value: 'SWITCH' },
    { label: 'Retailer', value: 'RETAILER' },
    { label: 'Referral', value: 'REFERRAL' },
    // { label: 'Adjustment', value: 'ADJUSTMENT' },
  ];

  const options = [];

  if (includeBlank) {
    options.push({ label: blankLabel, value: 'ALL', key: 'blank' });
    options.push({ label: '---', disabled: true, key: 'divider-1' });
  }
  options.push(...typeOptions);
  return options;
};

const RewardTypeSelectComponent = ({
  label,
  id,
  name,
  isLoading,
  placeholder,
  isRequired,
  disabled,
  noFormik,
  includeBlank,
  blankLabel,
  value,
  setValue,
  onChange,
  ...props
}) => {
  const options = getOptions({ includeBlank, blankLabel });

  if (noFormik) {
    return (
      <SelectInput
        label={label}
        id={id}
        name={name}
        placeholder={isLoading ? 'Loading options...' : placeholder}
        isRequired={isRequired}
        disabled={isLoading || disabled}
        options={options}
        value={value}
        onChange={(e) => {
          if (setValue) setValue(e.target.value);
          if (onChange) onChange(e);
        }}
        {...props}
      />
    );
  }

  return (
    <>
      <Field
        label={label}
        id={id}
        name={name}
        placeholder={isLoading ? 'Loading options...' : placeholder}
        component={SelectInput}
        options={options}
        isRequired={isRequired}
        disabled={isLoading || disabled}
        {...props}
      />
    </>
  );
};

RewardTypeSelectComponent.propTypes = {
  includeBlank: PropTypes.bool,
  blankLabel: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  noFormik: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
};

RewardTypeSelectComponent.defaultProps = {
  includeBlank: false,
  blankLabel: 'All typees',
  id: 'type',
  label: 'Type',
  name: 'type',
  placeholder: 'Select a type',
  isRequired: false,
  disabled: false,
  isLoading: false,
  noFormik: false,
  value: undefined,
  setValue: undefined,
  onChange: undefined,
};

export default RewardTypeSelectComponent;
