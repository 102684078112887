/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles';

const InputLabel = (props) => {
  const { label, isRequired, isHidden } = props;
  return (
    <Styles.InputLabel
      required={isRequired}
      aria-required={isRequired}
      isHidden={isHidden}
      {...props}
    >
      {label}
      {isRequired && <FontAwesomeIcon icon="asterisk" />}
    </Styles.InputLabel>
  );
};

InputLabel.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isHidden: PropTypes.bool,
};

InputLabel.defaultProps = {
  isRequired: false,
  isHidden: false,
};

export default InputLabel;
