import React from 'react';
import CreateRetailerForm from './Form';
import AdminNav from '../../admin/AdminNav';

const CreateRetailerView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Create Retailer</h1>
      <CreateRetailerForm />
    </div>
  );
};

export default CreateRetailerView;
