import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import theme from '../../../styles/theme';

export default styled(ToastContainer)`
  .Toastify__toast {
    border-radius: ${theme.borderRadius[1]};
    padding: ${theme.space[2]};
    font-size: ${theme.fontSize[1]};
    background-color: ${theme.color.white};
    border-left: ${`${theme.space[1]} solid`};
    font-family: ${theme.fontFamily};
  }

  .Toastify__toast--success {
    background: ${theme.color.white};
    border-color: ${theme.color['gray-500']};
    color: ${theme.color['gray-800']};

    .Toastify__progress-bar {
      background: ${theme.color['gray-300']};
    }

    .Toastify__close-button {
      color: ${theme.color['gray-700']};
    }
  }

  .Toastify__toast--error {
    background: ${theme.color.white};
    border-color: ${theme.color['red-500']};
    color: ${theme.color['gray-800']};

    .Toastify__progress-bar {
      background: ${theme.color['gray-300']};
    }

    .Toastify__close-button {
      color: ${theme.color['gray-700']};
    }
  }
`;
