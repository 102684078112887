import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import AdminMortgage from '../../../components/mortgage/AdminMortgage';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminMortgageView = () => {
  const { id } = useParams();

  return (
    <AnalyticsWrapper>
      <Layout title="Mortgage">
        <AdminMortgage id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminMortgageView;
