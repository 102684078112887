import styled from 'styled-components';
import { Styles as MessageStyles } from '../Message';

const Input = styled.input`
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  outline: none;

  padding: ${({ theme }) => theme.space[3]};
  font-size: ${({ theme }) => theme.fontSize[3]};
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.danger : theme.inputBorder)};
  border-radius: ${({ theme }) => theme.borderRadius[1]};

  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.inputColor};
  width: 100%;
  display: block;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};

  ::placeholder {
    color: ${({ theme }) => theme.inputPlaceholder};
  }

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: ${({ theme }) => theme.inputPlaceholder};
  }

  &:focus {
    border-color: ${({ theme }) => theme.inputColor};
  }

  :-webkit-autofill {
    box-shadow: 0 0 0 100px white inset;
    -webkit-box-shadow: 0 0 0 100px white inset;
    -webkit-text-fill-color: ${({ theme }) => theme.inputColor};
  }

  /* Remove increment arrows */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const InputError = styled.span`
  color: ${({ theme }) => theme.danger};
  display: block;
  margin-top: ${({ theme }) => theme.space[1]};
  font-size: ${({ theme }) => theme.fontSize[2]};
`;

const InputGroup = styled.div`
  padding: ${({ theme }) => `${theme.space[4]} 0`};
`;

const InputUnit = styled.div`
  padding: ${({ theme }) => `0 ${theme.space[3]}`};
  font-size: ${({ theme }) => `${theme.fontSize[4]}`};
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.danger : theme.inputBorder)};
  ${({ pre }) => (!pre ? 'border-left: none' : 'border-right: none')};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: ${({ pre, theme }) =>
    pre
      ? `${theme.borderRadius[1]} 0 0 ${theme.borderRadius[1]}`
      : `0 ${theme.borderRadius[1]} ${theme.borderRadius[1]} 0`};
  font-weight: 700;
  background-color: ${({ theme }) => theme.color['gray-300']};
  color: ${({ theme }) => theme.color['gray-700']};
`;

const InputWithUnit = styled.div`
  display: flex;
  /* max-width: ${({ theme }) => `${theme.space[10]}`}; */

  ${({ pre, theme }) => {
    return `
      ${Input} {
        border-radius: ${
          pre
            ? `0 ${theme.borderRadius[1]} ${theme.borderRadius[1]} 0`
            : `${theme.borderRadius[1]} 0 0 ${theme.borderRadius[1]}`
        }};
        text-align: center;
      }`;
  }}
`;

const InputWrapper = styled.div`
  margin: ${({ theme }) => `${theme.space[1]} ${0} ${theme.space[3]}`};
  width: 100%;

  ${MessageStyles.Message} {
    margin-top: ${({ theme }) => theme.space[0]};
  }
`;

export default {
  Input,
  InputError,
  InputGroup,
  InputUnit,
  InputWithUnit,
  InputWrapper,
};
