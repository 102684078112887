import StartStep from './StartStep';
import LenderStep from './LenderStep';
import BalanceStep from './BalanceStep';
import PropertyValueStep from './PropertyValueStep';
import TypeStep from './TypeStep';
import FixedRateStep from './FixedRateStep';
import InterestRateStep from './InterestRateStep';
import PaymentsStep from './PaymentsStep';
import DobStep from './DobStep';
import SummaryStep from './SummaryStep';

export const steps = {
  start: StartStep,
  lender: LenderStep,
  balance: BalanceStep,
  propertyValue: PropertyValueStep,
  type: TypeStep,
  fixedRate: FixedRateStep,
  interestRate: InterestRateStep,
  payments: PaymentsStep,
  dob: DobStep,
  summary: SummaryStep,
};

export const getInitialStep = ({ mortgage, user }) => {
  if (!mortgage || !mortgage._id) {
    return 'start';
  }
  if (!mortgage.lender_id) {
    return 'lender';
  }
  if (!mortgage.initialBalance) {
    return 'balance';
  }
  if (!mortgage.estimatedPropertyValue) {
    return 'propertyValue';
  }
  if (!user.accelerateEligibility) {
    return 'type';
  }
  if (!mortgage.fixedRate) {
    return 'fixedRate';
  }
  if (!mortgage.interestRate) {
    return 'interestRate';
  }
  if (!mortgage.monthlyPayment) {
    return 'payments';
  }
  // if (!user.postcode) {
  //   return 'postcode';
  // }
  if (!user.profile.dobYear) {
    return 'dob';
  }
  return 'summary';
};
