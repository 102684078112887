import React from 'react';
import RequestResetPasswordForm from './Form';
import LayoutWithImage from '../../layout/LayoutWithImage/Component';

const RequestResetPasswordView = () => {
  return (
    <LayoutWithImage>
      <h1>Request a password reset link</h1>
      <p>Enter your email below and we&apos;ll send you a special link</p>
      <RequestResetPasswordForm />
    </LayoutWithImage>
  );
};

export default RequestResetPasswordView;
