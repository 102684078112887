import styled from 'styled-components';

const LockedCell = styled.span`
  font-size: 1.4rem;
  padding: 0 10px;

  svg {
    color: ${({ theme, isLocked }) =>
      theme.color[isLocked ? 'gray-700' : 'gray-400']};
  }
`;

export default {
  LockedCell,
};
