import React from 'react';
import PropTypes from 'prop-types';

import { getInterestSaved } from '@rateswitch/amm-utils';

import { formatCurrency } from '@homamo/meadow-utils';

import Panel from '../../common/Panel';
import Button from '../../common/Button';
import Styles from './Styles';
import referralImg from '../../../images/referral.svg';

const RewardsMessage = ({ remainingBalance, interestRate, monthlyPayment }) => {
  const scenario100InterestSaved = getInterestSaved({
    remainingBalance,
    interestRate,
    monthlyPayment,
    overpaymentAmount: 10000,
    overpaymentInterval: 'single',
  });

  return (
    <Styles.RewardsMessage>
      <Panel heading="Accelerate with rewards" smallHeader>
        <img src={referralImg} alt="" />
        <div className="inner">
          <ul>
            <li>
              You can earn unlimited rewards paid directly off your mortgage
              balance
            </li>
            <li>
              A reward payment of £100 today could save you a further{' '}
              {formatCurrency(scenario100InterestSaved)} in interest on your
              remaining balance
            </li>
          </ul>
          <Button mode="primary" label="Earn rewards" linkTo="/rewards" block />
        </div>
      </Panel>
    </Styles.RewardsMessage>
  );
};

RewardsMessage.propTypes = {
  remainingBalance: PropTypes.number.isRequired,
  interestRate: PropTypes.number.isRequired,
  monthlyPayment: PropTypes.number.isRequired,
};

export default RewardsMessage;
