/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Styles as InputStyles } from '../Input';
import Styles from './Styles';

const CheckboxInput = ({
  label,
  isRequired,
  size,
  field: { name, value, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const error = errors[name];
  const touch = touched[name];
  return (
    <Styles.CheckboxInput size={size}>
      <input id={name} type="checkbox" {...field} {...props} checked={value} />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label
        htmlFor={name}
        error={error}
        required={isRequired}
        aria-required={isRequired}
      >
        {label}
        {isRequired && <FontAwesomeIcon icon="asterisk" />}
      </label>
      {touch && error && (
        <InputStyles.InputError>{error}</InputStyles.InputError>
      )}
    </Styles.CheckboxInput>
  );
};

CheckboxInput.defaultProps = {
  isRequired: false,
  size: 'm',
};

CheckboxInput.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  size: PropTypes.string,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
};

export default CheckboxInput;
