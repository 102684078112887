import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import API, { handleApiError } from '../../../../../lib/api';

import OnboardingViewActions from '../../../OnboardingViewActions';
import Button, { ButtonGroup } from '../../../../common/Button';
import Form from '../../../../common/Form';
import RadioInput from '../../../../common/RadioInput';
import RadioGroup from '../../../../common/RadioGroup';
import Message from '../../../../common/Message';
import Error from '../../../../common/ErrorMessage';

import LayoutWithImage from '../../../../layout/LayoutWithImage';
import ArrangeCallback from '../../../../switch/ArrangeCallback';

import checklistImg from '../../../../../images/checklist.svg';

const validationSchema = Yup.object().shape({
  accelerateEligibility: Yup.string().required('You need to select an option'),
});

const OnboardingType = ({
  user,
  setSelectedStep,
  setDisplayError,
  setUserData,
  displayError,
}) => {
  const [isIneligible, setIsIneligible] = React.useState(false);
  const [callbackModalIsOpen, setCallbackModalIsOpen] = React.useState(false);

  return (
    <LayoutWithImage image={checklistImg}>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          if (!callbackModalIsOpen) {
            await API.post(`auth/update-settings`, {
              accelerateEligibility: values.accelerateEligibility === 'no',
            })
              .then((res) => {
                setSelectedStep('fixedRate');
                setUserData(res.data);
              })
              .catch((error) => {
                handleApiError({ error, setDisplayError });
              })
              .finally(() => setSubmitting(false));
          }
        }}
        initialValues={{
          accelerateEligibility: user && user.accelerateEligibility ? 'no' : '',
        }}
        validationSchema={validationSchema}
        render={({ isSubmitting, errors, values, touched }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <h2>Is any part of your mortgage interest only?</h2>
                <RadioGroup
                  id="accelerateEligibility"
                  label="Choose an option"
                  value={values.type}
                  error={errors.type}
                  touched={touched.type}
                >
                  <Field
                    name="accelerateEligibility"
                    id="yes"
                    label="Yes"
                    valueLabel="yes"
                    component={RadioInput}
                    onClick={() => setIsIneligible(true)}
                  />
                  <Field
                    name="accelerateEligibility"
                    id="no"
                    label="No"
                    valueLabel="no"
                    component={RadioInput}
                    onClick={() => setIsIneligible(false)}
                  />
                </RadioGroup>
                {isIneligible && (
                  <Message size="l" mode="warning">
                    We currently only support repayment (capital &amp; interest)
                    mortgages, but we may still be able to help save you money.
                  </Message>
                )}
                <Error error={displayError} />
                {isIneligible ? (
                  <ButtonGroup>
                    <Button
                      label="Back"
                      mode="default"
                      onClick={() => setSelectedStep('propertyValue')}
                    />
                    <ArrangeCallback
                      buttonLabel="Talk to our team"
                      userPhone={user.phone}
                      context="ineligibleOnboarding"
                      blockParentForm={() => setCallbackModalIsOpen(true)}
                      unblockParentForm={() => setCallbackModalIsOpen(false)}
                      buttonMode="primary"
                    />
                  </ButtonGroup>
                ) : (
                  <OnboardingViewActions
                    previousView="propertyValue"
                    isSubmitting={isSubmitting}
                    setSelectedStep={setSelectedStep}
                  />
                )}
              </fieldset>
            </Form>
          );
        }}
      />
    </LayoutWithImage>
  );
};

OnboardingType.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    accelerateEligibility: PropTypes.bool,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingType.defaultProps = {
  displayError: undefined,
};

export default OnboardingType;
