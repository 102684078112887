/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { keepParams } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import SelectInput from '../../common/SelectInput';
import TextInput from '../../common/TextInput';
import CheckboxInput from '../../common/CheckboxInput';
import ImageUpload from '../../common/ImageUpload';
import Textarea from '../../common/Textarea';
import { Styles as InputStyles } from '../../common/Input';

import retailerCategories, {
  retailerCategoryLabel,
} from '../../../lib/retailerCategories';

const validationSchema = Yup.object().shape({
  heading: Yup.string().required('You need to enter a heading'),
  description: Yup.string().required('You need to enter a description'),
  link: Yup.string().required('You need to add a link'),
  advertiserId: Yup.string(),
  partnerNetwork: Yup.string(),
});

const EditRetailerForm = ({ id, initialData }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();
  const [imageUrl, setImageUrl] = React.useState(initialData.image);
  const [isUploading, setIsUploading] = React.useState(false);

  const initialValues = {
    heading: initialData.heading || undefined,
    image: initialData.image || undefined,
    description: initialData.description || undefined,
    link: initialData.link || undefined,
    live: initialData.live || false,
    advertiserId: initialData.advertiserId || undefined,
    partnerNetwork: initialData.partnerNetwork || undefined,
  };

  retailerCategories.forEach((category) => {
    initialValues[category] =
      initialData.categories && initialData.categories.includes(category);
  });

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const update = {
            heading: values.heading,
            description: values.description,
            link: values.link,
            live: values.live,
            image: imageUrl,
            categories: [],
            advertiserId: values.advertiserId,
            partnerNetwork: values.partnerNetwork,
          };

          retailerCategories.forEach((category) => {
            if (values[category]) update.categories.push(category);
          });

          await API.post(`retailers/update/${id}`, update)
            .then((res) => {
              const retailer_id = res.data._id;
              toast.success('Retailer updated');
              history.push(keepParams(`/admin/retailer/${retailer_id}`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset
                disabled={isSubmitting || isUploading}
                aria-busy={isSubmitting || isUploading}
              >
                <Field
                  label="Heading"
                  id="heading"
                  name="heading"
                  placeholder="Enter a heading"
                  component={TextInput}
                  isRequired
                />
                <ImageUpload
                  label="Image"
                  imageUrl={imageUrl}
                  onUpload={() => setIsUploading(true)}
                  onUploadSuccess={(url) => setImageUrl(url)}
                  onUploadError={(error) => setDisplayError(error)}
                  onUploadComplete={() => setIsUploading(false)}
                />
                <Field
                  label="Description"
                  id="description"
                  name="description"
                  placeholder="Enter a description"
                  component={Textarea}
                  isRequired
                />
                <Field
                  label="Link"
                  id="link"
                  name="link"
                  placeholder="Enter a link"
                  component={TextInput}
                  helpMessage="Add {userId} anywhere in the url and it will be automatically be replaced with the user's id in the link when they click it. This can be used to track users. e.g https://www.awin1.com/cread.php?s=2087&amp;v=6683&amp;q=301&amp;r=111&amp;pref1=accelerate&amp;pref2={userId}"
                  isRequired
                />
                <Field
                  label="Advertiser ID"
                  id="advertiserId"
                  name="advertiserId"
                  placeholder="Enter an advertiser ID"
                  component={TextInput}
                />
                <Field
                  label="Partner Network"
                  id="partnerNetwork"
                  name="partnerNetwork"
                  component={SelectInput}
                  options={[
                    { label: 'AWIN', value: 'AWIN' },
                    { label: 'PARTNERIZE', value: 'PARTNERIZE' },
                    { label: 'EBAY', value: 'EBAY' },
                  ]}
                  placeholder="Select a partner network"
                />
                <InputStyles.InputGroup>
                  <Field
                    name="live"
                    id="live"
                    label="Make retailer link live"
                    valueLabel="link"
                    component={CheckboxInput}
                  />
                </InputStyles.InputGroup>
                <InputStyles.InputGroup>
                  <h4>Categories</h4>
                  {retailerCategories.map((category) => (
                    <Field
                      name={category}
                      id={category}
                      label={retailerCategoryLabel[category]}
                      valueLabel={category}
                      component={CheckboxInput}
                    />
                  ))}
                </InputStyles.InputGroup>
                <ButtonGroup>
                  <Button
                    label="Update Retailer"
                    type="submit"
                    mode="primary"
                    loadingLabel="Updating Retailer"
                    isLoading={isSubmitting || isUploading}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/retailers'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

EditRetailerForm.propTypes = {
  id: PropTypes.string.isRequired,
  initialData: PropTypes.shape({
    _id: PropTypes.string,
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    live: PropTypes.bool,
    link: PropTypes.string,
    categories: PropTypes.arrayOf([PropTypes.string]),
    advertiserId: PropTypes.string,
    partnerNetwork: PropTypes.string,
  }),
};

EditRetailerForm.defaultProps = {
  initialData: {},
};

export default EditRetailerForm;
