import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { convertToPence, convertToPounds } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';
import Button from '../../common/Button';
import Form from '../../common/Form';
import Error from '../../common/ErrorMessage';
import CurrencyInput from '../../common/CurrencyInput';
import Message from '../../common/Message';

const validationSchema = Yup.object().shape({
  monthlyPayment: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('Enter your approximate monthly payment'),
  monthlyOverpayment: Yup.string().test({
    name: 'min',
    message: 'You need to enter a value greater than £0',
    test: (value) => {
      return convertToPence(value) >= 0;
    },
  }),
});

const MortgageSettingsPaymentsForm = ({ initialData, refetchData }) => {
  const [displayError, setDisplayError] = React.useState();

  const mortgageId = initialData._id;

  const initialValues = {
    monthlyPayment: initialData.monthlyPayment
      ? convertToPounds(initialData.monthlyPayment)
      : undefined,
    monthlyOverpayment:
      initialData.monthlyOverpayment || initialData.monthlyOverpayment === 0
        ? convertToPounds(initialData.monthlyOverpayment)
        : undefined,
  };

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`mortgages/update/${mortgageId}`, {
            monthlyPayment: values.monthlyPayment
              ? convertToPence(values.monthlyPayment)
              : undefined,
            monthlyOverpayment:
              values.monthlyOverpayment || values.monthlyOverpayment === 0
                ? convertToPence(values.monthlyOverpayment)
                : undefined,
          })
            .then(() => {
              toast.success('Settings updated!');
              refetchData();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="Monthly payment"
                  id="monthlyPayment"
                  name="monthlyPayment"
                  component={CurrencyInput}
                  isRequired
                />
                <Message mode="warning">
                  Updating your monthly payment may affect your remaining
                  balance. You can adjust your remaining balance in your
                  history.
                </Message>
                <Field
                  label="Monthly overpayment"
                  id="monthlyOverpayment"
                  name="monthlyOverpayment"
                  component={CurrencyInput}
                />
                <Button
                  label="Update settings"
                  type="submit"
                  mode="primary"
                  loadingLabel="Updating settings"
                  isLoading={isSubmitting}
                  disabled={!dirty}
                />
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

MortgageSettingsPaymentsForm.propTypes = {
  initialData: PropTypes.shape({
    _id: PropTypes.string,
    monthlyPayment: PropTypes.number,
    monthlyOverpayment: PropTypes.number,
  }),
  refetchData: PropTypes.func.isRequired,
};

MortgageSettingsPaymentsForm.defaultProps = {
  initialData: {},
};

export default MortgageSettingsPaymentsForm;
