import React from 'react';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { keepParams } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';
import Button, { ButtonGroup } from '../../common/Button';
import CheckboxInput from '../../common/CheckboxInput';
import Form from '../../common/Form';
import Error from '../../common/ErrorMessage';
import { Styles as InputStyles } from '../../common/Input';
import TextInput from '../../common/TextInput';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('We need a name to keep the account secure'),
  lastName: Yup.string().required('We need a name to keep the account secure'),
  email: Yup.string()
    .email("Whoops! That doesn't look like a valid email")
    .required('We need an email address to keep the account secure'),
});

const AdminCreateUserForm = () => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const data = {
            profile: { firstName: values.firstName, lastName: values.lastName },
            email: values.email,
            roles: [],
          };

          if (values.roleAdmin) data.roles.push('ADMIN');
          if (values.roleUser) data.roles.push('USER');

          await API.post('users/create', data)
            .then(() => {
              toast.success('User created');
              history.push(keepParams(`/admin/users`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          roleAdmin: '',
          roleUser: true,
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="First name"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter a first name"
                  component={TextInput}
                  isRequired
                />
                <Field
                  label="Last name"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter a last name"
                  component={TextInput}
                  isRequired
                />
                <Field
                  label="Email address"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter an email address"
                  component={TextInput}
                  isRequired
                />
                <InputStyles.InputGroup>
                  <h4>Roles</h4>
                  <Field
                    label="Admin"
                    id="roleAdmin"
                    name="roleAdmin"
                    component={CheckboxInput}
                  />
                  <Field
                    label="User"
                    id="roleUser"
                    name="roleUser"
                    component={CheckboxInput}
                    disabled
                  />
                </InputStyles.InputGroup>
                <ButtonGroup>
                  <Button
                    label="Create User"
                    type="submit"
                    mode="primary"
                    loadingLabel="Creating User"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/users'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AdminCreateUserForm;
