import React from 'react';
import Layout from '../../components/layout/Layout';
import SignIn from '../../components/auth/SignIn';
import AnalyticsWrapper from '../../components/common/AnalyticsWrapper';

const SignInView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Sign in">
        <SignIn />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default SignInView;
