/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Styles as InputStyles } from '../Input';
import InputLabel from '../InputLabel';
import Message from '../Message';

const NumberInput = ({
  label,
  helpMessage,
  isRequired,
  field: { name, disabled, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const error = errors[name];
  const touch = touched[name];
  return (
    <InputStyles.InputWrapper>
      <InputLabel
        label={label}
        htmlFor={name}
        error={error}
        disabled={disabled}
        isRequired={isRequired}
      />
      <InputStyles.Input
        id={name}
        type="number"
        hasError={error && touch}
        {...field}
        {...props}
      />
      {touch && error && (
        <InputStyles.InputError>{error}</InputStyles.InputError>
      )}
      {helpMessage && <Message>{helpMessage}</Message>}
    </InputStyles.InputWrapper>
  );
};

NumberInput.defaultProps = {
  helpMessage: undefined,
  isRequired: false,
};

NumberInput.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  helpMessage: PropTypes.string,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
};

export default NumberInput;
