import React from 'react';
import Layout from '../../components/layout/Layout';
import AccountSettings from '../../components/account/AccountSettings';
import AnalyticsWrapper from '../../components/common/AnalyticsWrapper';

const AccountView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="My Account">
        <AccountSettings />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AccountView;
