/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import API, { handleApiError } from '../../../lib/api';
import EditRetailerView from './View';
import RetailerNotFound from '../RetailerNotFound';

import Loading from '../../common/Loading';

function EditRetailerContainer({ id }) {
  const retailer_id = id;
  // Data state to store the users data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [displayError, setDisplayError] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    await API.get(`retailers/${retailer_id}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!retailer_id) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retailer_id]);

  if (isLoading) return <Loading />;
  return data && data._id ? (
    <EditRetailerView data={data} refetchData={fetchData} />
  ) : (
    <RetailerNotFound id={retailer_id} displayError={displayError} />
  );
}

EditRetailerContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default EditRetailerContainer;
