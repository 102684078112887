import styled from 'styled-components';
import Panel from '../../common/Panel';

const LenderLogo = styled.img`
  width: 100%;
  max-width: 200px;
  max-height: 100px;
  object-fit: contain;
  object-position: left;
`;

const GridPanel = styled(Panel)`
  grid-column: 1;
  grid-row: 1;

  @media ${({ theme }) => theme.device.laptop} {
    grid-column: 2;
    grid-row: 1;
  }
`;

export default { LenderLogo, GridPanel };
