import React from 'react';
import Layout from '../components/layout/Layout';
import Switch from '../components/switch/Switch';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const SwitchView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Switch">
        <Switch />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default SwitchView;
