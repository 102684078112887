/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { checkUserHasOneOfRoles } from '@homamo/meadow-utils';

import Layout from '../../layout/Layout';
import Loading from '../../common/Loading';
import NotAuthorised from '../NotAuthorised';

import { useAuth } from '../../../context/auth';
import TermsAgreement from '../TermsAgreement';
import SuspendedMessage from '../SuspendedMessage';
import Onboarding from '../../onboarding/Onboarding/Container';

/**
 * Checks whether a user is authenticated
 * If not redirects them to sign-in
 * Stores the original path as a referrer to come back to
 */

function PrivateRoute({ component: Component, permit, ...rest }) {
  const { isAuthenticated, user, isCheckingAuth } = useAuth();

  if (isCheckingAuth) return <Loading />;

  // If the user hasn't agreed to terms, show them this message
  if (user && !user.termsAgreed) {
    return <TermsAgreement />;
  }

  // If the user has been suspended, show them this message
  if (user && user.suspended) {
    return <SuspendedMessage />;
  }

  // If the user hasn't completed onboarding yet
  if (user && !user.onboardingComplete) {
    return <Onboarding />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated) {
          return !permit.length || checkUserHasOneOfRoles(user, permit) ? (
            <Component {...props} />
          ) : (
            <Layout>
              <NotAuthorised pathname={props.location.pathname} />
            </Layout>
          );
        }

        return (
          <Redirect
            to={{ pathname: '/sign-in', state: { referer: props.location } }}
          />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  permit: PropTypes.arrayOf(PropTypes.string),
};

PrivateRoute.defaultProps = {
  location: {},
  permit: [],
};

export default PrivateRoute;
