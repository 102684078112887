/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  convertToPounds,
  convertToPence,
  formatCurrency,
} from '@homamo/meadow-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Styles as InputStyles } from '../Input';
import InputLabel from '../InputLabel';
import Message from '../Message';

const CurrencyInput = ({
  label,
  isRequired,
  wholePounds,
  helpMessage,
  field: { name, value, disabled, onBlur, onFocus, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const currencyInputRef = React.useRef(null);
  React.useEffect(() => {
    const handleWheel = (e) => e.preventDefault();
    currencyInputRef.current.addEventListener('wheel', handleWheel);

    return () => {
      if (currencyInputRef && currencyInputRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        currencyInputRef.current.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  const error = errors[name];
  const touch = touched[name];

  const [inputType, setInputType] = React.useState('text');

  const setDisplayValue = (workingValue) => {
    if (workingValue || workingValue === 0) {
      setInputType('text');
      const displayValue = formatCurrency(workingValue, {
        includeSymbol: false,
        source: 'pounds',
        wholePounds,
      });
      setFieldValue(name, displayValue);
    }
  };

  const setWorkingValue = (displayValue) => {
    if (displayValue || displayValue === 0) {
      setInputType('number');
      const workingValue = convertToPounds(convertToPence(displayValue));
      setFieldValue(name, workingValue);
    }
  };

  React.useEffect(() => {
    setDisplayValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <InputStyles.InputWrapper>
      <InputLabel
        label={label}
        htmlFor={name}
        error={error}
        disabled={disabled}
        isRequired={isRequired}
      />
      <InputStyles.InputWithUnit pre>
        <InputStyles.InputUnit pre>
          <FontAwesomeIcon icon="pound-sign" />
        </InputStyles.InputUnit>
        <InputStyles.Input
          id={name}
          type={inputType}
          step={wholePounds ? '1' : '0.01'}
          hasError={error && touch}
          value={value}
          onBlur={(e) => {
            const workingValue = e.target.value;
            setDisplayValue(workingValue);
            if (onBlur) onBlur(e);
          }}
          onFocus={(e) => {
            const displayValue = e.target.value;
            setWorkingValue(displayValue);
            if (onFocus) onFocus(e);
          }}
          ref={currencyInputRef}
          {...field}
          {...props}
        />
      </InputStyles.InputWithUnit>
      {touch && error && (
        <InputStyles.InputError>{error}</InputStyles.InputError>
      )}
      {helpMessage && <Message>{helpMessage}</Message>}
    </InputStyles.InputWrapper>
  );
};

CurrencyInput.defaultProps = {
  isRequired: false,
  helpMessage: undefined,
  wholePounds: false,
};

CurrencyInput.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
  helpMessage: PropTypes.string,
  wholePounds: PropTypes.bool,
};

export default CurrencyInput;
