import React from 'react';

export default (initialValue) => {
  const [rates, setRates] = React.useState(initialValue);

  return {
    rates,
    setRates,

    addRate: (newRate) => {
      setRates([...rates, newRate]);
    },

    deleteRate: (rateId) => {
      setRates((prevRates) => prevRates.filter((rate) => rate._id !== rateId));
    },
  };
};
