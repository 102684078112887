export default {
  'blue-900': 'hsla(200, 41%, 10%, 1.00)',
  'blue-800': 'hsla(199, 66%, 14%, 1.00)',
  'blue-700': 'hsla(199, 60%, 19%, 1.00)',
  'blue-600': 'hsla(199, 47%, 25%, 1.00)',
  'blue-500': 'hsla(199, 31%, 32%, 1.00)',
  'blue-400': 'hsla(201, 29%, 46%, 1.00)',
  'blue-300': 'hsla(199, 49%, 54%, 1.00)',
  'blue-200': 'hsla(200, 70%, 68%, 1.00)',
  'blue-100': 'hsla(199, 74%, 78%, 1.00)',

  'orange-900': 'hsla(16, 78%, 12%, 1.00)',
  'orange-800': 'hsla(16, 74%, 31%, 1.00)',
  'orange-700': 'hsla(16, 82%, 53%, 1.00)',
  'orange-600': 'hsla(27, 85%, 57%, 1.00)',
  'orange-500': 'hsla(38, 93%, 55%, 1.00)',
  'orange-400': 'hsla(38, 100%, 67%, 1.00)',
  'orange-300': 'hsla(38, 100%, 75%, 1.00)',
  'orange-200': 'hsla(37, 100%, 86%, 1.00)',
  'orange-100': 'hsla(36, 100%, 96%, 1.00)',

  'green-900': 'hsla(143, 78%, 12%, 1.00)',
  'green-800': 'hsla(143, 74%, 31%, 1.00)',
  'green-700': 'hsla(143, 100%, 35%, 1.00)',
  'green-600': 'hsla(143, 96%, 41%, 1.00)',
  'green-500': 'hsla(143, 81%, 52%, 1.00)',
  'green-400': 'hsla(143, 89%, 64%, 1.00)',
  'green-300': 'hsla(143, 100%, 75%, 1.00)',
  'green-200': 'hsla(143, 100%, 86%, 1.00)',
  'green-100': 'hsla(141, 100%, 96%, 1.00)',

  'red-900': 'hsla(5, 78%, 12%, 1.00)',
  'red-800': 'hsla(354, 74%, 31%, 1.00)',
  'red-700': 'hsla(4, 100%, 35%, 1.00)',
  'red-600': 'hsla(4, 94%, 44%, 1.00)',
  'red-500': 'hsla(4, 93%, 55%, 1.00)',
  'red-400': 'hsla(4, 100%, 67%, 1.00)',
  'red-300': 'hsla(4, 100%, 75%, 1.00)',
  'red-200': 'hsla(4, 100%, 86%, 1.00)',
  'red-100': 'hsla(3, 100%, 96%, 1.00)',

  'gray-900': 'hsla(180, 5%, 7%, 1.00)',
  'gray-800': 'hsla(197, 12%, 23%, 1.00)',
  'gray-700': 'hsla(197, 11%, 41%, 1.00)',
  'gray-600': 'hsla(199, 14%, 60%, 1.00)',
  'gray-500': 'hsla(198, 20%, 77%, 1.00)',
  'gray-400': 'hsla(199, 28%, 86%, 1.00)',
  'gray-300': 'hsla(200, 33%, 91%, 1.00)',
  'gray-200': 'hsla(204, 33%, 97%, 1.00)',
  'gray-100': 'hsla(180, 25%, 98%, 1.00)',

  'yellow-900': 'hsla(27, 100%, 49%, 1.00)',
  'yellow-800': 'hsla(34, 99%, 49%, 1.00)',
  'yellow-700': 'hsla(43, 99%, 49%, 1.00)',
  'yellow-600': 'hsla(46, 97%, 51%, 1.00)',
  'yellow-500': 'hsla(46, 96%, 57%, 1.00)',
  'yellow-400': 'hsla(47, 96%, 65%, 1.00)',
  'yellow-300': 'hsla(46, 95%, 75%, 1.00)',
  'yellow-200': 'hsla(46, 95%, 85%, 1.00)',
  'yellow-100': 'hsla(47, 93%, 94%, 1.00)',

  white: '#FFFFFF',
};
