import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import ExportMortgagesForm from './Form';

const ExportMortgagesModal = ({ buttonLabel, lenderId }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  return (
    <>
      <Button
        size="s"
        label={buttonLabel || 'Export Mortgages'}
        onClick={() => setModalIsOpen(true)}
      />
      <Modal
        heading="Export Mortgages"
        isOpen={modalIsOpen}
        closeAction={() => setModalIsOpen(false)}
        contentLabel="Export Mortgages"
      >
        <p>Export all Mortgage data as a CSV</p>
        <ExportMortgagesForm
          lenderId={lenderId}
          successAction={() => setModalIsOpen(false)}
          cancelAction={() => setModalIsOpen(false)}
        />
      </Modal>
    </>
  );
};

ExportMortgagesModal.propTypes = {
  buttonLabel: PropTypes.string,
  lenderId: PropTypes.string,
};

ExportMortgagesModal.defaultProps = {
  buttonLabel: undefined,
  lenderId: undefined,
};

export default ExportMortgagesModal;
