import styled from 'styled-components';
import { Styles as ButtonStyles } from '../../../Button';

const Pagination = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${ButtonStyles.ButtonGroup} {
    grid-auto-flow: column;
  }

  ${ButtonStyles.Button} {
    font-size: ${({ theme }) => theme.fontSize[2]};
    padding: ${({ theme }) => `${theme.space[1]}`};
  }

  @media ${({ theme }) => theme.device.mobileL} {
    ${ButtonStyles.Button} {
      font-size: ${({ theme }) => theme.fontSize[3]};
      padding: ${({ theme }) => `${theme.space[2]}`};
    }
  }
`;

const PageDetails = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[1]};
  align-items: center;
  justify-items: center;
  font-size: ${({ theme }) => theme.fontSize[1]};
  padding: ${({ theme }) => theme.space[2]};

  @media ${({ theme }) => theme.device.mobileL} {
    grid-auto-flow: column;
    grid-gap: ${({ theme }) => theme.space[2]};
  }

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ isCompact, theme }) =>
      isCompact ? theme.fontSize[2] : theme.fontSize[3]};
  }

  select {
    font-size: ${({ theme }) => theme.fontSize[1]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ isCompact, theme }) =>
        isCompact ? theme.fontSize[2] : theme.fontSize[3]};
    }
  }
`;

export default {
  Pagination,
  PageDetails,
};
