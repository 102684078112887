import styled from 'styled-components';

const Scenario = styled.div`
  padding: 0;
  border-bottom: 1px solid ${({ theme }) => theme.color['gray-400']};

  p {
    margin: ${({ theme }) => `0 0 ${theme.space[5]} 0`};
  }

  p,
  input[type='date'],
  select {
    font-size: ${({ theme }) => theme.fontSize[3]};
    font-family: ${({ theme }) => theme.fontFamily};
  }

  &:last-child {
    border-bottom: 0;
  }

  @media ${({ theme }) => theme.device.mobileL} {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: ${({ theme }) => `${theme.space[3]}`};
    }

    p,
    input[type='date'],
    select {
      font-size: ${({ theme }) => theme.fontSize[3]};
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    p,
    input[type='date'],
    select {
      font-size: ${({ theme }) => theme.fontSize[4]};
    }
  }
`;

export default {
  Scenario,
};
