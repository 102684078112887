import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  convertToPence,
  convertToPounds,
  keepParams,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import DateInput from '../../common/DateInput';
import CurrencyInput from '../../common/CurrencyInput';

const validationSchema = Yup.object().shape({
  amount: Yup.string().required('You need to enter an amount'),
  paymentDate: Yup.string().required('You need to enter a payment date'),
});

const UpdateTransactionForm = ({ id, initialData, mode }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();

  const isAdminMode = mode === 'admin';

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`transactions/update/${id}`, {
            date: values.paymentDate,
            amount: convertToPence(values.amount),
          })
            .then((res) => {
              const transactionId = res.data._id;
              toast.success('Transaction updated');
              history.push(
                isAdminMode
                  ? keepParams(`/admin/payment/${transactionId}`)
                  : `/transaction/${transactionId}`,
              );
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          amount: initialData.amount
            ? convertToPounds(initialData.amount)
            : undefined,
          paymentDate: initialData.date || undefined,
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="Amount"
                  id="amount"
                  name="amount"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Transaction Date"
                  id="paymentDate"
                  name="paymentDate"
                  component={DateInput}
                  isRequired
                />
                <ButtonGroup>
                  <Button
                    label="Update Transaction"
                    type="submit"
                    mode="primary"
                    loadingLabel="Creating Transaction"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => {
                      history.push(
                        isAdminMode
                          ? keepParams('/admin/payments')
                          : '/history',
                      );
                    }}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

UpdateTransactionForm.propTypes = {
  id: PropTypes.string.isRequired,
  initialData: PropTypes.shape({
    amount: PropTypes.number,
    date: PropTypes.string,
  }),
  mode: PropTypes.string,
};

UpdateTransactionForm.defaultProps = {
  initialData: {},
  mode: 'default',
};

export default UpdateTransactionForm;
