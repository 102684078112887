import React from 'react';
import PropTypes from 'prop-types';
import PageIntro from '../../common/PageIntro';
import Styles from './Styles';

import noRatesImg from '../../../images/no-rates.svg';

const NoRates = ({ heading, text, children }) => {
  return (
    <Styles.NoRates>
      <img src={noRatesImg} alt="" />
      <PageIntro heading={heading} text={text}>
        {children}
      </PageIntro>
    </Styles.NoRates>
  );
};

NoRates.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

NoRates.defaultProps = {
  children: undefined,
};

export default NoRates;
