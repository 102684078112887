import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminCreatePayment from '../../../components/transaction/AdminCreatePayment';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const CreatePaymentView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Create payment">
        <AdminCreatePayment />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default CreatePaymentView;
