/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Styles as InputStyles } from '../Input';
import InputLabel from '../InputLabel';

const DateInput = ({
  label,
  isRequired,
  disabled,
  field: { name, value, ...field }, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const error = errors[name];
  const touch = touched[name];
  return (
    <InputStyles.InputWrapper>
      <InputLabel
        label={label}
        htmlFor={name}
        error={error}
        disabled={disabled}
        isRequired={isRequired}
      />
      <InputStyles.Input
        id={name}
        type="date"
        hasError={error && touch}
        disabled={field.disabled || disabled}
        value={value && new Date(value).toISOString().substr(0, 10)}
        {...field}
        {...props}
      />
      {touch && error && (
        <InputStyles.InputError>{error}</InputStyles.InputError>
      )}
    </InputStyles.InputWrapper>
  );
};

DateInput.defaultProps = {
  disabled: false,
  isRequired: false,
};

DateInput.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
};

export default DateInput;
