/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { formatBytes } from '@homamo/meadow-utils';
import Styles from './Styles';
import InputLabel from '../InputLabel';

const FileInput = React.forwardRef(
  (
    {
      label,
      name,
      isRequired,
      isUploading,
      onChange,
      disabled,
      hideLabel,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = React.useState(false);
    const [selectedFile, setSelectedFile] = React.useState(undefined);

    const updateSelectedFile = (input) => {
      if (input) {
        if (input.files && input.files.length) {
          setSelectedFile(input.files[0]);
        } else {
          setSelectedFile(undefined);
        }
      }
    };

    return (
      <Styles.FileInput isFocused={isFocused}>
        <InputLabel
          label={label}
          htmlFor={name}
          disabled={disabled}
          isHidden={hideLabel}
          isRequired={isRequired}
        />
        <Styles.FileInputLabel htmlFor={name}>
          {selectedFile ? (
            <span>
              {selectedFile.name} - {formatBytes(selectedFile.size)}
            </span>
          ) : (
            <span>Choose a file</span>
          )}
          <Styles.FileInputField
            type="file"
            id={name}
            name={name}
            ref={ref}
            {...props}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(e) => {
              updateSelectedFile(e.target);
              if (onChange) onChange(e);
            }}
          />
        </Styles.FileInputLabel>
      </Styles.FileInput>
    );
  },
);

FileInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isUploading: PropTypes.bool,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
  onChange: PropTypes.func,
};

FileInput.defaultProps = {
  isUploading: false,
  isRequired: false,
  disabled: false,
  hideLabel: false,
  onChange: undefined,
};

export default FileInput;
