import React from 'react';
import PropTypes from 'prop-types';
import EditRewardForm from './Form';
import AdminNav from '../../admin/AdminNav';

const EditRewardView = ({ data }) => {
  return (
    <div>
      <AdminNav />
      <h1>Edit reward: {data._id}</h1>
      <EditRewardForm id={data._id} initialData={data} />
    </div>
  );
};

EditRewardView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export default EditRewardView;
