import React from 'react';
import PropTypes from 'prop-types';
import {
  formatCurrency,
  formatDateTime,
  formatPercentage,
  keepParams,
} from '@homamo/meadow-utils';

import Definition, { DefinitionList } from '../../common/Definition';
import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Panel, { PanelColumn } from '../../common/Panel';
import AdminNav from '../../admin/AdminNav';

const RateView = ({ data, displayError }) => {
  const termWidth = '200px';

  return (
    <div>
      <AdminNav />
      <h1>{data.product}</h1>
      <ButtonGroup>
        <Button
          size="s"
          label="View all rates"
          icon="arrow-left"
          linkTo={keepParams('/admin/rates')}
        />
        <Button
          size="s"
          label="Edit"
          linkTo={keepParams(`/admin/rate/${data._id}/edit`)}
        />
      </ButtonGroup>
      <Error error={displayError} />
      <PanelColumn>
        <Panel heading="Details">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Lender"
              definition={data.lender && data.lender.name}
            />
            <Definition
              term="Rate"
              definition={formatPercentage(data.rate, 2)}
            />
            <Definition term="Fee" definition={formatCurrency(data.fee)} />
          </DefinitionList>
        </Panel>
        <Panel heading="Eligibility">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Min Loan Amount"
              definition={formatCurrency(data.loanAmountMin)}
            />
            <Definition
              term="Max Loan Amount"
              definition={formatCurrency(data.loanAmountMax)}
            />
            <Definition
              term="Min LTV"
              definition={formatPercentage(data.ltvMin, 2)}
            />
            <Definition
              term="Max LTV"
              definition={formatPercentage(data.ltvMax, 2)}
            />
            <Definition term="Info" definition={data.info} />
          </DefinitionList>
        </Panel>
        <Panel heading="Meta">
          <DefinitionList termWidth={termWidth}>
            <Definition term="ID" definition={data._id} />
            <Definition term="Order Rate ID" definition={data.rateId} />
            <Definition
              term="Created"
              definition={formatDateTime(data.createdAt)}
            />
            <Definition
              term="Updated"
              definition={formatDateTime(data.updatedAt)}
            />
          </DefinitionList>
        </Panel>
      </PanelColumn>
    </div>
  );
};

RateView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    lender: PropTypes.shape({
      name: PropTypes.string,
    }),
    product: PropTypes.string,
    rateId: PropTypes.string,
    rate: PropTypes.number,
    fee: PropTypes.number,
    loanAmountMin: PropTypes.number,
    loanAmountMax: PropTypes.number,
    ltvMin: PropTypes.number,
    ltvMax: PropTypes.number,
    info: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

RateView.defaultProps = {
  displayError: undefined,
};

export default RateView;
