import React from 'react';
import Button, { Styles as ButtonStyles } from '../../common/Button';
import LendersTable from '../LendersTable';
import AdminNav from '../../admin/AdminNav';

const LendersView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Lenders</h1>
      <ButtonStyles.ButtonGroup>
        <Button
          label="Create lender"
          mode="primary"
          linkTo="/admin/create-lender"
          size="s"
        />
      </ButtonStyles.ButtonGroup>

      <LendersTable />
    </div>
  );
};

export default LendersView;
