import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  formatTerm,
  getInterestSaved,
  getSwitchStatus,
  getTermReduction,
} from '@rateswitch/amm-utils';

import { formatCurrency, formatUserName } from '@homamo/meadow-utils';

import { useAuth } from '../../../context/auth';
import Error from '../../common/ErrorMessage';
import DashboardBlock from '../DashboardBlock';
import { PanelGroup } from '../../common/Panel';
import PageIntro from '../../common/PageIntro';
import Styles from './Styles';

import rewardsImg from '../../../images/rewards.svg';
import overpayImg from '../../../images/overpay.svg';
import switchImg from '../../../images/switch.svg';

const DashboardView = ({ mortgage, displayError }) => {
  const { user } = useAuth();

  const {
    // lender,
    // accountNumber,
    // initialBalance,
    remainingBalance,
    interestRate,
    monthlyPayment,
    fixedRate,
    fixedRateEndDate,
  } = mortgage;

  // const mortgageId = mortgage.id;

  const monthlyOverpayment = mortgage.monthlyOverpayment || 0;

  const status = getSwitchStatus({
    fixedRate,
    fixedRateEndDate,
    interestRate,
  });

  // SWITCH
  const switchStatusMessage = {
    now: 'Time to switch',
    soon: `Get ready to switch`,
    future: `You're currently fixed`,
    unknown: 'You may be able to switch',
  }[status.label];

  const switchStatusIcon = {
    now: 'check-circle',
    soon: `calendar-alt`,
    future: 'calendar-alt',
    unknown: 'question-circle',
  }[status.label];

  const switchStatusText = {
    now: `We help homeowners like you save by switching to a better rate. We'll also pay up to £100 off your mortgage balance.`,
    soon: `Looks like you'll soon be able to switch to a better rate. Take a look at the rates available today.`,
    future: `We're continuously reviewing the best rates available to you and will alert you when you can switch.`,
    unknown: `We help homeowners like you save by switching to a better rate. We'll also pay up to £100 off your mortgage balance.`,
  }[status.label];

  const switchButtonText = {
    now: 'Find a better rate',
    soon: 'Discover rates',
    future: 'Find out more',
    unknown: 'Discover rates',
  }[status.label];

  // OVERPAY
  /* We round it so there's no pence */
  const scenarioMonthlyOverpayment = monthlyOverpayment
    ? Math.floor(monthlyOverpayment * 0.2)
    : 10000;

  const existingTotalMonthlyPayment = monthlyOverpayment
    ? monthlyPayment + monthlyOverpayment
    : monthlyPayment;

  const scenarioTermReduction = getTermReduction({
    remainingBalance,
    interestRate,
    monthlyPayment: existingTotalMonthlyPayment,
    overpaymentAmount: scenarioMonthlyOverpayment,
    overpaymentInterval: 'monthly',
  });

  const scenarioInterestSaved = getInterestSaved({
    remainingBalance,
    interestRate,
    monthlyPayment: existingTotalMonthlyPayment,
    overpaymentAmount: scenarioMonthlyOverpayment,
    overpaymentInterval: 'monthly',
  });

  /* OVERPAY MESSAGE */
  const overpayAmount = formatCurrency(scenarioMonthlyOverpayment);
  const overpayTimeSaved = formatTerm(scenarioTermReduction);
  const overpayInterestSaved = formatCurrency(scenarioInterestSaved, {
    wholePounds: true,
  });

  const overpayMessage =
    monthlyOverpayment > 0
      ? `Overpaying ${overpayAmount} more each month could save you ${overpayInterestSaved} in interest and give you an extra ${overpayTimeSaved} of mortgage free life.`
      : `Overpaying ${overpayAmount} monthly could save you ${overpayInterestSaved} in interest and give you an extra ${overpayTimeSaved} of mortgage free life.`;

  return (
    <Styles.Dashboard>
      <Error error={displayError} />
      <PageIntro
        heading={`Hi ${formatUserName(user, {
          first: true,
        })}, here's how you can accelerate your mortgage today`}
        compact
      />
      <PanelGroup>
        <DashboardBlock
          heading="Rewards"
          image={rewardsImg}
          eligible
          eligibilityMessage="You're eligible"
          message="Our retail cashback rewards and refer a friend scheme can accelerate you to mortgage freedom."
          buttonLabel="Earn rewards"
          buttonLink="/rewards"
        />
        <DashboardBlock
          heading="Overpay"
          image={overpayImg}
          eligible
          eligibilityMessage="You're eligible"
          message={overpayMessage}
          buttonLabel="Explore overpayments"
          buttonLink="/overpay"
        />
        <DashboardBlock
          heading="Switch"
          image={switchImg}
          eligible={status.label === 'now'}
          eligibilityMessage={switchStatusMessage}
          eligibilityIcon={switchStatusIcon}
          message={switchStatusText}
          buttonLabel={switchButtonText}
          buttonLink="/switch"
        />
      </PanelGroup>
      <div className="footer">
        <Link to="/mortgage">See your progress to mortgage freedom</Link>
      </div>
    </Styles.Dashboard>
  );
};

DashboardView.propTypes = {
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
  mortgage: PropTypes.shape({
    initialBalance: PropTypes.number,
    monthlyOverpayment: PropTypes.number,
    remainingBalance: PropTypes.number,
    interestRate: PropTypes.number,
    monthlyPayment: PropTypes.number,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
  }).isRequired,
};

DashboardView.defaultProps = {
  displayError: undefined,
};

export default DashboardView;
