import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  convertDecimalToPercent,
  convertPercentToDecimal,
  convertToPence,
  convertToPounds,
  formatDateTime,
  getDateFromMonthAndYear,
  getMonthSelectOptions,
  getYearSelectOptions,
  keepParams,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import SelectInput from '../../common/SelectInput';
import CurrencyInput from '../../common/CurrencyInput';
import CheckboxInput from '../../common/CheckboxInput';
import RadioInput from '../../common/RadioInput';
import RadioGroup from '../../common/RadioGroup';
import LenderSelect from '../../lender/LenderSelect';
import PercentageInput from '../../common/PercentageInput';

const validationSchema = Yup.object().shape({
  lender: Yup.string().required('Select your lender from the list'),
  initialBalance: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('You need to enter your balance'),
  estimatedPropertyValue: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('Enter an estimated property valuation'),
  accountNumber: Yup.string(),
  monthlyPayment: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('Enter your approximate monthly payment'),
  monthlyOverpayment: Yup.string().test({
    name: 'min',
    message: 'You need to enter a value greater than £0',
    test: (value) => {
      return convertToPence(value) >= 0;
    },
  }),
  interestRate: Yup.number().required('Enter your interest rate'),
  fixedRate: Yup.string().required('Choose an option'),
  fixedRateEndMonth: Yup.string().when('fixedRate', {
    is: 'yes',
    then: Yup.string().required('Select the month your fixed rate ends'),
    otherwise: Yup.string(),
  }),
  fixedRateEndYear: Yup.string().when('fixedRate', {
    is: 'yes',
    then: Yup.string().required('Select the year your fixed rate ends'),
    otherwise: Yup.string(),
  }),
  switchInProgress: Yup.boolean(),
});

const AdminEditMortgageForm = ({ initialData }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();

  const mortgageId = initialData._id;

  const initialValues = {
    lender: initialData.lender_id || undefined,
    initialBalance: initialData.initialBalance
      ? convertToPounds(initialData.initialBalance)
      : undefined,
    estimatedPropertyValue: initialData.estimatedPropertyValue
      ? convertToPounds(initialData.estimatedPropertyValue)
      : undefined,
    accountNumber: initialData.accountNumber || undefined,
    monthlyPayment: initialData.monthlyPayment
      ? convertToPounds(initialData.monthlyPayment)
      : undefined,
    monthlyOverpayment: initialData.monthlyOverpayment
      ? convertToPounds(initialData.monthlyOverpayment)
      : undefined,
    interestRate: initialData.interestRate
      ? convertDecimalToPercent(initialData.interestRate)
      : undefined,
    fixedRate: initialData.fixedRate || undefined,
    fixedRateEndMonth:
      (initialData.fixedRateEndDate &&
        formatDateTime(initialData.fixedRateEndDate, 'mon').toLowerCase()) ||
      '',
    fixedRateEndYear:
      (initialData.fixedRateEndDate &&
        formatDateTime(initialData.fixedRateEndDate, 'year')) ||
      '',
    switchInProgress: initialData.switchInProgress,
  };

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const {
            fixedRate,
            fixedRateEndMonth,
            fixedRateEndYear,
            switchInProgress,
          } = values;

          const fixedRateEndDate =
            fixedRate === 'yes' &&
            fixedRateEndMonth !== '' &&
            fixedRateEndMonth !== null &&
            fixedRateEndYear !== '' &&
            fixedRateEndYear !== null
              ? getDateFromMonthAndYear(fixedRateEndMonth, fixedRateEndYear)
              : null;

          await API.post(`mortgages/update/${mortgageId}`, {
            lender_id: values.lender,
            accountNumber: values.accountNumber,
            initialBalance: convertToPence(values.initialBalance),
            estimatedPropertyValue: convertToPence(
              values.estimatedPropertyValue,
            ),
            monthlyPayment: convertToPence(values.monthlyPayment),
            monthlyOverpayment: convertToPence(values.monthlyOverpayment),
            interestRate: convertPercentToDecimal(values.interestRate),
            fixedRate: values.fixedRate,
            fixedRateEndDate,
            switchInProgress,
          })
            .then(() => {
              toast.success('Mortgage updated');
              history.push(keepParams(`/admin/mortgage/${mortgageId}`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, values, touched, errors }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  name="switchInProgress"
                  id="switchInProgress"
                  label="Switch in progress"
                  component={CheckboxInput}
                />
                <LenderSelect />
                <Field
                  label="Mortgage account number"
                  id="accountNumber"
                  name="accountNumber"
                  component={TextInput}
                  helpMessage="When you’ve earned a reward we use your account
                              number to contribute to paying off your mortgage"
                />
                <Field
                  label="Initial balance"
                  id="initialBalance"
                  name="initialBalance"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Estimated property valuation"
                  id="estimatedPropertyValue"
                  name="estimatedPropertyValue"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Monthly payment"
                  id="monthlyPayment"
                  name="monthlyPayment"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Monthly overpayment"
                  id="monthlyOverpayment"
                  name="monthlyOverpayment"
                  component={CurrencyInput}
                />
                <Field
                  label="Interest rate"
                  id="interestRate"
                  name="interestRate"
                  component={PercentageInput}
                  isRequired
                />
                <RadioGroup
                  id="fixedRate"
                  label="Are you on a fixed rate?"
                  value={values.fixedRate}
                  error={errors.fixedRate}
                  touched={touched.fixedRate}
                  isRequired
                >
                  <Field
                    name="fixedRate"
                    id="fixed-yes"
                    label="Yes"
                    valueLabel="yes"
                    component={RadioInput}
                  />
                  <Field
                    name="fixedRate"
                    id="fixed-no"
                    label="No"
                    valueLabel="no"
                    component={RadioInput}
                  />
                  <Field
                    name="fixedRate"
                    id="fixed-unknown"
                    label="I don't know"
                    valueLabel="unknown"
                    component={RadioInput}
                  />
                </RadioGroup>
                <Field
                  label="Month your fixed rate ends"
                  id="fixedRateEndMonth"
                  name="fixedRateEndMonth"
                  component={SelectInput}
                  options={getMonthSelectOptions()}
                  placeholder="Select a month"
                  disabled={values.fixedRate !== 'yes'}
                />
                <Field
                  label="Year your fixed rate ends"
                  id="fixedRateEndYear"
                  name="fixedRateEndYear"
                  component={SelectInput}
                  options={getYearSelectOptions({
                    range: 10,
                    type: 'future',
                    ascending: true,
                  })}
                  placeholder="Select a year"
                  disabled={values.fixedRate !== 'yes'}
                />
                <ButtonGroup>
                  <Button
                    label="Update Mortgage"
                    type="submit"
                    mode="primary"
                    loadingLabel="Updating Mortgage"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/mortgages'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

AdminEditMortgageForm.propTypes = {
  initialData: PropTypes.shape({
    _id: PropTypes.string,
    owner_id: PropTypes.string,
    owner: PropTypes.shape({
      _id: PropTypes.string,
    }),
    lender_id: PropTypes.string,
    lender: PropTypes.shape({
      name: PropTypes.string,
    }),
    accountNumber: PropTypes.string,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
    remainingBalance: PropTypes.number,
    estimatedPropertyValue: PropTypes.number,
    initialBalance: PropTypes.number,
    totalPayments: PropTypes.number,
    totalRewards: PropTypes.number,
    monthlyPayment: PropTypes.number,
    monthlyOverpayment: PropTypes.number,
    interestRate: PropTypes.number,
    switchInProgress: PropTypes.bool,
  }),
};

AdminEditMortgageForm.defaultProps = {
  initialData: {},
};

export default AdminEditMortgageForm;
