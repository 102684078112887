/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles';

const DashboardEligibility = ({ message, icon, ...props }) => {
  return (
    <Styles.DashboardEligibility {...props}>
      <FontAwesomeIcon icon={icon} />
      {message}
    </Styles.DashboardEligibility>
  );
};

DashboardEligibility.propTypes = {
  message: PropTypes.string.isRequired,
  eligible: PropTypes.bool.isRequired,
  icon: PropTypes.string.isRequired,
};

export default DashboardEligibility;
