import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles';

const views = [
  { label: 'Lender', view: 'lender' },
  { label: 'Balance', view: 'balance' },
  { label: 'Property', view: 'propertyValue' },
  { label: 'Type', view: 'type' },
  { label: 'Rate', view: 'fixedRate', secondaryView: 'interestRate' },
  { label: 'Payments', view: 'payments' },
  { label: 'Details', view: 'dob' },
  { label: 'Summary', view: 'summary' },
];

const completeCheck = ({ view, mortgage, user }) => {
  if (view === 'lender') return mortgage && mortgage.lender_id;
  if (view === 'balance')
    return (
      mortgage && (mortgage.initialBalance || mortgage.initialBalance === 0)
    );
  if (view === 'propertyValue')
    return (
      mortgage &&
      (mortgage.estimatedPropertyValue || mortgage.estimatedPropertyValue === 0)
    );
  if (view === 'type') return user && user.accelerateEligibility;
  if (view === 'fixedRate')
    return (
      mortgage &&
      (mortgage.interestRate || mortgage.interestRate === 0) &&
      (mortgage.fixedRate || mortgage.fixedRate === 0)
    );
  if (view === 'payments')
    return (
      mortgage && (mortgage.monthlyPayment || mortgage.monthlyPayment === 0)
    );
  if (view === 'dob') return user && user.dobYear;
  return false;
};

const disabledCheck = ({ view, mortgage, user }) => {
  if (!mortgage) return true;
  if (view === 'lender') return !mortgage.lender_id;
  if (view === 'balance') return !mortgage.lender_id;
  if (view === 'propertyValue')
    return !mortgage.initialBalance && mortgage.initialBalance !== 0;
  if (view === 'type')
    return (
      !mortgage.estimatedPropertyValue && mortgage.estimatedPropertyValue !== 0
    );
  if (view === 'fixedRate') return !user || !user.accelerateEligibility;
  if (view === 'payments')
    return (
      (!mortgage.interestRate && mortgage.interestRate !== 0) ||
      (!mortgage.fixedRate && mortgage.fixedRate !== 0)
    );
  if (view === 'dob')
    return !mortgage.monthlyPayment && mortgage.monthlyPayment !== 0;
  if (view === 'summary')
    return !mortgage.monthlyPayment && mortgage.monthlyPayment !== 0;
  return true;
};

const OnboardingNav = ({ setSelectedStep, selectedStep, mortgage, user }) => {
  return (
    <Styles.Nav>
      {views.map(({ label, view, secondaryView }) => {
        return (
          <Styles.NavItem
            key={label}
            type="button"
            onClick={() => {
              return mortgage && selectedStep !== view && setSelectedStep(view);
            }}
            isActive={selectedStep === view || selectedStep === secondaryView}
            disabled={disabledCheck({ view, mortgage, user })}
          >
            {completeCheck({ view, mortgage, user }) && (
              <FontAwesomeIcon icon="check-circle" />
            )}{' '}
            {label}
          </Styles.NavItem>
        );
      })}
    </Styles.Nav>
  );
};

OnboardingNav.propTypes = {
  setSelectedStep: PropTypes.func.isRequired,
  selectedStep: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export default OnboardingNav;
