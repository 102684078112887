import React from 'react';
import PropTypes from 'prop-types';
import Password from '../AccountSettingsPassword';
import Profile from '../AccountSettingsProfile';
import Referrals from '../AccountSettingsReferrals';
import Notifications from '../AccountSettingsNotifications';
import DeleteAccount from '../AccountSettingsDelete';

import Tabs from '../../common/Tabs';

const AccountSettingsView = ({ data, refetchData }) => {
  return (
    <div>
      <h1>Account Settings</h1>
      <Tabs
        items={[
          {
            label: 'Profile',
            path: '/account',
            component: <Profile data={data} refetchData={refetchData} />,
          },

          {
            label: 'Password',
            path: '/account/password',
            component: <Password />,
          },
          {
            label: 'Notifications',
            path: '/account/notifications',
            component: <Notifications data={data} refetchData={refetchData} />,
          },
          {
            label: 'Refer a friend',
            path: '/account/referrals',
            component: <Referrals data={data} refetchData={refetchData} />,
          },
          {
            label: 'Delete account',
            path: '/account/delete',
            component: <DeleteAccount data={data} refetchData={refetchData} />,
          },
        ]}
      />
    </div>
  );
};

AccountSettingsView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

AccountSettingsView.defaultProps = {
  data: {},
};

export default AccountSettingsView;
