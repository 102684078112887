import React from 'react';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import ConfirmModalForm from './Form';

const ConfirmModal = ({
  heading,
  message,
  contentLabel,
  confirmAction,
  confirmButtonLabel,
  confirmButtonLoadingLabel,
  confirmButtonMode,
  confirmTextValue,
  cancelAction,
  cancelButtonLabel,
  modalIsOpen,
  closeModal,
}) => {
  return (
    <Modal
      heading={heading}
      isOpen={modalIsOpen}
      closeAction={() => closeModal()}
      contentLabel={contentLabel}
    >
      {message}
      <ConfirmModalForm
        confirmButtonLabel={confirmButtonLabel}
        confirmButtonLoadingLabel={confirmButtonLoadingLabel}
        cancelButtonLabel={cancelButtonLabel}
        confirmButtonMode={confirmButtonMode}
        confirmTextValue={confirmTextValue}
        confirmAction={() => {
          closeModal();
          if (confirmAction) confirmAction();
        }}
        cancelAction={() => {
          closeModal();
          if (cancelAction) cancelAction();
        }}
      />
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  heading: 'Are you sure?',
  message: undefined,
  contentLabel: 'Confirm',
  confirmAction: undefined,
  cancelAction: undefined,
  confirmTextValue: undefined,
  confirmButtonLabel: undefined,
  confirmButtonLoadingLabel: undefined,
  cancelButtonLabel: undefined,
  modalIsOpen: false,
  confirmButtonMode: undefined,
};

ConfirmModal.propTypes = {
  heading: PropTypes.string,
  message: PropTypes.node,
  contentLabel: PropTypes.string,
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func,
  confirmButtonLabel: PropTypes.string,
  confirmButtonLoadingLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  confirmTextValue: PropTypes.string,
  modalIsOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  confirmButtonMode: PropTypes.string,
};

export default ConfirmModal;
