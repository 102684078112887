import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@homamo/meadow-utils';

import { Link } from 'react-router-dom';
import { ButtonGroup } from '../../common/Button';
import Message from '../../common/Message';
import MarketingSiteLink from '../../common/MarketingSiteLink';
import HistoryHeader from '../../layout/HistoryHeader';
import TransactionsTable from '../TransactionsTable';
import LogTransactionModal from '../LogTransaction';
import AdjustBalanceModal from '../../mortgage/AdjustBalance';

const TransactionHistoryView = ({ mortgageId, mortgage, user, fetchData }) => {
  const {
    remainingBalance,
    monthlyPayment,
    monthlyOverpayment,
    totalPayments,
    updatedAt,
  } = mortgage;

  return (
    <div>
      <HistoryHeader
        heading="Transaction History"
        description="We’ll log your contractual monthly mortgage payments, calculated interest, and any monthly overpayments you’ve informed us of automatically on the 2nd of each month."
        blocks={[
          {
            label: 'Remaining balance',
            value: formatCurrency(remainingBalance || 0),
          },

          {
            label: 'Total payments',
            value: formatCurrency(totalPayments || 0),
          },

          {
            label: 'Monthly payment',
            value: formatCurrency(monthlyPayment || 0),
          },

          {
            label: 'Overpayment',
            value: `${formatCurrency(monthlyOverpayment || 0)} monthly`,
          },
        ]}
        updatedAt={updatedAt}
      />
      <ButtonGroup center>
        <LogTransactionModal
          mortgageId={mortgageId}
          successAction={() => {
            if (fetchData) fetchData();
          }}
        />
        <AdjustBalanceModal
          mortgageId={mortgageId}
          remainingBalance={remainingBalance}
          successAction={() => {
            if (fetchData) fetchData();
          }}
        />
      </ButtonGroup>
      <TransactionsTable
        ownerId={user._id}
        mortgageId={mortgageId}
        remainingBalance={mortgage.remainingBalance}
      />
      <Message big mode="warning" center>
        <p>
          We lock rewards on the 2nd of each month. If your remaining balance is
          inaccurate you can change it here and we&apos;ll add an adjustment to
          your history.{' '}
          <MarketingSiteLink
            path="/track"
            label="Find out more about transactions."
          />
        </p>
        <p>
          <small>
            To change other information{' '}
            <Link to="/mortgage/settings">update your details</Link> or{' '}
            <Link to="/mortgage/settings/payments">
              update your overpayment amount
            </Link>
          </small>
        </p>
      </Message>
    </div>
  );
};

TransactionHistoryView.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    monthlyOverpayment: PropTypes.number,
    lender: PropTypes.shape({
      name: PropTypes.string,
    }),
    accountNumber: PropTypes.string,
    initialBalance: PropTypes.number,
    remainingBalance: PropTypes.number,
    totalPayments: PropTypes.number,
    monthlyPayment: PropTypes.number,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
    updatedAt: PropTypes.string,
  }),
  user: PropTypes.shape({
    _id: PropTypes.string,
    profile: PropTypes.shape({
      dobMonth: PropTypes.string,
      dobYear: PropTypes.string,
    }),
  }),
  fetchData: PropTypes.func,
};

TransactionHistoryView.defaultProps = {
  mortgage: {},
  user: {},
  fetchData: undefined,
};

export default TransactionHistoryView;
