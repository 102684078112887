import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { convertToPence, convertToPounds } from '@homamo/meadow-utils';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import CurrencyInput from '../../common/CurrencyInput';
import DateInput from '../../common/DateInput';
import API, { handleApiError } from '../../../lib/api';

const validationSchema = Yup.object().shape({
  amount: Yup.string().required('You need to enter an amount'),
  paymentDate: Yup.string().required('You need to enter a payment date'),
});

const LogTransactionForm = ({
  mortgageId,
  successAction,
  cancelAction,
  monthlyOverpayment,
  amount,
}) => {
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`transactions/create`, {
            mortgageId,
            date: values.paymentDate,
            amount: convertToPence(values.amount),
            type: 'PAYMENT',
            description: 'SINGLE_OVERPAYMENT',
          })
            .then(() => {
              setSubmitting(false);
              toast.success('Payment logged');
              if (successAction) successAction();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          amount: convertToPounds(amount || monthlyOverpayment || 5000),
          paymentDate: new Date(),
        }}
        validationSchema={validationSchema}
        render={({ errors, isSubmitting }) => {
          return (
            <Form>
              <Field
                label="Amount"
                id="amount"
                name="amount"
                placeholder="Enter an amount"
                component={CurrencyInput}
                isRequired
              />
              <Field
                label="Payment date"
                id="paymentDate"
                name="paymentDate"
                component={DateInput}
                isRequired
              />
              <Error error={errors} />
              <ButtonGroup>
                <Button
                  label="Log payment"
                  mode="primary"
                  type="submit"
                  loadingLabel="Logging payment"
                  isLoading={isSubmitting}
                />
                {cancelAction && (
                  <Button label="Cancel" onClick={cancelAction} />
                )}
              </ButtonGroup>
            </Form>
          );
        }}
      />
    </>
  );
};

LogTransactionForm.defaultProps = {
  successAction: undefined,
  cancelAction: undefined,
  monthlyOverpayment: 0,
  amount: undefined,
};

LogTransactionForm.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  monthlyOverpayment: PropTypes.number,
  successAction: PropTypes.func,
  cancelAction: PropTypes.func,
  amount: PropTypes.number,
};

export default LogTransactionForm;
