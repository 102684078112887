import React from 'react';
import PropTypes from 'prop-types';
import {
  formatCurrency,
  formatDateTime,
  formatPercentage,
} from '@homamo/meadow-utils';

import HeaderInfoBlock from '../../common/HeaderInfoBlock';

import Styles from './Styles';

const MortgageBalanceComponent = ({
  remainingBalance,
  initialBalance,
  interestRate,
  fixedRate,
  fixedRateEndDate,
  lender,
  accountNumber,
}) => {
  return (
    <Styles.GridPanel heading="My Mortgage" smallHeader>
      <HeaderInfoBlock
        label="Remaining balance"
        value={formatCurrency(remainingBalance)}
        subLabel={`Initial: ${formatCurrency(initialBalance)}`}
      />
      <HeaderInfoBlock
        label="Lender"
        value={lender.name}
        subLabel={
          accountNumber
            ? `Account number: ${accountNumber}`
            : 'Add an account number'
        }
        linkHref={!accountNumber ? '/mortgage/settings' : ''}
      >
        {lender.image && (
          <Styles.LenderLogo src={lender.image} alt={lender.name} />
        )}
      </HeaderInfoBlock>
      <HeaderInfoBlock
        label="Current rate"
        value={formatPercentage(interestRate, 2)}
        subLabel={
          fixedRate === 'yes' && fixedRateEndDate
            ? `Fixed until ${formatDateTime(fixedRateEndDate, 'monthYear')}`
            : null
        }
      />
    </Styles.GridPanel>
  );
};

MortgageBalanceComponent.defaultProps = {
  accountNumber: undefined,
  fixedRate: 'unknown',
  fixedRateEndDate: undefined,
};

MortgageBalanceComponent.propTypes = {
  remainingBalance: PropTypes.number.isRequired,
  initialBalance: PropTypes.number.isRequired,
  interestRate: PropTypes.number.isRequired,
  accountNumber: PropTypes.string,
  lender: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  fixedRate: PropTypes.string,
  fixedRateEndDate: PropTypes.string,
};

export default MortgageBalanceComponent;
