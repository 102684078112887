import styled from 'styled-components';

const HeaderInfoGroup = styled.dl`
  @media ${({ theme }) => theme.device.tablet} {
    display: grid;
    grid-gap: 3rem;
    justify-content: ${({ center }) => (center ? 'center' : 'start')};
    grid-auto-flow: column;
    grid-auto-columns: auto;
  }
`;

export default { HeaderInfoGroup };
