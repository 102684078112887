import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import Rate from '../../../components/rate/Rate';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const RateView = () => {
  const { id } = useParams();

  return (
    <AnalyticsWrapper>
      <Layout title="Rate">
        <Rate id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default RateView;
