import React from 'react';
import Layout from '../components/layout/Layout';
import MortgageSettings from '../components/mortgage/MortgageSettings';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const MortgageSettingsView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Mortgage Settings">
        <MortgageSettings />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default MortgageSettingsView;
