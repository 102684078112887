import React from 'react';
import PropTypes from 'prop-types';

import { formatTerm, getRemainingTerm } from '@rateswitch/amm-utils';

import {
  formatCurrency,
  formatDateTime,
  formatPercentage,
  formatUserName,
  keepParams,
} from '@homamo/meadow-utils';

import Definition, { DefinitionList } from '../../common/Definition';
import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Panel, { PanelColumn } from '../../common/Panel';
import Icon from '../../common/Icon';
import AdminNav from '../../admin/AdminNav';

const AdminMortgageView = ({ data, displayError }) => {
  const termWidth = '200px';

  return (
    <div>
      <AdminNav />
      <h1>Mortgage</h1>
      <ButtonGroup>
        <Button
          size="s"
          label="View all mortgages"
          icon="arrow-left"
          linkTo={keepParams('/admin/mortgages')}
        />

        <Button
          size="s"
          label="Edit"
          linkTo={keepParams(`/admin/mortgage/${data._id}/edit`)}
        />
      </ButtonGroup>
      <Error error={displayError} />
      <PanelColumn>
        <Panel heading="Details">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Owner"
              definition={formatUserName(data.owner)}
              linkTo={`/admin/user/${data.owner_id}`}
            />
            <Definition
              term="Lender"
              definition={data.lender && data.lender.name}
              linkTo={`/admin/lender/${data.lender_id}`}
            />

            <Definition term="Account Number" definition={data.accountNumber} />
            <Definition
              term="Initial Balance"
              definition={formatCurrency(data.initialBalance)}
            />
            <Definition
              term="Remaining Balance"
              definition={formatCurrency(data.remainingBalance)}
            />
            <Definition
              term="Estimated Property Value"
              definition={formatCurrency(data.estimatedPropertyValue)}
            />
            <Definition
              term="Remaining Term"
              definition={
                data.remainingBalance &&
                data.interestRate &&
                data.monthlyPayment
                  ? formatTerm(
                      getRemainingTerm({
                        remainingBalance: data.remainingBalance,
                        interestRate: data.interestRate,
                        monthlyPayment: data.monthlyPayment,
                        overpaymentAmount: data.monthlyOverpayment,
                        overpaymentInterval: 'monthly',
                      }),
                    )
                  : '-'
              }
            />
          </DefinitionList>
        </Panel>

        <Panel heading="Rate">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Switch in progress"
              definition={
                data.switchInProgress ? (
                  <Icon icon="check-circle" color="green-600" />
                ) : (
                  '-'
                )
              }
            />
            <Definition
              term="Last Switch Reminder"
              definition={formatDateTime(data.lastSwitchReminderDate)}
            />
            <Definition
              term="Interest Rate"
              definition={formatPercentage(data.interestRate, 2)}
            />
            <Definition term="Fixed Rate" definition={data.fixedRate} />
            <Definition
              term="Fixed Rate End"
              definition={
                data.fixedRateEndDate
                  ? formatDateTime(data.fixedRateEndDate, 'monthYear')
                  : '-'
              }
            />
          </DefinitionList>
        </Panel>

        <Panel heading="Payments">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Monthly Payment"
              definition={formatCurrency(data.monthlyPayment)}
            />
            <Definition
              term="Monthly Overpayment"
              definition={formatCurrency(data.monthlyOverpayment)}
            />
            <Definition
              term="Total Payments"
              definition={formatCurrency(data.totalPayments)}
            />
            <Definition
              term="Total Rewards"
              definition={formatCurrency(data.totalRewards)}
            />
          </DefinitionList>
        </Panel>

        <Panel heading="Meta">
          <DefinitionList termWidth={termWidth}>
            <Definition term="ID" definition={data._id} />
            <Definition
              term="Created"
              definition={formatDateTime(data.createdAt)}
            />
            <Definition
              term="Updated"
              definition={formatDateTime(data.updatedAt)}
            />
          </DefinitionList>
        </Panel>
      </PanelColumn>
    </div>
  );
};

AdminMortgageView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    owner_id: PropTypes.string,
    owner: PropTypes.shape({
      _id: PropTypes.string,
    }),
    lender_id: PropTypes.string,
    lender: PropTypes.shape({
      name: PropTypes.string,
    }),
    accountNumber: PropTypes.string,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
    remainingBalance: PropTypes.number,
    estimatedPropertyValue: PropTypes.number,
    initialBalance: PropTypes.number,
    totalPayments: PropTypes.number,
    totalRewards: PropTypes.number,
    monthlyPayment: PropTypes.number,
    monthlyOverpayment: PropTypes.number,
    interestRate: PropTypes.number,
    switchInProgress: PropTypes.bool,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    lastSwitchReminderDate: PropTypes.string,
  }).isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

AdminMortgageView.defaultProps = {
  displayError: undefined,
};

export default AdminMortgageView;
