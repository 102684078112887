import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

import GlobalStyles from './GlobalStyles';
import theme from './theme';

// Load typeface here if required
// e.g require('typeface-space-mono');

function StyleWrapper({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        {children}
      </>
    </ThemeProvider>
  );
}

StyleWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

StyleWrapper.defaultProps = {
  children: [],
};

export default StyleWrapper;
