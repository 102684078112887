import React from 'react';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import API, { handleApiError } from '../../../lib/api';
import Button from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import Error from '../../common/ErrorMessage';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required(
    'You need to enter your current password',
  ),
  newPassword: Yup.string()
    .required('Choose a new password')
    .min(10, 'Your password needs to be 10 characters or more'),
  confirmNewPassword: Yup.string()
    .required('Confirm your new password')
    .oneOf([Yup.ref('newPassword'), null], 'Your passwords must match'),
});

const initialValues = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};

const AccountPasswordForm = () => {
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await API.post('auth/update-password', values)
            .then(() => {
              toast.success('Password updated!');
              resetForm(initialValues);
              setDisplayError({});
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="Enter your current password"
                  id="currentPassword"
                  type="password"
                  name="currentPassword"
                  placeholder="Your current password"
                  component={TextInput}
                  autoComplete="current-password"
                  isRequired
                />
                <Field
                  label="Choose a new password"
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  placeholder="Your new password"
                  component={TextInput}
                  helpMessage="Your new password must be at least 10 characters"
                  autoComplete="new-password"
                  isRequired
                />
                <Field
                  label="Confirm your new password"
                  id="confirmNewPassword"
                  type="password"
                  name="confirmNewPassword"
                  placeholder="Re-enter your new password"
                  component={TextInput}
                  autoComplete="new-password"
                  isRequired
                />
                <Button
                  label="Update your password"
                  type="submit"
                  mode="primary"
                  loadingLabel="Updating your password"
                  isLoading={isSubmitting}
                  disabled={!dirty}
                />
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AccountPasswordForm;
