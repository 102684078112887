import styled from 'styled-components';

const ProgressBar = styled.div`
  border: 1px solid ${({ theme }) => theme.color['gray-500']};
  border-radius: 0.5rem;
  padding: 0.3rem;
  height: 8rem;
  position: relative;

  div {
    background: ${({ theme }) => theme.color['orange-500']};
    display: inline-block;
    transition: width 1s ease-in;
    height: 100%;
    border-radius: 0.5rem;
  }

  span {
    font-size: 3rem;
    position: absolute;
    z-index: 2;
    right: 2rem;
    top: 1rem;
  }
`;

const BalanceTracker = styled.div`
  @media ${({ theme }) => theme.device.tablet} {
    p {
      text-align: center;
      margin: 2rem auto 0;
      max-width: 800px;
      color: ${({ theme }) => theme.color['gray-700']};
    }
  }

  small {
    width: 100%;
    text-align: center;
    display: block;
    color: ${({ theme }) => theme.color['gray-700']};
    font-size: 1.3rem;
    margin-top: ${({ theme }) => theme.space[3]};

    @media ${({ theme }) => theme.device.tablet} {
      font-size: 1.6rem;
    }
  }
`;

export default { ProgressBar, BalanceTracker };
