import React from 'react';
import PropTypes from 'prop-types';
import { getMonthlyInterestRate } from '@rateswitch/amm-utils';
import Rate from '../Rate';
import Message from '../../common/Message';
import { PanelColumn } from '../../common/Panel';

const Rates = ({
  loanAmount,
  eligibleForSwitch,
  userPhone,
  userId,
  remainingTerm,
  rates,
}) => {
  if (rates && rates.length > 0) {
    return (
      <PanelColumn>
        {rates.map(({ product, rate, fee, info }) => {
          // GET MONTHLY FEE
          const monthlyInterestRate = getMonthlyInterestRate(rate);

          const monthly = Math.floor(
            (loanAmount *
              (monthlyInterestRate *
                (1 + monthlyInterestRate) ** remainingTerm)) /
              ((1 + monthlyInterestRate) ** remainingTerm - 1),
          );

          return (
            <Rate
              product={product}
              rate={rate}
              fee={fee}
              monthly={monthly}
              eligibleForSwitch={eligibleForSwitch}
              userPhone={userPhone}
              userId={userId}
              info={info}
            />
          );
        })}
      </PanelColumn>
    );
  }
  return (
    <Message size="l" center>
      Our online system has been unable to identify mortgage rates to match your
      circumstances, but some lenders may consider cases on a more individual
      basis. To see if we can find a better rate for you to switch to, please
      contact us by emailing{' '}
      <a href="mailto:support@acceleratemymortgage.com">
        support@acceleratemymortgage.com
      </a>
    </Message>
  );
};

Rates.propTypes = {
  loanAmount: PropTypes.number.isRequired,
  eligibleForSwitch: PropTypes.bool,
  userPhone: PropTypes.string,
  userId: PropTypes.string.isRequired,
  remainingTerm: PropTypes.number.isRequired,
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
};

Rates.defaultProps = {
  eligibleForSwitch: false,
  userPhone: undefined,
  rates: [],
};

export default Rates;
