import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import API, { handleApiError } from '../../../lib/api';
import { useAuth } from '../../../context/auth';

import Error from '../../common/ErrorMessage';
import Button from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';

const validationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required('Choose a new password')
    .min(10, 'Your password needs to be 10 characters or more'),
  confirmNewPassword: Yup.string()
    .required('Confirm your new password')
    .oneOf([Yup.ref('newPassword'), null], 'Your passwords must match'),
});

const ResetPasswordForm = ({ resetToken }) => {
  const [displayError, setDisplayError] = React.useState();
  const { onSignIn } = useAuth();

  return (
    <>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post('auth/reset-password', {
            resetToken,
            ...values,
          })
            .then((res) => {
              toast.success('Password reset!');
              onSignIn({ isAuthenticated: true, user: res.data.user });
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          newPassword: '',
          confirmNewPassword: '',
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="Choose a new password"
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  placeholder="Your new password"
                  component={TextInput}
                  isRequired
                />
                <Field
                  label="Confirm your new password"
                  id="confirmNewPassword"
                  type="password"
                  name="confirmNewPassword"
                  placeholder="Reenter your new password"
                  component={TextInput}
                  isRequired
                />
                <Error error={displayError} />
                <Button
                  label="Reset your password"
                  type="submit"
                  mode="primary"
                  loadingLabel="Resetting your password"
                  isLoading={isSubmitting}
                  block
                />
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

ResetPasswordForm.defaultProps = {
  resetToken: undefined,
};

ResetPasswordForm.propTypes = {
  resetToken: PropTypes.string,
};

export default ResetPasswordForm;
