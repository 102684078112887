import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  getMonthSelectOptions,
  getYearSelectOptions,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../../../lib/api';

import OnboardingViewActions from '../../../OnboardingViewActions';
import Form from '../../../../common/Form';
import SelectInput from '../../../../common/SelectInput';
import Message from '../../../../common/Message';
import LayoutWithImage from '../../../../layout/LayoutWithImage';
import Error from '../../../../common/ErrorMessage';

import checklistImg from '../../../../../images/checklist.svg';

const validationSchema = Yup.object().shape({
  dobMonth: Yup.string(),
  dobYear: Yup.string(),
});

const OnboardingDob = ({
  user,
  setSelectedStep,
  setDisplayError,
  setUserData,
  displayError,
}) => {
  return (
    <LayoutWithImage image={checklistImg}>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`auth/update-settings`, {
            'profile.dobMonth': values.dobMonth,
            'profile.dobYear': values.dobYear,
          })
            .then((res) => {
              setSelectedStep('summary');
              setUserData(res.data);
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          dobMonth:
            (user && user.profile && user.profile.dobMonth) || undefined,
          dobYear: (user && user.profile && user.profile.dobYear) || undefined,
        }}
        validationSchema={validationSchema}
        render={({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <h2>When were you born?</h2>
                <Field
                  label="Your birth month"
                  id="dobMonth"
                  name="dobMonth"
                  component={SelectInput}
                  options={getMonthSelectOptions()}
                  placeholder="Select your birth month"
                />
                <Field
                  label="Your birth year"
                  id="dobYear"
                  name="dobYear"
                  component={SelectInput}
                  options={getYearSelectOptions({ skip: 18 })}
                  placeholder="Select your birth year"
                />
                <Message big>
                  We use this information to calculate what age you&apos;ll
                  become mortgage free
                </Message>
                <Error error={displayError} />
                <OnboardingViewActions
                  previousView="payments"
                  isSubmitting={isSubmitting}
                  setSelectedStep={setSelectedStep}
                />
              </fieldset>
            </Form>
          );
        }}
      />
    </LayoutWithImage>
  );
};

OnboardingDob.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    profile: PropTypes.shape({
      dobMonth: PropTypes.string,
      dobYear: PropTypes.string,
    }),
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingDob.defaultProps = {
  displayError: undefined,
};

export default OnboardingDob;
