import React from 'react';
import PropTypes from 'prop-types';

import {
  formatTerm,
  getFreedomAge,
  getFreedomDate,
} from '@rateswitch/amm-utils';

import { formatDateTime, formatOrdinalNumber } from '@homamo/meadow-utils';

import HeaderInfoBlock from '../../common/HeaderInfoBlock';
import Styles from './Styles';

const MortgageProjection = ({
  remainingTerm,
  withoutOverpaymentsRemainingTerm,
  userDobYear,
  userDobMonth,
}) => {
  const freedomDate = getFreedomDate({ remainingTerm });
  const withoutOverpaymentsFreedomDate = getFreedomDate({
    remainingTerm: withoutOverpaymentsRemainingTerm,
  });
  const showWithoutOverpaymentsTerm =
    remainingTerm !== withoutOverpaymentsRemainingTerm;

  const freedomAge = getFreedomAge({
    userDobYear,
    userDobMonth,
    remainingTerm,
    freedomDate,
  });

  const withoutOverpaymentsFreedomAge = getFreedomAge({
    userDobYear,
    userDobMonth,
    remainingTerm: withoutOverpaymentsRemainingTerm,
    freedomDate: withoutOverpaymentsFreedomDate,
  });

  return (
    <Styles.GridPanel heading="Projection" smallHeader>
      <HeaderInfoBlock
        label="Remaining term"
        value={formatTerm(remainingTerm)}
        subLabel={
          showWithoutOverpaymentsTerm
            ? `${formatTerm(
                withoutOverpaymentsRemainingTerm,
              )} - no overpayments`
            : ''
        }
      />
      <HeaderInfoBlock
        label="Freedom date"
        value={formatDateTime(freedomDate, 'monthYear')}
        subLabel={
          showWithoutOverpaymentsTerm
            ? `${formatDateTime(
                withoutOverpaymentsFreedomDate,
                'monthYear',
              )} - no overpayments`
            : ''
        }
      />
      {freedomAge ? (
        <HeaderInfoBlock
          label="Freedom birthday"
          value={formatOrdinalNumber(freedomAge)}
          subLabel={
            showWithoutOverpaymentsTerm
              ? `${formatOrdinalNumber(
                  withoutOverpaymentsFreedomAge,
                )} - no overpayments`
              : ''
          }
        />
      ) : null}
    </Styles.GridPanel>
  );
};

MortgageProjection.defaultProps = {
  userDobMonth: undefined,
  userDobYear: undefined,
};

MortgageProjection.propTypes = {
  remainingTerm: PropTypes.number.isRequired,
  withoutOverpaymentsRemainingTerm: PropTypes.number.isRequired,
  userDobMonth: PropTypes.string,
  userDobYear: PropTypes.string,
};

export default MortgageProjection;
