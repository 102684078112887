import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  formatDateTime,
  getMonthSelectOptions,
  getYearSelectOptions,
  getDateFromMonthAndYear,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../../../lib/api';

import OnboardingViewActions from '../../../OnboardingViewActions';
import Form from '../../../../common/Form';
import SelectInput from '../../../../common/SelectInput';
import RadioInput from '../../../../common/RadioInput';
import RadioGroup from '../../../../common/RadioGroup';
import Message from '../../../../common/Message';
import Error from '../../../../common/ErrorMessage';

import LayoutWithImage from '../../../../layout/LayoutWithImage';
import checklistImg from '../../../../../images/checklist.svg';

const validationSchema = Yup.object().shape({
  fixedRate: Yup.string().required('Choose an option'),
  fixedRateEndMonth: Yup.string().when('fixedRate', {
    is: 'yes',
    then: Yup.string().required('Select the month your fixed rate ends'),
    otherwise: Yup.string(),
  }),
  fixedRateEndYear: Yup.string().when('fixedRate', {
    is: 'yes',
    then: Yup.string().required('Select the year your fixed rate ends'),
    otherwise: Yup.string(),
  }),
});

const OnboardingFixedRate = ({
  mortgageId,
  mortgage,
  setSelectedStep,
  setDisplayError,
  setMortgageData,
  displayError,
}) => {
  const [isFixed, setIsFixed] = React.useState(
    mortgage.fixedRate && mortgage.fixedRate === 'yes',
  );

  const initialValues = {
    fixedRate: (mortgage && mortgage.fixedRate) || '',
    fixedRateEndMonth:
      (mortgage &&
        mortgage.fixedRateEndDate &&
        formatDateTime(mortgage.fixedRateEndDate, 'mon').toLowerCase()) ||
      '',
    fixedRateEndYear:
      (mortgage &&
        mortgage.fixedRateEndDate &&
        formatDateTime(mortgage.fixedRateEndDate, 'year')) ||
      '',
  };

  return (
    <LayoutWithImage image={checklistImg}>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const { fixedRate, fixedRateEndMonth, fixedRateEndYear } = values;

          const fixedRateEndDate =
            fixedRate === 'yes' &&
            fixedRateEndMonth !== '' &&
            fixedRateEndMonth !== null &&
            fixedRateEndYear !== '' &&
            fixedRateEndYear !== null
              ? getDateFromMonthAndYear(fixedRateEndMonth, fixedRateEndYear)
              : undefined;

          await API.post(`mortgages/update/${mortgageId}`, {
            fixedRate: values.fixedRate,
            fixedRateEndDate,
          })
            .then((res) => {
              setSelectedStep('interestRate');
              setMortgageData(res.data);
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={({ isSubmitting, values, errors, touched }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <h2>Are you currently on a fixed rate?</h2>
                <RadioGroup
                  id="fixedRate"
                  label="Choose an option"
                  value={values.type}
                  error={errors.type}
                  touched={touched.type}
                >
                  <Field
                    name="fixedRate"
                    id="yes"
                    label="Yes"
                    valueLabel="yes"
                    component={RadioInput}
                    onClick={() => setIsFixed(true)}
                  />
                  <Field
                    name="fixedRate"
                    id="no"
                    label="No"
                    valueLabel="no"
                    component={RadioInput}
                    onClick={() => setIsFixed(false)}
                  />
                  <Field
                    name="fixedRate"
                    id="unknown"
                    label="I don't know"
                    valueLabel="unknown"
                    component={RadioInput}
                    onClick={() => setIsFixed(false)}
                  />
                </RadioGroup>
                {isFixed && (
                  <div className="form-section">
                    <h3>When does your fixed rate end?</h3>
                    <Field
                      label="Month your rate ends"
                      id="fixedRateEndMonth"
                      name="fixedRateEndMonth"
                      component={SelectInput}
                      options={getMonthSelectOptions()}
                      placeholder="Select a month"
                    />
                    <Field
                      label="Year your rate ends"
                      id="fixedRateEndYear"
                      name="fixedRateEndYear"
                      component={SelectInput}
                      options={getYearSelectOptions({
                        range: 10,
                        type: 'future',
                        ascending: true,
                      })}
                      placeholder="Select a year"
                    />
                    <Message big>
                      It&apos;s okay if you don&apos;t know exactly - just enter
                      your best guess
                    </Message>
                  </div>
                )}
                <Error error={displayError} />
                <OnboardingViewActions
                  previousView="type"
                  isSubmitting={isSubmitting}
                  setSelectedStep={setSelectedStep}
                />
              </fieldset>
            </Form>
          );
        }}
      />
    </LayoutWithImage>
  );
};

OnboardingFixedRate.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  setMortgageData: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingFixedRate.defaultProps = {
  displayError: undefined,
};

export default OnboardingFixedRate;
