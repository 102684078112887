import React, { useEffect, useState } from 'react';
import API, { handleApiError } from '../../../lib/api';
import RewardHistoryView from './View';

import Loading from '../../common/Loading';
import { useAuth } from '../../../context/auth';

const getRewardTotalAmount = (rewards) =>
  rewards.reduce((total, reward) => total + (reward.amount || 0), 0);

const filterRewards = (rewards, status) =>
  rewards.filter((r) => r.status === status);

function RewardHistoryContainer() {
  const { user, isCheckingAuth, onUpdateUser } = useAuth();
  // Data state to store the users data. Its initial value is an empty array
  const [rewardData, setRewardData] = useState({});
  const [displayError, setDisplayError] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const ownerId = user._id;

    if (ownerId) {
      await API.get(`rewards/`, { params: { ownerId } })
        .then((res) => {
          const rewards = res.data.data;
          setRewardData({
            totalRewards: getRewardTotalAmount(rewards),
            totalPending: getRewardTotalAmount(
              filterRewards(rewards, 'PENDING'),
            ),
            totalConfirmed: getRewardTotalAmount(
              filterRewards(rewards, 'CONFIRMED'),
            ),
            totalPaid: getRewardTotalAmount(filterRewards(rewards, 'PAID')),
          });
        })
        .catch((error) => {
          handleApiError({ error, setDisplayError });
        })
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCheckingAuth || isLoading) return <Loading />;

  return (
    <RewardHistoryView
      user={user}
      rewardData={rewardData}
      displayError={displayError}
      setDisplayError={setDisplayError}
      fetchData={fetchData}
      setRewardData={setRewardData}
      setUserData={onUpdateUser}
    />
  );
}

export default RewardHistoryContainer;
