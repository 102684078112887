import styled from 'styled-components';

const ModalHeader = styled.header`
  padding: ${({ theme }) => `${theme.space[2]} ${theme.space[3]}`};
  border-bottom: 4px solid ${({ theme }) => theme.color['gray-200']};
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: ${({ theme }) => `0 ${theme.space[3]} 0 0`};
    padding: 0;
    font-size: ${({ theme }) => theme.fontSize[3]};
    line-height: 1;
  }
`;

const ModalBody = styled.div`
  padding: ${({ theme }) => theme.space[4]};

  > :first-child {
    margin-top: 0;
  }

  p,
  li {
    font-size: ${({ theme }) => theme.fontSize[3]};
  }
`;

const ModalFooter = styled.div`
  padding-top: ${({ theme }) => theme.space[3]};
`;

export default {
  ModalHeader,
  ModalBody,
  ModalFooter,
};
