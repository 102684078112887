import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Error from '../../common/ErrorMessage';
import Message from '../../common/Message';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import Textarea from '../../common/Textarea';

import API, { handleApiError } from '../../../lib/api';

const validationSchema = Yup.object().shape({
  referralEmail: Yup.string()
    .email("Whoops! That doesn't look like a valid email")
    .required('Enter an email for us to send a link to'),
  referralMessage: Yup.string(),
});

const SendReferralLinkForm = ({
  referredByUserId,
  successAction,
  cancelAction,
}) => {
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`referrals/create`, {
            ownerId: referredByUserId,
            referralEmail: values.referralEmail,
            referralMessage: values.referralMessage,
          })
            .then(() => {
              setSubmitting(false);
              toast.success(`Thanks! We've sent your friend a message`);
              if (successAction) successAction();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          referralEmail: undefined,
          referralMessage:
            "Here's my personal referral link you can use to accelerate to mortgage freedom!",
        }}
        validationSchema={validationSchema}
        render={({ errors, isSubmitting }) => {
          return (
            <Form>
              <Error error={errors} />
              <Field
                label="Enter your friend's email address"
                id="referralEmail"
                name="referralEmail"
                placeholder="Your friend's email address"
                type="email"
                component={TextInput}
                isRequired
              />
              <Field
                label="Add an optional message"
                id="referralMessage"
                name="referralMessage"
                placeholder="Enter an optional message"
                component={Textarea}
              />
              <Message>
                By clicking &apos;Send referral link&apos; you are confirming
                that your friend is over 18, and that you have their consent for
                us to email them the referral link (and one reminder).
              </Message>
              <ButtonGroup>
                <Button
                  label="Send referral link"
                  mode="primary"
                  type="submit"
                  loadingLabel="Sending referral link"
                  isLoading={isSubmitting}
                />
                {cancelAction && (
                  <Button label="Cancel" onClick={cancelAction} />
                )}
              </ButtonGroup>
            </Form>
          );
        }}
      />
    </>
  );
};

SendReferralLinkForm.propTypes = {
  referredByUserId: PropTypes.string.isRequired,
  successAction: PropTypes.func,
  cancelAction: PropTypes.func,
};

SendReferralLinkForm.defaultProps = {
  successAction: undefined,
  cancelAction: undefined,
};

export default SendReferralLinkForm;
