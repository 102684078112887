import React from 'react';
import Layout from '../../components/layout/Layout';
import SignUp from '../../components/auth/SignUp';
import AnalyticsWrapper from '../../components/common/AnalyticsWrapper';

const SignUpView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Sign up">
        <SignUp />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default SignUpView;
