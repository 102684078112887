import React from 'react';

import Layout from '../components/layout/Layout';
import Rewards from '../components/reward/Rewards';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const RewardsView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Rewards">
        <Rewards />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default RewardsView;
