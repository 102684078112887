const descriptionLabels = {
  NONE: '-',
  ADMIN: 'Admin Adjustment',
  MONTHLY: 'Monthly',
  MONTHLY_OVERPAYMENT: 'Monthly overpayment',
  SINGLE_OVERPAYMENT: 'Single overpayment',
  REWARD_PAYMENT: 'From Rateswitch',
};

export default (description) => descriptionLabels[description];
