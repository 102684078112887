import React from 'react';
import PropTypes from 'prop-types';
import { saveFile } from '@homamo/meadow-utils';

import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import LenderSelect from '../../lender/LenderSelect';

const validationSchema = Yup.object().shape({
  lender: Yup.string().required('You need to select a lender'),
});

const ExportMortgagesForm = ({ lenderId, successAction, cancelAction }) => {
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const params = { lenderId: values.lender };
          await API.get('mortgages/export', {
            responseType: 'blob',
            params,
            headers: {
              Accept: 'text/csv',
            },
          })
            .then((res) => {
              saveFile({ headers: res.headers, file: res.data });
              toast.success('Mortgage data exported');
              successAction();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          lender: lenderId || undefined,
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <LenderSelect includeBlank />
                <ButtonGroup>
                  <Button
                    label="Export Data"
                    type="submit"
                    mode="primary"
                    loadingLabel="Exporting"
                    isLoading={isSubmitting}
                  />
                  <Button label="Cancel" onClick={() => cancelAction()} />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

ExportMortgagesForm.propTypes = {
  successAction: PropTypes.func,
  cancelAction: PropTypes.func,
  lenderId: PropTypes.string,
};

ExportMortgagesForm.defaultProps = {
  successAction: undefined,
  cancelAction: undefined,
  lenderId: undefined,
};

export default ExportMortgagesForm;
