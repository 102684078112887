import styled from 'styled-components';
import { Styles as PanelStyles } from '../../common/Panel';
import { Styles as MessageStyles } from '../../common/Message';

const CalculatorResultsMessage = styled.div`
  img {
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
    display: block;
  }

  .inner {
    max-width: 650px;
    ul {
      font-size: 2.2rem;
      color: ${({ theme }) => theme.color['blue-700']};
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    ${PanelStyles.Panel} {
      .body {
        display: flex;
        justify-content: center;
        align-items: flex-start;

        ${MessageStyles.Message} {
          margin-top: 1rem;
        }
      }
    }

    img {
      margin: 0 3rem 0 0;
      max-width: ${({ theme }) => theme.space[10]};
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    img {
      margin: 0 5rem 0 0;
    }
  }
`;

export default {
  CalculatorResultsMessage,
};
