import React from 'react';
import { Redirect, Link } from 'react-router-dom';

import { useAuth } from '../../../context/auth';

import SignUpForm from './Form';
import NotAuthorised from '../NotAuthorised';
import config from '../../../config';
import LayoutWithImage from '../../layout/LayoutWithImage/Component';
import MarketingSiteLink from '../../common/MarketingSiteLink';
import MaintenanceMessage from '../MaintenanceMessage';

import journeyImg from '../../../images/journey.svg';
import StagingMessage from '../StagingMessage';

const SignUpView = () => {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  if (config.signUpDisabled)
    return (
      <NotAuthorised
        pathname="/sign-up"
        message={`Registering new accounts has been disabled. Please contact ${config.supportEmail} if you have any questions.`}
      />
    );

  return (
    <LayoutWithImage image={journeyImg} padTop>
      <h1>Sign up to accelerate to mortgage freedom</h1>
      <p>
        We help homeowners take years off their mortgage and save thousands of
        pounds in interest
      </p>
      <p>
        Want to find out how it works before you sign up?{' '}
        <MarketingSiteLink label="Read this" />
      </p>
      <MaintenanceMessage />
      <StagingMessage />
      <SignUpForm />
      <p>
        <small>
          Already have an account? <Link to="/sign-in">Sign in here</Link> or
          view <a href={config.termsUrl}> Terms &amp; Conditions</a>
        </small>
      </p>
    </LayoutWithImage>
  );
};

export default SignUpView;
