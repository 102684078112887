import styled from 'styled-components';

const TabsContainer = styled.div``;

const TabsView = styled.div``;

export default {
  TabsContainer,
  TabsView,
};
