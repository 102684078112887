import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import AdminEditUser from '../../../components/user/AdminEditUser';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminEditUserView = () => {
  const { id } = useParams();
  return (
    <AnalyticsWrapper>
      <Layout title="Admin - Edit user">
        <AdminEditUser id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminEditUserView;
