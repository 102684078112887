import styled from 'styled-components';

const InputLabel = styled.label`
  margin-bottom: ${({ theme }) => theme.space[1]};
  font-size: ${({ theme }) => theme.fontSize[1]};
  letter-spacing: 0.5px;
  display: block;
  font-weight: 600;
  color: ${({ theme }) => theme.inputLabelColor};
  ${({ disabled }) => disabled && 'opacity: 0.8'};

  /* REQUIRED ASTERISK */
  svg {
    color: ${({ theme }) => theme.color['orange-500']};
    font-size: ${({ theme }) => theme.fontSize[1]};
    margin-left: ${({ theme }) => theme.space[2]};
  }

  ${({ isHidden }) =>
    isHidden &&
    `position: absolute !important;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */`}
`;

export default {
  InputLabel,
};
