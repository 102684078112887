import React from 'react';
import PropTypes from 'prop-types';
import API, { handleApiError } from '../../../lib/api';
import TransactionsTable from './Component';
// import { Styles as ButtonStyles } from '../../common/Button';

import Error from '../../common/ErrorMessage';

function TransactionsTableContainer({ ownerId, mortgageId, remainingBalance }) {
  /*
   * Delete mortgage is used to delete from the state cache
   * rather than reload data from the API
   */

  // We'll start our table without any data
  const [mortgages, setTransactions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [displayError, setDisplayError] = React.useState();

  const fetchIdRef = React.useRef(0);
  const initialSortBy = [{ id: 'createdAt', desc: true }];

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, searchTerm, sortBy, selectedFilter }) => {
      // Give this fetch an ID
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;

      if (fetchId === fetchIdRef.current) {
        // Set the loading state
        setLoading(true);

        const params = {
          searchTerm,
          pageSize,
          pageIndex,
          type: selectedFilter,
          ownerId,
          mortgageId,
        };

        if (sortBy && sortBy.length > 0) {
          params.sortBy = sortBy[0].id;
          params.sortByDesc = sortBy[0].desc;
        }

        const response = await API.get('transactions/', { params }).catch(
          (error) => {
            handleApiError({ error, setDisplayError });
          },
        );

        if (response) {
          setTransactions(response.data.data);
          setPageCount(Math.ceil(response.data.totalCount / pageSize));
          setTotalCount(response.data.totalCount);
        }

        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ownerId, remainingBalance],
  );

  const filterOptions = [
    {
      label: 'All',
      value: undefined,
    },
    {
      label: 'Payment',
      value: 'PAYMENT',
    },
    {
      label: 'Interest',
      value: 'INTEREST',
    },
    {
      label: 'Reward',
      value: 'REWARD',
    },
    {
      label: 'Adjustment',
      value: 'ADJUSTMENT',
    },
  ];

  return (
    <>
      <Error error={displayError} />
      <TransactionsTable
        data={mortgages}
        isLoading={loading}
        pageCount={pageCount}
        fetchData={fetchData}
        totalCount={totalCount}
        initialSortBy={initialSortBy}
        filterOptions={filterOptions}
      />
    </>
  );
}

TransactionsTableContainer.propTypes = {
  ownerId: PropTypes.string,
  mortgageId: PropTypes.string,
  remainingBalance: PropTypes.number,
};

TransactionsTableContainer.defaultProps = {
  ownerId: undefined,
  mortgageId: undefined,
  remainingBalance: undefined,
};

export default TransactionsTableContainer;
