import styled from 'styled-components';
import Panel from '../../common/Panel';
import { Styles as ButtonStyles } from '../../common/Button';
import { Styles as HeaderInfoBlockStyles } from '../../common/HeaderInfoBlock';

const Rate = styled(Panel)`
  ${ButtonStyles.Button} {
    display: block;
    width: 100%;
    margin-top: 2rem;

    @media ${({ theme }) => theme.device.laptop} {
      margin-top: 0;
      margin-left: 2rem;
    }
  }

  .body {
    @media ${({ theme }) => theme.device.laptop} {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ${ButtonStyles.Button} {
        display: inline-block;
        width: auto;
        min-width: 200px;
      }
    }
  }

  .inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1rem 0rem;
    width: 100%;

    @media ${({ theme }) => theme.device.tablet} {
      justify-content: flex-start;
      padding: 0 2rem 0 0;
    }

    ${HeaderInfoBlockStyles.HeaderInfoBlock} {
      margin: 0 1rem;
      flex: 1 1;

      &:first-child {
        margin-left: 0;
        flex: 1 1 100%;
        margin-bottom: 2rem;

        @media ${({ theme }) => theme.device.tablet} {
          flex: 1 1;
        }

        @media ${({ theme }) => theme.device.laptop} {
          margin-bottom: 0;
        }
      }
    }
  }

  .attributes {
    display: flex;
    flex: 1 1 100%;

    @media ${({ theme }) => theme.device.tablet} {
      flex: 1 1;
      margin-left: 1rem;
    }

    ${HeaderInfoBlockStyles.HeaderInfoBlock} {
      margin: 0 1rem;
      flex: 1 0;

      @media ${({ theme }) => theme.device.tablet} {
        flex: 1 1;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
`;

export default {
  Rate,
};
