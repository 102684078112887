import styled from 'styled-components';

const SmallVersionWrapper = styled.div`
  display: none;
  width: 100%;

  ${({ theme, mobileSelect }) => {
    if (mobileSelect) {
      return `
      display: block;

      @media ${theme.device.laptop} {
        display: none;
      }`;
    }
    return '';
  }}
`;

const LargeVersionWrapper = styled.div`
  display: block;

  ${({ theme, mobileSelect }) => {
    if (mobileSelect) {
      return `
      display: none;

      @media ${theme.device.laptop} {
        display: block;
      }`;
    }
    return '';
  }}
`;

export default {
  SmallVersionWrapper,
  LargeVersionWrapper,
};
