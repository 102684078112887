import styled from 'styled-components';

const WarningBar = styled.div`
  padding: 2rem;
  color: ${({ theme }) => theme.color['orange-700']};
  background-color: ${({ theme }) => theme.color['orange-100']};
  text-align: center;
  font-size: 1.6rem;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: 1.8rem;
  }

  @media ${({ theme }) => theme.device.laptop} {
    font-size: 2rem;
  }

  a {
    color: ${({ theme }) => theme.color['orange-700']};
    text-decoration: underline;
  }
`;

export default {
  WarningBar,
};
