import React from 'react';
import PropTypes from 'prop-types';
import AdminEditMortgageForm from './Form';
import AdminNav from '../../admin/AdminNav';

const AdminEditMortgageView = ({ data }) => {
  return (
    <div>
      <AdminNav />
      <h1>Edit Mortgage: {data._id}</h1>
      <AdminEditMortgageForm id={data._id} initialData={data} />
    </div>
  );
};

AdminEditMortgageView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export default AdminEditMortgageView;
