/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';

import { getInterestSaved } from '@rateswitch/amm-utils';
import { formatCurrency } from '@homamo/meadow-utils';

import { useAuth } from '../../../context/auth';
import Error from '../../common/ErrorMessage';
import PageIntro from '../../common/PageIntro';
import PageContent from '../../layout/PageContent';
import Message from '../../common/Message';
import MarketingSiteLink from '../../common/MarketingSiteLink';
import ReferralMessage from '../../referral/ReferralMessage';
import RetailersList from '../../retailer/RetailersList';
import Section from '../../layout/Section';

const RewardsView = ({ mortgage, displayError }) => {
  const { user } = useAuth();

  const {
    remainingBalance,
    interestRate,
    monthlyPayment,
    // fixedRate,
    // fixedRateEndDate,
    // switchInProgress,
    // estimatedPropertyValue,
    // lender,
  } = mortgage;

  const scenarioInterestSaved = getInterestSaved({
    remainingBalance,
    interestRate,
    monthlyPayment,
    overpaymentAmount: 5000,
    overpaymentInterval: 'single',
  });

  return (
    <PageContent>
      <Error error={displayError} />
      <PageIntro
        heading="Repay your mortgage faster by shopping with our retail partners"
        text={`We'll pay all cashback rewards directly off your mortgage when you get to £50, saving you a further ${formatCurrency(
          scenarioInterestSaved,
          { wholePounds: true },
        )} in interest on your remaining balance`}
        compact
      />
      <Section>
        <RetailersList userId={user.id} />
        <Message big center>
          <MarketingSiteLink
            path="/retailer-cashback"
            label="View individual retailer cashback rates"
          />
        </Message>
      </Section>
      <Section>
        <ReferralMessage userId={user.id} />
        <Message size="l" center>
          Want to know more about how rewards work?{' '}
          <MarketingSiteLink
            path="/rewards-guide"
            label="View our support article"
          />
        </Message>
      </Section>
    </PageContent>
  );
};

RewardsView.propTypes = {
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
  mortgage: PropTypes.shape({
    initialBalance: PropTypes.number,
    monthlyOverpayment: PropTypes.number,
    remainingBalance: PropTypes.number,
    estimatedPropertyValue: PropTypes.number,
    interestRate: PropTypes.number,
    monthlyPayment: PropTypes.number,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
    switchInProgress: PropTypes.bool,
    lender: PropTypes.shape({
      _id: PropTypes.string,
      name: PropTypes.string,
      representativeExample: PropTypes.string,
      showRates: PropTypes.bool,
    }),
  }).isRequired,
};

RewardsView.defaultProps = {
  displayError: undefined,
};

export default RewardsView;
