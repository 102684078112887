import React from 'react';
import PropTypes from 'prop-types';
import ReferralMessage from '../../referral/ReferralMessage';

const AccountReferralsView = ({ data }) => {
  return (
    <div>
      <ReferralMessage userId={data._id} hideHeader />
    </div>
  );
};

AccountReferralsView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }),
};

AccountReferralsView.defaultProps = {
  data: {},
};

export default AccountReferralsView;
