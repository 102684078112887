import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import Modal, { ModalFooter } from '../../common/Modal';

const ExampleModal = ({ buttonLabel }) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  return (
    <>
      <Button
        size="s"
        label={buttonLabel || 'Example Modal'}
        onClick={() => setModalIsOpen(true)}
      />
      <Modal
        heading="Example Modal"
        isOpen={modalIsOpen}
        closeAction={() => setModalIsOpen(false)}
        contentLabel="Example"
      >
        <p>Where the modal content goes</p>
        <ModalFooter>
          <Button label="Okay" onClick={() => setModalIsOpen(false)} />
        </ModalFooter>
      </Modal>
    </>
  );
};

ExampleModal.defaultProps = {
  buttonLabel: undefined,
};

ExampleModal.propTypes = {
  buttonLabel: PropTypes.string,
};

export default ExampleModal;
