import ReactGA from 'react-ga';
import config from '../config';

export const initGA = () => {
  if (config.googleAnalytics) {
    ReactGA.initialize(config.googleAnalytics);
  }
};

export const logPageView = () => {
  if (config.googleAnalytics) {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);
  }
};

export const logEvent = (category = '', action = '') => {
  if (category && action && config.googleAnalytics) {
    ReactGA.event({ category, action });
  }
};

export const logException = (description = '', fatal = false) => {
  if (description && config.googleAnalytics) {
    ReactGA.exception({ description, fatal });
  }
};
