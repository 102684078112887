import React from 'react';
import { keepParams } from '@homamo/meadow-utils';
import Button, { Styles as ButtonStyles } from '../../common/Button';
import RetailersTable from '../RetailersTable';
import AdminNav from '../../admin/AdminNav';

const RetailersView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Retailers</h1>
      <ButtonStyles.ButtonGroup>
        <Button
          label="Create retailer"
          mode="primary"
          linkTo={keepParams('/admin/create-retailer')}
          size="s"
        />
      </ButtonStyles.ButtonGroup>

      <RetailersTable />
    </div>
  );
};

export default RetailersView;
