import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@homamo/meadow-utils';

import HeaderInfoBlock from '../../common/HeaderInfoBlock';
import LogTransactionModal from '../../transaction/LogTransaction';
import Message from '../../common/Message';
import Styles from './Styles';

const MortgagePaymentDetailsComponent = ({
  mortgageId,
  monthlyPayment,
  monthlyOverpayment,
  fetchData,
}) => {
  return (
    <Styles.GridPanel heading="Monthly Payments" smallHeader>
      <HeaderInfoBlock
        label="Contractual"
        value={formatCurrency(monthlyPayment)}
        subLabel="View history"
        linkHref="/history"
      />
      <HeaderInfoBlock
        label="Optional overpayments"
        value={formatCurrency(monthlyOverpayment)}
        subLabel="Change monthly overpayment"
        linkHref="/mortgage/settings/payments"
      />
      <Message size="s">
        We calculate your interest and automatically log your monthly payments
        on the 2nd of every month
      </Message>
      <LogTransactionModal
        mortgageId={mortgageId}
        monthlyPayment={monthlyPayment}
        monthlyOverpayment={monthlyOverpayment}
        buttonSize="s"
        block
        successAction={() => {
          if (fetchData) fetchData();
        }}
      />
    </Styles.GridPanel>
  );
};

MortgagePaymentDetailsComponent.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  monthlyPayment: PropTypes.number.isRequired,
  monthlyOverpayment: PropTypes.number.isRequired,
  fetchData: PropTypes.func,
};

MortgagePaymentDetailsComponent.defaultProps = {
  fetchData: undefined,
};

export default MortgagePaymentDetailsComponent;
