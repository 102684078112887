/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import SelectInput from '../../common/SelectInput';

const getOptions = ({ lenders, includeBlank, blankLabel }) => {
  // As 'Other lender; is stored as an option we need to seperate it out
  // in order to put it at the bottom of the option list
  const lenderOptions = lenders
    .filter(({ name }) => name !== 'Other lender')
    .map((option) => ({
      label: option.name,
      value: option._id,
    }));
  // Get the 'Other lender' option
  const otherLenderOption = lenders
    .filter(({ name }) => name === 'Other lender')
    .map((option) => ({
      label: option.name,
      value: option._id,
    }))[0];

  const options = [];

  if (includeBlank) {
    options.push({ label: blankLabel, value: 'ALL', key: 'blank' });
    options.push({ label: '---', disabled: true, key: 'divider-1' });
  }

  options.push(...lenderOptions);

  if (otherLenderOption) {
    options.push({ label: '---', disabled: true, key: 'divider-2' });
    options.push(otherLenderOption);
  }
  return options;
};

const LenderSelectComponent = ({
  label,
  id,
  name,
  lenders,
  isLoading,
  placeholder,
  isRequired,
  disabled,
  noFormik,
  includeBlank,
  blankLabel,
  value,
  setValue,
  onChange,
  ...props
}) => {
  const options = getOptions({ lenders, includeBlank, blankLabel });

  if (noFormik) {
    return (
      <SelectInput
        label={label}
        id={id}
        name={name}
        placeholder={isLoading ? 'Loading options...' : placeholder}
        isRequired={isRequired}
        disabled={isLoading || disabled}
        options={options}
        value={value}
        onChange={(e) => {
          if (setValue) setValue(e.target.value);
          if (onChange) onChange(e);
        }}
        {...props}
      />
    );
  }

  return (
    <>
      <Field
        label={label}
        id={id}
        name={name}
        placeholder={isLoading ? 'Loading options...' : placeholder}
        component={SelectInput}
        options={options}
        isRequired={isRequired}
        disabled={isLoading || disabled}
        {...props}
      />
    </>
  );
};

LenderSelectComponent.propTypes = {
  includeBlank: PropTypes.bool,
  blankLabel: PropTypes.string,
  lenders: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      _id: PropTypes.string,
    }),
  ),
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  noFormik: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
};

LenderSelectComponent.defaultProps = {
  includeBlank: false,
  blankLabel: 'All lenders',
  lenders: [],
  id: 'lender',
  label: 'Lender',
  name: 'lender',
  placeholder: 'Select a lender',
  isRequired: false,
  disabled: false,
  isLoading: false,
  noFormik: false,
  value: undefined,
  setValue: undefined,
  onChange: undefined,
};

export default LenderSelectComponent;
