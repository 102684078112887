import styled from 'styled-components';

export default styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[3]};
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  margin-bottom: ${({ theme }) => theme.space[5]};

  @media ${({ theme }) => theme.device.laptop} {
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
  }
`;
