import React from 'react';
import PropTypes from 'prop-types';
import API, { handleApiError } from '../../../lib/api';
import RetailersList from './List';

import Error from '../../common/ErrorMessage';
import Search from '../../common/Table/lib/Search';
import Filter from '../../common/Table/lib/Filter';
import Styles from './Styles';

import retailerCategories, {
  retailerCategoryLabel,
} from '../../../lib/retailerCategories';

function RetailersListContainer({ userId }) {
  const [data, setData] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState(undefined);
  const [selectedFilter, setSelectedFilter] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [displayError, setDisplayError] = React.useState();

  const fetchData = async () => {
    await API.get('retailers/', {
      params: {
        searchTerm,
        category: selectedFilter,
        live: true,
        sortBy: 'heading',
      },
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, selectedFilter]);

  return (
    <Styles.RetailersListContainer>
      <Styles.RetailersListSearch>
        <Search
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          placeholder="Search for a retailer"
          isLoading={isLoading}
        />
        <Filter
          mobileSelect
          options={[
            { label: 'All', value: '' },
            ...retailerCategories.map((category) => ({
              label: retailerCategoryLabel[category],
              value: category,
            })),
          ]}
          selectedFilter={selectedFilter}
          setSelectedFilter={setSelectedFilter}
        />
      </Styles.RetailersListSearch>
      <Error error={displayError} />
      <RetailersList
        retailers={data}
        userId={userId}
        isLoading={isLoading}
        fetchData={fetchData}
        searchTerm={searchTerm}
      />
    </Styles.RetailersListContainer>
  );
}

RetailersListContainer.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default RetailersListContainer;
