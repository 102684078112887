import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles';

const PageHeader = ({ heading, introduction, children, isLarge }) => {
  return (
    <Styles.PageHeader isLarge={isLarge}>
      <h2>{heading}</h2>
      <p>{introduction}</p>
      <div>{children}</div>
    </Styles.PageHeader>
  );
};

PageHeader.defaultProps = {
  isLarge: false,
  introduction: undefined,
  children: undefined,
};

PageHeader.propTypes = {
  isLarge: PropTypes.bool,
  heading: PropTypes.string.isRequired,
  introduction: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PageHeader;
