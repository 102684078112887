import styled from 'styled-components';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  overflow-x: scroll;
`;

const NavItem = styled.button`
  display: flex;
  align-items: center;
  border: 0;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme, isActive }) =>
    isActive ? theme.color['blue-900'] : theme.color['blue-800']};
  background: 0;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.color['gray-100'] : 'none'};
  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
  border-radius: 0.5rem;
  padding: 2rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  font-size: 1.4rem;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: uppercase;

  svg {
    margin-right: 1rem;
    height: 2rem;
    width: 2rem;
    color: ${({ theme }) => theme.color['blue-400']};
  }
`;

export default {
  Nav,
  NavItem,
};
