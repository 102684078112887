import React from 'react';
import { Redirect, useLocation, Link } from 'react-router-dom';

import { useAuth } from '../../../context/auth';
import config from '../../../config';

import SignInForm from './Form';
import LayoutWithImage from '../../layout/LayoutWithImage/Component';
import MaintenanceMessage from '../MaintenanceMessage';
import StagingMessage from '../StagingMessage';
// import Message from '../../common/Message';

import relaxImg from '../../../images/relax.svg';

const SignInView = () => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  const referer = (location.state && location.state.referer) || '/';

  if (isAuthenticated) {
    return <Redirect to={referer} />;
  }

  return (
    <LayoutWithImage image={relaxImg} padTop>
      <h1>Sign in to view your account</h1>
      <p>
        We help homeowners take years off their mortgage and save thousands of
        pounds in interest
      </p>
      {!config.signUpDisabled && (
        <p>
          Don&apos;t have an account yet?{' '}
          <Link to="/sign-up">Sign up here</Link>
        </p>
      )}
      {/* <Message>
        We&apos;ve just launched a brand new version of our service! For
        security, you&apos;ll just need to{' '}
        <Link to="/request-reset-password">reset your password</Link>.
      </Message> */}
      <MaintenanceMessage />
      <StagingMessage />
      <SignInForm />
    </LayoutWithImage>
  );
};

export default SignInView;
