import React from 'react';

export default (initialValue) => {
  const [lenders, setLenders] = React.useState(initialValue);

  return {
    lenders,
    setLenders,

    addLender: (newLender) => {
      setLenders([...lenders, newLender]);
    },

    deleteLender: (lenderId) => {
      setLenders((prevLenders) =>
        prevLenders.filter((lender) => lender._id !== lenderId),
      );
    },
  };
};
