import styled from 'styled-components';

const TabsNavContainer = styled.div`
  border-bottom: ${({ theme }) =>
    `${theme.borderWidth[2]} solid ${theme.color['gray-200']}`};
  margin-bottom: ${({ theme }) => theme.space[2]};
  overflow: scroll;
`;

const TabsNavList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: ${({ theme }) => theme.space[2]};
  grid-auto-columns: auto;
  grid-auto-flow: column;
  justify-content: flex-start;

  @media ${({ theme }) => theme.device.tablet} {
    grid-gap: ${({ theme }) => theme.space[5]};
  }
`;

const TabNavLink = styled.li`
  padding: 0;
  margin: 0;
  white-space: nowrap;

  a {
    display: inline-block;
    text-decoration: none;
    padding: ${({ theme }) => `${theme.space[3]} ${theme.space[2]}`};
  }

  a.--is-active {
    border-bottom: ${({ theme }) =>
      `${theme.borderWidth[3]} solid ${theme.color['gray-500']}`};
  }

  a:hover {
    text-decoration: none;
  }
`;

export default {
  TabsNavContainer,
  TabsNavList,
  TabNavLink,
};
