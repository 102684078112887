import React from 'react';
import TabsNav from '../../common/TabsNav';

const AdminNavComponent = () => {
  return (
    <TabsNav
      items={[
        {
          label: 'Users',
          path: '/admin/users',
          pathActiveIfIncludes: '/admin/user',
        },
        {
          label: 'Mortgages',
          path: '/admin/mortgages',
          pathActiveIfIncludes: '/admin/mortgage',
        },
        {
          label: 'Lenders',
          path: '/admin/lenders',
          pathActiveIfIncludes: '/admin/lender',
        },
        {
          label: 'Rates',
          path: '/admin/rates',
          pathActiveIfIncludes: '/admin/rate',
        },
        {
          label: 'Retailers',
          path: '/admin/retailers',
          pathActiveIfIncludes: '/admin/retailer',
        },
        {
          label: 'Rewards',
          path: '/admin/rewards',
          pathActiveIfIncludes: '/admin/reward',
        },
        {
          label: 'Payments',
          path: '/admin/payments',
          pathActiveIfIncludes: '/admin/payment',
        },
      ]}
    />
  );
};

export default AdminNavComponent;
