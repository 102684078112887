import React, { createContext, useState, useContext } from 'react';

export const AppContext = createContext(null);

const initialAppState = { menuOpen: false };

const AppContextProvider = (props) => {
  const [appState, setAppState] = useState(initialAppState);

  const onMobileMenuOpen = () => setAppState({ menuOpen: true });
  const onMobileMenuClose = () => setAppState({ menuOpen: false });
  const appStateValue = { onMobileMenuOpen, onMobileMenuClose, ...appState };

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AppContext.Provider value={appStateValue} {...props} />;
};

export const useAppContext = () => useContext(AppContext);

export default AppContextProvider;
