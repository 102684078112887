import React from 'react';
import config from '../../../config';

import Message from '../../common/Message';

const StagingMessage = () => {
  return config.isStaging ? (
    <Message mode="warning">
      This is the testing version of AMM. If you&apos;re here by accident, you
      can{' '}
      <a href="https://app.acceleratemymortgage.com">
        sign in to the public version here.
      </a>
    </Message>
  ) : null;
};

export default StagingMessage;
