import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import DashboardEligibility from '../DashboardEligibility/Component';
import Styles from './Styles';

const DashboardBlock = ({
  heading,
  image,
  eligible,
  eligibilityMessage,
  eligibilityIcon,
  message,
  buttonLabel,
  buttonLink,
}) => {
  return (
    <Styles.DashboardBlock heading={heading} smallHeader>
      <div className="inner">
        <img src={image} alt="" />
        <DashboardEligibility
          message={eligibilityMessage}
          eligible={eligible}
          icon={eligibilityIcon}
        />
        {message && <p>{message}</p>}
        <Button label={buttonLabel} linkTo={buttonLink} mode="primary" block />
      </div>
    </Styles.DashboardBlock>
  );
};

DashboardBlock.defaultProps = {
  eligible: false,
  eligibilityIcon: 'check-circle',
  message: undefined,
};

DashboardBlock.propTypes = {
  heading: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  eligible: PropTypes.bool,
  eligibilityMessage: PropTypes.string.isRequired,
  eligibilityIcon: PropTypes.string,
  message: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
};

export default DashboardBlock;
