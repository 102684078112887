import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminRetailers from '../../../components/retailer/Retailers';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const RetailersView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Retailers">
        <AdminRetailers />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default RetailersView;
