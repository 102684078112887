import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatCurrency, formatPercentage } from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Modal, { ModalFooter } from '../../common/Modal';
import config from '../../../config';

const AssumptionsModal = ({
  preLabel,
  buttonLabel,
  interestRate,
  monthlyPayment,
  monthlyOverpayment,
  remainingBalance,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  return (
    <>
      <p>
        {preLabel}
        <Button
          mode="link"
          size="s"
          label={buttonLabel || 'View key assumptions'}
          onClick={() => setModalIsOpen(true)}
        />
      </p>
      <Modal
        heading="Our key assumptions"
        isOpen={modalIsOpen}
        closeAction={() => setModalIsOpen(false)}
        contentLabel="Our assumptions"
      >
        <p>We assume that:</p>
        <ul>
          <li>Your mortgage is residential</li>
          <li>
            You reside at the property, or you have a ‘consent to let’ agreement
            in place
          </li>
          <li>
            Your mortgage is in one part, or if split over multiple parts, they
            are all aligned to a single term and are on the same rate/deal
          </li>
        </ul>
        <p>You&apos;ve told us that:</p>
        <ul>
          <li>Your remaining balance is {formatCurrency(remainingBalance)}</li>
          <li>Your interest rate is {formatPercentage(interestRate, 2)}</li>
          <li>Your monthly payment is {formatCurrency(monthlyPayment)}</li>
          <li>
            Your scheduled monthly overpayment is{' '}
            {formatCurrency(monthlyOverpayment)}
          </li>
        </ul>
        <p>
          If any of these details need to be updated, you can do it{' '}
          <Link to="/mortgage/settings">here</Link>
        </p>

        <p>
          Our estimations are provided on a non-advised basis. For more details
          of our full Terms &amp; Conditions,{' '}
          <a href={config.termsUrl} target="_blank" rel="noreferrer noopener">
            click here
          </a>
        </p>
        <ModalFooter>
          <Button label="Okay" onClick={() => setModalIsOpen(false)} />
        </ModalFooter>
      </Modal>
    </>
  );
};

AssumptionsModal.defaultProps = {
  preLabel: undefined,
  buttonLabel: undefined,
  monthlyOverpayment: 0,
};

AssumptionsModal.propTypes = {
  preLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  interestRate: PropTypes.number.isRequired,
  monthlyPayment: PropTypes.number.isRequired,
  remainingBalance: PropTypes.number.isRequired,
  monthlyOverpayment: PropTypes.number,
};

export default AssumptionsModal;
