import React from 'react';
import Layout from '../../../components/layout/Layout';
import CreateRetailer from '../../../components/retailer/CreateRetailer';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const CreateRetailerView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Create retailer">
        <CreateRetailer />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default CreateRetailerView;
