import * as Sentry from '@sentry/browser';
import config from '../config';

const loadSentry = () => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      release: `${config.appName}@${process.env.npm_package_version}`,
      // Define from process.env in case we test Sentry in development
      environment: process.env.NODE_ENV,
    });
  }
};

export default loadSentry;
