import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import Styles from './Styles';

const TabsNavComponent = ({ items }) => {
  return (
    <Styles.TabsNavContainer>
      <Styles.TabsNavList>
        {items.map(({ label, path, pathActiveIfIncludes }) => (
          <Styles.TabNavLink key={path}>
            <NavLink
              to={path}
              activeClassName="--is-active"
              isActive={(match, location) => {
                return (
                  match || location.pathname.includes(pathActiveIfIncludes)
                );
              }}
              exact
            >
              {label}
            </NavLink>
          </Styles.TabNavLink>
        ))}
      </Styles.TabsNavList>
    </Styles.TabsNavContainer>
  );
};

TabsNavComponent.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.shape,
      label: PropTypes.string,
      pathActiveIfIncludes: PropTypes.string,
    }),
  ).isRequired,
};

export default TabsNavComponent;
