import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { convertToPence, convertToPounds } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../../../lib/api';

import OnboardingViewActions from '../../../OnboardingViewActions';
import Form from '../../../../common/Form';
import CurrencyInput from '../../../../common/CurrencyInput';
import Message from '../../../../common/Message';
import Error from '../../../../common/ErrorMessage';

import LayoutWithImage from '../../../../layout/LayoutWithImage';
import checklistImg from '../../../../../images/checklist.svg';

const validationSchema = Yup.object().shape({
  estimatedPropertyValue: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('Enter an estimated value for your property'),
});

const OnboardingPropertyValue = ({
  mortgageId,
  mortgage,
  setSelectedStep,
  setDisplayError,
  setMortgageData,
  displayError,
}) => (
  <LayoutWithImage image={checklistImg}>
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        await API.post(`mortgages/update/${mortgageId}`, {
          estimatedPropertyValue: convertToPence(values.estimatedPropertyValue),
        })
          .then((res) => {
            setSelectedStep('type');
            setMortgageData(res.data);
          })
          .catch((error) => {
            handleApiError({ error, setDisplayError });
          })
          .finally(() => setSubmitting(false));
      }}
      initialValues={{
        estimatedPropertyValue:
          (mortgage && convertToPounds(mortgage.estimatedPropertyValue)) ||
          undefined,
      }}
      validationSchema={validationSchema}
      render={({ isSubmitting }) => {
        return (
          <Form>
            <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
              <h2>How much is your property currently worth?</h2>
              <Field
                label="Enter an estimated valuation of your property"
                id="estimatedPropertyValue"
                name="estimatedPropertyValue"
                component={CurrencyInput}
              />
              <Message big>
                If you don&apos;t know your property value, enter your best
                estimate and we can update it later.
              </Message>
              <Error error={displayError} />
              <OnboardingViewActions
                previousView="balance"
                setSelectedStep={setSelectedStep}
                isLoading={isSubmitting}
              />
            </fieldset>
          </Form>
        );
      }}
    />
  </LayoutWithImage>
);

OnboardingPropertyValue.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    estimatedPropertyValue: PropTypes.number,
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  setMortgageData: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingPropertyValue.defaultProps = {
  displayError: undefined,
};

export default OnboardingPropertyValue;
