import React from 'react';
import PropTypes from 'prop-types';
import {
  formatDateTime,
  formatPercentage,
  keepParams,
} from '@homamo/meadow-utils';

import Definition, { DefinitionList } from '../../common/Definition';
import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Panel, { PanelColumn } from '../../common/Panel';
import AdminRatesTable from '../../rate/AdminRatesTable';
import AdminNav from '../../admin/AdminNav';

const LenderView = ({ data, displayError }) => {
  const termWidth = '200px';

  return (
    <div>
      <AdminNav />
      <h1>{data.name}</h1>
      <ButtonGroup>
        <Button
          size="s"
          label="View all lenders"
          icon="arrow-left"
          linkTo={keepParams('/admin/lenders')}
        />
        <Button
          size="s"
          label="Edit"
          linkTo={keepParams(`/admin/lender/${data._id}/edit`)}
        />
      </ButtonGroup>
      <Error error={displayError} />
      <PanelColumn>
        <Panel heading="Details">
          <DefinitionList termWidth={termWidth}>
            <Definition term="Logo">
              {data.image && <img src={data.image} alt={data.name} />}
            </Definition>
            <Definition term="SVR" definition={formatPercentage(data.svr, 2)} />
            <Definition
              term="Show Rates"
              definition={data.showRates ? 'TRUE' : 'FALSE'}
            />
            <Definition
              term="Representative Example"
              definition={data.representativeExample}
            />
          </DefinitionList>
        </Panel>
        <Panel heading="Notes">
          <DefinitionList termWidth={termWidth}>
            <Definition
              term="Product Transfer"
              definition={data.productTransferNotes}
            />
            <Definition term="Overpayment" definition={data.overpaymentNotes} />
            <Definition term="General" definition={data.generalNotes} />
          </DefinitionList>
        </Panel>
        <Panel heading="Lender rates">
          <AdminRatesTable lenderId={data._id} fixedLender />
        </Panel>
        <Panel heading="Meta">
          <DefinitionList termWidth={termWidth}>
            <Definition term="ID" definition={data._id} />
            <Definition
              term="Created"
              definition={formatDateTime(data.createdAt)}
            />
            <Definition
              term="Updated"
              definition={formatDateTime(data.updatedAt)}
            />
          </DefinitionList>
        </Panel>
      </PanelColumn>
    </div>
  );
};

LenderView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.string,
    svr: PropTypes.number,
    showRates: PropTypes.bool,
    representativeExample: PropTypes.string,
    productTransferNotes: PropTypes.string,
    overpaymentNotes: PropTypes.string,
    generalNotes: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
  }).isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

LenderView.defaultProps = {
  displayError: undefined,
};

export default LenderView;
