import styled from 'styled-components';

import { Styles as BadgeStyles } from '../Badge';
import { Styles as ButtonStyles } from '../Button';

const MobileMenu = styled.div`
  padding: ${({ theme }) =>
    `${theme.space[5]} ${theme.space[4]} ${theme.space[2]} ${theme.space[4]}`};
  position: relative;
  background: ${({ theme }) => theme.color['blue-800']};
  color: ${({ theme }) => theme.color.white};
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  width: 100%;
  max-width: ${({ theme }) => `${theme.space[11]}`};
  bottom: 0;
  transform: translateX(100%);
  transition: all 0.3s;
  box-shadow: 0 2px 10px 0 rgba(81, 159, 196, 0.2);
  z-index: 5;
  display: grid;
  grid-template-rows: auto 1fr;

  ${(props) => props.isOpen && `transform: translateX(0);`};

  header {
    display: flex;
    justify-content: flex-end;
    font-size: ${({ theme }) => `${theme.fontSize[4]}`};
    color: ${({ theme }) => theme.color['gray-500']};
    position: fixed;
    right: 0;
  }

  footer {
    small {
      color: ${({ theme }) => theme.color['gray-400']};
      display: block;
      margin-bottom: ${({ theme }) => `${theme.space[2]}`};
      text-align: center;
    }

    ${ButtonStyles.Button} {
      font-size: ${({ theme }) => `${theme.fontSize[2]}`};
      display: block;
      width: 100%;
      color: ${({ theme }) => theme.color['gray-500']};
      background: 0;
      border-radius: ${({ theme }) => `${theme.borderRadius[1]}`};
      border: 1px solid ${({ theme }) => theme.color['gray-500']};

      &:hover {
        background: ${({ theme }) => theme.color['gray-900']};
      }
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow-y: scroll;
    margin-bottom: ${({ theme }) => `${theme.space[2]}`};
    padding-bottom: ${({ theme }) => `${theme.space[2]}`};

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 10px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      /* -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); */
    }

    li {
      cursor: pointer;
      font-size: ${({ theme }) => `${theme.fontSize[2]}`};
    }

    a {
      padding: ${({ theme }) => `${theme.space[1]} 0`};
      margin: ${({ theme }) => `0 ${theme.space[2]}`};
      color: ${({ theme }) => theme.color['gray-500']};
      cursor: pointer;

      svg {
        margin-left: ${({ theme }) => `${theme.space[1]}`};
      }
    }

    a:hover {
      text-decoration: none;
    }

    a.--is-active {
      color: ${({ theme }) => theme.color['gray-200']};
    }

    ${BadgeStyles.Badge} {
      margin-left: ${({ theme }) => `${theme.space[1]}`};
    }
  }
`;

export default { MobileMenu };
