/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Styles from './Styles';

const BadgeComponent = ({ icon, children, mode, size, ...props }) => {
  return (
    <Styles.Badge mode={mode} size={size} {...props}>
      {icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </Styles.Badge>
  );
};

BadgeComponent.propTypes = {
  mode: PropTypes.string,
  icon: PropTypes.string,
  size: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

BadgeComponent.defaultProps = {
  mode: 'default',
  icon: undefined,
  size: 'm',
};

export default BadgeComponent;
