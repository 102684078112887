import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import API, { handleApiError } from '../../../lib/api';
import TransactionView from './View';
import TransactionNotFound from '../TransactionNotFound';

import Loading from '../../common/Loading';

function TransactionContainer({ id, mode }) {
  const transactionId = id;
  // Data state to store the users data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [displayError, setDisplayError] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    await API.get(`transactions/${transactionId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!transactionId) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  if (isLoading) return <Loading />;
  return data && data._id ? (
    <TransactionView
      data={data}
      refetchData={fetchData}
      setDisplayError={setDisplayError}
      displayError={displayError}
      mode={mode}
    />
  ) : (
    <TransactionNotFound id={transactionId} displayError={displayError} />
  );
}

TransactionContainer.propTypes = {
  id: PropTypes.string.isRequired,
  mode: PropTypes.string,
};

TransactionContainer.defaultProps = {
  mode: 'default',
};

export default TransactionContainer;
