import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  formatTerm,
  getInterestSaved,
  getTermReduction,
} from '@rateswitch/amm-utils';

import { formatCurrency } from '@homamo/meadow-utils';

import Message from '../../common/Message';

const MortgageScenarioComponent = ({
  monthlyPayment,
  monthlyOverpayment,
  remainingBalance,
  interestRate,
}) => {
  /* We round it so there's no pence */
  const scenarioMonthlyOverpayment = monthlyOverpayment
    ? Math.floor(monthlyOverpayment * 0.2)
    : Math.floor(monthlyPayment * 0.1);

  const existingTotalMonthlyPayment = monthlyOverpayment
    ? monthlyPayment + monthlyOverpayment
    : monthlyPayment;

  const scenarioTermReduction = getTermReduction({
    remainingBalance,
    interestRate,
    monthlyPayment: existingTotalMonthlyPayment,
    overpaymentAmount: scenarioMonthlyOverpayment,
    overpaymentInterval: 'monthly',
  });

  const scenarioInterestSaved = getInterestSaved({
    remainingBalance,
    interestRate,
    monthlyPayment: existingTotalMonthlyPayment,
    overpaymentAmount: scenarioMonthlyOverpayment,
    overpaymentInterval: 'monthly',
  });

  const msgPayment = formatCurrency(scenarioMonthlyOverpayment, {
    wholePounds: true,
  });
  const msgSavingInterest = formatCurrency(scenarioInterestSaved, {
    wholePounds: true,
  });
  const msgSavingTerm = formatTerm(scenarioTermReduction);

  return (
    <div>
      {monthlyOverpayment ? (
        <Message size="l" center>
          Increasing your monthly overpayments by {msgPayment} could save you a
          further {msgSavingInterest} in interest and give you an extra{' '}
          {msgSavingTerm} of mortgage free life.{' '}
          <Link to="/overpay">Find out more</Link>
        </Message>
      ) : (
        <Message size="l" center>
          Overpaying your mortgage by {msgPayment} a month could save you{' '}
          {msgSavingInterest} in interest and give you an extra {msgSavingTerm}{' '}
          of mortgage free life. <Link to="/overpay">Find out more</Link>
        </Message>
      )}
    </div>
  );
};

MortgageScenarioComponent.defaultProps = {
  monthlyOverpayment: undefined,
};

MortgageScenarioComponent.propTypes = {
  monthlyPayment: PropTypes.number.isRequired,
  monthlyOverpayment: PropTypes.number,
  remainingBalance: PropTypes.number.isRequired,
  interestRate: PropTypes.number.isRequired,
};

export default MortgageScenarioComponent;
