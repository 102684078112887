import styled from 'styled-components';

const Loading = styled.div`
  height: 100%;
  width: 100%;
  min-height: ${({ theme }) => theme.space[10]};
  padding: ${({ theme }) => theme.space[3]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize[10]};
  color: ${({ theme }) => theme.color['gray-500']};
`;

export default {
  Loading,
};
