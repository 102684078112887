import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import API, { handleApiError } from '../../../lib/api';
import { ConfirmButton } from '../../common/Confirm';

const DeleteRetailerComponent = ({
  id,
  retailer,
  onRetailerDeleted,
  setDisplayError,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const deleteRetailer = async () => {
    setIsLoading(true);
    await API.post(`retailers/delete/${id}`)
      .then(() => {
        if (onRetailerDeleted) onRetailerDeleted(id);
        toast.success('Retailer deleted');
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
        toast.error('Delete retailer failed');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <ConfirmButton
      buttonLabel="Delete"
      buttonSize="s"
      buttonMode="danger"
      contentLabel={`Delete retailer: ${retailer.name}`}
      confirmAction={() => deleteRetailer()}
      confirmButtonLabel="Delete retailer"
      confirmButtonLoadingLabel="Deleting"
      confirmTextValue={retailer.name}
      modalHeading={`Delete retailer: ${retailer.name}`}
      modalMessage="Deleting a retailer will make it unselectable and potentially cause issues for users with this retailer. Consider this before deleting."
      isConfirmActionloading={isLoading}
    />
  );
};

DeleteRetailerComponent.propTypes = {
  id: PropTypes.string.isRequired,
  onRetailerDeleted: PropTypes.func,
  setDisplayError: PropTypes.func,
  retailer: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

DeleteRetailerComponent.defaultProps = {
  onRetailerDeleted: undefined,
  setDisplayError: undefined,
};

export default DeleteRetailerComponent;
