import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime } from '@homamo/meadow-utils';
import RequestAccountDelete from './Component';
import Panel from '../../common/Panel';

const DeleteAccountView = ({ data, refetchData }) => {
  return (
    <div>
      <Panel>
        {data.accountDeletionRequested ? (
          <>
            <p>
              Your account deletion request was submitted
              {data.accountDeletionRequested &&
                ` ${formatDateTime(data.accountDeletionRequestedDate)}`}
              .
            </p>
            <p>One of our team will be in touch soon to confirm.</p>
          </>
        ) : (
          <p>
            Submit an account deletion request and one of our team will be in
            touch to arrange your account closure.
          </p>
        )}
        <RequestAccountDelete
          data={data}
          refetchData={refetchData}
          resend={data.accountDeletionRequested}
        />
      </Panel>
    </div>
  );
};

DeleteAccountView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    accountDeletionRequested: PropTypes.bool,
    accountDeletionRequestedDate: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

DeleteAccountView.defaultProps = {
  data: {},
};

export default DeleteAccountView;
