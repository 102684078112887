import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import API, { handleApiError } from '../../../lib/api';
import Button from '../../common/Button';

const DeleteRateComponent = ({ id, onRateDeleted, setDisplayError }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Button
      label="Delete"
      size="s"
      mode="danger"
      isLoading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        await API.post(`rates/delete/${id}`)
          .then(() => {
            if (onRateDeleted) onRateDeleted(id);
            toast.success('Rate deleted');
          })
          .catch((error) => {
            handleApiError({ error, setDisplayError });
            toast.error('Delete rate failed');
          })
          .finally(() => setIsLoading(false));
      }}
    />
  );
};

DeleteRateComponent.propTypes = {
  id: PropTypes.string.isRequired,
  onRateDeleted: PropTypes.func,
  setDisplayError: PropTypes.func,
};

DeleteRateComponent.defaultProps = {
  onRateDeleted: undefined,
  setDisplayError: undefined,
};

export default DeleteRateComponent;
