import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../../../../styles/theme';

import Swatch from './Swatch';

const ColorBlock = ({ className, color, label }) => (
  <div className={className}>
    <Swatch color={color} />
    <h4>{label || color}</h4>
    <span>{theme.color[color]}</span>
  </div>
);

ColorBlock.propTypes = {
  className: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  label: PropTypes.string,
};

ColorBlock.defaultProps = {
  label: undefined,
};

export default styled(ColorBlock)`
  width: 100%;

  span {
    font-size: ${theme.fontSize[1]};
  }
`;
