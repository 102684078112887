import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import EditTransaction from '../../../components/transaction/EditTransaction';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const EditPaymentView = () => {
  const { id } = useParams();
  return (
    <AnalyticsWrapper>
      <Layout title="Edit payment">
        <EditTransaction id={id} mode="admin" />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default EditPaymentView;
