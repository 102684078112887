import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminUsers from '../../../components/user/AdminUsers';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminUsersView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Admin - Manage users">
        <AdminUsers />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminUsersView;
