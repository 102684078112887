/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles';

const PageIntroComponent = ({ heading, text, children, ...props }) => (
  <Styles.PageIntro {...props}>
    {heading && <h1>{heading}</h1>}
    {text && <p>{text}</p>}
    {children}
  </Styles.PageIntro>
);

PageIntroComponent.defaultProps = {
  heading: undefined,
  text: undefined,
  children: undefined,
};

PageIntroComponent.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default PageIntroComponent;
