import { createGlobalStyle } from 'styled-components';
import theme from './theme';
import config from '../config';

const { device } = theme;

const GlobalStyles = createGlobalStyle`

  @font-face {
    font-family: 'hk_groteskmedium';
    src: url('${config.appUrl}/fonts/hkgrotesk-medium.eot');
    src: url('${config.appUrl}/fonts/hkgrotesk-medium.eot?#iefix') format('embedded-opentype'),
         url('${config.appUrl}/fonts/hkgrotesk-medium.woff2') format('woff2'),
         url('${config.appUrl}/fonts/hkgrotesk-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'hk_grotesksemibold';
    src: url('${config.appUrl}/fonts/hkgrotesk-semibold.eot');
    src: url('${config.appUrl}/fonts/hkgrotesk-semibold.eot?#iefix') format('embedded-opentype'),
         url('${config.appUrl}/fonts/hkgrotesk-semibold.woff2') format('woff2'),
         url('${config.appUrl}/fonts/hkgrotesk-semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'hk_groteskbold';
    src: url('${config.appUrl}/fonts/hkgrotesk-bold.eot');
    src: url('${config.appUrl}/fonts/hkgrotesk-bold.eot?#iefix') format('embedded-opentype'),
         url('${config.appUrl}/fonts/hkgrotesk-bold.woff2') format('woff2'),
         url('${config.appUrl}/fonts/hkgrotesk-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: ${theme.baseSize};
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }

  *:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  body {
    padding: 0;
    margin: 0;
    font-size: ${theme.fontSize[2]};
    font-family: ${theme.fontFamily};
    color: ${theme.color['gray-800']};
    background: ${theme.color['gray-200']};
    height: 100%;
    min-height: 100vh;
  }

  body.ReactModal__Body--open {
    overflow: hidden;
  }

  a {
    text-decoration: none;
    color: ${theme.linkColor};
    :hover {
      text-decoration: underline;
    }
  }

  h1, h2, h3, h4, h5 {
    color: ${theme.headingColor};
    font-family: ${theme.headingFontFamily};
    font-weight: 700;
  }

  h1 {
    font-size: ${theme.fontSize[6]};

    @media ${device.mobileL} {
      font-size: ${theme.fontSize[7]};
    }

    @media ${device.tablet} {
      font-size: ${theme.fontSize[8]};
    }
  }

  h2 {
    font-size: ${theme.fontSize[6]};

    @media ${device.tablet} {
      font-size: ${theme.fontSize[7]};
    }
  }

  h3 {
    font-size: ${theme.fontSize[5]};

    @media ${device.tablet} {
      font-size: ${theme.fontSize[6]};
    }
  }

  h4 {
    font-size: ${theme.fontSize[6]};

    @media ${device.tablet} {
      font-size: ${theme.fontSize[5]};
    }
  }

  p, li {
    font-size: ${theme.fontSize[2]};
    line-height: 1.5;

    @media ${device.tablet} {
      font-size: ${theme.fontSize[3]};
    }

    a {
      text-decoration: underline;
    }

    strong {
      font-family: ${theme.headingFontFamily};
      font-weight: 700;
    }

    &.center {
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }

  small, p small {
    font-size: ${theme.fontSize[1]};
  }
`;

export default GlobalStyles;
