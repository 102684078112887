import React from 'react';
import { formatDateTime } from '@homamo/meadow-utils';
import Layout from '../../layout/Layout';
import config from '../../../config';
import { ButtonGroup } from '../../common/Button';
import SignOut from '../SignOut';
import { useAuth } from '../../../context/auth';

const SuspendedMessageView = () => {
  const { user } = useAuth();

  return (
    <Layout title="Suspended account">
      <h1>Your account has been temporarily suspended</h1>
      <p>
        Your account was marked as suspended at{' '}
        {formatDateTime(user.suspendedDate)}. If you would like more information
        please contact{' '}
        <a href={`mailto:${config.supportEmail}`}>{config.supportEmail}</a>.
      </p>
      <ButtonGroup>
        <SignOut />
      </ButtonGroup>
    </Layout>
  );
};

export default SuspendedMessageView;
