/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles';

const Panel = ({ heading, children, smallHeader, ...props }) => (
  <Styles.Panel smallHeader={smallHeader} {...props}>
    {heading && (
      <div className="header">
        <h3>{heading}</h3>
      </div>
    )}
    <div className="body">{children}</div>
  </Styles.Panel>
);

Panel.defaultProps = {
  heading: undefined,
  smallHeader: false,
  children: undefined,
};

Panel.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  smallHeader: PropTypes.bool,
};

export default Panel;
