import React from 'react';
import PropTypes from 'prop-types';
import MortgageSettingsPaymentsForm from './Form';

const MortgageSettingsPaymentsView = ({ data, refetchData }) => {
  return (
    <div>
      <MortgageSettingsPaymentsForm
        initialData={data}
        refetchData={refetchData}
      />
    </div>
  );
};

MortgageSettingsPaymentsView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

MortgageSettingsPaymentsView.defaultProps = {
  data: {},
};

export default MortgageSettingsPaymentsView;
