import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import Transaction from '../../../components/transaction/Transaction';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const PaymentView = () => {
  const { id } = useParams();

  return (
    <AnalyticsWrapper>
      <Layout title="Payment">
        <Transaction id={id} mode="admin" />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default PaymentView;
