import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import Modal from '../../common/Modal';
import Message from '../../common/Message';
import ArrangeCallbackForm from './Form';

const ArrangeCallbackModal = ({
  buttonLabel,
  buttonMode,
  buttonSize,
  buttonBlock,
  eligibleForSwitch,
  userPhone,
  product,
  context,
  message,
  blockParentForm,
  unblockParentForm,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const openModal = () => {
    setModalIsOpen(true);
    if (blockParentForm) unblockParentForm();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    if (unblockParentForm) unblockParentForm();
  };

  return (
    <>
      <Button
        size={buttonSize}
        mode={buttonMode}
        label={buttonLabel}
        block={buttonBlock}
        onClick={() => openModal()}
      />
      <Modal
        heading="Arrange a callback"
        isOpen={modalIsOpen}
        closeAction={() => closeModal()}
        contentLabel="Arrange a callback"
      >
        {eligibleForSwitch ? (
          <p>
            Great - let’s get started! Leave your contact number and one of our
            expert mortgage advisers will call you back promptly
          </p>
        ) : (
          <p>
            We’re always happy to help - leave your number and one of our
            experienced mortgage advisers will call you back promptly
          </p>
        )}
        <ArrangeCallbackForm
          userPhone={userPhone}
          context={context}
          product={product}
          successAction={closeModal}
          cancelAction={closeModal}
        />
        <br />
        {message && <Message>{message}</Message>}
      </Modal>
    </>
  );
};

ArrangeCallbackModal.propTypes = {
  buttonLabel: PropTypes.string,
  buttonMode: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonBlock: PropTypes.bool,
  context: PropTypes.string,
  product: PropTypes.string,
  userPhone: PropTypes.string,
  blockParentForm: PropTypes.func,
  unblockParentForm: PropTypes.func,
  message: PropTypes.string,
  eligibleForSwitch: PropTypes.bool,
};

ArrangeCallbackModal.defaultProps = {
  buttonLabel: 'Arrange a callback',
  buttonMode: 'default',
  buttonSize: 'm',
  buttonBlock: false,
  userPhone: undefined,
  blockParentForm: undefined,
  unblockParentForm: undefined,
  context: undefined,
  product: undefined,
  message: undefined,
  eligibleForSwitch: false,
};

export default ArrangeCallbackModal;
