import styled from 'styled-components';

const ErrorMessage = styled.div`
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color['red-900']};

  padding: ${({ theme }) =>
    `${theme.space[0]} ${theme.space[4]} ${theme.space[1]}`} !important;
  margin: ${({ theme }) => theme.space[3]} 0 !important;
  box-shadow: ${({ theme }) => theme.boxShadow[3]};

  border: ${({ theme }) => `solid ${theme.danger}`};
  border-width: 0;
  border-left-width: ${({ theme }) => theme.borderWidth[4]};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
`;

const ErrorMessageText = styled.p`
  margin: ${({ theme }) => `0 0 ${theme.space[1]}`} !important;
  padding: 0;
`;

const ErrorMessageCode = styled.p`
  color: ${({ theme }) => theme.color['red-600']};
  padding: ${({ theme }) => theme.space[0]};
  font-size: ${({ theme }) => theme.fontSize[0]} !important;
  text-transform: uppercase;
  background: ${({ theme }) => theme.color['red-100']};
  display: inline-block;
  border-radius: ${({ theme }) => theme.borderRadius[1]};

  svg {
    margin-right: ${({ theme }) => theme.space[1]};
  }
`;

export default {
  ErrorMessage,
  ErrorMessageText,
  ErrorMessageCode,
};
