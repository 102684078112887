/* eslint-disable react/jsx-curly-newline */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  convertToPence,
  convertToPounds,
  formatCurrency,
} from '@homamo/meadow-utils';

import Panel from '../../common/Panel';
// import Error from './ErrorMessage';
import Form from '../../common/Form';
import CurrencyInput from '../../common/CurrencyInput';
import Button, { ButtonGroup } from '../../common/Button';
import RadioGroup from '../../common/RadioGroup';
import RadioInput from '../../common/RadioInput';
import Styles from './Styles';

const validationSchema = Yup.object().shape({
  amount: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('Enter an amount'),
});

const CalculatorPaymentInput = ({
  amount,
  interval,
  runOverpaymentCalculation,
  className,
}) => {
  return (
    <div className={className}>
      <Panel heading="Choose an amount and type" smallHeader>
        <Formik
          enableReinitialize
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(false);
            runOverpaymentCalculation({
              overpaymentAmount: convertToPence(values.amount),
              overpaymentInterval: values.interval,
            });
          }}
          initialValues={{
            amount: convertToPounds(amount) || undefined,
            interval,
          }}
          validationSchema={validationSchema}
          render={({
            errors,
            values,
            touched,
            isSubmitting,
            setFieldValue,
          }) => {
            const incrementAmount = values.interval === 'monthly' ? 20 : 500;

            const updateAmount = (addValue) => {
              const newAmount =
                convertToPounds(convertToPence(values.amount)) + addValue;
              const displayValue = formatCurrency(newAmount, {
                includeSymbol: false,
                source: 'pounds',
                wholePounds: true,
              });
              setFieldValue('amount', displayValue);
            };

            const subtractIsDisabled =
              convertToPounds(convertToPence(values.amount)) - incrementAmount <
              0;

            return (
              <Form>
                <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                  <Styles.Layout>
                    <div>
                      <Field
                        label="Overpayment amount"
                        id="amount"
                        name="amount"
                        component={CurrencyInput}
                        min="0"
                        wholePounds
                        isRequired
                      />
                      <ButtonGroup>
                        <Button
                          label={`-£${incrementAmount}`}
                          onClick={() => updateAmount(-incrementAmount)}
                          disabled={subtractIsDisabled}
                        />
                        <Button
                          label={`+£${incrementAmount}`}
                          onClick={() => updateAmount(incrementAmount)}
                        />
                      </ButtonGroup>
                    </div>
                    <div>
                      <RadioGroup
                        id="interval"
                        label="Type"
                        value={values.interval}
                        error={errors.interval}
                        touched={touched.interval}
                        inRow
                      >
                        <Field
                          name="interval"
                          id="monthly"
                          label="Monthly"
                          valueLabel="monthly"
                          component={RadioInput}
                        />
                        <Field
                          name="interval"
                          id="single"
                          label="One-off"
                          valueLabel="single"
                          component={RadioInput}
                        />
                      </RadioGroup>
                    </div>
                  </Styles.Layout>
                  <Button
                    label="Calculate"
                    mode="primary"
                    type="submit"
                    loadingLabel="Calculating"
                    block
                    isLoading={isSubmitting}
                  />
                </fieldset>
              </Form>
            );
          }}
        />
      </Panel>
    </div>
  );
};

CalculatorPaymentInput.propTypes = {
  amount: PropTypes.number,
  interval: PropTypes.string,
  runOverpaymentCalculation: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};

CalculatorPaymentInput.defaultProps = {
  amount: 100,
  interval: 'monthly',
};

export default styled(CalculatorPaymentInput)`
  ${Form} {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;
  }
`;
