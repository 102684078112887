/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Styles from './Styles';

const HeaderInfoBlock = ({
  label,
  value,
  subLabel,
  linkHref,
  children,
  big,
  ...props
}) => {
  return (
    <Styles.HeaderInfoBlock {...props}>
      <dt>{label}</dt>
      <dd>{children || value}</dd>
      {(subLabel && linkHref && (
        <span>
          <Link to={linkHref}>{subLabel}</Link>
        </span>
      )) ||
        (subLabel && <span>{subLabel}</span>)}
    </Styles.HeaderInfoBlock>
  );
};

HeaderInfoBlock.defaultProps = {
  value: undefined,
  children: undefined,
  subLabel: undefined,
  linkHref: undefined,
  big: false,
};

HeaderInfoBlock.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  subLabel: PropTypes.string,
  linkHref: PropTypes.string,
  big: PropTypes.bool,
};

export default HeaderInfoBlock;
