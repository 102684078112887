import React from 'react';
import PropTypes from 'prop-types';
import API, { handleApiError } from '../../../lib/api';
import Rates from './Component';
import Error from '../../common/ErrorMessage';

function RatesContainer({
  lenderId,
  loanToValue,
  loanAmount,
  eligibleForSwitch,
  userPhone,
  userId,
  remainingTerm,
}) {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [displayError, setDisplayError] = React.useState();

  const fetchData = async () => {
    await API.get('rates/', {
      params: { lenderId, loanToValue, loanAmount, sortBy: 'rate' },
    })
      .then((res) => {
        setData(res.data.data);
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  React.useEffect(() => {
    if (!lenderId) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lenderId, loanToValue]);

  return (
    <>
      <Error error={displayError} />
      <Rates
        rates={data}
        isLoading={isLoading}
        fetchData={fetchData}
        loanAmount={loanAmount}
        eligibleForSwitch={eligibleForSwitch}
        userPhone={userPhone}
        userId={userId}
        remainingTerm={remainingTerm}
      />
    </>
  );
}

RatesContainer.propTypes = {
  lenderId: PropTypes.string.isRequired,
  loanAmount: PropTypes.number.isRequired,
  loanToValue: PropTypes.number.isRequired,
  eligibleForSwitch: PropTypes.bool,
  userPhone: PropTypes.string,
  userId: PropTypes.string.isRequired,
  remainingTerm: PropTypes.number.isRequired,
};

RatesContainer.defaultProps = {
  eligibleForSwitch: false,
  userPhone: undefined,
};

export default RatesContainer;
