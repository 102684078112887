import styled from 'styled-components';
import { Styles as MessageStyles } from '../Message';

const PageIntro = styled.div`
  max-width: 100%;
  text-align: center;
  margin: ${({ theme, compact }) =>
    `${theme.space[compact ? 3 : 6]} auto ${theme.space[compact ? 0 : 7]}`};

  @media ${({ theme }) => theme.device.tablet} {
    margin: ${({ theme, compact }) =>
      `${theme.space[compact ? 3 : 7]} auto ${theme.space[compact ? 0 : 7]}`};
  }

  h1 {
    font-size: ${({ theme }) => theme.fontSize[4]};
    font-weight: 400;
    font-family: ${({ theme }) => theme.fontFamily};
    max-width: ${({ theme }) => theme.space[14]};
    margin: 0 auto;

    @media ${({ theme }) => theme.device.tablet} {
      font-size: ${({ theme }) => theme.fontSize[6]};
    }
  }

  p {
    margin: ${({ theme }) => `${theme.space[4]} auto`};
    color: ${({ theme }) => theme.color['gray-600']};
    max-width: ${({ theme }) => theme.space[14]};
  }

  ${MessageStyles.Message} {
    max-width: 800px;
    margin: ${({ theme }) => `${theme.space[4]} auto 0`};
  }
`;

export default {
  PageIntro,
};
