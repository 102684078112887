import React from 'react';

import API, { handleApiError } from '../../../lib/api';
import UserTable from './Component';
import Error from '../../common/ErrorMessage';

function AdminUsersTableContainer() {
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [displayError, setDisplayError] = React.useState();
  const [users, setUsers] = React.useState([]);

  const fetchIdRef = React.useRef(0);
  const initialSortBy = [{ id: 'profile.firstName', desc: false }];

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, searchTerm, sortBy }) => {
      // Give this fetch an ID
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;

      if (fetchId === fetchIdRef.current) {
        // Set the loading state
        setLoading(true);

        const params = {
          searchTerm,
          pageSize,
          pageIndex,
        };

        if (sortBy && sortBy.length > 0) {
          params.sortBy = sortBy[0].id;
          params.sortByDesc = sortBy[0].desc;
        }

        const response = await API.get('users/', { params }).catch((error) => {
          handleApiError({ error, setDisplayError });
        });

        if (response) {
          setUsers(response.data.data);
          setPageCount(Math.ceil(response.data.totalCount / pageSize));
          setTotalCount(response.data.totalCount);
        }

        setLoading(false);
      }
    },
    [],
  );

  return (
    <>
      <Error error={displayError} />
      <UserTable
        data={users}
        isLoading={loading}
        pageCount={pageCount}
        fetchData={fetchData}
        totalCount={totalCount}
        initialSortBy={initialSortBy}
      />
    </>
  );
}

export default AdminUsersTableContainer;
