import React from 'react';
import PropTypes from 'prop-types';

import { formatTerm } from '@rateswitch/amm-utils';

import {
  formatCurrency,
  formatOrdinalNumber,
  formatPercentage,
  round,
} from '@homamo/meadow-utils';
import { toast } from 'react-toastify';

import Error from '../../common/ErrorMessage';
import Button from '../../common/Button';
import Message from '../../common/Message';
import Panel from '../../common/Panel';
import MarketingSiteLink from '../../common/MarketingSiteLink';

import LogTransactionModal from '../../transaction/LogTransaction';
import Styles from './Styles';
import overpayImg from '../../../images/overpay.svg';

import API, { handleApiError } from '../../../lib/api';

const CalculatorResultsMessage = ({
  mortgageId,
  interestRate,
  overpaymentAmount,
  overpaymentInterval,
  interestSaved,
  termReduction,
  freedomAge,
  fetchData,
  showSetOverpaymentButton,
}) => {
  const [displayError, setDisplayError] = React.useState();
  const [isUpdatingMortgage, setIsUpdatingMortgage] = React.useState(false);

  const updateMonthlyOverpayment = async () => {
    setIsUpdatingMortgage(true);

    await API.post(`mortgages/update/${mortgageId}`, {
      monthlyOverpayment: overpaymentAmount, // Already in pence
    })
      .then(() => {
        toast.success('Payment amount updated');
        if (fetchData) fetchData();
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsUpdatingMortgage(false));
  };

  return (
    <Styles.CalculatorResultsMessage id="calculator-results">
      <Panel
        heading={`If your interest rate stays at ${formatPercentage(
          interestRate,
          2,
        )}, making a ${overpaymentInterval} overpayment of ${formatCurrency(
          overpaymentAmount,
          { wholePounds: true },
        )} could...`}
        smallHeader
      >
        <img src={overpayImg} alt="" />
        <div className="inner">
          <ul>
            <li>
              Save you{' '}
              <strong>
                {formatCurrency(interestSaved, { wholePounds: true })}
              </strong>{' '}
              in interest
            </li>
            <li>
              Make you mortgage free{' '}
              <strong>{formatTerm(termReduction)}</strong> sooner
            </li>
            {freedomAge && (
              <li>
                Pay off your mortgage before your{' '}
                <strong>{formatOrdinalNumber(freedomAge)}</strong> birthday
              </li>
            )}
          </ul>
          <Error error={displayError} />
          {overpaymentInterval === 'monthly' && showSetOverpaymentButton && (
            <Button
              label={`Set my monthly overpayment amount to ${formatCurrency(
                overpaymentAmount,
                { wholePounds: true },
              )}`}
              onClick={() => updateMonthlyOverpayment()}
              block
              mode="primary"
              loadingLabel="Updating overpayment"
              isLoading={isUpdatingMortgage}
            />
          )}

          {overpaymentInterval !== 'monthly' && (
            <LogTransactionModal
              label={`Log a one-off ${formatCurrency(overpaymentAmount, {
                wholePounds: true,
              })} overpayment`}
              successAction={() => {
                if (fetchData) fetchData();
              }}
              mortgageId={mortgageId}
              amount={round(overpaymentAmount / 100, 0) * 100}
              block
              primary
            />
          )}
          <Message big center>
            Need help setting up overpayments?{' '}
            <MarketingSiteLink
              path="/overpayment-guide"
              label="View our guide"
            />
          </Message>
        </div>
      </Panel>
    </Styles.CalculatorResultsMessage>
  );
};

CalculatorResultsMessage.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  interestRate: PropTypes.number,
  overpaymentAmount: PropTypes.number,
  overpaymentInterval: PropTypes.string,
  interestSaved: PropTypes.number,
  termReduction: PropTypes.number,
  freedomAge: PropTypes.number,
  fetchData: PropTypes.func,
  showSetOverpaymentButton: PropTypes.bool,
};

CalculatorResultsMessage.defaultProps = {
  interestRate: undefined,
  overpaymentAmount: undefined,
  overpaymentInterval: undefined,
  interestSaved: undefined,
  termReduction: undefined,
  freedomAge: undefined,
  fetchData: undefined,
  showSetOverpaymentButton: false,
};

export default CalculatorResultsMessage;
