import React from 'react';
import AccountPasswordForm from './Form';

const AccountPasswordView = () => {
  return (
    <div>
      <AccountPasswordForm />
    </div>
  );
};

export default AccountPasswordView;
