import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import API, { handleApiError } from '../../../lib/api';
import Button from '../../common/Button';
import Error from '../../common/ErrorMessage';

const RequestDeleteAccountComponent = ({ refetchData, resend }) => {
  const [displayError, setDisplayError] = React.useState();

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <>
      <Error error={displayError} />
      <Button
        label={
          resend
            ? 'Resend account deletion request'
            : 'Request account deletion'
        }
        size="m"
        mode="danger"
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          await API.post(`auth/request-account-deletion`)
            .then(() => {
              toast.success('Request sent');
              refetchData();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
              toast.error('Request failed');
            })
            .finally(() => setIsLoading(false));
        }}
      />
    </>
  );
};

RequestDeleteAccountComponent.propTypes = {
  refetchData: PropTypes.func.isRequired,
  resend: PropTypes.bool,
};

RequestDeleteAccountComponent.defaultProps = {
  resend: false,
};

export default RequestDeleteAccountComponent;
