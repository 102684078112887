import React from 'react';
import Layout from '../components/layout/Layout';
import TransactionHistory from '../components/transaction/TransactionHistory';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const HistoryView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Transaction History">
        <TransactionHistory />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default HistoryView;
