import React from 'react';
import { keepParams } from '@homamo/meadow-utils';
import Button, { Styles as ButtonStyles } from '../../common/Button';
import AdminPaymentsTable from '../AdminPaymentsTable';
// import ExportPayments from '../ExportPayments';
// import ImportPayments from '../ImportPayments';
import AdminNav from '../../admin/AdminNav';

const PaymentsView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Payments</h1>
      <ButtonStyles.ButtonGroup>
        <Button
          label="Log a payment"
          mode="primary"
          linkTo={keepParams('/admin/create-payment')}
          size="s"
        />
        {/* <ExportPayments />
        <ImportPayments /> */}
      </ButtonStyles.ButtonGroup>

      <AdminPaymentsTable />
    </div>
  );
};

export default PaymentsView;
