export default [
  'FOOD',
  'ELECTRONICS',
  'HOME',
  'TRAVEL',
  'FASHION',
  'HEALTH',
  'SERVICES',
  'GIFTS',
];

export const retailerCategoryLabel = {
  FOOD: 'Food & Drink',
  ELECTRONICS: 'Electronics',
  HOME: 'Home & Garden',
  TRAVEL: 'Travel & Leisure',
  FASHION: 'Fashion',
  HEALTH: 'Health & Beauty',
  SERVICES: 'Services',
  GIFTS: 'Gifts',
};
