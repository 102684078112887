import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@homamo/meadow-utils';

import config from '../../../config';
import ArrangeCallback from '../ArrangeCallback';
import Panel from '../../common/Panel';
import Message from '../../common/Message';
import Styles from './Styles';
import switchImg from '../../../images/switch.svg';
import MarketingSiteLink from '../../common/MarketingSiteLink';

const RateswitchBenefits = ({ userPhone }) => {
  return (
    <Styles.RateswitchBenefits>
      <Panel heading="Switching made easy" smallHeader>
        <img src={switchImg} alt="" />
        <div className="inner">
          <ul>
            <li>Fast, fee-free and no hassle</li>
            <li>
              Up to {formatCurrency(config.rewardDefaults.switch)} paid off your
              balance every time we switch you
            </li>
            <li>We always compare rates available from all other lenders</li>
          </ul>

          <ArrangeCallback
            buttonLabel="Arrange a callback"
            buttonMode="primary"
            userPhone={userPhone}
            context="moreInfo"
            buttonBlock
          />
          <Message>
            Want to know more about switching?{' '}
            <MarketingSiteLink
              path="/switch-guide"
              label="View our support article"
            />
          </Message>
        </div>
      </Panel>
    </Styles.RateswitchBenefits>
  );
};

RateswitchBenefits.defaultProps = {
  userPhone: undefined,
};

RateswitchBenefits.propTypes = {
  userPhone: PropTypes.string,
};

export default RateswitchBenefits;
