import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { convertToPence, convertToPounds } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../../../lib/api';

import OnboardingViewActions from '../../../OnboardingViewActions';
import Form from '../../../../common/Form';
import CurrencyInput from '../../../../common/CurrencyInput';
import RadioInput from '../../../../common/RadioInput';
import RadioGroup from '../../../../common/RadioGroup';
import Message from '../../../../common/Message';
import LayoutWithImage from '../../../../layout/LayoutWithImage';
import Error from '../../../../common/ErrorMessage';

import checklistImg from '../../../../../images/checklist.svg';

const validationSchema = Yup.object().shape({
  monthlyPayment: Yup.string()
    .required('Enter your approximate monthly payment')
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    }),
  overpayment: Yup.string().required('Do you make a monthly overpayment?'),
  monthlyOverpayment: Yup.string().when('overpayment', {
    is: 'yes',
    then: Yup.string()
      .required('Enter the amount you overpay monthly')
      .test({
        name: 'min',
        message: 'You need to enter a value greater than £0',
        test: (value) => {
          return convertToPence(value) >= 0;
        },
      }),
    otherwise: Yup.string(),
  }),
});

const OnboardingPayments = ({
  mortgageId,
  mortgage,
  setSelectedStep,
  setDisplayError,
  setMortgageData,
  displayError,
}) => {
  const [makesOverpayment, setMakesOverpayment] = React.useState(
    mortgage.monthlyOverpayment > 0,
  );
  return (
    <LayoutWithImage image={checklistImg}>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`mortgages/update/${mortgageId}`, {
            monthlyPayment: convertToPence(values.monthlyPayment),
            monthlyOverpayment:
              values.overpayment === 'yes' && values.monthlyOverpayment
                ? convertToPence(values.monthlyOverpayment)
                : 0,
          })
            .then((res) => {
              setSelectedStep('dob');
              setMortgageData(res.data);
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          initialBalance:
            (mortgage && convertToPounds(mortgage.initialBalance)) || undefined,
          monthlyPayment:
            (mortgage && convertToPounds(mortgage.monthlyPayment)) || undefined,
          overpayment: mortgage.monthlyOverpayment > 0 ? 'yes' : 'no',
          monthlyOverpayment:
            (mortgage && convertToPounds(mortgage.monthlyOverpayment)) ||
            undefined,
        }}
        validationSchema={validationSchema}
        render={({ isSubmitting, values, errors, touched }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <h2>How much do you pay towards your mortgage each month?</h2>
                <Field
                  label="What is your monthly payment excluding overpayments?"
                  id="monthlyPayment"
                  name="monthlyPayment"
                  component={CurrencyInput}
                />
                <Message big>
                  This is your minimum/contractual payment set by your lender
                </Message>
                <div className="form-section">
                  <RadioGroup
                    id="overpayment"
                    label="Do you currently make regular monthly overpayments?"
                    value={values.type}
                    error={errors.type}
                    touched={touched.type}
                  >
                    <Field
                      name="overpayment"
                      id="yes"
                      label="Yes"
                      valueLabel="yes"
                      component={RadioInput}
                      onClick={() => setMakesOverpayment(true)}
                    />
                    <Field
                      name="overpayment"
                      id="no"
                      label="No"
                      valueLabel="no"
                      component={RadioInput}
                      onClick={() => setMakesOverpayment(false)}
                    />
                  </RadioGroup>
                </div>
                {makesOverpayment && (
                  <div className="form-section">
                    <Field
                      label="How much do you regularly overpay each month?"
                      id="monthlyOverpayment"
                      name="monthlyOverpayment"
                      component={CurrencyInput}
                    />
                  </div>
                )}
                <Error error={displayError} />
                <OnboardingViewActions
                  previousView="interestRate"
                  isSubmitting={isSubmitting}
                  setSelectedStep={setSelectedStep}
                />
              </fieldset>
            </Form>
          );
        }}
      />
    </LayoutWithImage>
  );
};

OnboardingPayments.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    monthlyPayment: PropTypes.number,
    monthlyOverpayment: PropTypes.number,
    initialBalance: PropTypes.number,
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  setMortgageData: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingPayments.defaultProps = {
  displayError: undefined,
};

export default OnboardingPayments;
