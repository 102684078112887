import React from 'react';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import API, { handleApiError } from '../../../lib/api';
import { useAuth } from '../../../context/auth';

import Error from '../../common/ErrorMessage';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import Button from '../../common/Button';
import CheckboxInput from '../../common/CheckboxInput';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("That doesn't look like a valid email")
    .required('Enter your registered email address'),
  password: Yup.string().required('Enter your password to sign in'),
});

const SignInForm = () => {
  const [displayError, setDisplayError] = React.useState();
  const { onSignIn } = useAuth({ setDisplayError });

  return (
    <>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post('auth/sign-in', values)
            .then((res) => {
              toast.success('Signed in!');
              onSignIn({ isAuthenticated: true, user: res.data.user });
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        validationSchema={validationSchema}
        initialValues={{
          email: '',
          password: '',
          staySignedIn: false,
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="Your email"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  component={TextInput}
                  autoComplete="email"
                  isRequired
                />
                <Field
                  label="Password"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Your password"
                  component={TextInput}
                  autoComplete="current-password"
                  isRequired
                />
                <Field
                  label="Keep me signed in for 7 days"
                  id="staySignedIn"
                  name="staySignedIn"
                  component={CheckboxInput}
                />
                <Error error={displayError} />
                <Button
                  mode="primary"
                  label="Sign in"
                  type="submit"
                  block
                  loadingLabel="Signing in"
                  isLoading={isSubmitting}
                />
              </fieldset>
              <p>
                <small>
                  Forgotten your password?{' '}
                  <Link to="/request-reset-password">Request a reset link</Link>
                </small>
              </p>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SignInForm;
