import React from 'react';
import Layout from '../../../components/layout/Layout';
import CreateRate from '../../../components/rate/CreateRate';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const CreateRateView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Create rate">
        <CreateRate />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default CreateRateView;
