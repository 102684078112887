import React from 'react';
import PropTypes from 'prop-types';

const MarketingSiteLink = ({ path, label }) => {
  return (
    <a
      href={`https://acceleratemymortgage.com${path}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {label}
    </a>
  );
};

MarketingSiteLink.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string.isRequired,
};

MarketingSiteLink.defaultProps = {
  path: '',
};

export default MarketingSiteLink;
