import borderRadius from './borderRadius';
import borderWidth from './borderWidth';
import boxShadow from './boxShadow';
import color from './color';
import device from './device';
import fontSize from './fontSize';
import gradient from './gradient';
import space from './space';

export default {
  borderRadius,
  borderWidth,
  boxShadow,
  color,
  device,
  fontSize,
  gradient,
  space,
  success: color['green-500'],
  danger: color['red-500'],
  textColor: color['gray-800'],
  overlayColor: 'hsla(0, 0%, 13%, 0.7)', // gray-900 with 70% alpha

  inputPlaceholder: color['gray-500'],
  inputBorder: color['gray-600'],
  inputColor: color['gray-800'],
  inputLabelColor: color['gray-800'],

  linkColor: color['blue-600'],
  maxWidth: '1280px',
  fontFamily:
    '"hk_groteskmedium", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  headingFontFamily:
    '"hk_groteskbold", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  headingColor: color['blue-600'],
  baseSize: '10px',
};
