import React from 'react';
import PropTypes from 'prop-types';

import API, { handleApiError } from '../../../lib/api';
import { useAuth } from '../../../context/auth';
import Button from '../../common/Button';

const SignOutComponent = ({
  buttonLabel,
  buttonSize,
  closeAction,
  setDisplayError,
}) => {
  const { onSignOut } = useAuth();
  return (
    <Button
      label={buttonLabel}
      size={buttonSize}
      onClick={async () => {
        await API.post('auth/sign-out')
          .then(() => {
            if (closeAction) closeAction();
            onSignOut();
          })
          .catch((error) => {
            handleApiError({ error, setDisplayError });
          });
      }}
    />
  );
};

SignOutComponent.propTypes = {
  closeAction: PropTypes.func,
  setDisplayError: PropTypes.func,
  buttonSize: PropTypes.string,
  buttonLabel: PropTypes.string,
};

SignOutComponent.defaultProps = {
  closeAction: undefined,
  setDisplayError: undefined,
  buttonSize: undefined,
  buttonLabel: 'Signout',
};

export default SignOutComponent;
