import React from 'react';
import PropTypes from 'prop-types';
import { constructDealLink } from '@rateswitch/amm-utils';

import Loading from '../../common/Loading';
import PageIntro from '../../common/PageIntro';
import RetailersListItem from './Component';
import Styles from './Styles';

const RetailersList = ({ retailers, userId, searchTerm, isLoading }) => {
  if (isLoading)
    return (
      <PageIntro>
        <Loading />
      </PageIntro>
    );
  return (
    <Styles.RetailersList>
      <>
        {retailers && retailers.length ? (
          retailers.map((retailer) => (
            <RetailersListItem
              key={retailer._id}
              id={retailer._id}
              image={retailer.image}
              heading={retailer.heading}
              description={retailer.description}
              categories={retailer.categories}
              link={constructDealLink({ url: retailer.link, userId })}
              live={retailer.live}
            />
          ))
        ) : (
          <PageIntro
            heading={
              searchTerm
                ? `No retailers found for '${searchTerm}'`
                : `No retailers found`
            }
            text="Try choosing another category or 'All'"
          />
        )}
      </>
    </Styles.RetailersList>
  );
};

RetailersList.propTypes = {
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      image: PropTypes.string,
      live: PropTypes.bool,
      link: PropTypes.string.isRequired,
      categories: PropTypes.arrayOf([PropTypes.string]),
    }),
  ),
  userId: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  searchTerm: PropTypes.string,
};

RetailersList.defaultProps = {
  retailers: [],
  isLoading: false,
  searchTerm: undefined,
};

export default RetailersList;
