import React from 'react';
import PropTypes from 'prop-types';

import { getInterestSaved, getRemainingTerm } from '@rateswitch/amm-utils';

import { PanelGroup } from '../../common/Panel';
import Message from '../../common/Message';
import PageIntro from '../../common/PageIntro';
import PageContent from '../../layout/PageContent';
import AssumptionsModal from '../../overpay/AssumptionsModal';
import MortgageBalance from '../MortgageBalance';
import MortgageScenario from '../MortgageScenario';
import MortgageProjection from '../MortgageProjection';
import MortgagePaymentDetails from '../MortgagePaymentDetails';
import BalanceTracker from '../BalanceTracker';

const MyMortgageView = ({ mortgageId, mortgage, user, fetchData }) => {
  const {
    lender,
    accountNumber,
    initialBalance,
    remainingBalance,
    estimatedPropertyValue,
    interestRate,
    monthlyPayment,
    fixedRate,
    fixedRateEndDate,
  } = mortgage;

  const monthlyOverpayment = mortgage.monthlyOverpayment || 0;

  const initialRemainingTerm = getRemainingTerm({
    remainingBalance,
    interestRate,
    monthlyPayment,
  });

  const withoutOverpaymentsRemainingTerm = getRemainingTerm({
    remainingBalance,
    interestRate,
    monthlyPayment,
  });

  const remainingTerm = getRemainingTerm({
    remainingBalance,
    interestRate,
    monthlyPayment,
    overpaymentAmount: monthlyOverpayment,
    overpaymentInterval: 'monthly',
  });

  const interestSaved = getInterestSaved({
    remainingBalance,
    interestRate,
    monthlyPayment,
    overpaymentAmount: monthlyOverpayment,
    overpaymentInterval: 'monthly',
  });

  return (
    <PageContent>
      <PageIntro heading="Track your progress to mortgage freedom" compact>
        <MortgageScenario
          monthlyPayment={monthlyPayment}
          monthlyOverpayment={monthlyOverpayment}
          remainingBalance={remainingBalance}
          interestRate={interestRate}
        />
      </PageIntro>

      <BalanceTracker
        initialBalance={initialBalance}
        remainingBalance={remainingBalance}
        estimatedPropertyValue={estimatedPropertyValue}
        monthlyOverpayment={monthlyOverpayment}
        interestSaved={interestSaved}
        termReduction={initialRemainingTerm - remainingTerm}
      />

      <PanelGroup>
        <MortgageBalance
          lender={lender}
          accountNumber={accountNumber}
          interestRate={interestRate}
          initialBalance={initialBalance}
          remainingBalance={remainingBalance}
          fixedRate={fixedRate}
          fixedRateEndDate={fixedRateEndDate}
        />
        <MortgageProjection
          remainingTerm={remainingTerm}
          withoutOverpaymentsRemainingTerm={withoutOverpaymentsRemainingTerm}
          remainingBalance={remainingBalance}
          monthlyPayment={monthlyPayment}
          monthlyOverpayment={monthlyOverpayment}
          interestRate={interestRate}
          userDobMonth={user.profile && user.profile.dobMonth}
          userDobYear={user.profile && user.profile.dobYear}
        />
        <MortgagePaymentDetails
          mortgageId={mortgageId}
          monthlyPayment={monthlyPayment}
          monthlyOverpayment={monthlyOverpayment}
          fetchData={fetchData}
        />
      </PanelGroup>
      <Message center>
        Our projections are based on the information you&apos;ve provided and
        our key assumptions
        <AssumptionsModal
          buttonLabel="View key assumptions"
          remainingBalance={remainingBalance}
          interestRate={interestRate}
          monthlyPayment={monthlyPayment}
          monthlyOverpayment={monthlyOverpayment}
        />
      </Message>
    </PageContent>
  );
};

MyMortgageView.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    monthlyOverpayment: PropTypes.number,
    lender: PropTypes.shape({
      name: PropTypes.string,
    }),
    accountNumber: PropTypes.string,
    initialBalance: PropTypes.number,
    remainingBalance: PropTypes.number,
    estimatedPropertyValue: PropTypes.number,
    interestRate: PropTypes.number,
    monthlyPayment: PropTypes.number,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
  }),
  user: PropTypes.shape({
    profile: PropTypes.shape({
      dobMonth: PropTypes.string,
      dobYear: PropTypes.string,
    }),
  }),
  fetchData: PropTypes.func,
};

MyMortgageView.defaultProps = {
  mortgage: {},
  user: {},
  fetchData: undefined,
};

export default MyMortgageView;
