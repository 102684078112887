import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../common/Tabs';
import MortgageSettingsDetails from '../MortgageSettingsDetails';
import MortgageSettingsRate from '../MortgageSettingsRate';
import MortgageSettingsPayments from '../MortgageSettingsPayments';

const MortgageSettingsView = ({ data, refetchData }) => {
  return (
    <div>
      <h1>Mortgage Settings</h1>
      <Tabs
        items={[
          {
            label: 'Mortgage',
            path: '/mortgage/settings',
            component: (
              <MortgageSettingsDetails data={data} refetchData={refetchData} />
            ),
          },
          {
            label: 'Rate',
            path: '/mortgage/settings/rate',
            component: (
              <MortgageSettingsRate data={data} refetchData={refetchData} />
            ),
          },
          {
            label: 'Payments',
            path: '/mortgage/settings/payments',
            component: (
              <MortgageSettingsPayments data={data} refetchData={refetchData} />
            ),
          },
        ]}
      />
    </div>
  );
};

MortgageSettingsView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

MortgageSettingsView.defaultProps = {
  data: {},
};

export default MortgageSettingsView;
