import React from 'react';
import { useLocation } from 'react-router-dom';

import Layout from '../components/layout/Layout';
import Referral from '../components/referral/Referral';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const ReferralView = () => {
  const query = new URLSearchParams(useLocation().search);
  const userId = query.get('id');

  return (
    <AnalyticsWrapper>
      <Layout title="Referral">
        <Referral userId={userId} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default ReferralView;
