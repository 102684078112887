import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../components/layout/Layout';
import EditTransaction from '../components/transaction/EditTransaction';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const EditTransactionView = () => {
  const { id } = useParams();
  return (
    <AnalyticsWrapper>
      <Layout title="Edit transaction">
        <EditTransaction id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default EditTransactionView;
