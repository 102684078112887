import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Error from '../../common/ErrorMessage';
import AdminNav from '../../admin/AdminNav';

const TransactionNotFoundComponent = ({ id, displayError }) => {
  return (
    <>
      <AdminNav />
      <h1>404. Transaction not found</h1>
      <p>No transaction found with ID: {id}</p>
      <p>
        <Link to="/admin/transactions">Back to all transactions</Link>
      </p>
      {displayError && displayError.message && (
        <>
          <p>
            <small>
              The following information may be useful for technical support:
            </small>
          </p>
          <Error error={displayError} />
        </>
      )}
    </>
  );
};

TransactionNotFoundComponent.propTypes = {
  id: PropTypes.string.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

TransactionNotFoundComponent.defaultProps = {
  displayError: {},
};

export default TransactionNotFoundComponent;
