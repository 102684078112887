import React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import API, { handleApiError } from '../../../lib/api';
import AdminMortgagesTable from './Component';
import LenderSelect from '../../lender/LenderSelect';
import { Styles as ButtonStyles } from '../../common/Button';
import ExportMortgages from '../ExportMortgages';

import Error from '../../common/ErrorMessage';

function AdminMortgagesTableContainer({ lenderId, fixedLender, enableExport }) {
  // GET READY FOR PARAMS
  const location = useLocation();
  const useQuery = () => {
    return new URLSearchParams(location.search);
  };
  const query = useQuery();

  /*
   * Delete mortgage is used to delete from the state cache
   * rather than reload data from the API
   */

  // We'll start our table without any data
  const [mortgages, setMortgages] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [displayError, setDisplayError] = React.useState();
  const [selectedLenderId, setSelectedLenderId] = React.useState(
    lenderId || query.get(`mortgages_selectedLenderId`) || 'ALL',
  );
  const fetchIdRef = React.useRef(0);
  const initialSortBy = [{ id: 'createdAt', desc: false }];

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, searchTerm, sortBy, selectedFilter }) => {
      // Give this fetch an ID
      // eslint-disable-next-line no-plusplus
      const fetchId = ++fetchIdRef.current;

      // LENDER PARAM SETTING
      const urlParams = new URLSearchParams(window.location.search);
      function setParam(field, value) {
        if (value !== undefined && value !== null) {
          urlParams.set(field, value);
        } else {
          urlParams.delete(field);
        }
      }
      setParam(`mortgages_selectedLenderId`, selectedLenderId);
      window.history.replaceState(
        {},
        '',
        `${window.location.pathname}?${urlParams}`,
      );

      // -----

      if (fetchId === fetchIdRef.current) {
        // Set the loading state
        setLoading(true);

        const params = {
          ownerId: searchTerm,
          pageSize,
          pageIndex,
          switchInProgress: selectedFilter,
          lenderId: selectedLenderId,
        };

        if (sortBy && sortBy.length > 0) {
          params.sortBy = sortBy[0].id;
          params.sortByDesc = sortBy[0].desc;
        }

        const response = await API.get('mortgages/', { params }).catch(
          (error) => {
            handleApiError({ error, setDisplayError });
          },
        );

        if (response) {
          setMortgages(response.data.data);
          setPageCount(Math.ceil(response.data.totalCount / pageSize));
          setTotalCount(response.data.totalCount);
        }

        setLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedLenderId],
  );

  const filterOptions = [
    {
      label: 'All',
      value: undefined,
    },
    {
      label: 'Switch in progress',
      value: true,
    },
  ];

  return (
    <>
      <Error error={displayError} />
      {enableExport && (
        <ButtonStyles.ButtonGroup>
          <ExportMortgages lenderId={selectedLenderId} />
        </ButtonStyles.ButtonGroup>
      )}
      {!fixedLender && (
        <LenderSelect
          onChange={(e) => setSelectedLenderId(e.target.value)}
          value={selectedLenderId}
          setValue={setSelectedLenderId}
          includeBlank
          noFormik
        />
      )}
      <AdminMortgagesTable
        data={mortgages}
        isLoading={loading}
        pageCount={pageCount}
        fetchData={fetchData}
        totalCount={totalCount}
        initialSortBy={initialSortBy}
        filterOptions={filterOptions}
      />
    </>
  );
}

AdminMortgagesTableContainer.propTypes = {
  lenderId: PropTypes.string,
  fixedLender: PropTypes.bool,
  enableExport: PropTypes.bool,
};

AdminMortgagesTableContainer.defaultProps = {
  lenderId: undefined,
  fixedLender: false,
  enableExport: false,
};

export default AdminMortgagesTableContainer;
