import React from 'react';
import PropTypes from 'prop-types';
import Layout from '../../layout/Layout';
import OnboardingNav from '../OnboardingNav';
import { steps } from './steps';

import Styles from './Styles';

const OnboardingView = ({
  user,
  mortgageId,
  mortgage,
  selectedStep,
  setSelectedStep,
  displayError,
  fetchData,
  setMortgageData,
  setUserData,
  setDisplayError,
}) => {
  return (
    <Layout title="Setup">
      {selectedStep !== 'start' && (
        <OnboardingNav
          user={user}
          mortgage={mortgage}
          selectedStep={selectedStep}
          setSelectedStep={setSelectedStep}
        />
      )}
      <Styles.OnboardingView>
        {React.createElement(steps[selectedStep], {
          mortgageId,
          mortgage,
          user,
          setSelectedStep,
          fetchData,
          setMortgageData,
          setUserData,
          setDisplayError,
          displayError,
        })}
      </Styles.OnboardingView>
    </Layout>
  );
};

OnboardingView.propTypes = {
  selectedStep: PropTypes.string.isRequired,
  mortgageId: PropTypes.string,
  mortgage: PropTypes.shape({
    lender: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      svr: PropTypes.number,
    }),
    balance: PropTypes.number,
  }),
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
  setDisplayError: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  setMortgageData: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

OnboardingView.defaultProps = {
  mortgageId: undefined,
  mortgage: undefined,
  displayError: undefined,
};

export default OnboardingView;
