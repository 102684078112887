import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  formatCurrency,
  formatDateTime,
  formatOrdinalNumber,
} from '@homamo/meadow-utils';

import Styles from './Styles';

const CalculatorResultsTable = ({ current, scenario }) => {
  const years =
    current.balances.length > scenario.balances.length
      ? current.balances.map(({ year }) => year)
      : scenario.balances.map(({ year }) => year);

  const scenarioFreedomIndex = scenario.balances.findIndex(
    ({ balance }) => balance === 0,
  );

  return (
    <Styles.TableWrapper>
      <Styles.Table>
        <thead>
          <tr>
            <th scope="col"> </th>
            <th scope="col">With overpayments</th>
            <th scope="col">No overpayments</th>
          </tr>
        </thead>
        <Styles.Details>
          {current.freedomAge && (
            <tr>
              <th scope="row">Freedom birthday</th>
              <td>
                {(scenario && formatOrdinalNumber(scenario.freedomAge)) || '-'}
              </td>
              <td>
                {(current && formatOrdinalNumber(current.freedomAge)) || '-'}
              </td>
            </tr>
          )}
          <tr>
            <th scope="row">Freedom year</th>
            <td>
              {(scenario &&
                formatDateTime(scenario.freedomDate, 'monthYear')) ||
                '-'}
            </td>
            <td>
              {(current && formatDateTime(current.freedomDate, 'monthYear')) ||
                '-'}
            </td>
          </tr>
          <tr>
            <th scope="row">Total interest</th>
            <td>
              {(scenario &&
                formatCurrency(scenario.totalInterest, {
                  wholePounds: true,
                })) ||
                '-'}
            </td>
            <td>
              {(current &&
                formatCurrency(current.totalInterest, { wholePounds: true })) ||
                '-'}
            </td>
          </tr>
          <tr>
            <th scope="row">Total cost</th>
            <td>
              {(scenario &&
                formatCurrency(scenario.totalCost, { wholePounds: true })) ||
                '-'}
            </td>
            <td>
              {(current &&
                formatCurrency(current.totalCost, { wholePounds: true })) ||
                '-'}
            </td>
          </tr>
        </Styles.Details>
        <Styles.Balances>
          {years &&
            years.map((y, i) => {
              const isFreedomYear = scenarioFreedomIndex === i;
              return (
                <Styles.Row key={y} freedomHighlight={isFreedomYear}>
                  <th scope="row">
                    {y}
                    {isFreedomYear && <FontAwesomeIcon icon="star" />}
                  </th>
                  <td>
                    {formatCurrency(
                      (scenario.balances[i] && scenario.balances[i].balance) ||
                        0,
                      { wholePounds: true },
                    )}
                  </td>
                  <td>
                    {formatCurrency(
                      (current.balances[i] && current.balances[i].balance) || 0,
                      { wholePounds: true },
                    )}
                  </td>
                </Styles.Row>
              );
            })}
        </Styles.Balances>
      </Styles.Table>
    </Styles.TableWrapper>
  );
};

const defaultResultsObject = {
  freedomAge: undefined,
  freedomYear: undefined,
  freedomDate: undefined,
  totalInterest: undefined,
  totalCost: undefined,
  balances: [],
};

CalculatorResultsTable.defaultProps = {
  current: defaultResultsObject,
  scenario: defaultResultsObject,
};

const resultsObject = PropTypes.shape({
  freedomAge: PropTypes.number,
  freedomYear: PropTypes.string,
  totalInterest: PropTypes.number,
  totalCost: PropTypes.number,
  freedomDate: PropTypes.instanceOf(Date),
  balances: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.string,
      balance: PropTypes.number,
    }),
  ),
});

CalculatorResultsTable.propTypes = {
  current: resultsObject,
  scenario: resultsObject,
};

export default CalculatorResultsTable;
