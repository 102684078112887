import React from 'react';
// import PropTypes from 'prop-types';
import {
  convertToPence,
  convertToPounds,
  keepParams,
} from '@homamo/meadow-utils';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import Textarea from '../../common/Textarea';
import CurrencyInput from '../../common/CurrencyInput';
import DateInput from '../../common/DateInput';
import config from '../../../config';
import RewardTypeSelect from '../RewardTypeSelect';
import RewardStatusSelect from '../RewardStatusSelect';

const validationSchema = Yup.object().shape({
  userEmail: Yup.string()
    .email("Whoops! That doesn't look like a valid email")
    .required(`Enter the email address of the user you're paying`),
  amount: Yup.string().required('You need to enter an amount'),
  rewardDate: Yup.string().required('You need to enter a reward date'),
  status: Yup.string().required('You must select a status'),
  type: Yup.string().required('You must select a type'),
  description: Yup.string(),
});

const AdminCreateRewardForm = () => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post('rewards/create', {
            userEmail: values.userEmail,
            date: values.rewardDate,
            amount: convertToPence(values.amount),
            createdSource: 'AMM',
            status: values.status,
            type: values.type,
            description: values.description,
          })
            .then((res) => {
              const rewardId = res.data._id;
              toast.success('Reward created');
              history.push(keepParams(`/admin/reward/${rewardId}`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          userEmail: undefined,
          amount: convertToPounds(config.rewardDefaults.switch),
          rewardDate: new Date(),
          status: 'PENDING',
          type: undefined,
          description: undefined,
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="User email"
                  id="userEmail"
                  name="userEmail"
                  placeholder="Enter a user's email address"
                  component={TextInput}
                  isRequired
                />
                <RewardTypeSelect />
                <RewardStatusSelect />
                <Field
                  label="Amount"
                  id="amount"
                  name="amount"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Reward Date"
                  id="rewardDate"
                  name="rewardDate"
                  component={DateInput}
                  isRequired
                />
                <Field
                  label="Description"
                  id="description"
                  name="description"
                  placeholder="Enter an optional description"
                  component={Textarea}
                />
                <ButtonGroup>
                  <Button
                    label="Create Reward"
                    type="submit"
                    mode="primary"
                    loadingLabel="Creating Reward"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/rewards'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AdminCreateRewardForm;
