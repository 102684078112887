import styled from 'styled-components';

const SubNav = styled.ul`
  display: none;
`;

const NavItem = styled.li`
  a {
    text-decoration: none;

    &.--is-active {
      text-decoration: underline;
      color: ${({ theme }) => theme.color['orange-500']};
      font-weight: 500;

      ${SubNav} {
        display: block;
        padding: ${({ theme }) => theme.space[3]};
        li {
          font-size: ${({ theme }) => theme.fontSize[1]};
          font-weight: 400;
          &.--is-active {
            font-weight: 500;
          }
        }
      }
    }
  }
`;

const Nav = styled.ul``;

export default {
  Nav,
  NavItem,
  SubNav,
};
