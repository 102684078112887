import styled from 'styled-components';

const LayoutWithImage = styled.div`
  width: 100%;

  img {
    display: none;
  }

  @media ${({ theme }) => theme.device.tablet} {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    img {
      display: block;
      width: 100%;
      margin-left: 4rem;
      margin-top: ${({ padTop }) => (padTop ? '10rem' : '0')};
      max-width: 300px;
    }
  }

  @media ${({ theme }) => theme.device.laptop} {
    main {
      width: 500px;
    }

    img {
      margin-left: 10rem;
      max-width: 400px;
    }
  }

  @media ${({ theme }) => theme.device.laptopL} {
    img {
      margin-left: 10rem;
      max-width: 500px;
    }
  }
`;

export default {
  LayoutWithImage,
};
