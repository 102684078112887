import React from 'react';
import Layout from '../components/layout/Layout';
import Overpay from '../components/overpay/Overpay';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const OverpayView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Overpay">
        <Overpay />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default OverpayView;
