/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Styles as InputStyles } from '../Input';
import InputLabel from '../InputLabel';
import Styles from './Styles';

const SelectInput = ({
  label,
  isRequired,
  options,
  placeholder,
  value,
  field = {},
  form = {}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const { name, disabled, ...fieldProps } = field;
  const { touched = [], errors = [] } = form;
  const error = errors[name];
  const touch = touched[name];

  return (
    <InputStyles.InputWrapper>
      <InputLabel
        label={label}
        htmlFor={name}
        error={error}
        disabled={disabled}
        isRequired={isRequired}
      />
      <Styles.SelectInput
        id={name}
        hasError={error && touch}
        {...fieldProps}
        {...props}
        value={value || field.value || ''}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options.map((option) => (
          <option
            key={option.key || option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </option>
        ))}
      </Styles.SelectInput>
      {touch && error && (
        <InputStyles.InputError>{error}</InputStyles.InputError>
      )}
    </InputStyles.InputWrapper>
  );
};

SelectInput.defaultProps = {
  placeholder: 'Select an option',
  isRequired: false,
  options: [],
  value: undefined,
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  value: PropTypes.string,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
};

export default SelectInput;
