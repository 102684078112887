import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
  useLocation,
} from 'react-router-dom';
import { scrollToTop } from '@homamo/meadow-utils';

import PrivateRoute from './components/auth/PrivateRoute';

import SignIn from './pages/auth/sign-in';
import SignUp from './pages/auth/sign-up';
import RequestResetPassword from './pages/auth/request-reset-password';
import ResetPassword from './pages/auth/reset-password';
import Referral from './pages/referral';

import DashboardView from './pages/dashboard';
import OverpayView from './pages/overpay';
import MortgageView from './pages/mortgage';
import MortgageSettingsView from './pages/mortgage-settings';
import SwitchView from './pages/switch';
import RewardsView from './pages/rewards';
import RewardHistoryView from './pages/rewards-history';
import HistoryView from './pages/history';
import TransactionView from './pages/transaction';
import EditTransactionView from './pages/edit-transaction';

import Account from './pages/account';

import AdminUsers from './pages/admin/user/users';
import AdminUser from './pages/admin/user/user';
import AdminCreateUser from './pages/admin/user/create-user';
import AdminEditUser from './pages/admin/user/edit-user';
import AdminDeleteUser from './pages/admin/user/delete-user';

import AdminMortgage from './pages/admin/mortgage/mortgage';
import AdminMortgages from './pages/admin/mortgage/mortgages';
import AdminEditMortgage from './pages/admin/mortgage/edit-mortgage';

import AdminLenders from './pages/admin/lender/lenders';
import AdminLender from './pages/admin/lender/lender';
import AdminCreateLender from './pages/admin/lender/create-lender';
import AdminEditLender from './pages/admin/lender/edit-lender';

import AdminRetailers from './pages/admin/retailer/retailers';
import AdminRetailer from './pages/admin/retailer/retailer';
import AdminCreateRetailer from './pages/admin/retailer/create-retailer';
import AdminEditRetailer from './pages/admin/retailer/edit-retailer';

import AdminRates from './pages/admin/rate/rates';
import AdminRate from './pages/admin/rate/rate';
import AdminCreateRate from './pages/admin/rate/create-rate';
import AdminEditRate from './pages/admin/rate/edit-rate';

import AdminRewards from './pages/admin/reward/rewards';
import AdminReward from './pages/admin/reward/reward';
import AdminCreateReward from './pages/admin/reward/create-reward';
import AdminEditReward from './pages/admin/reward/edit-reward';

import AdminPayments from './pages/admin/payment/payments';
import AdminPayment from './pages/admin/payment/payment';
import AdminCreatePayment from './pages/admin/payment/create-payment';
import AdminEditPayment from './pages/admin/payment/edit-payment';

import ComponentList from './pages/dev/component-list';

import NotFound from './pages/404';

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    scrollToTop();
  }, [pathname]);

  return null;
}

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/sign-in" exact component={() => <SignIn />} />
        <Route path="/sign-up" exact component={() => <SignUp />} />

        <Route
          path="/signin"
          exact
          component={() => <Redirect to="/sign-in" />}
        />
        <Route
          path="/signup"
          exact
          component={() => <Redirect to="/sign-up" />}
        />

        <Route
          path="/request-reset-password"
          exact
          component={() => <RequestResetPassword />}
        />
        <Route
          path="/reset-password"
          exact
          component={() => <ResetPassword />}
        />

        <Route path="/referral" exact component={() => <Referral />} />

        <PrivateRoute path="/" exact component={() => <DashboardView />} />
        <PrivateRoute path="/overpay" exact component={() => <OverpayView />} />
        <PrivateRoute
          path="/mortgage"
          exact
          component={() => <MortgageView />}
        />
        <PrivateRoute
          path="/mortgage/settings"
          component={() => <MortgageSettingsView />}
        />
        <PrivateRoute path="/switch" exact component={() => <SwitchView />} />
        <PrivateRoute path="/rewards" exact component={() => <RewardsView />} />
        <PrivateRoute
          path="/rewards/history"
          exact
          component={() => <RewardHistoryView />}
        />

        <PrivateRoute path="/history" exact component={() => <HistoryView />} />

        <PrivateRoute
          path="/transaction/:id/"
          exact
          component={() => <TransactionView />}
        />

        <PrivateRoute
          path="/transaction/:id/edit"
          exact
          component={() => <EditTransactionView />}
        />

        <PrivateRoute path="/account" component={() => <Account />} />

        <PrivateRoute
          path="/admin"
          exact
          component={() => <Redirect to="/admin/users" />}
        />

        <PrivateRoute
          path="/admin/users"
          exact
          component={() => <AdminUsers />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/create-user"
          exact
          component={() => <AdminCreateUser />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/user/:id"
          exact
          component={() => <AdminUser />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/user/:id/edit"
          exact
          component={() => <AdminEditUser />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/user/:id/delete"
          exact
          component={() => <AdminDeleteUser />}
          permit={['ADMIN']}
        />

        <PrivateRoute
          path="/admin/mortgages"
          exact
          component={() => <AdminMortgages />}
          permit={['ADMIN']}
        />

        <PrivateRoute
          path="/admin/mortgage/:id/"
          exact
          component={() => <AdminMortgage />}
          permit={['ADMIN']}
        />

        <PrivateRoute
          path="/admin/mortgage/:id/edit"
          exact
          component={() => <AdminEditMortgage />}
          permit={['ADMIN']}
        />

        <PrivateRoute
          path="/admin/lenders"
          exact
          component={() => <AdminLenders />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/create-lender"
          exact
          component={() => <AdminCreateLender />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/lender/:id"
          exact
          component={() => <AdminLender />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/lender/:id/edit"
          exact
          component={() => <AdminEditLender />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/lender"
          exact
          component={() => <Redirect to="/admin/lenders" />}
          permit={['ADMIN']}
        />

        <PrivateRoute
          path="/admin/retailers"
          exact
          component={() => <AdminRetailers />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/create-retailer"
          exact
          component={() => <AdminCreateRetailer />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/retailer/:id"
          exact
          component={() => <AdminRetailer />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/retailer/:id/edit"
          exact
          component={() => <AdminEditRetailer />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/retailer"
          exact
          component={() => <Redirect to="/admin/retailers" />}
          permit={['ADMIN']}
        />

        <PrivateRoute
          path="/admin/rates"
          exact
          component={() => <AdminRates />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/create-rate"
          exact
          component={() => <AdminCreateRate />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/rate/:id"
          exact
          component={() => <AdminRate />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/rate/:id/edit"
          exact
          component={() => <AdminEditRate />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/rate"
          exact
          component={() => <Redirect to="/admin/rates" />}
          permit={['ADMIN']}
        />

        <PrivateRoute
          path="/admin/rewards"
          exact
          component={() => <AdminRewards />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/create-reward"
          exact
          component={() => <AdminCreateReward />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/reward/:id"
          exact
          component={() => <AdminReward />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/reward/:id/edit"
          exact
          component={() => <AdminEditReward />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/reward"
          exact
          component={() => <Redirect to="/admin/rewards" />}
          permit={['ADMIN']}
        />

        <PrivateRoute
          path="/admin/payments"
          exact
          component={() => <AdminPayments />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/create-payment"
          exact
          component={() => <AdminCreatePayment />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/payment/:id"
          exact
          component={() => <AdminPayment />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/payment/:id/edit"
          exact
          component={() => <AdminEditPayment />}
          permit={['ADMIN']}
        />
        <PrivateRoute
          path="/admin/payment"
          exact
          component={() => <Redirect to="/admin/payments" />}
          permit={['ADMIN']}
        />

        {process.env.NODE_ENV === 'development' && (
          <Route
            path="/dev/components"
            exact
            component={() => <ComponentList />}
          />
        )}

        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
