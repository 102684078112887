import React from 'react';
import PropTypes from 'prop-types';
import EditLenderForm from './Form';
import AdminNav from '../../admin/AdminNav';

const EditLenderView = ({ data }) => {
  return (
    <div>
      <AdminNav />
      <h1>Edit lender</h1>
      <EditLenderForm id={data._id} initialData={data} />
    </div>
  );
};

EditLenderView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }).isRequired,
};

export default EditLenderView;
