import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Modal from '../../common/Modal';
import Message from '../../common/Message';
import AdjustBalanceForm from './Form';

const AdjustBalanceModal = ({
  buttonLabel,
  buttonMode,
  buttonSize,
  buttonBlock,
  message,
  blockParentForm,
  unblockParentForm,
  amount,
  mortgageId,
  successAction,
  mode,
  remainingBalance,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const isAdminMode = mode === 'admin';

  const openModal = () => {
    setModalIsOpen(true);
    if (blockParentForm) unblockParentForm();
  };

  const closeModal = () => {
    setModalIsOpen(false);
    if (unblockParentForm) unblockParentForm();
  };

  return (
    <>
      <Button
        size={buttonSize}
        mode={buttonMode}
        label={
          buttonLabel ||
          (amount
            ? `Log a ${formatCurrency(amount)} overpayment`
            : 'Log a lump sum overpayment')
        }
        block={buttonBlock}
        onClick={() => openModal()}
      />
      <Modal
        heading={
          isAdminMode
            ? 'Adjust mortgage remaining balance'
            : 'Adjust your remaining balance'
        }
        isOpen={modalIsOpen}
        closeAction={() => closeModal()}
        contentLabel={
          isAdminMode
            ? 'Adjust mortgage remaining balance'
            : 'Adjust your remaining balance'
        }
      >
        {isAdminMode ? (
          <p>
            Adjusting the remaining balance for a user will create a balance
            adjustment transaction in their history. You are editing the balance
            for mortgage: {mortgageId}.
          </p>
        ) : (
          <p>
            Your balance can be incorrect for a number of reasons, but it&apos;s
            easy to update! Call your lender, get your up to date balance and
            enter it here. We&apos;ll log the adjustment on your history for
            you.
          </p>
        )}
        <AdjustBalanceForm
          mortgageId={mortgageId}
          remainingBalance={remainingBalance}
          isAdminMode={isAdminMode}
          successAction={() => {
            if (successAction) successAction();
            closeModal();
          }}
          cancelAction={closeModal}
        />
        <br />
        {message && <Message>{message}</Message>}
      </Modal>
    </>
  );
};

AdjustBalanceModal.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonMode: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonBlock: PropTypes.bool,
  blockParentForm: PropTypes.func,
  unblockParentForm: PropTypes.func,
  message: PropTypes.string,
  amount: PropTypes.number,
  successAction: PropTypes.func,
  remainingBalance: PropTypes.number.isRequired,
  mode: PropTypes.string,
};

AdjustBalanceModal.defaultProps = {
  buttonMode: 'default',
  buttonSize: 'm',
  buttonBlock: false,
  blockParentForm: undefined,
  unblockParentForm: undefined,
  message: undefined,
  amount: undefined,
  successAction: undefined,
  mode: 'user',
  buttonLabel: 'Adjust remaining balance',
};

export default AdjustBalanceModal;
