import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Styles as InputStyles } from '../Input';
import Styles from './Styles';

const RadioGroup = ({ error, isRequired, touched, label, children }) => {
  return (
    <Styles.RadioGroup>
      <legend required={isRequired} aria-required={isRequired}>
        {label}
        {isRequired && <FontAwesomeIcon icon="asterisk" />}
      </legend>
      {children}
      {touched && <InputStyles.InputError error={error} />}
    </Styles.RadioGroup>
  );
};

RadioGroup.defaultProps = {
  touched: false,
  error: {},
  inRow: true,
  isRequired: false,
};

RadioGroup.propTypes = {
  label: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.object,
  ]),
  touched: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  inRow: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default RadioGroup;
