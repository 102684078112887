import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import ConfirmModal from './Modal';

const ConfirmButton = ({
  buttonLabel,
  buttonSize,
  buttonMode,
  buttonType,
  contentLabel,
  confirmAction,
  confirmButtonLabel,
  confirmButtonLoadingLabel,
  confirmTextValue,
  cancelAction,
  cancelButtonLabel,
  modalHeading,
  modalMessage,
  isConfirmActionloading,
}) => {
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  return (
    <>
      <Button
        size={buttonSize}
        mode={buttonMode}
        label={buttonLabel}
        type={buttonType}
        isLoading={isConfirmActionloading}
        onClick={() => setModalIsOpen(true)}
      />
      <ConfirmModal
        heading={modalHeading}
        modalIsOpen={modalIsOpen}
        closeModal={() => setModalIsOpen(false)}
        contentLabel={contentLabel}
        message={modalMessage}
        confirmButtonLabel={confirmButtonLabel}
        confirmButtonLoadingLabel={confirmButtonLoadingLabel}
        confirmButtonMode={buttonMode}
        cancelButtonLabel={cancelButtonLabel}
        confirmTextValue={confirmTextValue}
        confirmAction={confirmAction}
        cancelAction={cancelAction}
      />
    </>
  );
};

ConfirmButton.defaultProps = {
  contentLabel: 'Confirm',
  buttonLabel: 'Do this',
  buttonSize: 's',
  buttonMode: undefined,
  buttonType: 'button',
  confirmAction: undefined,
  confirmTextValue: undefined,
  confirmButtonLabel: undefined,
  confirmButtonLoadingLabel: undefined,
  isConfirmActionloading: false,
  cancelAction: undefined,
  cancelButtonLabel: undefined,
  modalHeading: 'Are you sure?',
  modalMessage: undefined,
};

ConfirmButton.propTypes = {
  contentLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonSize: PropTypes.string,
  buttonMode: PropTypes.string,
  buttonType: PropTypes.string,
  confirmAction: PropTypes.func,
  isConfirmActionloading: PropTypes.bool,
  confirmButtonLabel: PropTypes.string,
  confirmButtonLoadingLabel: PropTypes.string,
  confirmTextValue: PropTypes.string,
  cancelAction: PropTypes.func,
  cancelButtonLabel: PropTypes.string,
  modalHeading: PropTypes.string,
  modalMessage: PropTypes.node,
};

export default ConfirmButton;
