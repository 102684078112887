import styled from 'styled-components';
import Panel from '../../common/Panel';

const GridPanel = styled(Panel)`
  grid-column: 1;
  grid-row: 2;

  @media ${({ theme }) => theme.device.laptop} {
    grid-column: 1;
    grid-row: 1;
  }
`;

export default { GridPanel };
