import React from 'react';
import PropTypes from 'prop-types';
import MortgageSettingsDetailsForm from './Form';

const MortgageSettingsDetailsView = ({ data, refetchData }) => {
  return (
    <div>
      <MortgageSettingsDetailsForm
        initialData={data}
        refetchData={refetchData}
      />
    </div>
  );
};

MortgageSettingsDetailsView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

MortgageSettingsDetailsView.defaultProps = {
  data: {},
};

export default MortgageSettingsDetailsView;
