import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../components/layout/Layout';
import NotFound from '../components/common/NotFound';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const NotFoundView = ({ location }) => {
  return (
    <AnalyticsWrapper>
      <Layout title="404 - Not Found">
        <NotFound pathname={location.pathname} />
      </Layout>
    </AnalyticsWrapper>
  );
};

NotFoundView.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

export default NotFoundView;
