import React from 'react';
import { formatNumberFromPx } from '@homamo/meadow-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import inView from 'in-view';
import theme from '../../../styles/theme';

import Styles from './Styles';

const splitErrorMessage = (string) => {
  let source = string.replace(/\[/g, '').split(']');
  const message = source.pop().replace(/^ /, '');
  source = source.join(' /');
  return {
    message,
    source,
  };
};

const ErrorMessage = ({ error }) => {
  const errorEl = React.useRef(null);

  React.useEffect(() => {
    // Only scroll if not 100% in view
    inView.threshold(1);
    if (!inView.is(errorEl.current)) {
      const yOffset = -formatNumberFromPx(theme.space[4]);
      const y =
        errorEl.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [error]);

  const { message } = splitErrorMessage(error.message);

  return (
    <Styles.ErrorMessage ref={errorEl}>
      {error.code && (
        <Styles.ErrorMessageCode>
          <FontAwesomeIcon icon="exclamation-triangle" />
          Error code: {error.code}
        </Styles.ErrorMessageCode>
      )}
      <Styles.ErrorMessageText>{message}</Styles.ErrorMessageText>
    </Styles.ErrorMessage>
  );
};

ErrorMessage.propTypes = {
  error: PropTypes.shape({
    code: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    message: PropTypes.string,
  }),
};

ErrorMessage.defaultProps = {
  error: {},
};

export default ErrorMessage;
