import React from 'react';
import { Link } from 'react-router-dom';

import Styles from './Styles';
import { useAppContext } from '../../../context/app';
import { useAuth } from '../../../context/auth';
import Nav from '../../common/Nav';
import AccountMenu from '../../common/AccountMenu';
import MobileMenu from '../../common/MobileMenu';
import logo from '../../../images/amm_logo.svg';
import WarningBar from '../../switch/WarningBar';

const Header = () => {
  const { onMobileMenuOpen, onMobileMenuClose, menuOpen } = useAppContext();
  const { isAuthenticated, user } = useAuth();
  const [showSvrWarning, setShowSvrWarning] = React.useState(false);

  const navItems =
    user && user.onboardingComplete
      ? [
          { label: 'Rewards', path: '/rewards', privateRoute: true },
          { label: 'Overpay', path: '/overpay', privateRoute: true },
          { label: 'Switch', path: '/switch', privateRoute: true },
          { label: 'My Mortgage', path: '/mortgage', privateRoute: true },
        ]
      : [];

  React.useEffect(() => {
    setShowSvrWarning(
      user &&
        user.onboardingComplete &&
        user.mortgages &&
        user.mortgages[0] &&
        !user.mortgages[0].switchInProgress,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isAuthenticated]);

  return (
    <>
      {showSvrWarning && (
        <WarningBar
          interestRate={
            user &&
            user.mortgages &&
            user.mortgages[0] &&
            user.mortgages[0].interestRate
          }
          fixedRate={
            user &&
            user.mortgages &&
            user.mortgages[0] &&
            user.mortgages[0].fixedRate
          }
          fixedRateEndDate={
            user &&
            user.mortgages &&
            user.mortgages[0] &&
            user.mortgages[0].fixedRateEndDate
          }
        />
      )}
      <Styles.LargeHeader>
        <Link className="logo-link" to="/">
          <Styles.Logo src={logo} alt="Accelerate My Mortgage" />
        </Link>
        <Nav isAuthenticated={isAuthenticated} navItems={navItems} />
        {!isAuthenticated && (
          <Nav
            isAuthenticated={isAuthenticated}
            navItems={[
              {
                label: 'Sign up',
                path: '/sign-up',
                notAuthenticatedRoute: true,
              },
              {
                label: 'Sign in',
                path: '/sign-in',
                notAuthenticatedRoute: true,
              },
            ]}
          />
        )}
        {isAuthenticated && <AccountMenu />}
      </Styles.LargeHeader>

      <Styles.MobileHeader>
        <Link className="logo-link" to="/">
          <Styles.Logo src={logo} alt="Accelerate My Mortgage" />
        </Link>
        <Styles.MenuButton type="button" onClick={() => onMobileMenuOpen()}>
          Menu
        </Styles.MenuButton>
        <MobileMenu
          isOpen={menuOpen}
          closeAction={() => onMobileMenuClose()}
          isAuthenticated={isAuthenticated}
        />
      </Styles.MobileHeader>
    </>
  );
};

export default Header;
