import React, { useEffect, useState } from 'react';
import API, { handleApiError } from '../../../lib/api';
import SwitchView from './View';

import Loading from '../../common/Loading';
import { useAuth } from '../../../context/auth';

function SwitchContainer() {
  const { user, isCheckingAuth, onUpdateUser } = useAuth();
  // Data state to store the users data. Its initial value is an empty array
  const [mortgageData, setMortgageData] = useState({});
  const [displayError, setDisplayError] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    const mortgageId =
      user.mortgage_ids && user.mortgage_ids.length && user.mortgage_ids[0];

    if (mortgageId) {
      await API.get(`mortgages/${mortgageId}`)
        .then((res) => {
          setMortgageData(res.data);
        })
        .catch((error) => {
          handleApiError({ error, setDisplayError });
        })
        .finally(() => setIsLoading(false));
    } else {
      handleApiError({
        error: {
          message:
            'No mortgage data found. Please refresh your browser or contact support',
        },
        setDisplayError,
      });
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isCheckingAuth || isLoading) return <Loading />;

  return (
    <SwitchView
      user={user}
      mortgageId={mortgageData._id}
      mortgage={mortgageData}
      displayError={displayError}
      setDisplayError={setDisplayError}
      fetchData={fetchData}
      setMortgageData={setMortgageData}
      setUserData={onUpdateUser}
    />
  );
}

export default SwitchContainer;
