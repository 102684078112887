import styled from 'styled-components';
import { Styles as ButtonStyles } from '../Button';

const FileInputLabel = styled.label`
  padding: ${({ theme }) => `${theme.space[6]} ${theme.space[6]}`};
  border: ${({ theme }) => `1px solid ${theme.inputBorder}`};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  background-color: ${({ theme }) => theme.color['gray-100']};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const FileInputField = styled.input`
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
`;

const FileInput = styled.div`
  width: 100%;
  margin: ${({ theme }) => `${theme.space[4]} 0`};

  ${ButtonStyles.Button} {
    pointer-events: none !important;
  }

  ${({ isFocused, theme }) =>
    isFocused &&
    `${FileInputLabel} {
      border-color: ${theme.inputColor};
      background: ${theme.color['gray-200']};
    }`}
`;

export default { FileInput, FileInputField, FileInputLabel };
