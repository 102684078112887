import React from 'react';
import Layout from '../../components/layout/Layout';
import RequestResetPassword from '../../components/auth/RequestResetPassword';
import AnalyticsWrapper from '../../components/common/AnalyticsWrapper';

const RequestResetPasswordView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Request password reset">
        <RequestResetPassword />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default RequestResetPasswordView;
