const clean = (string) => string && string.replace(/"/g, '').replace(/,/g, '');

export default {
  appName: clean(process.env.REACT_APP_NAME),
  apiUrl: clean(process.env.REACT_APP_API_URL),
  appUrl: clean(process.env.REACT_APP_URL),
  version: '2.0.15',
  termsUrl: 'https://acceleratemymortgage.com/terms',
  privacyUrl: 'https://acceleratemymortgage.com/privacy',
  supportEmail: clean(process.env.REACT_APP_SUPPORT_EMAIL),
  signUpDisabled: false,
  seo: {
    title: 'Accelerate My Mortgage',
    description: 'Get to mortgage freedom faster',
  },
  decimalDigits: 12,
  rewardDefaults: {
    switch: 10000,
    referral: 2500,
    partner: 0,
  },
  svrInterestRate: 5.11,
  isStaging: process.env.REACT_APP_STAGING,
  isMaintenanceMode: process.env.REACT_APP_MAINTENANCE_MODE,
  googleAnalytics: process.env.REACT_GOOGLE_ANALYTICS,
};
