import styled from 'styled-components';

const PageHeader = styled.header`
  margin: 3rem 0;

  h2,
  p {
    width: 100%;
  }

  h2 {
    font-size: 2.4rem;
    @media ${({ theme }) => theme.device.mobileL} {
      font-size: 2.6rem;
    }
  }

  @media ${({ theme }) => theme.device.tablet} {
    h2 {
      font-size: ${({ isLarge }) => (isLarge ? '4rem' : '3rem')};
    }
    p {
      font-size: ${({ isLarge }) => (isLarge ? '3rem' : '2rem')};
    }
  }

  h2,
  p {
    margin: 0 0 2rem;
    line-height: 1.3;
    max-width: 600px;
  }
`;

export default {
  PageHeader,
};
