import styled from 'styled-components';
import { Styles as InputStyles } from '../Input';

const CheckboxInput = styled.div`
  margin: ${({ theme, size }) => {
    if (size === 'l') return `${theme.space[3]} 0 ${theme.space[4]}`;
    return `${theme.space[2]} 0 ${theme.space[3]}`;
  }};
  width: 100%;

  input[type='checkbox'] {
    opacity: 0;
    position: fixed;
  }

  label {
    font-size: ${({ theme, size }) => {
      if (size === 'l') return theme.fontSize[3];
      return theme.fontSize[2];
    }};

    line-height: 1.2;
    max-width: ${({ theme }) => theme.space[13]};
    color: ${({ theme }) => theme.inputLabelColor};
    position: relative;
    display: inline-block;
    padding-left: ${({ theme }) => theme.space[6]};
    cursor: pointer;

    /* REQUIRED ASTERISK */
    svg {
      color: ${({ theme }) => theme.color['orange-500']};
      font-size: ${({ theme }) => theme.fontSize[1]};
      margin-left: ${({ theme }) => theme.space[2]};
    }

    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: -4px;
      display: inline-block;
      height: ${({ theme, size }) => {
        if (size === 'l') return theme.space[5];
        return theme.space[4];
      }};
      width: ${({ theme, size }) => {
        if (size === 'l') return theme.space[5];
        return theme.space[4];
      }};
      border: 1px solid ${({ theme }) => theme.inputBorder};
      border-radius: ${({ theme }) => theme.borderRadius[1]};
      background: ${({ theme }) => theme.color.white};
      transition: background-color 100ms ease-out;

      @media ${({ theme }) => theme.device.tablet} {
        top: -2px;
      }
    }

    ::after {
      content: '';
      top: 3px;
      position: absolute;
      left: ${({ size }) => {
        if (size === 'l') return '6px';
        return '4px';
      }};
      right: ${({ size }) => {
        if (size === 'l') return '6px';
        return '4px';
      }};
      display: inline-block;
      height: ${({ size }) => {
        if (size === 'l') return '12px';
        return '8px';
      }};
      width: ${({ size }) => {
        if (size === 'l') return '20px';
        return '16px';
      }};
      border-left: 4px solid ${({ theme }) => theme.color.white};
      border-bottom: 4px solid ${({ theme }) => theme.color.white};
      transform: rotate(-45deg);

      @media ${({ theme }) => theme.device.tablet} {
        top: 4px;
      }
    }
  }

  /* Hide the checkmark by default */
  input[type='checkbox'] + label::after {
    content: none;
  }
  /* Unhide the checkmark on the checked state */
  input[type='checkbox']:checked + label {
    ::before {
      background: ${({ theme }) => theme.color['gray-700']};
      transition: background-color 100ms ease-out;
    }
    ::after {
      content: '';
    }
  }

  input[type='checkbox']:focus + label::before {
    outline: rgb(59, 153, 252) auto 5px;
  }

  ${InputStyles.InputError} {
    display: block;
  }
`;

export default {
  CheckboxInput,
};
