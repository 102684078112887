import React, { useEffect, useState } from 'react';

import API, { handleApiError } from '../../../lib/api';
import MortgageSettingsView from './View';
import { useAuth } from '../../../context/auth';

import Loading from '../../common/Loading';

function MortgageSettingsContainer() {
  // Data state to store the users data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [displayError, setDisplayError] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const { user, isCheckingAuth, fetchUserData } = useAuth();

  const mortgageId = user && user.mortgage_ids && user.mortgage_ids[0];

  const fetchData = async () => {
    await API.get(`mortgages/${mortgageId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!mortgageId) return;
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isCheckingAuth) return <Loading />;
  return (
    data && (
      <MortgageSettingsView
        data={data}
        displayError={displayError}
        refetchData={() => {
          fetchData();
          fetchUserData();
        }}
      />
    )
  );
}

export default MortgageSettingsContainer;
