import styled from 'styled-components';
import { Styles as ButtonStyles } from '../../common/Button';

const Layout = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 4rem;

  @media ${({ theme }) => theme.device.tablet} {
    grid-auto-flow: column;
  }

  ${ButtonStyles.ButtonGroup} {
    width: 100%;
    display: flex;

    button {
      margin: 0 1rem;

      padding: 0.5rem 0;
      color: ${({ theme }) => theme.color['gray-700']};
      background-color: ${({ theme }) => theme.color['gray-200']};
      border: 0;

      &:hover {
        color: ${({ theme }) => theme.color['gray-700']};
        background-color: ${({ theme }) => theme.color['gray-400']};
      }
    }
  }

  p,
  small {
    margin: 0;
  }
`;

export default {
  Layout,
};
