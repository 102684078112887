import React from 'react';
import CreateRateForm from './Form';
import AdminNav from '../../admin/AdminNav';

const CreateRateView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Create Rate</h1>
      <CreateRateForm />
    </div>
  );
};

export default CreateRateView;
