import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminPayments from '../../../components/transaction/AdminPayments';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const PaymentsView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Payments">
        <AdminPayments />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default PaymentsView;
