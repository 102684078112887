import styled from 'styled-components';
import { Styles as FileInputStyles } from '../FileInput';

const ImageUpload = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${FileInputStyles.FileInput} {
    height: 100%;
  }
`;

const ImageUploadPreview = styled.img`
  display: flex;
  margin-right: ${({ theme }) => theme.space[3]};
  height: 140px;
`;

export default { ImageUpload, ImageUploadPreview };
