import styled from 'styled-components';
import { Styles as FormStyles } from '../../../Form';
import { Styles as InputStyles } from '../../../Input';

const Search = styled.div`
  margin: ${({ theme }) => theme.space[3]} 0;
  width: 100%;

  ${FormStyles.Form} {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;

    fieldset {
      width: 100%;
    }
  }

  ${InputStyles.InputWrapper} {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  ${InputStyles.Input} {
    width: 100%;
  }
`;

const SearchActions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: ${({ theme }) => theme.space[0]};
`;

export default {
  Search,
  SearchActions,
};
