import styled from 'styled-components';
import { Styles as MessageStyles } from '../../common/Message';

const HistoryHeader = styled.div`
  max-width: 1024px;
  margin: 0 auto;

  .top {
    padding: 1rem 0;
    border-bottom: 1px solid ${({ theme }) => theme.color['gray-400']};

    @media ${({ theme }) => theme.device.tablet} {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  ${MessageStyles.Message} {
    margin-top: 2rem;
    max-width: 800px;
  }
`;

export default { HistoryHeader };
