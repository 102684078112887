import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { keepParams } from '@homamo/meadow-utils';
import Error from '../../common/ErrorMessage';
import AdminNav from '../../admin/AdminNav';

const RateNotFoundComponent = ({ id, displayError }) => {
  return (
    <>
      <AdminNav />
      <h1>404. Rate not found</h1>
      <p>No rate found with ID: {id}</p>
      <p>
        <Link to={keepParams('/admin/rates')}>Back to all rates</Link>
      </p>
      {displayError && displayError.message && (
        <>
          <p>
            <small>
              The following information may be useful for technical support:
            </small>
          </p>
          <Error error={displayError} />
        </>
      )}
    </>
  );
};

RateNotFoundComponent.propTypes = {
  id: PropTypes.string.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

RateNotFoundComponent.defaultProps = {
  displayError: {},
};

export default RateNotFoundComponent;
