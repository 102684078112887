import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import Modal, { ModalFooter } from '../../common/Modal';
import MarketingSiteLink from '../../common/MarketingSiteLink';

const RewardsInfoModal = ({ retailerName, isOpen, closeAction }) => (
  <Modal
    heading="We're tracking..."
    isOpen={isOpen}
    closeAction={closeAction}
    contentLabel="Rewards Tracking Information"
  >
    <ul>
      <li>We&apos;ve logged your visit to {retailerName}</li>
      <li>
        Complete your purchase as you normally would and we&apos;ll be sure to
        track any qualifying purchases you make
      </li>
      <li>
        If you need more information about the rate of cashback your purchase is
        likely to be eligible for,{' '}
        <MarketingSiteLink path="/retailer-cashback" label="visit this page" />
      </li>
      <li>
        Any cashback you qualify for will be added to your Mortgage Rewards
        pending balance and will be included within your next monthly Mortgage
        Rewards statement email
      </li>
    </ul>
    <ModalFooter>
      <Button label="Done" onClick={closeAction} />
    </ModalFooter>
  </Modal>
);

RewardsInfoModal.propTypes = {
  retailerName: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  closeAction: PropTypes.func.isRequired,
};

RewardsInfoModal.defaultProps = {
  isOpen: false,
};

export default RewardsInfoModal;
