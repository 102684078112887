import { library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';

import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faAsterisk,
  faBan,
  faCalendarAlt,
  faCaretDown,
  faCheckCircle,
  faComment,
  faCommentDots,
  faExclamationTriangle,
  faFileDownload,
  faHandPointer,
  faLock,
  faLockOpen,
  faQuestionCircle,
  faPercent,
  faPlus,
  faPoundSign,
  faSortAmountDown,
  faSortAmountUp,
  faSpinner,
  faStar,
  faTimes,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

const loadFontAwesome = () => {
  // Setup Font Awesome with icon imports we'll be using
  library.add(
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faAsterisk,
    faBan,
    faCalendarAlt,
    faCaretDown,
    faCheckCircle,
    faComment,
    faCommentDots,
    faExclamationTriangle,
    faFileDownload,
    faHandPointer,
    faLock,
    faLockOpen,
    faQuestionCircle,
    faPercent,
    faPlus,
    faPoundSign,
    faSortAmountDown,
    faSortAmountUp,
    faSpinner,
    faStar,
    faTimes,
    faTimesCircle,
  );
};

export default loadFontAwesome;
