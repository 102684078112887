import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import AdminEditReward from '../../../components/reward/AdminEditReward';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const EditRewardView = () => {
  const { id } = useParams();
  return (
    <AnalyticsWrapper>
      <Layout title="Edit reward">
        <AdminEditReward id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default EditRewardView;
