import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import EditRetailer from '../../../components/retailer/EditRetailer';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const EditRetailerView = () => {
  const { id } = useParams();
  return (
    <AnalyticsWrapper>
      <Layout title="Edit retailer">
        <EditRetailer id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default EditRetailerView;
