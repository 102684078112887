import React from 'react';
import PropTypes from 'prop-types';
import FileInput from '../FileInput';
import Styles from './Styles';

const ImageUpload = ({
  label,
  name,
  imageUrl,
  onUpload,
  onUploadSuccess,
  onUploadComplete,
  onUploadError,
}) => {
  const fileInput = React.useRef(null);
  const [isUploadingImage, setIsUploadingImage] = React.useState();
  const [uploadedImageUrl, setUploadedImageUrl] = React.useState(imageUrl);

  const uploadImage = async (event) => {
    event.preventDefault();

    if (fileInput && fileInput.current) {
      setIsUploadingImage(true);
      if (onUpload) onUpload();

      const { files } = fileInput.current;

      const data = new FormData();
      data.append('file', files[0]); // Just first file
      data.append('upload_preset', process.env.REACT_APP_CLOUDINARY_PRESET);

      fetch(process.env.REACT_APP_CLOUDINARY_URL, {
        method: 'POST',
        body: data,
      })
        .then(async (res) => {
          const file = await res.json();
          setUploadedImageUrl(file.secure_url);
          if (onUploadSuccess) onUploadSuccess(file.secure_url);
        })
        .catch((error) => {
          if (onUploadError) onUploadError(error);
        })
        .finally(() => {
          setIsUploadingImage(false);
          if (onUploadComplete) onUploadComplete();
        });
    }
  };

  return (
    <Styles.ImageUpload>
      {uploadedImageUrl && (
        <Styles.ImageUploadPreview
          src={uploadedImageUrl}
          alt={`${name}-preview`}
        />
      )}
      <FileInput
        name={name}
        label={label}
        ref={fileInput}
        isUploading={isUploadingImage}
        onChange={uploadImage}
      />
    </Styles.ImageUpload>
  );
};

ImageUpload.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  imageUrl: PropTypes.string,
  onUpload: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onUploadError: PropTypes.func,
  onUploadComplete: PropTypes.func,
};

ImageUpload.defaultProps = {
  label: 'Image',
  name: 'image',
  imageUrl: undefined,
  onUpload: undefined,
  onUploadSuccess: PropTypes.func,
  onUploadError: PropTypes.func,
  onUploadComplete: PropTypes.func,
};

export default ImageUpload;
