import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { keepParams } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import Error from '../../common/ErrorMessage';

const AdminDeleteUserForm = ({ id, email }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`users/delete/${id}`, values)
            .then(() => {
              toast.success('User updated');
              history.push(keepParams(`/admin/users`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Whoops! That doesn't look like a valid email")
            .required('Email is required')
            .lowercase()
            .test(
              'confirm-email',
              "Email doesn't match user email",
              (value) => value === email,
            ),
        })}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="Enter the user's email address"
                  id="email"
                  type="email"
                  name="email"
                  placeholder={email}
                  component={TextInput}
                  isRequired
                />
                <ButtonGroup>
                  <Button
                    label="Delete User"
                    type="submit"
                    mode="primary"
                    loadingLabel="Deleting User"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/users'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

AdminDeleteUserForm.propTypes = {
  id: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

export default AdminDeleteUserForm;
