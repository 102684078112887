import React from 'react';
import AdminCreatePaymentForm from './Form';
import AdminNav from '../../admin/AdminNav';
import Message from '../../common/Message';

const AdminCreatePaymentView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Log a payment</h1>
      <Message mode="warning" size="l">
        This is just a log of the payment. The actual transfer needs to be made
        manually. This payment will affect the user&apos;s displayed balance.
      </Message>
      <AdminCreatePaymentForm />
    </div>
  );
};

export default AdminCreatePaymentView;
