/* eslint-disable react/prop-types */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  checkUserHasRole,
  formatDateTime,
  formatUserName,
  keepParams,
} from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Table, { TableButtonGroup } from '../../common/Table';

const AdminUsersTableComponent = ({
  data,
  isLoading,
  pageCount,
  fetchData,
  totalCount,
  initialSortBy,
}) => {
  const columns = useMemo(
    /* eslint-disable react/prop-types */
    () => [
      {
        Header: 'Name',
        accessor: ({ profile, roles, suspended }) => (
          <>
            {formatUserName({ profile })}
            {roles && roles.includes('ADMIN') && (
              <Icon icon="star" color="yellow-600" pad="left" />
            )}
            {suspended && <Icon icon="ban" color="red-500" pad="left" />}
          </>
        ),
        sortType: 'basic',
        id: 'profile.firstName',
      },
      {
        Header: 'Email',
        accessor: 'email',
        sortType: 'basic',
        id: 'email',
      },
      {
        Header: 'ID',
        accessor: '_id',
        sortType: 'basic',
        id: 'ID',
        width: 250,
      },
      {
        Header: 'Signed up',
        accessor: ({ createdAt }) => formatDateTime(createdAt),
        sortType: 'datetime',
        id: 'createdAt',
        width: 180,
      },

      {
        Header: 'Last login',
        accessor: ({ lastLoginDate }) =>
          (lastLoginDate && formatDateTime(lastLoginDate)) || '-',
        sortType: 'datetime',
        id: 'lastLoginDate',
        width: 180,
      },
      {
        Header: 'Actions',
        accessor: '_id',
        disableSortBy: true,
        width: 300,
        sticky: 'right',
        Cell: ({ cell: { value }, row: { original } }) => (
          <TableButtonGroup>
            <Button
              label="View"
              size="s"
              linkTo={keepParams(`/admin/user/${value}`)}
            />
            {original.mortgage_ids && original.mortgage_ids[0] && (
              <Button
                label="Mortgage"
                size="s"
                linkTo={keepParams(
                  `/admin/mortgage/${original.mortgage_ids[0]}`,
                )}
              />
            )}
            <Button
              label="Edit"
              size="s"
              linkTo={keepParams(`/admin/user/${value}/edit`)}
            />
            <Button
              label="Remove"
              size="s"
              mode="danger"
              isDisabled={checkUserHasRole(original, 'ADMIN')}
              linkTo={
                !checkUserHasRole(original, 'ADMIN') &&
                keepParams(`/admin/user/${value}/delete`)
              }
            />
          </TableButtonGroup>
        ),
      },
    ],
    [], // Second array to allow memo
    /* eslint-enable react/prop-types */
  );
  return (
    <Table
      name="admin-users"
      columns={columns}
      data={data}
      fetchData={fetchData}
      isLoading={isLoading}
      pageCount={pageCount}
      totalCount={totalCount}
      blankState="No users found"
      initialSortBy={initialSortBy}
      defaultPageSize={20}
      enableSearch
      searchPlaceholder="Search by email"
    />
  );
};

AdminUsersTableComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  initialSortBy: PropTypes.arrayOf([
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ]).isRequired,
};

AdminUsersTableComponent.defaultProps = {
  data: [],
  isLoading: false,
  pageCount: 0,
  totalCount: 0,
};

export default AdminUsersTableComponent;
