import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getSwitchStatus } from '@rateswitch/amm-utils';

import Styles from './Styles';

const WarningBar = ({ interestRate, fixedRate, fixedRateEndDate }) => {
  const switchStatus = getSwitchStatus({
    fixedRate,
    fixedRateEndDate,
    interestRate,
  });

  // On SVR
  if (switchStatus.label === 'now' && switchStatus.svr) {
    return (
      <Styles.WarningBar>
        <FontAwesomeIcon icon="exclamation-triangle" /> You&apos;re probably
        paying more than you need to on your lender&apos;s Standard Variable
        Rate. <Link to="/switch">See your switching options</Link>
      </Styles.WarningBar>
    );
  }

  if (switchStatus.label === 'now') {
    return (
      <Styles.WarningBar>
        <FontAwesomeIcon icon="exclamation-triangle" /> Your fixed rate ends
        soon, so your mortgage payments are likely to increase.{' '}
        <Link to="/switch">See your switching options</Link>
      </Styles.WarningBar>
    );
  }

  return null;
};

WarningBar.defaultProps = {
  fixedRateEndDate: undefined,
};

WarningBar.propTypes = {
  interestRate: PropTypes.number.isRequired,
  fixedRate: PropTypes.string.isRequired,
  fixedRateEndDate: PropTypes.string,
};

export default WarningBar;
