import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import {
  convertPercentToDecimal,
  convertDecimalToPercent,
  formatDateTime,
  getDateFromMonthAndYear,
  getMonthSelectOptions,
  getYearSelectOptions,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';
import Button from '../../common/Button';
import Form from '../../common/Form';
import RadioInput from '../../common/RadioInput';
import RadioGroup from '../../common/RadioGroup';
import Error from '../../common/ErrorMessage';
import Message from '../../common/Message';
import SelectInput from '../../common/SelectInput';
import PercentageInput from '../../common/PercentageInput';

const validationSchema = Yup.object().shape({
  interestRate: Yup.number().required('Enter your interest rate'),
  fixedRate: Yup.string().required('Choose an option'),
  fixedRateEndMonth: Yup.string().when('fixedRate', {
    is: 'yes',
    then: Yup.string().required('Select the month your fixed rate ends'),
    otherwise: Yup.string(),
  }),
  fixedRateEndYear: Yup.string().when('fixedRate', {
    is: 'yes',
    then: Yup.string().required('Select the year your fixed rate ends'),
    otherwise: Yup.string(),
  }),
});

const MortgageSettingsRateForm = ({ initialData, refetchData }) => {
  const [displayError, setDisplayError] = React.useState();

  const mortgageId = initialData._id;

  const initialValues = {
    interestRate: initialData.interestRate
      ? convertDecimalToPercent(initialData.interestRate)
      : undefined,
    fixedRate: initialData.fixedRate || undefined,
    fixedRateEndMonth:
      (initialData.fixedRateEndDate &&
        formatDateTime(initialData.fixedRateEndDate, 'mon').toLowerCase()) ||
      undefined,
    fixedRateEndYear:
      (initialData.fixedRateEndDate &&
        formatDateTime(initialData.fixedRateEndDate, 'year')) ||
      undefined,
  };

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const { fixedRate, fixedRateEndMonth, fixedRateEndYear } = values;

          const fixedRateEndDate =
            fixedRate === 'yes' &&
            fixedRateEndMonth &&
            fixedRateEndMonth !== '' &&
            fixedRateEndMonth !== null &&
            fixedRateEndYear &&
            fixedRateEndYear !== '' &&
            fixedRateEndYear !== null
              ? getDateFromMonthAndYear(fixedRateEndMonth, fixedRateEndYear)
              : undefined;

          await API.post(`mortgages/update/${mortgageId}`, {
            interestRate: convertPercentToDecimal(values.interestRate),
            fixedRate: values.fixedRate,
            fixedRateEndDate,
          })
            .then(() => {
              toast.success('Settings updated!');
              refetchData();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting, values, errors, touched }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="Interest rate"
                  id="interestRate"
                  name="interestRate"
                  component={PercentageInput}
                  isRequired
                />
                <Message mode="warning">
                  Updating your rate may affect your remaining balance. You can
                  adjust your remaining balance in your history.
                </Message>
                <RadioGroup
                  id="fixedRate"
                  label="Are you on a fixed rate?"
                  value={values.fixedRate}
                  error={errors.fixedRate}
                  touched={touched.fixedRate}
                  isRequired
                >
                  <Field
                    name="fixedRate"
                    id="fixed-yes"
                    label="Yes"
                    valueLabel="yes"
                    component={RadioInput}
                  />
                  <Field
                    name="fixedRate"
                    id="fixed-no"
                    label="No"
                    valueLabel="no"
                    component={RadioInput}
                  />
                  <Field
                    name="fixedRate"
                    id="fixed-unknown"
                    label="I don't know"
                    valueLabel="unknown"
                    component={RadioInput}
                  />
                </RadioGroup>
                <Field
                  label="Month your fixed rate ends"
                  id="fixedRateEndMonth"
                  name="fixedRateEndMonth"
                  component={SelectInput}
                  options={getMonthSelectOptions()}
                  placeholder="Select a month"
                  disabled={values.fixedRate !== 'yes'}
                />
                <Field
                  label="Year your fixed rate ends"
                  id="fixedRateEndYear"
                  name="fixedRateEndYear"
                  component={SelectInput}
                  options={getYearSelectOptions({
                    range: 10,
                    type: 'future',
                    ascending: true,
                  })}
                  placeholder="Select a year"
                  disabled={values.fixedRate !== 'yes'}
                />
                <Button
                  label="Update settings"
                  type="submit"
                  mode="primary"
                  loadingLabel="Updating settings"
                  isLoading={isSubmitting}
                  disabled={!dirty}
                />
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

MortgageSettingsRateForm.propTypes = {
  initialData: PropTypes.shape({
    _id: PropTypes.string,
    interestRate: PropTypes.number,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

MortgageSettingsRateForm.defaultProps = {
  initialData: {},
};

export default MortgageSettingsRateForm;
