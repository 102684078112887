import styled from 'styled-components';

const Panel = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius[3]};
  box-shadow: ${({ theme }) => theme.boxShadow[3]};

  .header {
    padding: ${({ theme, smallHeader }) =>
      smallHeader ? theme.space[2] : theme.space[3]};
    border-bottom: 4px solid ${({ theme }) => theme.color['gray-200']};

    h3 {
      margin: 0;
      padding: 0;
      font-size: ${({ theme, smallHeader }) =>
        smallHeader ? theme.fontSize[2] : theme.fontSize[4]};
      text-transform: ${({ smallHeader }) =>
        smallHeader ? 'uppercase' : 'auto'};
      line-height: 1.3;
      max-width: ${({ smallHeader }) => (smallHeader ? '100%' : '650px')};
      text-align: ${({ smallHeader }) => (smallHeader ? 'center' : 'left')};
    }
  }

  .body {
    padding: ${({ theme }) => theme.space[4]};

    h3 {
      margin: 0;
    }

    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }

    @media ${({ theme }) => theme.device.tablet} {
      padding: ${({ theme }) => theme.space[4]};
    }

    a {
      text-decoration: underline;
    }
  }
`;

const PanelColumn = styled.div`
  display: block;

  ${Panel} {
    margin-bottom: ${({ theme }) => theme.space[3]};

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PanelGroup = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[5]};
  grid-template-columns: 1fr;

  @media ${({ theme }) => theme.device.laptop} {
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;

    ${Panel} {
      height: 100%;
    }
  }
`;

export default {
  Panel,
  PanelColumn,
  PanelGroup,
};
