import React from 'react';
import Layout from '../../../components/layout/Layout';
import CreateLender from '../../../components/lender/CreateLender';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const CreateLenderView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Create lender">
        <CreateLender />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default CreateLenderView;
