import React from 'react';
import PropTypes from 'prop-types';
import { formatDateTime, keepParams } from '@homamo/meadow-utils';
import Definition, { DefinitionList } from '../../common/Definition';
import Error from '../../common/ErrorMessage';
import Badge, { BadgeGroup } from '../../common/Badge';
import Button, { ButtonGroup } from '../../common/Button';
import Panel, { PanelColumn } from '../../common/Panel';
import AdminNav from '../../admin/AdminNav';
import { retailerCategoryLabel } from '../../../lib/retailerCategories';

const RetailerView = ({ data, displayError }) => {
  const termWidth = '200px';

  const categories = data.categories || [];

  return (
    <div>
      <AdminNav />
      <h1>{data.heading}</h1>
      <ButtonGroup>
        <Button
          size="s"
          label="View all retailers"
          icon="arrow-left"
          linkTo={keepParams('/admin/retailers')}
        />
        <Button
          size="s"
          label="Edit"
          linkTo={keepParams(`/admin/retailer/${data._id}/edit`)}
        />
      </ButtonGroup>
      <Error error={displayError} />
      <PanelColumn>
        <Panel heading="Details">
          <DefinitionList termWidth={termWidth}>
            <Definition term="Logo">
              {data.image && <img src={data.image} alt={data.heading} />}
            </Definition>
            <Definition term="Description" definition={data.description} />
            <Definition term="Link" definition={data.link} />
            <Definition term="Advertiser ID" definition={data.advertiserId} />
            <Definition
              term="Partner Network"
              definition={data.partnerNetwork}
            />
            <Definition
              term="Show live link"
              definition={data.live ? 'TRUE' : 'FALSE'}
            />
            <Definition term="Categories">
              <BadgeGroup>
                {categories.length
                  ? categories.map((category) => (
                      // eslint-disable-next-line react/jsx-indent
                      <Badge size="s">{retailerCategoryLabel[category]}</Badge>
                    ))
                  : '-'}
              </BadgeGroup>
            </Definition>
          </DefinitionList>
        </Panel>
        <Panel heading="Meta">
          <DefinitionList termWidth={termWidth}>
            <Definition term="ID" definition={data._id} />
            <Definition
              term="Created"
              definition={formatDateTime(data.createdAt)}
            />
            <Definition
              term="Updated"
              definition={formatDateTime(data.updatedAt)}
            />
          </DefinitionList>
        </Panel>
      </PanelColumn>
    </div>
  );
};

RetailerView.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    heading: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.string,
    live: PropTypes.bool,
    link: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
    categories: PropTypes.arrayOf([PropTypes.string]),
    advertiserId: PropTypes.string,
    partnerNetwork: PropTypes.string,
  }).isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

RetailerView.defaultProps = {
  displayError: undefined,
};

export default RetailerView;
