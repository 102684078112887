import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import API, { handleApiError } from '../../../lib/api';

const validationSchema = Yup.object().shape({
  userPhone: Yup.string().required('Please enter a phone number'),
});

const ArrangeCallbackForm = ({
  userPhone,
  context,
  product,
  successAction,
  cancelAction,
}) => {
  const [displayError, setDisplayError] = React.useState();

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`mortgages/arrange-callback`, {
            userPhone: values.userPhone || userPhone || null,
            context,
            product,
          })
            .then(() => {
              setSubmitting(false);
              toast.success(
                'One of our expert mortgage advisers will be in touch shortly',
              );
              if (successAction) successAction();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          userPhone: userPhone || '',
        }}
        validationSchema={validationSchema}
        render={({ errors, isSubmitting, submitForm }) => {
          return (
            <Form>
              <Error error={errors} />
              <Field
                label="Your phone number"
                id="userPhone"
                name="userPhone"
                placeholder="Your phone number"
                component={TextInput}
              />
              <ButtonGroup>
                <Button
                  label="Contact me"
                  mode="primary"
                  onClick={() => submitForm()}
                  type="button"
                  loadingLabel="Notifying our team"
                  isLoading={isSubmitting}
                />
                {cancelAction && (
                  <Button label="Cancel" onClick={cancelAction} />
                )}
              </ButtonGroup>
            </Form>
          );
        }}
      />
    </>
  );
};

ArrangeCallbackForm.defaultProps = {
  successAction: undefined,
  cancelAction: undefined,
  userPhone: undefined,
  context: undefined,
  product: undefined,
};

ArrangeCallbackForm.propTypes = {
  userPhone: PropTypes.string,
  context: PropTypes.string,
  product: PropTypes.string,
  successAction: PropTypes.func,
  cancelAction: PropTypes.func,
};

export default ArrangeCallbackForm;
