import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

const ButtonStyles = css`
  border-radius: ${({ theme }) => theme.borderRadius[1]};

  display: ${({ block }) => (block ? 'block' : 'inline-block')};
  width: ${({ block }) => (block ? '100%' : 'auto')};

  padding: ${({ mode, size, theme }) => {
    if (mode === 'link') return `0 ${theme.space[1]}`;
    if (size === 's') return theme.space[1];
    return theme.space[3];
  }};

  font-size: ${({ size, theme }) => {
    if (size === 's') return theme.fontSize[2];
    return theme.fontSize[3];
  }};

  /* font-weight: ${({ size, mode }) => {
    if (size === 's' || mode === undefined) return '400';
    return '700';
  }}; */

  border: 1px solid ${({ theme }) => theme.color['gray-300']};
  font-family: ${({ theme }) => theme.fontFamily};
  text-decoration: ${({ mode }) =>
    mode === 'link' ? 'underline' : 'none'} !important;
  line-height: 1;
  text-align: center;

  cursor: pointer;

  background-color: ${({ theme, mode }) => {
    if (mode === 'danger') return theme.color['red-100'];
    if (mode === 'primary') return theme.color['orange-700'];
    if (mode === 'outline') return 'transparent';
    if (mode === 'link') return 'transparent !important';
    return theme.color['gray-300'];
  }};

  color: ${({ theme, mode }) => {
    if (mode === 'danger') return theme.danger;
    if (mode === 'primary') return theme.color.white;
    if (mode === 'outline') return theme.color['gray-700'];
    if (mode === 'link') return theme.linkColor;
    return theme.color['gray-700'];
  }};

  border-color: ${({ theme, mode }) => {
    if (mode === 'danger') return theme.danger;
    if (mode === 'primary') return theme.color['orange-700'];
    if (mode === 'outline') return theme.color['gray-700'];
    if (mode === 'link') return 'transparent !important';
    return theme.color['gray-300'];
  }};

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;

    &:hover {
      opacity: 0.3;
    }
  }

  &:hover {
    cursor: pointer;

    background-color: ${({ theme, mode }) => {
      if (mode === 'danger') return theme.danger;
      if (mode === 'primary') return theme.color['orange-500'];
      if (mode === 'outline') return theme.color['gray-200'];
      if (mode === 'link') return 'transparent';
      return theme.color['gray-400'];
    }};

    border-color: ${({ theme, mode }) => {
      if (mode === 'danger') return theme.danger;
      if (mode === 'primary') return theme.color['orange-500'];
      if (mode === 'outline') return theme.color['gray-700'];
      if (mode === 'link') return 'transparent';
      return theme.color['gray-400'];
    }};

    color: ${({ theme, mode }) => {
      if (mode === 'danger') return theme.color.white;
      if (mode === 'primary') return theme.color.white;
      if (mode === 'outline') return theme.color['gray-700'];
      if (mode === 'link') return theme.linkColor;
      return theme.color['gray-700'];
    }};
  }
`;

const Button = styled.button`
  ${ButtonStyles}
`;

const LinkButton = styled(Link)`
  ${ButtonStyles}
`;

const ExternalLinkButton = styled.a`
  ${ButtonStyles}
`;

const ButtonInner = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  svg {
    ${({ isIconButton, theme }) =>
      !isIconButton && `margin-right: ${theme.space[1]};`}
  }
`;

const ButtonGroup = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.space[1]};
  grid-auto-flow: row;
  grid-auto-rows: auto;
  width: 100%;
  margin: ${({ theme }) => theme.space[2]} 0;

  ${Button}, ${LinkButton}, ${ExternalLinkButton} {
    width: 100%;
    height: 100%;
    margin-top: 0;
    display: block;
  }

  @media ${({ theme }) => theme.device.mobileL} {
    width: auto;
    grid-auto-flow: ${({ vertical }) => (vertical ? 'row' : 'column')};
    grid-auto-columns: auto;
    justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  }
`;

export default {
  Button,
  LinkButton,
  ExternalLinkButton,
  ButtonInner,
  ButtonGroup,
};
