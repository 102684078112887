import styled from 'styled-components';

const SelectInput = styled.select`
  font-family: ${({ theme }) => theme.fontFamily};
  padding: ${({ theme }) => theme.space[3]};
  font-size: ${({ theme }) => theme.fontSize[3]};
  border: 1px solid
    ${({ hasError, theme }) => (hasError ? theme.danger : theme.inputBorder)};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  background: #fff;
  width: 100%;
  display: block;
  box-sizing: border-box;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  ::placeholder {
    color: ${({ theme }) => theme.inputPlaceholder};
  }
  /* Arrow */
  background-image: linear-gradient(
      45deg,
      transparent 50%,
      ${({ theme }) => theme.inputBorder} 50%
    ),
    linear-gradient(
      135deg,
      ${({ theme }) => theme.inputBorder} 50%,
      transparent 50%
    );
  background-position: calc(100% - 28px) calc(0.8em + 1rem),
    calc(100% - 20px) calc(0.8em + 1rem);
  background-size: 8px 8px, 8px 8px;
  background-repeat: no-repeat;

  @media ${({ theme }) => theme.device.tablet} {
    background-position: calc(100% - 28px) calc(1em + 1rem),
      calc(100% - 20px) calc(1em + 1rem);
  }
`;

export default { SelectInput };
