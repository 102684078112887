import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import {
  getMonthSelectOptions,
  getYearSelectOptions,
  keepParams,
  safeUserProfileField,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';
import Button, { ButtonGroup } from '../../common/Button';
import CheckboxInput from '../../common/CheckboxInput';
import Form from '../../common/Form';
import { Styles as InputStyles } from '../../common/Input';
import TextInput from '../../common/TextInput';
import Textarea from '../../common/Textarea';
import SelectInput from '../../common/SelectInput';

import Error from '../../common/ErrorMessage';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('We need a name to keep the account secure'),
  lastName: Yup.string().required('We need a name to keep the account secure'),
  email: Yup.string()
    .email("Whoops! That doesn't look like a valid email")
    .required('We need an email address to keep the account secure'),
});

const AdminEditUserForm = ({ id, initialData }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();

  const user = initialData;

  const initialValues = {
    firstName: safeUserProfileField(user, 'firstName'),
    lastName: safeUserProfileField(user, 'lastName'),
    email: user.email || undefined,
    roleAdmin: user.roles && user.roles.includes('ADMIN'),
    roleUser: user.roles && user.roles.includes('USER'),
    phone: safeUserProfileField(user, 'phone'),
    dobMonth: safeUserProfileField(user, 'dobMonth'),
    dobYear: safeUserProfileField(user, 'dobYear'),
    postcode: safeUserProfileField(user, 'postcode'),
    notes: user.notes || undefined,
    enableBetaFeatures: user.enableBetaFeatures,
  };

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const update = {
            'profile.firstName': values.firstName,
            'profile.lastName': values.lastName,
            'profile.phone': values.phone,
            'profile.postcode': values.postcode,
            'profile.dobMonth': values.dobMonth,
            'profile.dobYear': values.dobYear,
            email: values.email,
            roles: [],
            notes: values.notes,
            enableBetaFeatures: values.enableBetaFeatures,
          };

          if (values.roleAdmin) update.roles.push('ADMIN');
          if (values.roleUser) update.roles.push('USER');

          await API.post(`users/update/${id}`, update)
            .then(() => {
              toast.success('User updated');
              history.push(keepParams(`/admin/users`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="First name"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter a first name"
                  component={TextInput}
                  isRequired
                />
                <Field
                  label="Last name"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter a last name"
                  component={TextInput}
                  isRequired
                />
                <Field
                  label="Email address"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Enter an email address"
                  component={TextInput}
                  isRequired
                />
                <Field
                  label="Phone number"
                  id="phone"
                  name="phone"
                  placeholder="Phone number"
                  component={TextInput}
                />
                <Field
                  label="Postcode"
                  id="postcode"
                  name="postcode"
                  placeholder="Postcode"
                  component={TextInput}
                />
                <Field
                  label="Birth month"
                  id="dobMonth"
                  name="dobMonth"
                  component={SelectInput}
                  options={getMonthSelectOptions()}
                  placeholder="Select birth month"
                />
                <Field
                  label="Birth year"
                  id="dobYear"
                  name="dobYear"
                  component={SelectInput}
                  options={getYearSelectOptions({ skip: 18 })}
                  placeholder="Select birth year"
                />

                <Field
                  label="Notes"
                  id="notes"
                  name="notes"
                  placeholder="Notes"
                  component={Textarea}
                />

                <InputStyles.InputGroup>
                  <h4>Roles</h4>
                  <Field
                    label="Admin"
                    id="roleAdmin"
                    name="roleAdmin"
                    component={CheckboxInput}
                  />
                  <Field
                    label="User"
                    id="roleUser"
                    name="roleUser"
                    component={CheckboxInput}
                    disabled
                  />
                  <Field
                    label="Enable Beta Features"
                    id="enableBetaFeatures"
                    name="enableBetaFeatures"
                    component={CheckboxInput}
                  />
                </InputStyles.InputGroup>
                <ButtonGroup>
                  <Button
                    label="Update User"
                    type="submit"
                    mode="primary"
                    loadingLabel="Updating User"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/users'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

AdminEditUserForm.propTypes = {
  id: PropTypes.string.isRequired,

  initialData: PropTypes.shape({
    profile: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
      phone: PropTypes.string,
      address: PropTypes.shape({
        postcode: PropTypes.string,
      }),
      dobMonth: PropTypes.string,
      dobYear: PropTypes.string,
    }),
    email: PropTypes.string.isRequired,
    roles: PropTypes.arrayOf(PropTypes.String),
    notes: PropTypes.string,
  }),
};

AdminEditUserForm.defaultProps = {
  initialData: {},
};

export default AdminEditUserForm;
