import React from 'react';
import PropTypes from 'prop-types';

import Nav from '../Nav';
import SignOut from '../../auth/SignOut';
import CloseButton from '../CloseButton';
import MarketingSiteLink from '../MarketingSiteLink';
import Styles from './Styles';
import config from '../../../config';

const { supportEmail } = config;
const SupportLink = () => <a href={`mailto:${supportEmail}`}>Support</a>;

const MobileMenu = ({ isOpen, closeAction, isAuthenticated }) => {
  return (
    <Styles.MobileMenu isOpen={isOpen}>
      <header>
        <CloseButton closeAction={() => closeAction()} />
      </header>
      <ul>
        <Nav
          isAuthenticated={isAuthenticated}
          onChangeRoute={() => closeAction()}
          navItems={[
            {
              label: 'Sign in',
              path: 'sign-in',
              notAuthenticatedRoute: true,
            },
            {
              label: 'Sign up',
              path: 'sign-up',
              notAuthenticatedRoute: true,
            },
            {
              label: 'Admin',
              path: '/admin',
              privateRoute: true,
              adminRoute: true,
            },
            {
              label: 'Refer a friend',
              path: '/account/referrals',
              privateRoute: true,
            },

            {
              key: 'how_it_works',
              privateRoute: true,
              component: (
                <MarketingSiteLink path="/help" label="How it works" />
              ),
            },
            { label: 'Rewards', path: '/rewards', privateRoute: true },
            { label: 'Overpay', path: '/overpay', privateRoute: true },
            { label: 'Switch', path: '/switch', privateRoute: true },
            { label: 'My Mortgage', path: '/mortgage', privateRoute: true },
            {
              label: 'Account settings',
              path: '/account',
              privateRoute: true,
            },
            {
              label: 'Mortgage settings',
              path: '/mortgage/settings',
              privateRoute: true,
            },
            {
              label: 'Transaction history',
              path: '/history',
              privateRoute: true,
            },
            {
              label: 'Reward history',
              path: '/rewards/history',
              privateRoute: true,
            },
            {
              label: 'Support',
              component: supportEmail && <SupportLink />,
              privateRoute: true,
            },
          ]}
        />
      </ul>
      {isAuthenticated && (
        <footer>
          <SignOut buttonSize="s" closeAction={() => closeAction()} />
        </footer>
      )}
    </Styles.MobileMenu>
  );
};

MobileMenu.propTypes = {
  isOpen: PropTypes.bool,
  closeAction: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

MobileMenu.defaultProps = {
  isOpen: false,
  isAuthenticated: false,
};

export default MobileMenu;
