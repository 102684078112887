import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { getMonthlyOverpaymentRequired } from '@rateswitch/amm-utils';

import {
  formatOrdinalNumber,
  getDateFromMonthAndYear,
} from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Panel from '../../common/Panel';
import Message from '../../common/Message';

import Styles from './Styles';

const getBirthdaySelectOptions = ({ currentAge, currentFreedomAge }) => {
  const options = [];
  for (let i = currentAge + 1; i < currentFreedomAge; i += 1) {
    options.push({
      label: formatOrdinalNumber(i),
      value: i,
    });
  }
  return options;
};

const ScenarioList = ({
  remainingBalance,
  interestRate,
  monthlyPayment,
  dobMonth,
  dobYear,
  runOverpaymentCalculation,
  currentFreedomAge,
}) => {
  const birthday = getDateFromMonthAndYear(dobMonth, dobYear);
  const currentAge = dayjs(new Date()).diff(birthday, 'year');

  const [isLoading, setIsLoading] = React.useState(false);
  const [freedomBirthday, setFreedomBirthday] = React.useState(currentAge + 1);

  const showBirthdayScenario = dobMonth && dobYear;

  const runBirthdayScenario = async () => {
    setIsLoading(true);
    const dob = dayjs(getDateFromMonthAndYear(dobMonth, dobYear)).date(1);
    const currentAgeInMonths = dayjs(new Date()).date(1).diff(dob, 'month');
    // Plus 12 months to clear balance by the age set, not at it
    const monthsToFreedom = freedomBirthday * 12 - (currentAgeInMonths + 1);

    const monthlyOverpayment = getMonthlyOverpaymentRequired({
      remainingBalance,
      monthlyPayment,
      interestRate,
      monthToClearBy: monthsToFreedom,
    });

    runOverpaymentCalculation({
      overpaymentAmount: monthlyOverpayment,
      overpaymentInterval: 'monthly',
    }).finally(() => setIsLoading(false));
  };

  const updateFreedomBirthday = (e) => {
    e.preventDefault();
    const age = e.target.value;
    setFreedomBirthday(age);
  };

  const birthdayOptions = getBirthdaySelectOptions({
    currentAge,
    currentFreedomAge,
  });

  return (
    <div>
      <Panel heading="Choose a freedom birthday" smallHeader>
        {showBirthdayScenario ? (
          <Styles.Scenario>
            <p>
              I want to be mortgage free by my{' '}
              <select
                value={freedomBirthday}
                onChange={(e) => updateFreedomBirthday(e)}
              >
                {birthdayOptions.map(({ value, label }) => (
                  <option key={label} value={value}>
                    {label}
                  </option>
                ))}
              </select>{' '}
              birthday
            </p>
            <Button
              label="Show me how"
              loadingLabel="Calculating"
              mode="primary"
              onClick={() => runBirthdayScenario()}
              isLoading={isLoading}
            />
          </Styles.Scenario>
        ) : (
          <Message size="l" center mode="warning">
            <Link to="/account">
              Add your birth month and year to your profile
            </Link>{' '}
            to use this feature
          </Message>
        )}
      </Panel>
    </div>
  );
};

ScenarioList.propTypes = {
  remainingBalance: PropTypes.number.isRequired,
  monthlyPayment: PropTypes.number.isRequired,
  interestRate: PropTypes.number.isRequired,
  runOverpaymentCalculation: PropTypes.func.isRequired,
  dobMonth: PropTypes.string,
  dobYear: PropTypes.string,
  currentFreedomAge: PropTypes.number,
};

ScenarioList.defaultProps = {
  dobMonth: undefined,
  dobYear: undefined,
  currentFreedomAge: undefined,
};

export default ScenarioList;
