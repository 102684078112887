/* eslint-disable camelcase */
import React from 'react';

export default (initialValue) => {
  const [retailers, setRetailers] = React.useState(initialValue);

  return {
    retailers,
    setRetailers,

    addRetailer: (newRetailer) => {
      setRetailers([...retailers, newRetailer]);
    },

    deleteRetailer: (retailer_id) => {
      setRetailers((prevRetailers) =>
        prevRetailers.filter((retailer) => retailer._id !== retailer_id),
      );
    },
  };
};
