import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  convertPercentToDecimal,
  convertDecimalToPercent,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../../../lib/api';

import OnboardingViewActions from '../../../OnboardingViewActions';
import Form from '../../../../common/Form';
import PercentageInput from '../../../../common/PercentageInput';
import Message from '../../../../common/Message';
import Error from '../../../../common/ErrorMessage';

import LayoutWithImage from '../../../../layout/LayoutWithImage';
import checklistImg from '../../../../../images/checklist.svg';
import config from '../../../../../config';
import Button from '../../../../common/Button';

const validationSchema = Yup.object().shape({
  interestRate: Yup.number().required('Enter your interest rate'),
});

const OnboardingInterestRate = ({
  mortgageId,
  mortgage,
  setSelectedStep,
  setDisplayError,
  setMortgageData,
  displayError,
}) => {
  const initialValues = {
    interestRate:
      (mortgage && convertDecimalToPercent(mortgage.interestRate)) || '2.50',
  };

  return (
    <LayoutWithImage image={checklistImg}>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`mortgages/update/${mortgageId}`, {
            interestRate: convertPercentToDecimal(values.interestRate),
          })
            .then((res) => {
              setSelectedStep('payments');
              setMortgageData(res.data);
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
        render={({ isSubmitting, setFieldValue }) => {
          const lenderSvr =
            (mortgage && mortgage.lender && mortgage.lender.svr) ||
            config.svrInterestRate;
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <h2>What is your current interest rate?</h2>
                <Field
                  label="Enter your current rate"
                  id="interestRate"
                  name="interestRate"
                  component={PercentageInput}
                />
                <Message big>
                  Don&apos;t worry if you don&apos;t know this exactly, enter
                  your best guess and update it later.
                </Message>
                <Message big>
                  If you have no idea at all, you can use your lender&apos;s
                  Standard Variable Rate (SVR) for now.
                  <br />
                  <Button
                    label={
                      mortgage.lender && mortgage.lender.name
                        ? `Use ${mortgage.lender.name}'s SVR`
                        : 'Use default SVR'
                    }
                    size="s"
                    block
                    onClick={() => {
                      setFieldValue(
                        'interestRate',
                        convertDecimalToPercent(lenderSvr),
                      );
                    }}
                  />
                </Message>
                <Error error={displayError} />
                <OnboardingViewActions
                  previousView="fixedRate"
                  isSubmitting={isSubmitting}
                  setSelectedStep={setSelectedStep}
                />
              </fieldset>
            </Form>
          );
        }}
      />
    </LayoutWithImage>
  );
};

OnboardingInterestRate.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    interestRate: PropTypes.number,
    lender: PropTypes.shape({
      name: PropTypes.string,
      svr: PropTypes.number,
    }),
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  setMortgageData: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingInterestRate.defaultProps = {
  displayError: undefined,
};

export default OnboardingInterestRate;
