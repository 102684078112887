/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import SelectInput from '../../common/SelectInput';

const getOptions = ({ includeBlank, blankLabel }) => {
  const statusOptions = [
    { label: 'Pending', value: 'PENDING' },
    { label: 'Confirmed', value: 'CONFIRMED' },
    { label: 'Paid', value: 'PAID' },
    { label: 'Declined', value: 'DECLINED' },
  ];

  const options = [];

  if (includeBlank) {
    options.push({ label: blankLabel, value: 'ALL', key: 'blank' });
    options.push({ label: '---', disabled: true, key: 'divider-1' });
  }
  options.push(...statusOptions);
  return options;
};

const RewardStatusSelectComponent = ({
  label,
  id,
  name,
  isLoading,
  placeholder,
  isRequired,
  disabled,
  noFormik,
  includeBlank,
  blankLabel,
  value,
  setValue,
  onChange,
  ...props
}) => {
  const options = getOptions({ includeBlank, blankLabel });

  if (noFormik) {
    return (
      <SelectInput
        label={label}
        id={id}
        name={name}
        placeholder={isLoading ? 'Loading options...' : placeholder}
        isRequired={isRequired}
        disabled={isLoading || disabled}
        options={options}
        value={value}
        onChange={(e) => {
          if (setValue) setValue(e.target.value);
          if (onChange) onChange(e);
        }}
        {...props}
      />
    );
  }

  return (
    <>
      <Field
        label={label}
        id={id}
        name={name}
        placeholder={isLoading ? 'Loading options...' : placeholder}
        component={SelectInput}
        options={options}
        isRequired={isRequired}
        disabled={isLoading || disabled}
        {...props}
      />
    </>
  );
};

RewardStatusSelectComponent.propTypes = {
  includeBlank: PropTypes.bool,
  blankLabel: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  isRequired: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  noFormik: PropTypes.bool,
  value: PropTypes.string,
  setValue: PropTypes.func,
  onChange: PropTypes.func,
};

RewardStatusSelectComponent.defaultProps = {
  includeBlank: false,
  blankLabel: 'All statuses',
  id: 'status',
  label: 'Status',
  name: 'status',
  placeholder: 'Select a status',
  isRequired: false,
  disabled: false,
  isLoading: false,
  noFormik: false,
  value: undefined,
  setValue: undefined,
  onChange: undefined,
};

export default RewardStatusSelectComponent;
