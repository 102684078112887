import React from 'react';
import CreateRewardForm from './Form';
import AdminNav from '../../admin/AdminNav';

const CreateRewardView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Log a reward</h1>
      <CreateRewardForm />
    </div>
  );
};

export default CreateRewardView;
