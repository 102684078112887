import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { formatDateTime } from '@homamo/meadow-utils';

import Panel from '../../common/Panel';
import { ConfirmButton } from '../../common/Confirm';
import ErrorMessage from '../../common/ErrorMessage';
import API, { handleApiError } from '../../../lib/api';

const AdminEditUserSuspensionComponent = ({ userId, user, onUpdateUser }) => {
  const [displayError, setDisplayError] = React.useState();
  const [isLoading, setIsLoading] = React.useState();

  const suspendUser = async () => {
    setIsLoading(true);
    await API.post(`users/suspend/${userId}`)
      .then((res) => {
        if (res.data && res.data._id) onUpdateUser(res.data);
        toast.success('User suspended');
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  const reinstateUser = async () => {
    setIsLoading(true);
    await API.post(`users/reinstate/${userId}`)
      .then((res) => {
        if (res.data && res.data._id) onUpdateUser(res.data);
        toast.success('User reinstated');
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Panel>
      <ErrorMessage error={displayError} />
      {user.suspended ? (
        <>
          <p>
            This user was suspended on {formatDateTime(user.suspendedDate)}. To
            restore access to the software you need to reinstate them.
          </p>
          <ConfirmButton
            buttonLabel="Reinstate user"
            buttonSize="m"
            contentLabel="Reinstate user"
            confirmAction={() => reinstateUser()}
            confirmButtonLabel="Reinstate user"
            confirmButtonLoadingLabel="Reinstating user"
            confirmTextValue={user.email}
            modalHeading="Reinstate user"
            modalMessage="Enter the user's email address to confirm account resinstatement"
            isConfirmActionloading={isLoading}
          />
        </>
      ) : (
        <>
          <p>
            To temporarily block access to the software, you can suspend this
            user.
          </p>
          <ConfirmButton
            buttonLabel="Suspend user"
            buttonMode="danger"
            buttonSize="m"
            contentLabel="Suspend user"
            confirmAction={() => suspendUser()}
            confirmButtonLabel="Suspend user"
            confirmButtonLoadingLabel="Suspending user"
            confirmTextValue={user.email}
            isConfirmActionloading={isLoading}
            modalHeading="Suspend user"
            modalMessage="Enter the user's email address to confirm account suspension"
          />
        </>
      )}
    </Panel>
  );
};

AdminEditUserSuspensionComponent.propTypes = {
  userId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    suspended: PropTypes.bool,
    suspendedDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
  }).isRequired,
  onUpdateUser: PropTypes.func.isRequired,
};

export default AdminEditUserSuspensionComponent;
