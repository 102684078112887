import styled from 'styled-components';

const Content = styled.div`
  max-width: ${({ theme }) => theme.space[18]};
  margin: 0 auto;
  padding: ${({ theme }) => theme.space[1]};

  @media ${(props) => props.theme.device.tablet} {
    padding: ${({ theme }) => theme.space[2]};
  }
`;

const StagingWarning = styled.div`
  background-color: ${({ theme }) => theme.color['yellow-600']};
  padding: ${({ theme }) => theme.space[0]};
  text-align: center;
  text-transform: uppercase;
  position: fixed;
  width: 100%;
  opacity: 0.8;
`;

const MaintenanceWarning = styled.div`
  background-color: ${({ theme }) => theme.color['red-500']};
  color: white;
  padding: ${({ theme }) => theme.space[0]};
  text-align: center;
  position: fixed;
  width: 100%;
  opacity: 0.8;
`;

export default {
  Content,
  StagingWarning,
  MaintenanceWarning,
};
