import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../components/layout/Layout';
import Transaction from '../components/transaction/Transaction';
import AnalyticsWrapper from '../components/common/AnalyticsWrapper';

const TransactionView = () => {
  const { id } = useParams();

  return (
    <AnalyticsWrapper>
      <Layout title="Transaction">
        <Transaction id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default TransactionView;
