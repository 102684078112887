import React from 'react';
import AdminCreateUserForm from './Form';
import Message from '../../common/Message';
import AdminNav from '../../admin/AdminNav';

const AdminCreateUserView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Create User</h1>
      <Message>The new user will be sent an email with a link to login</Message>
      <AdminCreateUserForm />
    </div>
  );
};

export default AdminCreateUserView;
