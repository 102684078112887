import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminCreateReward from '../../../components/reward/AdminCreateReward';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const CreateRewardView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Create reward">
        <AdminCreateReward />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default CreateRewardView;
