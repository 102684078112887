import React from 'react';
import PropTypes from 'prop-types';
import inView from 'in-view';

import {
  getFreedomAge,
  getFreedomDate,
  getInterestSaved,
  getRemainingTerm,
  getTermReduction,
  getTotalInterest,
  getYearToYearBalance,
} from '@rateswitch/amm-utils';

// import Error from '../_common/ErrorMessage';

import ScenarioList from '../ScenarioList';
import CalculatorPaymentInput from '../CalculatorPaymentInput';
import CalculatorResultsMessage from '../CalculatorResultsMessage';
import CalculatorResultsTable from '../CalculatorResultsTable';
import PageIntro from '../../common/PageIntro';
import PageContent from '../../layout/PageContent';
import AssumptionsModal from '../AssumptionsModal';
import Message from '../../common/Message';
import MarketingSiteLink from '../../common/MarketingSiteLink';

const buildScenario = async ({
  mortgage,
  userDobMonth,
  userDobYear,
  overpaymentAmount,
  overpaymentInterval,
}) => {
  const { monthlyPayment, interestRate, remainingBalance } = mortgage;

  const scenarioTotalMonthlyPayment =
    overpaymentInterval === 'monthly'
      ? monthlyPayment + overpaymentAmount
      : monthlyPayment;

  const scenarioRemainingBalance =
    overpaymentInterval === 'monthly'
      ? remainingBalance
      : remainingBalance - overpaymentAmount;

  const scenarioRemainingTerm = getRemainingTerm({
    remainingBalance,
    interestRate,
    monthlyPayment,
    overpaymentAmount,
    overpaymentInterval,
  });

  const scenarioTotalInterest = getTotalInterest({
    remainingTerm: scenarioRemainingTerm,
    monthlyPayment: scenarioTotalMonthlyPayment,
    remainingBalance: scenarioRemainingBalance,
  });

  return {
    freedomAge: getFreedomAge({
      userDobMonth,
      userDobYear,
      remainingTerm: scenarioRemainingTerm,
    }),

    freedomDate: getFreedomDate({ remainingTerm: scenarioRemainingTerm }),

    totalInterest: scenarioTotalInterest,
    totalCost: remainingBalance + scenarioTotalInterest,

    balances: getYearToYearBalance({
      remainingBalance,
      interestRate,
      monthlyPayment,
      overpaymentAmount,
      overpaymentInterval,
    }),

    interestSaved: getInterestSaved({
      remainingBalance,
      interestRate,
      monthlyPayment,
      overpaymentAmount,
      overpaymentInterval,
    }),

    termReduction: getTermReduction({
      remainingBalance,
      interestRate,
      monthlyPayment,
      overpaymentAmount,
      overpaymentInterval,
    }),
  };
};

const setWorkingScenario = async ({
  setData,
  overpaymentAmount,
  overpaymentInterval,
  mortgage,
  userDobMonth,
  userDobYear,
}) => {
  const scenario = await buildScenario({
    overpaymentAmount,
    overpaymentInterval,
    mortgage,
    userDobMonth,
    userDobYear,
  });
  setData(scenario);
};

const OverpayView = ({ mortgage, user, fetchData }) => {
  // prettier-ignore
  const {
    monthlyPayment,
    monthlyOverpayment,
    remainingBalance,
    interestRate,
  } = mortgage;

  const mortgageId = mortgage && mortgage._id;

  const userDobMonth =
    (user && user.profile && user.profile.dobMonth) || undefined;
  const userDobYear =
    (user && user.profile && user.profile.dobYear) || undefined;

  const [showScenarioList] = React.useState(
    userDobMonth !== undefined && userDobYear !== undefined,
  );

  // prettier-ignore
  const [
    workingOverpaymentAmount,
    setWorkingOverpaymentAmount,
  ] = React.useState(monthlyOverpayment || 10000);

  // prettier-ignore
  const [
    workingOverpaymentInterval,
    setWorkingOverpaymentInterval,
  ] = React.useState('monthly');

  // prettier-ignore
  const defaultScenarioStructure = {
    freedomAge: undefined,
    freedomYear: undefined,
    freedomDate: undefined,
    totalInterest: undefined,
    totalCost: undefined,
    balances: [],
  };

  const [current, setCurrent] = React.useState(defaultScenarioStructure);
  const [scenario, setScenario] = React.useState(defaultScenarioStructure);

  React.useEffect(() => {
    setWorkingScenario({
      setData: setCurrent,
      overpaymentAmount: 0,
      overpaymentInterval: 'monthly',
      mortgage,
      userDobMonth,
      userDobYear,
    });
    setWorkingScenario({
      setData: setScenario,
      overpaymentAmount: workingOverpaymentAmount,
      overpaymentInterval: workingOverpaymentInterval,
      mortgage,
      userDobMonth,
      userDobYear,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runOverpaymentCalculation = async ({
    overpaymentAmount,
    overpaymentInterval,
  }) => {
    const calcScenario = await buildScenario({
      overpaymentAmount,
      overpaymentInterval,
      mortgage,
      userDobMonth,
      userDobYear,
    });

    setWorkingOverpaymentAmount(overpaymentAmount);
    setWorkingOverpaymentInterval(overpaymentInterval);
    setScenario(calcScenario);

    // Only scroll if not 80% in view
    inView.threshold(0.8);
    if (!inView.is(document.querySelector('#calculator-results'))) {
      document
        .getElementById('calculator-results')
        .scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <PageContent>
      <PageIntro
        heading="Explore the power of overpayments"
        text="Use our calculator to see how you can accelerate to mortgage freedom"
        compact
      />
      <CalculatorResultsMessage
        mortgageId={mortgageId}
        interestRate={interestRate}
        overpaymentAmount={workingOverpaymentAmount}
        showSetOverpaymentButton={
          workingOverpaymentAmount !== monthlyOverpayment
        }
        overpaymentInterval={workingOverpaymentInterval}
        interestSaved={scenario.interestSaved}
        termReduction={scenario.termReduction}
        freedomAge={scenario.freedomAge}
        remainingBalance={remainingBalance}
        monthlyOverpayment={workingOverpaymentAmount}
        monthlyPayment={monthlyPayment}
        fetchData={fetchData}
      />
      <Message size="l" center>
        Want to know more about how overpayments work?{' '}
        <MarketingSiteLink
          path="/overpayment-guide"
          label="View our support article"
        />
      </Message>

      {showScenarioList ? (
        <>
          <PageIntro
            heading="Try another scenario"
            text="Enter the age you want to be mortgage free..."
            compact
          />
          <ScenarioList
            remainingBalance={remainingBalance}
            interestRate={interestRate}
            monthlyPayment={monthlyPayment}
            dobMonth={user.profile && user.profile.dobMonth}
            dobYear={user.profile && user.profile.dobYear}
            currentFreedomDate={current.freedomDate}
            currentFreedomAge={current.freedomAge}
            runOverpaymentCalculation={runOverpaymentCalculation}
          />
          <PageIntro text="...or try a different amount" compact />
        </>
      ) : (
        <PageIntro
          heading="Try another scenario"
          text="Select a different amount and see when you'll be mortgage free"
          compact
        />
      )}
      <CalculatorPaymentInput
        amount={workingOverpaymentAmount}
        interval="monthly"
        heading="Enter a monthly overpayment"
        runOverpaymentCalculation={runOverpaymentCalculation}
        scenarioListIsShown={showScenarioList}
      />
      <PageIntro
        heading="Your road to mortgage freedom"
        text="Here's a yearly comparison that shows the power of your overpayments"
        compact
      />
      <CalculatorResultsTable current={current} scenario={scenario} />

      <Message center>
        Our projections are based on the information you&apos;ve provided and
        our key assumptions
        <AssumptionsModal
          buttonLabel="View key assumptions"
          remainingBalance={remainingBalance}
          interestRate={interestRate}
          monthlyPayment={monthlyPayment}
          monthlyOverpayment={workingOverpaymentAmount}
        />
      </Message>
    </PageContent>
  );
};

OverpayView.propTypes = {
  mortgage: PropTypes.shape({
    _id: PropTypes.string,
    monthlyOverpayment: PropTypes.number,
    lender: PropTypes.shape({
      name: PropTypes.string,
    }),
    accountNumber: PropTypes.string,
    initialBalance: PropTypes.number,
    remainingBalance: PropTypes.number,
    estimatedPropertyValue: PropTypes.number,
    interestRate: PropTypes.number,
    monthlyPayment: PropTypes.number,
    fixedRate: PropTypes.string,
    fixedRateEndDate: PropTypes.string,
  }),
  user: PropTypes.shape({
    profile: PropTypes.shape({
      dobMonth: PropTypes.string,
      dobYear: PropTypes.string,
    }),
  }),
  fetchData: PropTypes.func,
};

OverpayView.defaultProps = {
  mortgage: {},
  user: {},
  fetchData: undefined,
};

export default OverpayView;
