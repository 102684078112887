import styled from 'styled-components';

const Definition = styled.span`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize[2]};

  dt {
    color: ${({ theme }) => theme.color['gray-600']};
    font-weight: 300;
    width: ${({ termWidth }) => termWidth || 'auto'};
    line-height: 1;
  }

  dt::after {
    content: ': ';
  }

  dd {
    margin-left: ${({ theme }) => theme.space[3]};
    font-weight: 500;
    color: ${({ theme }) => theme.color['gray-800']};
    line-height: 1;
    max-width: ${({ theme }) => theme.space[14]};
    word-break: break-word;

    img {
      max-height: ${({ theme }) => theme.space[9]};
      max-width: ${({ theme }) => theme.space[10]};
    }
  }
`;

const DefinitionList = styled.dl`
  margin: ${({ theme }) => `0 0 ${theme.space[5]}`};

  ${Definition} {
    margin-bottom: ${({ theme }) => theme.space[4]};
    font-size: ${({ large, theme }) =>
      large ? theme.fontSize[3] : theme.fontSize[2]};

    dt {
      width: ${({ termWidth }) => (termWidth ? '80px' : 'auto')};

      @media ${({ theme }) => theme.device.mobileM} {
        width: ${({ termWidth }) => (termWidth ? '120px' : 'auto')};
      }

      @media ${({ theme }) => theme.device.mobileL} {
        width: ${({ termWidth }) => termWidth || 'auto'};
      }
    }
  }
`;

export default { Definition, DefinitionList };
