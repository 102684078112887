import styled from 'styled-components';
import { Styles as BadgeStyles } from '../Badge';
import { Styles as ButtonStyles } from '../Button';

const AccountMenuDropDown = styled.div`
  /* Hidden by default */
  display: none;
  overflow: hidden;
  padding: 0;
  border-radius: ${({ theme }) => theme.borderRadius[2]};
  box-shadow: ${({ theme }) => theme.boxShadow[2]};
  background: ${({ theme }) => theme.color.white};
  list-style: none;
  text-align: left;
  position: absolute;
  width: 100%;
  min-width: ${({ theme }) => theme.space[10]};
  right: 0px;
  z-index: 19;

  a,
  button {
    height: 100%;
    width: 100%;
    display: block;
    padding: ${({ theme }) => `${theme.space[4]} ${theme.space[3]}`};
    line-height: 1;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize[2]};
    color: ${({ theme }) => theme.color['gray-800']};
    font-family: ${({ theme }) => theme.fontFamily};

    svg {
      margin-right: ${({ theme }) => theme.space[1]};
    }
  }

  ${ButtonStyles.Button} {
    background: 0;
    border: 0;
    border-radius: 0;
    text-align: left;
    cursor: pointer;
    font-weight: 400;
  }

  ${ButtonStyles.ButtonInner} {
    justify-content: flex-start;
    padding: 0;
    font-size: ${({ theme }) => theme.fontSize[2]};
  }

  ${BadgeStyles.Badge} {
    margin-left: ${({ theme }) => theme.space[2]};
    font-size: ${({ theme }) => theme.fontSize[1]};
    color: ${({ theme }) => theme.color.white};
    padding: ${({ theme }) => theme.space[2]};
  }

  a:hover,
  button:hover {
    color: ${({ theme }) => theme.color['gray-900']};
    background: ${({ theme }) => theme.color['gray-100']};
    text-decoration: none;
  }
`;

const AccountMenu = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius[2]};

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.color['gray-800']};
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: ${({ theme }) => theme.fontSize[2]};
    padding: ${({ theme }) =>
      `${theme.space[2]} 0 ${theme.space[2]} ${theme.space[3]}`};

    svg {
      margin-left: ${({ theme }) => theme.space[2]};
      height: ${({ theme }) => theme.fontSize[2]};
      width: ${({ theme }) => theme.fontSize[2]};
      color: ${({ theme }) => theme.color['gray-800']};
    }

    @media ${({ theme }) => theme.device.laptop} {
      font-size: ${({ theme }) => theme.fontSize[3]};
    }
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color['gray-100']};

    @media ${({ theme }) => theme.device.tablet} {
      ${AccountMenuDropDown} {
        display: block;

        a,
        a:hover {
          border: 0;
        }
      }
    }
  }
`;

export default {
  AccountMenu,
  AccountMenuDropDown,
};
