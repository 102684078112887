import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { convertToPence, convertToPounds } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';
import Button from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import Error from '../../common/ErrorMessage';
import CurrencyInput from '../../common/CurrencyInput';
import Message from '../../common/Message';
import LenderSelect from '../../lender/LenderSelect';

const validationSchema = Yup.object().shape({
  lender: Yup.string().required('Select your lender from the list'),
  initialBalance: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('You need to enter your balance'),
  estimatedPropertyValue: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('Enter an estimated property valuation'),

  accountNumber: Yup.string(),
});

const MortgageSettingsDetailsForm = ({ initialData, refetchData }) => {
  const [displayError, setDisplayError] = React.useState();

  const mortgageId = initialData._id;

  const initialValues = {
    lender: initialData.lender_id || undefined,
    initialBalance: initialData.initialBalance
      ? convertToPounds(initialData.initialBalance)
      : undefined,
    estimatedPropertyValue: initialData.estimatedPropertyValue
      ? convertToPounds(initialData.estimatedPropertyValue)
      : undefined,
    accountNumber: initialData.accountNumber || undefined,
  };

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`mortgages/update/${mortgageId}`, {
            lender_id: values.lender,
            accountNumber: values.accountNumber,
            initialBalance: convertToPence(values.initialBalance),
            estimatedPropertyValue: convertToPence(
              values.estimatedPropertyValue,
            ),
          })
            .then(() => {
              toast.success('Settings updated!');
              refetchData();
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ dirty, isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <LenderSelect />
                <Field
                  label="Mortgage account number"
                  id="accountNumber"
                  name="accountNumber"
                  component={TextInput}
                  helpMessage="When you’ve earned a reward we use your account
                              number to contribute to paying off your mortgage"
                />
                <Field
                  label="Initial balance"
                  id="initialBalance"
                  name="initialBalance"
                  component={CurrencyInput}
                  isRequired
                />
                <Message mode="warning">
                  Updating your initial balance may affect your remaining
                  balance. You can adjust your remaining balance in your
                  history.
                </Message>
                <Field
                  label="Estimated property valuation"
                  id="estimatedPropertyValue"
                  name="estimatedPropertyValue"
                  component={CurrencyInput}
                  isRequired
                />
                <Button
                  label="Update settings"
                  type="submit"
                  mode="primary"
                  loadingLabel="Updating settings"
                  isLoading={isSubmitting}
                  disabled={!dirty}
                />
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

MortgageSettingsDetailsForm.propTypes = {
  initialData: PropTypes.shape({
    _id: PropTypes.string,
    lender_id: PropTypes.string,
    estimatedPropertyValue: PropTypes.number,
    initialBalance: PropTypes.number,
    accountNumber: PropTypes.string,
  }),
  refetchData: PropTypes.func.isRequired,
};

MortgageSettingsDetailsForm.defaultProps = {
  initialData: {},
};

export default MortgageSettingsDetailsForm;
