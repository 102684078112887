import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@homamo/meadow-utils';

import Message from '../../common/Message';
import Panel from '../../common/Panel';
import SendReferralLinkModal from '../SendReferralLink';
import config from '../../../config';
import Styles from './Styles';
import referralImg from '../../../images/referral.svg';

const ReferralMessage = ({ userId, hideHeader }) => {
  const referralLink = `${config.appUrl}/referral?id=${userId}`;
  return (
    <Styles.ReferralMessage>
      <Panel heading={!hideHeader && 'Refer a friend'} smallHeader>
        <img src={referralImg} alt="" />
        <div className="inner">
          <p>
            Earn a {formatCurrency(config.rewardDefaults.referral)} reward for
            every friend you refer that completes a switch with us
          </p>
          <p>
            <small>Your personal referral link is:</small>
          </p>
          <Message wrapLinks>
            <a href={referralLink}>{referralLink}</a>
          </Message>
          <SendReferralLinkModal referredByUserId={userId} />
        </div>
      </Panel>
    </Styles.ReferralMessage>
  );
};

ReferralMessage.propTypes = {
  userId: PropTypes.string.isRequired,
  hideHeader: PropTypes.bool,
};

ReferralMessage.defaultProps = {
  hideHeader: false,
};

export default ReferralMessage;
