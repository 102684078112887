import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../context/auth';

const NotAuthorisedView = ({ pathname, message }) => {
  const { isAuthenticated } = useAuth();

  return (
    <div>
      <h1>403. Not Authorised</h1>
      <p>You don&apos;t have access to &apos;{pathname}&apos;</p>
      {message && <p>{message}</p>}
      <p>
        <Link to="/">
          {isAuthenticated ? 'Return to your dashboard' : 'Return to sign in'}
        </Link>
      </p>
    </div>
  );
};

NotAuthorisedView.propTypes = {
  pathname: PropTypes.string.isRequired,
  message: PropTypes.string,
};

NotAuthorisedView.defaultProps = {
  message: undefined,
};

export default NotAuthorisedView;
