import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';

import Form from '../../../Form';
import TextInput from '../../../TextInput';
import Button from '../../../Button';
import Styles from './Styles';

const SearchComponent = ({
  searchTerm,
  setSearchTerm,
  placeholder,
  isLoading,
}) => {
  return (
    <Formik
      onSubmit={async (values) => {
        setSearchTerm(values.searchTerm);
      }}
      initialValues={{
        searchTerm: searchTerm || '',
      }}
    >
      {({ handleSubmit, setFieldValue }) => {
        return (
          <Styles.Search>
            <Form>
              <fieldset disabled={isLoading} aria-busy={isLoading}>
                <Field
                  label="Search"
                  id="searchTerm"
                  type="text"
                  name="searchTerm"
                  placeholder={placeholder}
                  component={TextInput}
                  hideLabel
                  isRequired
                />
              </fieldset>
              <Styles.SearchActions>
                <Button
                  label="Search"
                  type="submit"
                  loadingLabel="Searching"
                  isLoading={isLoading}
                  size="m"
                />
                <Button
                  label="Clear"
                  size="m"
                  onClick={() => {
                    setFieldValue('searchTerm', '');
                    handleSubmit();
                  }}
                />
              </Styles.SearchActions>
            </Form>
          </Styles.Search>
        );
      }}
    </Formik>
  );
};

SearchComponent.propTypes = {
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
};

SearchComponent.defaultProps = {
  searchTerm: '',
  isLoading: false,
  placeholder: 'Search for an item',
};

export default SearchComponent;
