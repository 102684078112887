import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import API, { handleApiError } from '../../../lib/api';
import RewardView from './View';
import RewardNotFound from '../RewardNotFound';

import Loading from '../../common/Loading';

function RewardContainer({ id }) {
  const rewardId = id;
  // Data state to store the users data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [displayError, setDisplayError] = React.useState();
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    await API.get(`rewards/${rewardId}`)
      .then((res) => {
        setData(res.data);
      })
      .catch((error) => {
        handleApiError({ error, setDisplayError });
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (!rewardId) {
      return;
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardId]);

  if (isLoading) return <Loading />;
  return data && data._id ? (
    <RewardView
      data={data}
      refetchData={fetchData}
      setDisplayError={setDisplayError}
      displayError={displayError}
    />
  ) : (
    <RewardNotFound id={rewardId} displayError={displayError} />
  );
}

RewardContainer.propTypes = {
  id: PropTypes.string.isRequired,
};

export default RewardContainer;
