import React from 'react';
import { keepParams } from '@homamo/meadow-utils';
import Button from '../../common/Button';
import AdminUsersTable from '../AdminUsersTable';
import AdminNav from '../../admin/AdminNav';

const AdminUsersView = () => {
  return (
    <div>
      <AdminNav />
      <h1>Users</h1>
      <Button
        label="Create new user"
        mode="primary"
        size="s"
        linkTo={keepParams('/admin/create-user')}
      />
      <AdminUsersTable />
    </div>
  );
};

export default AdminUsersView;
