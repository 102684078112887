import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import Lender from '../../../components/lender/Lender';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const LenderView = () => {
  const { id } = useParams();

  return (
    <AnalyticsWrapper>
      <Layout title="Lender">
        <Lender id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default LenderView;
