import React from 'react';
import PropTypes from 'prop-types';
import Styles from './Styles';

const LayoutWithImage = ({ image, children, padTop }) => {
  return (
    <Styles.LayoutWithImage padTop={padTop}>
      <main>{children}</main>
      <img src={image} alt="" />
    </Styles.LayoutWithImage>
  );
};

LayoutWithImage.defaultProps = {
  padTop: false,
};

LayoutWithImage.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  padTop: PropTypes.bool,
};

export default LayoutWithImage;
