import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  convertDecimalToPercent,
  convertPercentToDecimal,
  convertToPence,
  convertToPounds,
  keepParams,
} from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import Textarea from '../../common/Textarea';
import CurrencyInput from '../../common/CurrencyInput';
import PercentageInput from '../../common/PercentageInput';
import LenderSelect from '../../lender/LenderSelect';

const validationSchema = Yup.object().shape({
  lender: Yup.string().required('Select a lender from the list'),
  rateId: Yup.string().required('You need to enter a rate ID'),
  product: Yup.string().required('You need to enter a product name'),
  rate: Yup.string().required('You need to enter a rate'),
  ltvMin: Yup.string().required('You need to enter a min LTV'),
  ltvMax: Yup.string()
    .required('You need to enter a max LTV')
    .max(200, 'Enter value below 200%'),
  fee: Yup.string().required('You need to enter a fee'),
  loanAmountMin: Yup.string().required('You need to enter min loan amount'),
  loanAmountMax: Yup.string().required('You need to enter max loan amount'),
});

const UpdateRateForm = ({ id, initialData }) => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();

  const initialValues = {
    lender: initialData.lender_id || undefined,
    rateId: initialData.rateId || undefined,
    product: initialData.product || undefined,
    rate: initialData.rate
      ? convertDecimalToPercent(initialData.rate)
      : undefined,
    ltvMin: initialData.ltvMin
      ? convertDecimalToPercent(initialData.ltvMin)
      : undefined,
    ltvMax: initialData.ltvMax
      ? convertDecimalToPercent(initialData.ltvMax)
      : undefined,
    fee: initialData.fee ? convertToPounds(initialData.fee) : undefined,
    loanAmountMin: initialData.loanAmountMin
      ? convertToPounds(initialData.loanAmountMin)
      : undefined,
    loanAmountMax: initialData.loanAmountMax
      ? convertToPounds(initialData.loanAmountMax)
      : undefined,
    info: initialData.info || undefined,
  };

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post(`rates/update/${id}`, {
            lenderId: values.lender,
            rateId: values.rateId,
            product: values.product,
            rate: convertPercentToDecimal(values.rate),
            fee: convertToPence(values.fee),
            ltvMin: convertPercentToDecimal(values.ltvMin),
            ltvMax: convertPercentToDecimal(values.ltvMax),
            loanAmountMin: convertToPence(values.loanAmountMin),
            loanAmountMax: convertToPence(values.loanAmountMax),
            info: values.info,
          })
            .then((res) => {
              const rateId = res.data._id;
              toast.success('Rate updated');
              history.push(keepParams(`/admin/rate/${rateId}`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <LenderSelect />
                <Field
                  label="Rate ID"
                  id="rateId"
                  name="rateId"
                  placeholder="Enter a Rate ID"
                  component={TextInput}
                  isRequired
                />
                <Field
                  label="Product"
                  id="product"
                  name="product"
                  placeholder="Enter a product name"
                  component={TextInput}
                  isRequired
                />
                <Field
                  label="Min LTV"
                  id="ltvMin"
                  name="ltvMin"
                  placeholder={0}
                  component={PercentageInput}
                  isRequired
                />
                <Field
                  label="Max LTV"
                  id="ltvMax"
                  name="ltvMax"
                  placeholder={0}
                  max={200}
                  component={PercentageInput}
                  isRequired
                />
                <Field
                  label="Rate"
                  id="rate"
                  name="rate"
                  component={PercentageInput}
                  isRequired
                />
                <Field
                  label="Fee"
                  id="fee"
                  name="fee"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Min loan amount"
                  id="loanAmountMin"
                  name="loanAmountMin"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Max loan amount"
                  id="loanAmountMax"
                  name="loanAmountMax"
                  component={CurrencyInput}
                  isRequired
                />
                <Field
                  label="Info"
                  id="info"
                  name="info"
                  component={Textarea}
                />
                <ButtonGroup>
                  <Button
                    label="Update Rate"
                    type="submit"
                    mode="primary"
                    loadingLabel="Updating Rate"
                    isLoading={isSubmitting}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/rates'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

UpdateRateForm.propTypes = {
  id: PropTypes.string.isRequired,
  initialData: PropTypes.shape({
    lender_id: PropTypes.string,
    product: PropTypes.string,
    rateId: PropTypes.string,
    rate: PropTypes.number,
    fee: PropTypes.number,
    loanAmountMin: PropTypes.number,
    loanAmountMax: PropTypes.number,
    ltvMin: PropTypes.number,
    ltvMax: PropTypes.number,
    info: PropTypes.string,
    updatedAt: PropTypes.string,
    createdAt: PropTypes.string,
  }),
};

UpdateRateForm.defaultProps = {
  initialData: {},
};

export default UpdateRateForm;
