/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Styles as InputStyles } from '../Input';
import Styles from './Styles';

const RadioInput = ({
  label,
  id,
  valueLabel,
  field: { name, value, ...field }, // { name, value, onChange, onBlur }
  form: { values, touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const error = errors[name];
  const touch = touched[name];
  const checked = values[name] === valueLabel;
  return (
    <Styles.RadioInputWrapper checked={checked}>
      <Styles.RadioInput
        name={name}
        id={id}
        type="radio"
        value={valueLabel}
        checked={checked}
        {...field}
        {...props}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <Styles.RadioInputLabel htmlFor={id} error={error}>
        {label}
      </Styles.RadioInputLabel>
      {touch && error && (
        <InputStyles.InputError>{error}</InputStyles.InputError>
      )}
    </Styles.RadioInputWrapper>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  valueLabel: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types,react/require-default-props */
  field: PropTypes.object,
  form: PropTypes.object,
  /* eslint-enable react/forbid-prop-types,react/require-default-props */
};

export default RadioInput;
