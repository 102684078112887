import React from 'react';
import { useParams } from 'react-router-dom';

import Layout from '../../../components/layout/Layout';
import Retailer from '../../../components/retailer/Retailer';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const RetailerView = () => {
  const { id } = useParams();

  return (
    <AnalyticsWrapper>
      <Layout title="Retailer">
        <Retailer id={id} />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default RetailerView;
