import styled from 'styled-components';

const DashboardEligibility = styled.div`
  background: ${({ eligible, theme }) =>
    eligible ? theme.color['green-100'] : theme.color['orange-100']};
  color: ${({ eligible, theme }) =>
    eligible ? theme.color['green-800'] : theme.color['orange-900']};
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  border-radius: ${({ theme }) => theme.borderRadius[1]};
  font-size: ${({ theme }) => theme.fontSize[2]};
  text-align: center;
  align-self: flex-start;

  @media ${({ theme }) => theme.device.tablet} {
    font-size: ${({ theme }) => theme.fontSize[3]};
  }

  svg {
    margin-right: 1rem;
    color: ${({ eligible, theme }) =>
      eligible ? theme.color['green-600'] : theme.color['orange-500']};
  }
`;

export default {
  DashboardEligibility,
};
