import React from 'react';
// import PropTypes from 'prop-types';

import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { convertPercentToDecimal, keepParams } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../lib/api';

import Error from '../../common/ErrorMessage';
import Button, { ButtonGroup } from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import PercentageInput from '../../common/PercentageInput';
import CheckboxInput from '../../common/CheckboxInput';
import ImageUpload from '../../common/ImageUpload';
import Textarea from '../../common/Textarea';
import { Styles as InputStyles } from '../../common/Input';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('You need to enter a lender name'),
  image: Yup.string(),
  showRates: Yup.boolean(),
  svr: Yup.number().required('You need to enter a standard variable rate'),
  representativeExample: Yup.string(),
  productTransferNotes: Yup.string(),
  overpaymentNotes: Yup.string(),
  generalNotes: Yup.string(),
});

const CreateLenderForm = () => {
  const history = useHistory();
  const [displayError, setDisplayError] = React.useState();
  const [imageUrl, setImageUrl] = React.useState();
  const [isUploading, setIsUploading] = React.useState(false);

  return (
    <>
      <Error error={displayError} />
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          await API.post('lenders/create', {
            ...values,
            svr: values.svr ? convertPercentToDecimal(values.svr) : undefined,
            image: imageUrl,
          })
            .then((res) => {
              const lenderId = res.data._id;
              toast.success('Lender created');
              history.push(keepParams(`/admin/lender/${lenderId}`));
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          name: '',
          image: '',
          svr: '',
          showRates: false,
          representativeExample: '',
          productTransferNotes: '',
          overpaymentNotes: '',
          generalNotes: '',
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset
                disabled={isSubmitting || isUploading}
                aria-busy={isSubmitting || isUploading}
              >
                <Field
                  label="Name"
                  id="name"
                  name="name"
                  placeholder="Enter a name"
                  component={TextInput}
                  isRequired
                />
                <ImageUpload
                  label="Image"
                  imageUrl={imageUrl}
                  onUpload={() => setIsUploading(true)}
                  onUploadSuccess={(url) => setImageUrl(url)}
                  onUploadError={(error) => setDisplayError(error)}
                  onUploadComplete={() => setIsUploading(false)}
                />
                <Field
                  label="SVR"
                  id="svr"
                  name="svr"
                  component={PercentageInput}
                  isRequired
                />
                <InputStyles.InputGroup>
                  <Field
                    label="Show rates"
                    id="showRates"
                    name="showRates"
                    component={CheckboxInput}
                  />
                </InputStyles.InputGroup>

                <Field
                  label="Representative Example"
                  id="representativeExample"
                  name="representativeExample"
                  placeholder="e.g Based on a repayment mortgage of £100,000 payable over 25 years, initially on a fixed rate for 2 years of 1.69%, followed by standard variable rate of 3.59% (currently) for the remaining 23 years, would require 24 monthly payments of £408.93 and 276 monthly payments of £497.93. The total amount payable would be £147,243.00, made up of the loan amount plus interest of £47,243.00. The overall cost for comparison is 3.31% APRC representative. What you will pay may vary from this and will depend on your personal circumstances."
                  component={Textarea}
                />
                <Field
                  label="Product Transfer Notes"
                  id="productTransferNotes"
                  name="productTransferNotes"
                  placeholder=""
                  component={Textarea}
                />
                <Field
                  label="Overpayment Notes"
                  id="overpaymentNotes"
                  name="overpaymentNotes"
                  placeholder=""
                  component={Textarea}
                />
                <Field
                  label="General Notes"
                  id="generalNotes"
                  name="generalNotes"
                  placeholder=""
                  component={Textarea}
                />
                <ButtonGroup>
                  <Button
                    label="Create Lender"
                    type="submit"
                    mode="primary"
                    loadingLabel="Creating Lender"
                    isLoading={isSubmitting || isUploading}
                  />
                  <Button
                    label="Cancel"
                    onClick={() => history.push(keepParams('/admin/lenders'))}
                  />
                </ButtonGroup>
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

// CreateLenderForm.defaultProps = {
//   successAction: undefined,
//   cancelAction: undefined,
// };

// CreateLenderForm.propTypes = {
//   successAction: PropTypes.func,
//   cancelAction: PropTypes.func,
// };

export default CreateLenderForm;
