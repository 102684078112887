import styled from 'styled-components';

const Dashboard = styled.div`
  display: grid;
  padding-top: ${({ theme }) => theme.space[5]};
  grid-gap: ${({ theme }) => theme.space[6]};

  .footer {
    text-align: center;
    margin: ${({ theme }) => `${theme.space[3]} auto`};

    a {
      text-decoration: underline;

      @media ${({ theme }) => theme.device.tablet} {
        font-size: ${({ theme }) => theme.fontSize[5]};
      }
    }
  }
`;

export default {
  Dashboard,
};
