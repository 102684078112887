import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '@homamo/meadow-utils';

import HistoryHeader from '../../layout/HistoryHeader';
import RewardsTable from '../RewardsTable';

const RewardHistoryView = ({
  user,
  rewardData,
  // fetchData,
}) => {
  return (
    <div>
      <HistoryHeader
        heading="Reward History"
        description="Reward transactions are updated every 24 hours"
        blocks={[
          {
            label: 'Confirmed',
            value: formatCurrency(rewardData.totalConfirmed || 0),
          },
          {
            label: 'Pending',
            value: formatCurrency(rewardData.totalPending || 0),
          },
          {
            label: 'Paid to date',
            value: formatCurrency(rewardData.totalPaid || 0),
          },
        ]}
        subMessage="Once your Confirmed balance climbs above £50 we'll be in touch to get it paid off your mortgage"
      />
      <RewardsTable ownerId={user._id} />
    </div>
  );
};

RewardHistoryView.propTypes = {
  user: PropTypes.shape({
    _id: PropTypes.string,
    lastRewardStatementDate: PropTypes.string,
  }),
  rewardData: PropTypes.shape({
    totalRewards: PropTypes.number,
    totalPending: PropTypes.number,
    totalConfirmed: PropTypes.number,
    totalPaid: PropTypes.number,
  }),
  // fetchData: PropTypes.func,
};

RewardHistoryView.defaultProps = {
  user: {},
  rewardData: {},
  // fetchData: undefined,
};

export default RewardHistoryView;
