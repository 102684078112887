import React from 'react';
import PropTypes from 'prop-types';
import API, { handleApiError } from '../../../../../lib/api';
import Error from '../../../../common/ErrorMessage';
import Button from '../../../../common/Button';
import PageHeader from '../../../../common/PageHeader';
import LayoutWithImage from '../../../../layout/LayoutWithImage';

import relaxImg from '../../../../../images/relax.svg';

const OnboardingStart = ({
  setSelectedStep,
  displayError,
  setDisplayError,
  setMortgageData,
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <LayoutWithImage image={relaxImg}>
      <PageHeader
        heading="You’re about to accelerate to mortgage freedom"
        introduction="Before we show you how, we need to know a bit about your mortgage"
        isLarge
      />
      <p>
        If you don’t know the exact answers right now, just use your best guess
        and you can update your details later
      </p>
      <Error error={displayError} />
      <Button
        label="Get started"
        loadingLabel="Setting up account"
        isLoading={isLoading}
        mode="primary"
        onClick={async () => {
          setIsLoading(true);
          await API.post('mortgages/create')
            .then((res) => {
              setSelectedStep('lender');
              setMortgageData(res.data);
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setIsLoading(false));
        }}
      />
    </LayoutWithImage>
  );
};

OnboardingStart.propTypes = {
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
  setMortgageData: PropTypes.func.isRequired,
};

OnboardingStart.defaultProps = {
  setDisplayError: undefined,
  displayError: undefined,
};

export default OnboardingStart;
