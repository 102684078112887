import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { convertToPence, convertToPounds } from '@homamo/meadow-utils';

import API, { handleApiError } from '../../../../../lib/api';

import OnboardingViewActions from '../../../OnboardingViewActions';
import Form from '../../../../common/Form';
import CurrencyInput from '../../../../common/CurrencyInput';
import Message from '../../../../common/Message';
import LayoutWithImage from '../../../../layout/LayoutWithImage';
import Error from '../../../../common/ErrorMessage';

import checklistImg from '../../../../../images/checklist.svg';

const validationSchema = Yup.object().shape({
  initialBalance: Yup.string()
    .test({
      name: 'min',
      message: 'You need to enter a value greater than £0',
      test: (value) => {
        return convertToPence(value) >= 0;
      },
    })
    .required('You need to enter your balance'),
});

const OnboardingBalance = ({
  mortgageId,
  mortgage,
  setSelectedStep,
  setDisplayError,
  setMortgageData,
  displayError,
}) => (
  <LayoutWithImage image={checklistImg}>
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        await API.post(`mortgages/update/${mortgageId}`, {
          initialBalance: convertToPence(values.initialBalance),
        })
          .then((res) => {
            setSelectedStep('propertyValue');
            setMortgageData(res.data);
          })
          .catch((error) => {
            handleApiError({ error, setDisplayError });
          })
          .finally(() => setSubmitting(false));
      }}
      initialValues={{
        initialBalance:
          (mortgage && convertToPounds(mortgage.initialBalance)) || undefined,
      }}
      validationSchema={validationSchema}
      render={({ isSubmitting }) => {
        return (
          <Form>
            <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
              <h2>How much do you currently owe on your mortgage?</h2>
              <Field
                label="Enter your current mortgage balance"
                id="initialBalance"
                name="initialBalance"
                component={CurrencyInput}
              />
              <Message>
                If you don&apos;t know your current balance, enter your best
                estimate and we can update it later. Your lender will be able to
                tell you the exact figure.
              </Message>
              <Error error={displayError} />
              <OnboardingViewActions
                previousView="lender"
                setSelectedStep={setSelectedStep}
                isLoading={isSubmitting}
              />
            </fieldset>
          </Form>
        );
      }}
    />
  </LayoutWithImage>
);

OnboardingBalance.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    initialBalance: PropTypes.number,
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  setMortgageData: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingBalance.defaultProps = {
  displayError: undefined,
};

export default OnboardingBalance;
