import styled from 'styled-components';

const Badge = styled.span`
  font-size: ${({ size, theme }) => {
    if (size === 's') return theme.fontSize[1];
    if (size === 'l') return theme.fontSize[3];
    return theme.fontSize[2];
  }};
  padding: ${({ theme }) => `${theme.space[1]} ${theme.space[2]}`};
  min-width: ${({ theme }) => theme.space[7]};
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadius[3]};
  font-weight: 600;
  text-transform: uppercase;
  font-feature-settings: 'tnum';

  background-color: ${({ theme, mode }) => {
    if (mode === 'warning' || mode === 'waiting')
      return theme.color['yellow-300'];
    if (mode === 'success' || mode === 'positive')
      return theme.color['green-200'];
    if (mode === 'danger' || mode === 'negative') return theme.color['red-200'];
    return theme.color['gray-300'];
  }};

  color: rgba(0, 0, 0, 0.7);
  white-space: nowrap;

  svg {
    margin-right: ${({ theme }) => theme.space[1]};
  }
`;

const BadgeGroup = styled.div`
  display: flex;
  flex-direction: row;

  ${Badge} {
    margin: 0 ${({ theme }) => theme.space[0]};

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export default {
  Badge,
  BadgeGroup,
};
