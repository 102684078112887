/* eslint-disable camelcase */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  formatCurrency,
  formatDateTime,
  keepParams,
} from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Table, { TableButtonGroup } from '../../common/Table';

const formatRetailer = ({ retailer_id, retailer, type, description }) => {
  if (type === 'REFERRAL' || type === 'SWITCH') return 'Mortgage Reward';

  if (type === 'RETAILER') {
    if (retailer && retailer.heading) return retailer.heading;
    if (retailer_id) return retailer_id;
    if (description) return description;

    return <em>Awaiting retailer...</em>;
  }

  return '-';
};

const AdminRewardsTableComponent = ({
  data,
  isLoading,
  pageCount,
  fetchData,
  totalCount,
  initialSortBy,
  filterOptions,
  isCompact,
}) => {
  const columns = useMemo(
    /* eslint-disable react/prop-types */
    () => [
      {
        Header: 'Payee Email',
        accessor: ({ owner_id, owner }) => {
          if (owner_id) {
            return (
              <Link to={keepParams(`/admin/user/${owner_id}`)}>
                {(owner && owner.email) || 'User not found'}
              </Link>
            );
          }
          return (owner && owner.email) || 'User not found';
        },
        sortType: 'basic',
        id: 'owner.email',
        width: 200,
      },
      {
        Header: 'Date',
        accessor: ({ date }) => formatDateTime(date, 'date'),
        sortType: 'datetime',
        id: 'date',
        width: 180,
      },
      {
        Header: 'Retailer',
        accessor: ({ retailer_id, retailer, type, description }) => {
          if (retailer_id) {
            return (
              <Link to={keepParams(`/admin/retailer/${retailer_id}`)}>
                {formatRetailer({ retailer_id, retailer, type })}
              </Link>
            );
          }
          return formatRetailer({ retailer_id, retailer, type, description });
        },
        sortType: 'basic',
        id: 'retailer.heading',
        width: 200,
      },
      {
        Header: 'Status',
        accessor: ({ status }) => <span>{status}</span>,
        sortType: 'basic',
        id: 'status',
      },

      {
        Header: 'Amount',
        accessor: ({ amount }) => formatCurrency(amount),
        sortType: 'basic',
        id: 'amount',
      },
      {
        Header: 'Type',
        accessor: ({ type }) => <span>{type}</span>,
        sortType: 'basic',
        id: 'type',
      },
      {
        Header: 'Source',
        accessor: 'createdSource',
        sortType: 'basic',
        id: 'createdSource',
      },
      {
        Header: 'Actions',
        accessor: '_id',
        disableSortBy: true,
        width: 140,
        sticky: 'right',
        Cell: ({ cell: { value } }) => (
          <TableButtonGroup>
            <Button
              label="View"
              size="s"
              linkTo={keepParams(`/admin/reward/${value}`)}
            />
            <Button
              label="Edit"
              size="s"
              linkTo={keepParams(`/admin/reward/${value}/edit`)}
            />
          </TableButtonGroup>
        ),
      },
    ],
    [], // Second array to allow memo
    /* eslint-enable react/prop-types */
  );

  return (
    <>
      <Table
        name="admin-rewards"
        columns={columns}
        data={data}
        fetchData={fetchData}
        isLoading={isLoading}
        pageCount={pageCount}
        totalCount={totalCount}
        blankState="No rewards found"
        initialSortBy={initialSortBy}
        filterOptions={filterOptions}
        isCompact={isCompact}
        searchPlaceholder="Search for an owner ID"
        enableSearch
      />
    </>
  );
};

AdminRewardsTableComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ).isRequired,
  isCompact: PropTypes.bool,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

AdminRewardsTableComponent.defaultProps = {
  data: [],
  isLoading: false,
  pageCount: 0,
  totalCount: 0,
  isCompact: false,
  filterOptions: [],
};

export default AdminRewardsTableComponent;
