import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

import API, { handleApiError } from '../../../lib/api';
import { useAuth } from '../../../context/auth';

import Error from '../../common/ErrorMessage';
import Button from '../../common/Button';
import Form from '../../common/Form';
import TextInput from '../../common/TextInput';
import { Styles as InputStyles } from '../../common/Input';
import CheckboxInput from '../../common/CheckboxInput';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required(
    'We need a name to keep your account secure',
  ),
  lastName: Yup.string().required('We need a name to keep your account secure'),
  email: Yup.string()
    .email("Whoops! That doesn't look like a valid email")
    .required('We need an email address to keep your account secure'),
  password: Yup.string()
    .min(10, 'Your password needs to be 10 characters or more')
    .required('You need to add a password to keep your account secure'),
  termsAgreed: Yup.bool()
    .test(
      'termsAgreed',
      'You have to agree to our Terms and Conditions',
      (value) => value === true,
    )
    .required('You have to agree to our Terms and Conditions'),
});

const SignUpForm = ({ referredByUserId }) => {
  const [displayError, setDisplayError] = React.useState();
  const { onSignIn } = useAuth();

  return (
    <>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          const referredByPartner = Cookies.get('amm_partner_referrer');

          await API.post('auth/sign-up', {
            email: values.email,
            password: values.password,
            profile: {
              firstName: values.firstName,
              lastName: values.lastName,
            },
            termsAgreed: values.termsAgreed,
            marketingConsent: values.marketingConsent,
            referredByUserId,
            referredByPartner,
          })
            .then((res) => {
              toast.success('Signed up!');
              onSignIn({ isAuthenticated: true, user: res.data.user });
            })
            .catch((error) => {
              handleApiError({ error, setDisplayError });
            })
            .finally(() => setSubmitting(false));
        }}
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          termsAgreed: false,
          marketingConsent: false,
        }}
        validationSchema={validationSchema}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
                <Field
                  label="First name"
                  id="firstName"
                  name="firstName"
                  placeholder="Your first name"
                  component={TextInput}
                  autoComplete="given-name"
                  isRequired
                />
                <Field
                  label="Last name"
                  id="lastName"
                  name="lastName"
                  placeholder="Your last name"
                  component={TextInput}
                  autoComplete="family-name"
                  isRequired
                />
                <Field
                  label="Email address"
                  id="email"
                  type="email"
                  name="email"
                  placeholder="Your email address"
                  component={TextInput}
                  autoComplete="email"
                  isRequired
                />
                <Field
                  label="Password"
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Choose a password"
                  component={TextInput}
                  helpMessage="Your password must be at least 10 characters"
                  autoComplete="current-password"
                  isRequired
                />
                <InputStyles.InputGroup>
                  <Field
                    label="I agree to the Terms &amp; Conditions"
                    id="termsAgreed"
                    name="termsAgreed"
                    component={CheckboxInput}
                    isRequired
                  />
                  <Field
                    label="I also want to be alerted to information from third parties about rewards, money saving ideas and anything else that will help me pay my mortgage off faster"
                    id="marketingConsent"
                    name="marketingConsent"
                    component={CheckboxInput}
                  />
                </InputStyles.InputGroup>
                <Error error={displayError} />
                <Button
                  label="Sign up"
                  mode="primary"
                  type="submit"
                  block
                  loadingLabel="Creating your account"
                  isLoading={isSubmitting}
                />
              </fieldset>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

SignUpForm.propTypes = {
  referredByUserId: PropTypes.string,
};

SignUpForm.defaultProps = {
  referredByUserId: undefined,
};

export default SignUpForm;
