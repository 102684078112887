import styled from 'styled-components';
import { Styles as PanelStyles } from '../../common/Panel';
import { Styles as BadgeStyles } from '../../common/Badge';
import { Styles as ButtonStyles } from '../../common/Button';

const RetailersListContainer = styled.div`
  .filter {
    display: grid;
    grid-gap: ${({ theme }) => theme.space[1]};
    grid-auto-flow: row;
    grid-auto-rows: auto;
    width: 100%;
    margin: ${({ theme }) => theme.space[2]} 0;
    grid-auto-columns: 1fr;

    @media ${({ theme }) => theme.device.tablet} {
      width: auto;
      grid-auto-flow: ${({ vertical }) => (vertical ? 'row' : 'column')};
      grid-auto-columns: auto;
      justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
    }

    ${ButtonStyles.Button} {
      font-size: ${({ theme }) => theme.fontSize[1]};
      width: 100%;
    }
  }
`;

const RetailersListSearch = styled.div`
  max-width: ${({ theme }) => theme.space[16]};
  margin: ${({ theme }) => `0 auto ${theme.space[6]}`};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RetailersListItem = styled.div`
  text-align: center;

  ${BadgeStyles.Badge} {
    font-size: ${({ theme }) => theme.fontSize[0]};
  }

  ${BadgeStyles.BadgeGroup} {
    margin-bottom: ${({ theme }) => theme.space[2]};
  }

  .mute {
    color: ${({ theme }) => theme.color['gray-600']};
  }

  ${PanelStyles.Panel} {
    .body {
      padding: 2rem;
    }

    .inner {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      p {
        max-width: 100%;
      }
    }

    img {
      width: 100%;
      max-width: 200px;
      height: 180px;
      object-fit: contain;
    }
  }
`;

const RetailersList = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 6rem;

  ${RetailersListItem} {
    margin: 1rem 0;
    flex: 0 1 100%;

    @media ${({ theme }) => theme.device.tablet} {
      margin: 1rem;
      flex: 0 1 calc(50% - 2rem);
    }

    @media ${({ theme }) => theme.device.laptop} {
      flex: 0 1 calc(25% - 2rem);
    }
  }
`;

export default {
  RetailersListItem,
  RetailersList,
  RetailersListContainer,
  RetailersListSearch,
};
