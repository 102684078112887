import React from 'react';
import Layout from '../../../components/layout/Layout';
import AdminMortgages from '../../../components/mortgage/AdminMortgages';
import AnalyticsWrapper from '../../../components/common/AnalyticsWrapper';

const AdminMortgagesView = () => {
  return (
    <AnalyticsWrapper>
      <Layout title="Mortgages">
        <AdminMortgages />
      </Layout>
    </AnalyticsWrapper>
  );
};

export default AdminMortgagesView;
