/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import API, { handleApiError } from '../../../lib/api';
import LenderSelectComponent from './Component';

function LenderSelectContainer({ setErrors, ...props }) {
  const [lenders, setLenders] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchData = async () => {
    await API.get(`lenders`)
      .then((response) => setLenders(response.data.data))
      .catch((error) => {
        handleApiError({ error, setErrors });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LenderSelectComponent lenders={lenders} isLoading={isLoading} {...props} />
  );
}

LenderSelectContainer.propTypes = {
  setErrors: PropTypes.func,
};

LenderSelectContainer.defaultProps = {
  setErrors: undefined,
};

export default LenderSelectContainer;
