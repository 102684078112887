import React from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';

import { useAuth } from '../../../context/auth';

import LayoutWithImage from '../../layout/LayoutWithImage/Component';

import ResetPasswordForm from './Form';
import ErrorMessage from '../../common/ErrorMessage';

const ResetPasswordView = () => {
  const query = new URLSearchParams(useLocation().search);
  const resetToken = query.get('token');

  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <LayoutWithImage>
      <h1>Reset your password</h1>
      <p>Enter a new password below</p>
      {resetToken ? (
        <ResetPasswordForm resetToken={resetToken} />
      ) : (
        <>
          <ErrorMessage error={{ message: 'No reset token' }} />
          <p>
            You need to request a personal reset token using your email.{' '}
            <Link to="request-reset-password">
              Request a reset password link here
            </Link>
          </p>
        </>
      )}
    </LayoutWithImage>
  );
};

export default ResetPasswordView;
