import styled from 'styled-components';
import { Styles as ButtonStyles } from '../../common/Button';
import { Styles as PanelStyles } from '../../common/Panel';

const RateswitchBenefits = styled.div`
  img {
    max-width: 150px;
    width: 100%;
    margin: 0 auto;
    display: block;

    @media ${({ theme }) => theme.device.tablet} {
      max-width: ${({ theme }) => theme.space[11]};
      margin: 0 3rem 0 0;
    }
  }

  .inner {
    max-width: 650px;
  }

  ul {
    padding-left: 2rem;
  }

  ${ButtonStyles.Button} {
    margin-bottom: 0.5rem;
  }

  @media ${({ theme }) => theme.device.tablet} {
    ${PanelStyles.Panel} {
      .body {
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
          margin-top: 0;
        }

        li {
          margin: 1.6rem 0;
          font-size: ${({ theme }) => theme.fontSize[3]};
          line-height: 1.4;
          max-width: 400px;
        }
      }
    }

    img {
      margin: 0 3rem 0 0;
    }
  }
`;

export default {
  RateswitchBenefits,
};
