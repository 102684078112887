import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  formatCurrency,
  formatDateTime,
  formatPercentage,
  formatUserName,
  keepParams,
} from '@homamo/meadow-utils';

import Button from '../../common/Button';
import Icon from '../../common/Icon';
import Table, { TableButtonGroup } from '../../common/Table';
import AdjustBalanceModal from '../AdjustBalance';

const AdminMortgagesTableComponent = ({
  data,
  isLoading,
  pageCount,
  fetchData,
  totalCount,
  initialSortBy,
  filterOptions,
  isCompact,
}) => {
  const columns = useMemo(
    /* eslint-disable react/prop-types */
    () => [
      {
        Header: 'Owner',
        accessor: ({ owner }) => {
          if (owner && owner.profile) {
            return (
              <Link to={keepParams(`/admin/user/${owner._id}`)}>
                {formatUserName(owner)}
              </Link>
            );
          }
          if (owner && owner.email) {
            return owner.email;
          }
          return <em>User deleted</em>;
        },
        sortType: 'basic',
        id: 'owner.profile.firstName',
        disableSortBy: true,
        minWidth: 180,
      },
      {
        Header: 'Lender',
        accessor: ({ lender }) => {
          if (lender && lender.name) {
            return (
              <Link to={keepParams(`/admin/lender/${lender._id}`)}>
                {lender.name}
              </Link>
            );
          }
          return <em>Lender deleted</em>;
        },
        sortType: 'basic',
        id: 'lender.name',
      },
      {
        Header: 'R.Balance',
        accessor: ({ remainingBalance }) => formatCurrency(remainingBalance),
        sortType: 'basic',
        id: 'remainingBalance',
      },
      {
        Header: 'Rate',
        accessor: ({ interestRate }) => formatPercentage(interestRate, 2),
        sortType: 'basic',
        id: 'interestRate',
        width: 80,
      },
      {
        Header: 'In Progress',
        accessor: ({ switchInProgress }) =>
          switchInProgress ? (
            <Icon icon="check-circle" color="green-600" />
          ) : (
            ''
          ),
        sortType: 'basic',
        id: 'switchInProgress',
        // width: 80,
      },

      {
        Header: 'Fixed Rate',
        accessor: ({ fixedRate }) => fixedRate,
        sortType: 'basic',
        id: 'fixedRate',
        minWidth: 80,
      },
      {
        Header: 'Fixed Rate End',
        accessor: ({ fixedRateEndDate }) =>
          formatDateTime(fixedRateEndDate, 'monthYear'),
        sortType: 'basic',
        id: 'fixedRateEndDate',
        width: 180,
      },
      {
        Header: 'M.Payment',
        accessor: ({ monthlyPayment }) => formatCurrency(monthlyPayment),
        sortType: 'basic',
        id: 'monthlyPayment',
      },
      {
        Header: 'M.Overpayment',
        accessor: ({ monthlyOverpayment }) =>
          formatCurrency(monthlyOverpayment),
        sortType: 'basic',
        id: 'monthlyOverpayment',
        width: 180,
      },
      {
        Header: 'Created',
        accessor: ({ createdAt }) => formatDateTime(createdAt),
        sortType: 'datetime',
        id: 'createdAt',
        width: 180,
      },
      {
        Header: 'Updated',
        accessor: ({ updatedAt }) => formatDateTime(updatedAt),
        sortType: 'datetime',
        id: 'updatedAt',
        width: 180,
      },
      {
        Header: 'Actions',
        accessor: '_id',
        disableSortBy: true,
        width: 200,
        sticky: 'right',
        Cell: ({ cell: { value }, row: { original } }) => (
          <TableButtonGroup>
            <Button
              label="View"
              size="s"
              linkTo={keepParams(`/admin/mortgage/${value}`)}
            />
            <Button
              label="Edit"
              size="s"
              linkTo={keepParams(`/admin/mortgage/${value}/edit`)}
            />
            <AdjustBalanceModal
              buttonLabel="Adjust"
              buttonSize="s"
              mortgageId={value}
              remainingBalance={original.remainingBalance}
              successAction={() => {
                if (fetchData) fetchData({});
              }}
              mode="admin"
            />
          </TableButtonGroup>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [], // Second array to allow memo
    /* eslint-enable react/prop-types */
  );

  return (
    <>
      <Table
        name="admin-mortgages"
        columns={columns}
        data={data}
        fetchData={fetchData}
        isLoading={isLoading}
        pageCount={pageCount}
        totalCount={totalCount}
        blankState="No mortgages found"
        initialSortBy={initialSortBy}
        searchPlaceholder="Search for an owner ID"
        filterOptions={filterOptions}
        isCompact={isCompact}
        enableSearch
      />
    </>
  );
};

AdminMortgagesTableComponent.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
    }),
  ),
  isLoading: PropTypes.bool,
  pageCount: PropTypes.number,
  totalCount: PropTypes.number,
  fetchData: PropTypes.func.isRequired,
  initialSortBy: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      desc: PropTypes.bool,
    }),
  ).isRequired,
  isCompact: PropTypes.bool,
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
};

AdminMortgagesTableComponent.defaultProps = {
  data: [],
  isLoading: false,
  pageCount: 0,
  totalCount: 0,
  isCompact: false,
  filterOptions: [],
};

export default AdminMortgagesTableComponent;
