import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import API, { handleApiError } from '../../../../../lib/api';

import OnboardingViewActions from '../../../OnboardingViewActions';
import Form from '../../../../common/Form';
import LenderSelect from '../../../../lender/LenderSelect';
import Message from '../../../../common/Message';
import Error from '../../../../common/ErrorMessage';

import LayoutWithImage from '../../../../layout/LayoutWithImage';
import checklistImg from '../../../../../images/checklist.svg';

const validationSchema = Yup.object().shape({
  lender: Yup.string().required(
    "Select your lender from the list or choose 'other'",
  ),
});

const OnboardingLender = ({
  mortgageId,
  mortgage,
  setSelectedStep,
  setDisplayError,
  setMortgageData,
  displayError,
}) => (
  <LayoutWithImage image={checklistImg}>
    <Formik
      onSubmit={async (values, { setSubmitting }) => {
        await API.post(`mortgages/update/${mortgageId}`, {
          lender_id: values.lender,
        })
          .then((res) => {
            setSelectedStep('balance');
            setMortgageData(res.data);
          })
          .catch((error) => {
            handleApiError({ error, setDisplayError });
          })
          .finally(() => setSubmitting(false));
      }}
      initialValues={{
        lender: (mortgage && mortgage.lender_id) || undefined,
      }}
      validationSchema={validationSchema}
      render={({ isSubmitting }) => {
        return (
          <Form>
            <fieldset disabled={isSubmitting} aria-busy={isSubmitting}>
              <h2>Who is your mortgage with?</h2>
              <LenderSelect />
              <Message>
                If your lender isn&apos;t listed select &lsquo;Other
                lender&rsquo;
              </Message>
              <Error error={displayError} />
              <OnboardingViewActions
                previousView="start"
                isSubmitting={isSubmitting}
                setSelectedStep={setSelectedStep}
              />
            </fieldset>
          </Form>
        );
      }}
    />
  </LayoutWithImage>
);

OnboardingLender.propTypes = {
  mortgageId: PropTypes.string.isRequired,
  mortgage: PropTypes.shape({
    lender_id: PropTypes.string,
  }).isRequired,
  setSelectedStep: PropTypes.func.isRequired,
  setDisplayError: PropTypes.func.isRequired,
  setMortgageData: PropTypes.func.isRequired,
  displayError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

OnboardingLender.defaultProps = {
  displayError: undefined,
};

export default OnboardingLender;
